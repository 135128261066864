import {Navigate} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import {useAppSelector} from "../app/hooks";


export default function RequireAuth({ children } : any) {
    const location = useLocation();

    const loggedIn = useAppSelector(state => state.userReducer.loggedIn)

    return loggedIn === true ? children : (<Navigate to="/login" replace state={{ path: location.pathname }} />);
}