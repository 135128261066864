import React from "react";
import LeftSideBar from "../../SideBars/LeftSideBar";
import {nanoid} from "@reduxjs/toolkit";
import Accordion, {Description, Title} from "../Accordion";
import Moment from "react-moment";
import {revokeToken} from "../../../app/features/user/actions/revokeToken";
import {toast} from "react-toastify";


function Wallet() {
    const [open, setOpen] = React.useState(true);

    const fakeNotification = () => {
        toast("You will be notified when the wallet system is active.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    return (
        <>
            <div className="flex flex-col lg:mx-20 md:mt-20">
                <div className="text-center my-8 font-normal text-lg">Wallet</div>
                <div className="flex lg:space-x-5 mx-4">
                    <LeftSideBar key={nanoid()} setOpen={setOpen} open={open}/>

                    <div className={`w-full flex flex-col `}>
                        <div className="w-full space-y-10 py-16 flex flex-col place-items-center text-center bg-white rounded-20 myaccountcoming-block">
                            <div>
                                <img className="mx-auto mb-4 w-[50px] h-[50px]" src="/comingsoonimage.png" alt=""/>
                                <span className="   animate-text text-xl ">Coming Soon</span>
                            </div>
                            <div className="flex-col space-y-4">
                                <span className="text-xs">Get notified when the option becomes available</span>
                                <button
                                    onClick={fakeNotification}
                                    className="mx-auto flex items-center justify-center gap-6py-2 w-fit px-20 gap-4 bg-black hover:bg-brown text-white rounded-full">
                                    <p className="text-xs">Notify me</p>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row bg-white checkout-block  text-center p-1 lg:p-0 rounded-20 hidden">
                            <div className="basis-1/3 self-center  lg:py-16 py-8 ">
                                <button
                                    className="bg-brown  inline-block relative hover:bg-brown items-start  py-3.5  px-7 md:py-2 lg:py-3.5 rounded-100 lg:px-16  text-white ">
                                    <p className="lg:text-sm ml-1  text-xs">Top up </p>
                                    <svg className="absolute lg:top-4 top-3.5 md:top-1.5 left-2  lg:left-6" width="18"
                                         height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 7.9145L6.70764 9.20747L6.70755 9.20755C6.5199 9.39521 6.26538 9.50063 6 9.50063C5.73462 9.50063 5.4801 9.39521 5.29245 9.20755C5.10479 9.0199 4.99937 8.76538 4.99937 8.5C4.99937 8.23462 5.10479 7.9801 5.29245 7.79245L8.292 4.79289L8 7.9145ZM8 7.9145L8 12.5C8 12.7652 8.10536 13.0196 8.29289 13.2071C8.48043 13.3946 8.73478 13.5 9 13.5C9.26522 13.5 9.51957 13.3946 9.70711 13.2071C9.89464 13.0196 10 12.7652 10 12.5L10 7.9145L11.2924 9.20747L11.2924 9.20755C11.4801 9.39521 11.7346 9.50063 12 9.50063C12.2654 9.50063 12.5199 9.39521 12.7076 9.20755C12.8952 9.0199 13.0006 8.76538 13.0006 8.5C13.0006 8.23462 12.8952 7.9801 12.7076 7.79245L9.708 4.79289C9.70793 4.79282 9.70786 4.79275 9.70778 4.79268C9.61494 4.69965 9.50466 4.62584 9.38327 4.57547C9.26178 4.52506 9.13153 4.49911 9 4.49911C8.86846 4.49911 8.73822 4.52506 8.61673 4.57547C8.49544 4.6258 8.38525 4.69953 8.29245 4.79245L8 7.9145ZM13.5962 4.40381C14.8152 5.62279 15.5 7.27609 15.5 9C15.5 10.7239 14.8152 12.3772 13.5962 13.5962C12.3772 14.8152 10.7239 15.5 9 15.5C7.27609 15.5 5.62279 14.8152 4.40381 13.5962C3.18482 12.3772 2.5 10.7239 2.5 9C2.5 7.27609 3.18482 5.62279 4.40381 4.40381C5.62279 3.18482 7.27609 2.5 9 2.5C10.7239 2.5 12.3772 3.18482 13.5962 4.40381ZM2.98959 2.98959C1.39553 4.58365 0.499999 6.74566 0.499999 9C0.499999 11.2543 1.39553 13.4163 2.98959 15.0104C4.58365 16.6045 6.74566 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 6.74566 16.6045 4.58365 15.0104 2.98959C13.4163 1.39553 11.2543 0.500001 9 0.500001C6.74566 0.500001 4.58365 1.39553 2.98959 2.98959Z"
                                            fill="white" stroke="#B59679"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="basis-1/3 self-center lg:space-y-4">
                                <p className="lg:text-3xl text-xs font-medium">€ 12.500,00</p>
                                <p className="lg:text-lg text-xs font-thin ">Available balance</p>
                            </div>
                            <div className="basis-1/3 self-center">
                                <button
                                    className="bg-black  inline-block relative hover:bg-brown items-start  py-3.5  px-7 md:py-2 lg:py-3.5 rounded-100 lg:px-14  text-white ">
                                    <p className="lg:text-sm ml-1  text-xs">Withdraw </p>
                                    <svg className="absolute lg:top-4 top-3.5 md:top-1.5 left-2  lg:left-6" width="18"
                                         height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M0.999999 8C0.999998 9.85652 1.7375 11.637 3.05025 12.9497C4.363 14.2625 6.14348 15 8 15C9.85651 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14349 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85651 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 0.999999 6.14348 0.999999 8ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84343 15.1571 2.34314 13.6569C0.842853 12.1566 -1.76974e-06 10.1217 -1.39876e-06 8C-1.02779e-06 5.87827 0.842854 3.84344 2.34315 2.34315C3.84344 0.842856 5.87827 1.02779e-06 8 1.39876e-06C10.1217 1.76974e-06 12.1566 0.842857 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8.5 4.5C8.5 4.36739 8.44732 4.24022 8.35355 4.14645C8.25978 4.05268 8.13261 4 8 4C7.86739 4 7.74021 4.05268 7.64645 4.14645C7.55268 4.24022 7.5 4.36739 7.5 4.5L7.5 10.293L5.354 8.146C5.26011 8.05211 5.13277 7.99937 5 7.99937C4.86722 7.99937 4.73988 8.05211 4.646 8.146C4.55211 8.23989 4.49937 8.36723 4.49937 8.5C4.49937 8.63278 4.55211 8.76011 4.646 8.854L7.646 11.854C7.69244 11.9006 7.74762 11.9375 7.80836 11.9627C7.86911 11.9879 7.93423 12.0009 8 12.0009C8.06577 12.0009 8.13089 11.9879 8.19163 11.9627C8.25238 11.9375 8.30755 11.9006 8.354 11.854L11.354 8.854C11.4479 8.76012 11.5006 8.63278 11.5006 8.5C11.5006 8.36723 11.4479 8.23989 11.354 8.146C11.2601 8.05212 11.1328 7.99937 11 7.99937C10.8672 7.99937 10.7399 8.05212 10.646 8.146L8.5 10.293L8.5 4.5Z"
                                              fill="white"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row py-3 hidden ">
                            <div className="basis-1/2 self-center ">
                                <div className=" flex flex-row text-xs space-x-3">
                                    <div>
                                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.5556 1V4.55556M5.44444 1V4.55556M1 8.11111H17M2.77778 2.77778H15.2222C16.2041 2.77778 17 3.57372 17 4.55556V17C17 17.9818 16.2041 18.7778 15.2222 18.7778H2.77778C1.79594 18.7778 1 17.9818 1 17V4.55556C1 3.57372 1.79594 2.77778 2.77778 2.77778Z"
                                                stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="self-center"><h6>
                                        Last 6 months activity
                                    </h6></div>
                                </div>
                            </div>
                            <div className="basis-1/2 text-end">
                                <button className="bg-white text-xs rounded-full py-1 px-5 shadow">
                                    Export founded results
                                    <svg
                                        className="my-auto ml-3 inline -mt-1"
                                        width="8"
                                        height="5"
                                        viewBox="0 0 8 5"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1.34042L4 4.34042L7 1.34042"
                                            stroke="#202020"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>

                        </div>
                        <Accordion
                            buttonPosition={"right-14 lg:right-20"}
                            className="bg-white rounded-20 hidden">
                            <Title
                                className=" bg-white  rounded-20 py-3  shadow">
                                <div className="flex md:px-10 px-2">
                                    <div
                                        className="flex sm:flex-row flex-col justify-around  basis-4/5 flex-wrap px-5 sm:px-0 ">
                                        <div className="flex flex-row  sm:flex-col ">
                                            <p className="text-base">Order 9843703</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                                <Moment format="DD MMMM YYYY"></Moment>
                                            </p>
                                        </div>
                                        <div className="flex flex-row sm:flex-col ">
                                            <p className="text-base">Total used amount</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                              -  € 250.500
                                            </p>
                                        </div>
                                        <div className="flex flex-row sm:flex-col ">
                                            <p className="text-xs">Balance</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                                € 12.500,00
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" sm:ml-auto self-center">
                                        <button
                                            className="w-11 h-11 group hover:bg-brown grid items-center justify-items-center border rounded-full">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.0625 11.3721L12.6231 9.81078L12.6232 9.81069C12.8226 9.61131 13.093 9.49929 13.375 9.49929C13.657 9.49929 13.9274 9.61131 14.1268 9.81069L13.7733 10.1642L14.1268 9.8107C14.3262 10.0101 14.4382 10.2805 14.4382 10.5625C14.4382 10.8445 14.3262 11.1149 14.1268 11.3143L10.7522 14.6889L11.0625 11.3721ZM11.0625 11.3721V6.0625C11.0625 5.78071 10.9506 5.51046 10.7513 5.3112C10.552 5.11194 10.2818 5 10 5C9.71821 5 9.44796 5.11194 9.2487 5.3112C9.04944 5.51046 8.9375 5.78071 8.9375 6.0625V11.3721L7.37689 9.81078L7.3768 9.8107C7.17741 9.6113 6.90698 9.49929 6.625 9.49929C6.34302 9.49929 6.07259 9.6113 5.8732 9.8107L6.22675 10.1642L5.87319 9.8107C5.67381 10.0101 5.56179 10.2805 5.56179 10.5625C5.56179 10.8445 5.67381 11.1149 5.87319 11.3143L6.22675 10.9608L5.8732 11.3143L9.24775 14.6889L11.0625 11.3721ZM9.78441 14.4581L9.59278 14.9199C9.46389 14.8664 9.3468 14.788 9.2482 14.6893L9.78441 14.4581ZM9.78441 14.4581L9.59278 14.9199C9.72186 14.9734 9.86024 15.001 10 15.001C10.1398 15.001 10.2781 14.9734 10.4072 14.9199L10.2156 14.4581M9.78441 14.4581H10.2156M10.2156 14.4581L10.4072 14.9199C10.5361 14.8664 10.6532 14.788 10.7518 14.6893L10.2156 14.4581ZM4.78509 15.2149C3.40201 13.8318 2.625 11.956 2.625 10C2.625 8.04403 3.40201 6.16817 4.78509 4.78509C6.16817 3.40201 8.04403 2.625 10 2.625C11.956 2.625 13.8318 3.40201 15.2149 4.78509C16.598 6.16817 17.375 8.04403 17.375 10C17.375 11.956 16.598 13.8318 15.2149 15.2149C13.8318 16.598 11.956 17.375 10 17.375C8.04403 17.375 6.16817 16.598 4.78509 15.2149ZM16.7175 16.7175C18.4991 14.9359 19.5 12.5196 19.5 10C19.5 7.48044 18.4991 5.06408 16.7175 3.28249C14.9359 1.50089 12.5196 0.5 10 0.5C7.48044 0.5 5.06408 1.50089 3.28249 3.28249C1.50089 5.06408 0.5 7.48044 0.5 10C0.5 12.5196 1.50089 14.9359 3.28249 16.7175C5.06408 18.4991 7.48044 19.5 10 19.5C12.5196 19.5 14.9359 18.4991 16.7175 16.7175Z"
                                                    fill="#202020" stroke="white"/>
                                            </svg>

                                        </button>
                                    </div>
                                </div>
                            </Title>
                            <Description className="py-4  ">
                                <div className="flex md:px-10 gap-4 px-2">
                                    <div
                                        className="flex sm:flex-row flex-col justify-around  basis-4/5 flex-wrap px-5 sm:px-0 ">
                                        <div className="flex flex-row  sm:flex-col ">
                                            <p className="text-xs">Transaction type</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                                Bank transfer
                                            </p>
                                        </div>
                                        <div className="flex flex-row sm:flex-col lg:ml-10 ">
                                            <p className="text-xs">Balance before  credit</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                                 € 250.500
                                            </p>
                                        </div>
                                        <div className="flex flex-row sm:flex-col ">
                                            <p className="text-xs ">Added balance</p>
                                            <p className="text-base font-medium ml-auto sm:ml-0">
                                                + € 200,00
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row sm:flex-col justify-self-center">
                                        <p className="text-xs">Total balance</p>
                                        <p className="text-base font-medium ml-auto sm:ml-0">
                                            € 12.863,25
                                        </p>
                                    </div>
                                </div>


                            </Description>

                        </Accordion>

                    </div>


                </div>

            </div>

        </>
    )
}

export default Wallet