import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const signUpUser = createAsyncThunk(
    "user/signUpUser",
    async (args: any,thunkAPI) => {
       
        try {

            const response = await axios.post(
                "https://www.stockperfume.com/wp/wp?rest_route=/simple-jwt-login/v1/users&AUTH_KEY=tCV!^654i8IcMB^" , args
            );
       
               
            return response.data;
        } catch (error: any) {
  
            return thunkAPI.rejectWithValue({ error: error.response });
        }
    });