import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const validateVAT = createAsyncThunk(
  "user/validateVAT",
  async (args: any, thunkAPI) => {
    // this route for validate vat but user is not logged in. this route point to snippet rest api (snippet name = VIES VAT CHECK)
    // GR ile başlıyorsa başını EL ile değiştir
    const vatNumber = args.vat_number.startsWith("GR")
      ? "EL" + args.vat_number.slice(2)
      : args.vat_number;
    try {
      const response = await axios.post(
        `https://www.stockperfume.com/wp/wp-json/wp/v3/vat_check`,
        {
          vat_number: vatNumber,
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
