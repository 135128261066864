import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const getCargoCompanies = createAsyncThunk(
    "cart/getCargoCompanies",
    async (args: any,thunkAPI) => {
        try {
            const response = await axios.post(
                `https://stockperfume.com/wp/wp-json/sendcloud/v1/shipping_methods`
            ,args);
                
            return response.data.data;
        } catch (error:any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });