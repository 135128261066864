import { createSlice } from "@reduxjs/toolkit";
import {
  Color,
  Brand,
  Memory,
  Price,
  Sort,
  Category,
  Order,
  Orderby,
  Gender,
} from "../filterTypes";
import { getColorFilters } from "../actions/getColorFilters";
import { getCategoryFilters } from "../actions/getCategoryFilters";
import { getBrandFilters } from "../actions/getBrandFilters";
import { getPriceFilters } from "../actions/getPriceFilters";
import { getMemoryFilters } from "../actions/getMemoryFilters";
import { getBrandsWithLetter } from "../actions/getBrandsWithLetter";
import Cookies from "js-cookie";
import { getGenderFilters } from "../actions/getGenderFilters";
type Nullable<T> = T | null;

type InitialState = {
  color_list: Color[];
  brand_list: Brand[];
  gender_list: Gender[];
  alphabetical_brand_list: Object;
  memory_list: Memory[];
  category_list: Category[];

  order_list: Order[];
  orderby_list: Orderby[];
  priceFilter: Price;
  selectedFilter: {
    category: Nullable<Category>;
    pa_brand: Nullable<Brand>;
    pa_color: Nullable<Color>;
    pa_gender: Nullable<Gender>;
    max_price: Nullable<number>;
    min_price: Nullable<number>;
    pa_size: Nullable<Memory>;
    order: Nullable<string>;
    orderby: Nullable<string>;
    per_page: number;
  };
  isSaved: boolean;
};

const initialState: InitialState = {
  color_list: [],
  brand_list: [],
  gender_list: [],
  memory_list: [],
  category_list: [],
  alphabetical_brand_list: {},
  order_list: [
    {
      id: "price",
      name: "Price",
    },
    {
      id: "popularity",
      name: "Popularity",
    },
    {
      id: "data",
      name: "New Arrrivals",
    },
  ],
  orderby_list: [
    {
      id: "ASC",
      name: "Ascending",
    },
    {
      id: "DESC",
      name: "Descending",
    },
  ],
  priceFilter: { min: 0, max: 9999999 },
  selectedFilter: Cookies.get("sp-filters")
    ? JSON.parse(Cookies.get("sp-filters"))
    : {
        category: null,
        pa_brand: null,
        pa_gender: null,
        pa_color: null,
        min_price: null,
        max_price: null,
        pa_size: null,
        order: null,
        orderby: null,
        per_page: 25,
      },
  isSaved: Cookies.get("sp-isSaved") === "true" ? true : false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    updateFilter: (state: any, action) => {
      state.selectedFilter[action.payload.filterType] = action.payload.value;
    },
    resetFilters: (state: any) => {
      state.selectedFilter = {
        category: null,
        pa_brand: null,
        pa_color: null,
        pa_gender: null,
        pa_stock: null,
        pa_size: null,
        min_price: null,
        max_price: null,
        order: null,
        orderby: null,
        per_page: 25,
      };

      state.isSaved = false;
      Cookies.set("sp-filters", "", { expires: -1 });
      const currentPath = window.location.pathname;
      const basePath = currentPath.split("/shop")[0]; // 'shop' kelimesinden önceki kısmı alıyoruz
      window.history.pushState({}, "", basePath + "/shop");
    },
    setIsSaved: (state: any, action) => {
      state.isSaved = action.payload;

      if (state.isSaved === false) {
        Cookies.set("sp-filters", "", { expires: -1 });
      } else {
        Cookies.set("sp-filters", JSON.stringify({ ...state.selectedFilter }), {
          expires: 7,
        });
      }
      Cookies.set("sp-isSaved", JSON.stringify(state.isSaved), { expires: 7 });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getColorFilters.fulfilled, (state, action) => {
      state.color_list = action.payload.filter(
        (color: Color) => color.id !== null
      );
    });
    builder.addCase(getBrandFilters.fulfilled, (state, action) => {
      state.brand_list = action.payload;
    });
    builder.addCase(getCategoryFilters.fulfilled, (state, action) => {
      state.category_list = action.payload;
    });
    builder.addCase(getPriceFilters.fulfilled, (state, action) => {
      state.priceFilter = action.payload;
    });
    builder.addCase(getGenderFilters.fulfilled, (state, action) => {
      state.gender_list = action.payload;
    });
    builder.addCase(getMemoryFilters.fulfilled, (state, action) => {
      state.memory_list = action.payload.filter(
        (memory: Memory) => memory.id !== null
      );
    });
    builder.addCase(getBrandsWithLetter.fulfilled, (state, action) => {
      state.alphabetical_brand_list = action.payload;
    });
  },
});
export const { updateFilter, resetFilters, setIsSaved } = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
