import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

function ProductDataTabs({ product }: any) {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Tabs
      className="w-[95%] lg:w-[87%] mx-auto bg-white rounded-20 lg:px-36 px-5 py-4 my-4 shadow"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <TabList className="border-b space-x-6 mb-4 mt-4">
        <Tab
          className="inline-block outline-0 pb-2 hover:cursor-pointer"
          selectedClassName="text-black border-b-[3px] font-[600] border-black"
        >
          Description
        </Tab>
        <Tab
          className="inline-block outline-0 pb-2 hover:cursor-pointer"
          selectedClassName="text-black border-b-[3px] font-[600] border-black"
        >
          Specification
        </Tab>
      </TabList>
      <TabPanel>
        <div
          className="text-xs lg:text-base py-10 "
          dangerouslySetInnerHTML={{
            __html: product?.description || "No description data",
          }}
        />
      </TabPanel>
      <TabPanel>
        <ul className="text-sm py-8">
          <li className="text-start">
            Brand:{" "}
            <span className="font-semibold">
              {product?.brand?.name || " No brand data"}
            </span>
          </li>

          <li className="text-start">
            EAN:{" "}
            <span className="font-semibold">{product?.ean || " No ean"}</span>
          </li>
          <li className="text-start">
            Gender:{" "}
            <span className="font-semibold">
              {product?.gender || " No gender data"}
            </span>
          </li>
          <li className="text-start">
            Size:{" "}
            <span className="font-semibold">
              {product?.size?.name || " No size data"}
            </span>
          </li>
          <li className="text-start">
            Processing time: <span className="font-semibold">5 days</span>
          </li>
        </ul>
      </TabPanel>
    </Tabs>
  );
}

export default ProductDataTabs;
