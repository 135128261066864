import React from "react";
import { motion } from "framer-motion";
import { info } from "../../constants/websiteInfo";

function TermsConditions() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div>
        <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] lg:-mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
          <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-2 col-end-13 self-center lg:mx-10">
            <p className="lg:text-2xl lg:text-2xl text-black font-medium tracking-normal">
              Terms & Conditions
            </p>
          </div>
        </div>

        <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 mt-3 lg:-mt-16">
          <div className="basis-1/1 lg:px-44 px-10">
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                INTRODUCTORY PROVISIONS
              </h6>
              <br />
              <br />
              These terms and conditions (hereinafter referred to as “Terms”)
              are the Terms which apply when you access our website
              www.stockperfume.com (hereinafter referred to as “Website”), or
              place an order to purchase any of the products made available to
              you on our website www.stockperfume.com.
              <br />
              <br />
              When accessing the website and placing orders, you agree to be
              bound by these Terms.
              <br />
              We recommend that you keep a copy of these Terms for future
              reference.
              <br />
              Stock Perfume
              <br />
              www.stockperfume.com
              <br />
              Customer service line:{" "}
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={`tel:${info.phone}`}
              >
                {" "}
                {info.formattedPhone}
              </a>
              <br />
              <br />
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                CHANGE OF THE TERMS AND CONDITIONS
              </h6>
              <br />
              <br />
              We reserve the right to change or modify these Terms for legal,
              regulatory or security reasons at any time. Whilst we will flag
              changes on the Website and/or send emails in relation to major
              changes, it is your responsibility to check the Terms for changes.
              If you do not agree to any of the Terms then you can simply stop
              using the Website at any time. If you have any questions,
              concerns, or comments about our Terms please email us using the
              contact us page.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> WEBSITE CONTENT</h6>
              <br />
              <br />
              All rights, titles, interests, and content displayed on the
              Website (“Content”) is owned or controlled by Stock Perfume,
              affiliates and licensors, and is protected by EU and international
              copyright, trademark, patent, or other intellectual property laws.
              Stock Perfume owns the copyright in the selection, compilation,
              assembly, arrangement, and enhancement of the content on the
              Website. Notwithstanding the above, Stock Perfume. is not
              responsible or liable for any material on the Website related to
              Stock Perfume.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> USE OF THE WEBSITE</h6>
              <br />
              <br />
              Subject to your compliance with these Terms, we grant you
              permission to download, display, view, use, and/or print sections
              of the Website for your personal, non-commercial use only. Any
              other use is strictly prohibited without prior approval of Stock
              Perfume.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> USER RESTRICTIONS</h6>
              <br />
              <br />
              You are prohibited from posting or transmitting any material to or
              from the Website that is:
              <br />• (a) Threatening, defamatory, obscene, indecent, seditious,
              offensive, pornographic, abusive, liable to incite racial hatred,
              discriminatory, menacing, scandalous, inflammatory, blasphemous,
              in breach of confidence, in breach of privacy or which may cause
              annoyance or inconvenience • (b) For which you have not obtained
              all necessary licenses and/or approvals; • (c) Which constitutes
              or encourages conduct that would be considered a criminal offense,
              gives rise to civil liability, or otherwise be contrary to the
              law, or infringe the rights of any third party, in any country in
              the world; or • (d) Which is technically harmful, including,
              without limitation to, computer viruses, logic bombs, Trojan
              horses, worms, harmful components, corrupted data or other
              malicious software or harmful data.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                PRODUCT AND PRICING INFORMATION
              </h6>
              <br />
              <br />
              Price and availability information is subject to change at any
              time, without notice and at our sole discretion. We make no
              representations or warranties about the accuracy or completeness
              of the information on the Website. There may be information on the
              Website that contains typographical errors, inaccuracies, or
              omissions that may relate to product descriptions, pricing,
              promotions, offers, and availability. We reserve the right to
              correct any errors, inaccuracies or omissions and to change or
              update information or cancel orders if any information on the
              Website is inaccurate. We have made every effort to display
              product colors as accurately as possible, however, we cannot
              guarantee the display of any such color on your particular
              computer monitor or device will be accurate. You are responsible
              for payments due for any products ordered on the Website. If we do
              not receive payment from your credit issuer or its agents, you
              agree to submit the payment to us in full. You shall be
              responsible for any costs of collection for overdue payments. Your
              purchases may be subject to governmental taxes.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> PAYMENT METHODS</h6>
              <br />
              <br />
              After you create an order, you are redirected to a secure payment
              bank gateway, where you will enter the necessary payment data. The
              payment of the purchase price is considered an advance payment. We
              would also like to inform you that our employees do not have
              access to the data of your card. All is exclusively in the hands
              of the bank.
            </p>

            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                SHIPMENT AND PROCESSING
              </h6>
              <br />
              <br />
              Stock Perfume is responsible for the financial settlement for
              Stock Perfume. The distribution of the goods will take place from
              the distribution center of Stock Perfume. For questions about the
              payment you can contact by phone. Stock Perfume, registered under
              Chamber of Commerce number: 84377348 – Address: BIXService B.V.
              Orteliuslaan 850 3528 BB Utrecht Netherlands – Telephone number: ,
              available on Monday through Friday between 10 am and 5 pm by
              phone.
              <br />
              <br />
              <span className="font-medium">
                For questions about the delivery, the products etc. Please
                contact Stock Perfume, Chamber of Commerce number: 84377348 –
                Address: BIXService B.V. Orteliuslaan 850 3528 BB Utrecht
                Netherlands – Telephone number: +31 (0) 85 800 02 32.
                <br />
                VAT: NL863188734B01{" "}
              </span>
              <br />
              <br />
              We are doing our best to ship each delivery as soon as possible.
              For delivery times, please allow approximately 3-4 business days
              after shipment. Please also allow an extra working day for
              delivery to UK offshore and Scottish Highlands destinations. You
              can receive more detailed information on the tracking page of the
              delivery service. The inventory status may change before we can
              ship your order. For your consideration, if we are no longer able
              to ship the product you ordered, we will contact you by phone or
              email. From time to time there might be such a situation like
              natural disasters and calamities which can affect the delivery
              process. We will contact you if your order is delayed due to
              natural disasters or calamities.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> SALES TAX</h6>
              <br />
              <br />
              We are charging sales tax for merchandise ordered on this Website
              based on Dutch sales tax rate. A tax of 21% is applied for all
              orders made in the Netherlands. Outside the Netherlands the tax is
              not taken into account.
              <br />
              <br />
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">CANCELLATION RIGHT</h6>
              <br />
              <br />
              As a wholesale company, we are not able to provide the option of
              cancellation after we receive the order. As a dedicated warehouse
              team, we are starting to process the order as soon as we receive
              them. Therefore, the cancellation of a processing order becomes
              infeasible.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">RETURNS</h6>
              <br />
              <br />
              Stockperfume is a website that seeks its customer’s contentedness.
              If you have received your order out of condition and damaged,
              please contact us at once.
              <br />
              <span className="font-medium">
                You can join us by phone at: or by e-mail at:{" "}
                <a
                  target={"_blank"}
                  href={`mailto:${info.email}`}
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {info.email}
                </a>
                <br />
                You can also get in touch via{" "}
                <a
                  href={`https://wa.me/${info.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Whatsapp
                </a>
              </span>
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                FOR PRODUCT RETURN, PLEASE CONTACT US{" "}
              </h6>
              <br />
              <br />
              Seller´s liability for defects The seller guarantees to the
              purchaser who is the consumer that the goods, upon takeover, are
              free from defects. The seller in particular guarantees to the
              purchaser that, upon takeover, the goods are free from defects. In
              type, quantity, quality, other characteristics and packaging,
              shall correspond to what can be deemed to have been agreed.
              <br />
              Except where something else can be deemed to have been agreed, the
              goods shall:
              <ol id="number-list" className="mt-8  ">
                <li className=" text-sm ">
                  be fit for the purpose for which such goods are ordinarily
                  used;
                </li>
                <li className="text-sm ">
                  conform to the particular purpose for which the goods were
                  intended, if the seller must have known of this purpose at the
                  conclusion of the sale and if the seller had not before the
                  conclusion of the sale informed the buyer that the goods
                  possibly do not conform to the intended purpose;
                </li>
                <li className="text-sm ">
                  conform to what has been represented by the seller and possess
                  the characteristics which the seller has implied by way of a
                  sample or model;
                </li>
                <li className="text-sm ">
                  be packaged in a manner that is usual or otherwise
                  appropriate, if packaging is necessary to preserve or protect
                  the goods; as well as to durability and otherwise, correspond
                  to what a consumer ordinarily may expect in the purchase of
                  such goods.
                </li>
                <li className="text-sm ">
                  in their characteristics conform to the requirements set by
                  law, decree or official decision, unless the buyer intended to
                  use the goods for a purpose where the said requirement is of
                  no significance. If the goods do not conform to the paragraphs
                  1-5 they are defective. However, the buyer shall not invoke as
                  a defect any circumstances that the buyer must have known of
                  at the conclusion of the sale. In addition, the buyer shall
                  not invoke as a defect any circumstances arising from the
                  materials that the buyer has supplied for the manufacture of
                  the goods, except if there has been negligence on the part of
                  the seller.
                </li>
              </ol>
              <br />
              The purchaser is entitled to lodge a complaint regarding these
              defects within 24 hours of takeover of the goods.
              <br />
              The purchaser is entitled to lodge a complaint regarding these
              defects within a reasonable time after he/she discovered or ought
              to have discovered the defect.
              <br />
              Should a defect become apparent within six months of takeover, it
              is assumed that the defect existed at the time of takeover.
              <br />
              The buyer has the right to require that the seller rectify the
              defect or deliver non- defective goods. Such rectification shall
              be performed within a reasonable time and so that the buyer does
              not thereby incur costs or significant inconvenience. However, the
              seller shall not be liable to rectify the defect if there is an
              unavoidable barrier to the same or if this would cause
              unreasonable costs. In this context, special attention shall be
              paid to the significance of the defect and the value of the goods,
              had these conformed to the contract, and to the issue whether the
              rectification can be performed in some other manner without
              causing significant inconvenience to the buyer. Even if the buyer
              does not require that the defect be rectified or that
              non-defective goods be delivered, the seller shall, at its own
              expense, have the right to perform such rectification if it offers
              to do so without delay after the buyer has notified it of the
              defect. The buyer shall have the right to refuse rectification of
              the defect if that would cause him/her essential inconvenience, a
              decrease in the value of the goods or a danger that the costs
              incurred by the buyer not be compensated, or if he/she has another
              special reason for refusal.
              <br />
              If the rectification of the defect or the delivery of
              non-defective goods is out of the question. the buyer shall have
              the right to:
              <br />
              <ol id="number-list" className="mt-8  ">
                <li className=" text-sm ">
                  demand a price reduction proportionate to the defect; or
                </li>
                <li className=" text-sm ">
                  cancel the contract, except if the defect is of minor
                  significance. If it is evident that the goods do not show the
                  properties and quality agreed upon between the parties, the
                  seller shall bear the costs of transportation of the defective
                  goods to the seller, and back to the customer.
                </li>
              </ol>
              <br />
              The rights arising from product defects can be asserted at the
              following addresses of the seller.
              <br />
              Purchaser’s rights in case of another kind of faulty performance
              than a defect, are governed by the Consumer Protection Act.
              <br />
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                VOUCHERS AND DISCOUNT CODES
              </h6>
              <br />
              <br />
              We would like to inform you it may show a discount code box
              situated at the bottom of the Website homepage. If you fill the
              box with your email address, you automatically get your own
              discount code. While placing your order, you will find the box
              where you can enter your Gift voucher or discount code. You must
              take care when entering codes as they are case and space
              sensitive. You may only redeem one code at a time so we advise you
              to choose the promotion best suited to your purchase. Discount
              codes cannot be used in conjunction with any other offer. Discount
              codes exclude some brands and exclusions may be amended from time
              to time at our sole discretion. If you have any questions about
              the discount codes, please feel free to contact us.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> PERSONAL ACCOUNT</h6>
              <br />
              <br />
              We are happy to make it easier for you to order products using our
              Website, so we offer you the opportunity to register for a
              personal account. If you take advantage of this opportunity, any
              personal data that you provide us with during the registration
              process will be stored in our database and will not need to be
              re-entered with each new order – it will be entered into the order
              form automatically.
              <br />
              To register, you must supply us with the online registration form.
              You can access, update or correct your personal account details
              through the “Sign in” button. We will only use the stored data in
              accordance with our Privacy Policy.
              <br />
              You are responsible for maintaining the confidentiality of your
              password and user name and are responsible for all activities that
              are carried out under them. We do not have the means to check the
              identities of people using the Website and will not be responsible
              for losses when your password or user name is used by someone else
              unless this is due to our negligence. You agree to notify us
              immediately by email using the contact us page or by phone on if
              you become aware or suspect of any unauthorized use of your
              password or username.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10"> E-INVOICING</h6>
              <br />
              <br />
              Upon shipment, the customer receives a confirmation email with an
              e-invoice which can be downloaded at any time. The e-invoice is
              also accessible from the customer’s account, or it can be obtained
              per request using the contact us page.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                ELECTRONIC RECORDS OF SALES
              </h6>
              <br />
              <br />
              In accordance with the Registration of Sales Act, the seller is
              obliged to issue a receipt for the buyer. The seller also must
              immediately register the sale online with the tax administrator.
              In case of technical difficulties, the registration needs to be
              done within 48 hours at the latest.
            </p>
            <p className="text-sm text-black mt-8 px-3">
              <h6 className="text-md font-medium mt-10">
                {" "}
                WE APPRECIATE YOU FEEDBACK
              </h6>
              <br />
              <br />
              We are striving to continuously improve the quality of service we
              provide to our customers. Your satisfaction is our top priority.
              To provide excellent service, we would appreciate your comments
              and suggestions. Please feel free to contact our customer line on
              phone No. , or email us using the contact us page.
            </p>
            <p className="text-sm text-black mt-8 mb-8 px-3">
              <h6 className="text-md font-medium mt-10"> FINAL PROVISIONS</h6>
              <br />
              <br />
              If any of these Terms are found to be invalid by any court or
              regulator, the other Terms shall continue to apply.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default TermsConditions;
