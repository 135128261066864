import React from "react";

function InfoCards() {
  return (
    <div className="grid grid-cols-12 gap-4   mx-3 sm:mx-40 lg:mx-24">
        {/* CATEGORIES */}
      {/* <div className="col-span-full flex gap-8 sm:hidden my-5 overflow-x-auto">
        <button className="bg-white col-span-full w-fit p-3 rounded-20 flex gap-3 items-center">
            <p className="text-xxs ">Category Category Ca</p>
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L4 4L1 1" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </button>
        <button className="bg-white col-span-full p-3 rounded-20 flex gap-3 items-center">
            <p className="break-normal">Women fragrances</p>
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L4 4L1 1" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </button>
        <button className="bg-white col-span-full p-3 rounded-20 flex gap-3 items-center">
            <p>Category</p>
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L4 4L1 1" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </button>
        <button className="bg-white col-span-full p-3 rounded-20 flex gap-3 items-center">
            <p>Category</p>
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L4 4L1 1" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </button>
        
      </div> */}

        {/* HEADING */}
      <div className="col-span-full">
        <h1 className="text-center lg:text-lg font-semibold text-lg text-black my-4 lg:mb-9 lg:mt-14">
          Take advantage of Stockperfume offers
        </h1>
      </div>

        {/* CARDS */}
      <div className="lg:col-span-4 col-span-12">
        <div className="bg-white rounded-20 shadow p-4">
          <div className="grid justify-items-center">
            <div className="bg-black w-20 h-20 grid justify-items-center items-center rounded-full">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5043 1.13168C16.6554 1.04539 16.8263 1 17.0003 1C17.1743 1 17.3453 1.04539 17.4963 1.13168L24.9965 5.41777C25.1495 5.5052 25.2768 5.63153 25.3653 5.78396C25.4538 5.9364 25.5004 6.10952 25.5005 6.28578V14.2759L32.4966 18.276C32.6491 18.3631 32.776 18.4888 32.8644 18.6405C32.9529 18.7922 32.9999 18.9645 33.0006 19.14V27.7122C33.0006 27.8885 32.9539 28.0616 32.8654 28.214C32.7769 28.3665 32.6497 28.4928 32.4966 28.5802L24.9965 32.8663C24.8454 32.9526 24.6745 32.998 24.5005 32.998C24.3265 32.998 24.1555 32.9526 24.0045 32.8663L17.0003 28.8662L9.99618 32.8683C9.84511 32.9546 9.67415 33 9.50017 33C9.3262 33 9.15523 32.9546 9.00416 32.8683L1.50401 28.5822C1.35095 28.4948 1.22372 28.3685 1.13521 28.216C1.0467 28.0636 1.00006 27.8905 1 27.7142V19.142C1.00006 18.9658 1.0467 18.7927 1.13521 18.6402C1.22372 18.4878 1.35095 18.3615 1.50401 18.274L8.50015 14.2759V6.28578C8.50021 6.10952 8.54685 5.9364 8.63537 5.78396C8.72388 5.63153 8.8511 5.5052 9.00416 5.41777L16.5043 1.13168ZM9.50017 16.008L4.01606 19.142L9.50017 22.2761L14.9843 19.142L9.50017 16.008ZM16.0003 20.8661L10.5002 24.0081V30.2763L16.0003 27.1342V20.8661ZM18.0003 27.1342L23.5005 30.2763V24.0081L18.0003 20.8661V27.1342ZM19.0164 19.142L24.5005 22.2761L29.9846 19.142L24.5005 16.008L19.0164 19.142ZM23.5005 14.2759V8.00782L18.0003 11.1519V17.42L23.5005 14.2759ZM16.0003 17.42V11.1519L10.5002 8.00782V14.2759L16.0003 17.42ZM11.5162 6.28578L17.0003 9.41985L22.4844 6.28578L17.0003 3.15172L11.5162 6.28578ZM31.0006 20.8661L25.5005 24.0081V30.2763L31.0006 27.1342V20.8661ZM8.50015 30.2763V24.0081L3.00004 20.8661V27.1342L8.50015 30.2763Z"
                  fill="white"
                  stroke="#202020"
                />
              </svg>
            </div>
            <p className="text-center text-sm font-normal mt-6 mb-2 px-5">
              Fragrances and cosmetics wholesaler for your company all around
              the world.
            </p>
          </div>
        </div>
      </div>
      <div className="lg:col-span-4 col-span-12">
        <div className="bg-white rounded-20 shadow p-4">
          <div className="grid justify-items-center">
            <div className="bg-black w-20 h-20 grid justify-items-center items-center rounded-full">
              <svg
                width="25"
                height="30"
                viewBox="0 0 25 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 1H3.8C3.05739 1 2.3452 1.295 1.8201 1.8201C1.295 2.3452 1 3.05739 1 3.8V26.2C1 26.9426 1.295 27.6548 1.8201 28.1799C2.3452 28.705 3.05739 29 3.8 29H20.6C21.3426 29 22.0548 28.705 22.5799 28.1799C23.105 27.6548 23.4 26.9426 23.4 26.2V9.4M15 1L23.4 9.4M15 1V9.4H23.4M12.2 23.4V15M8 19.2H16.4"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className="text-center text-sm font-normal mt-6 mb-2 px-5">
              User friendly platform and easy order process in on click by
              uploading a CSV file.
            </p>
          </div>
        </div>
      </div>
      <div className="lg:col-span-4 col-span-12">
        <div className="bg-white rounded-20 shadow p-4">
          <div className="grid justify-items-center">
            <div className="bg-black w-20 h-20 grid justify-items-center items-center rounded-full">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4228 2.95235C16.1979 2.86221 15.9469 2.86221 15.722 2.95235L4.47796 7.44958L9.00722 9.26015L20.6017 4.6235L16.4228 2.95235ZM23.1376 5.63901L11.5432 10.2757L16.0724 12.0862L27.6669 7.44958L23.1376 5.63901ZM29.2608 8.84189L17.0144 13.7404V28.6659L29.2608 23.7673V8.84189ZM15.1304 28.6678V13.7385L2.88405 8.84189V23.7692L15.1304 28.6678ZM15.023 1.20207C15.6967 0.932645 16.4482 0.932645 17.1218 1.20207L30.5532 6.57538C30.7279 6.64536 30.8775 6.76603 30.983 6.92184C31.0884 7.07764 31.1448 7.26145 31.1448 7.44958V23.7692C31.1446 24.1457 31.0316 24.5135 30.8203 24.8251C30.6091 25.1367 30.3094 25.378 29.9598 25.5176L16.4228 30.9324C16.1979 31.0225 15.9469 31.0225 15.722 30.9324L2.18695 25.5176C1.83699 25.3783 1.53685 25.1372 1.32527 24.8255C1.11369 24.5139 1.00039 24.1459 1 23.7692V7.44958C1.00004 7.26145 1.05642 7.07764 1.16185 6.92184C1.26729 6.76603 1.41696 6.64536 1.59159 6.57538L15.023 1.20207Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </div>
            <p className="text-center text-sm font-normal mt-6 mb-2 px-5">
              Dropshipping order possibility all around the European area.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
