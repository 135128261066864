import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Shop from "./components/Shop/Shop";
import Orders from "./components/MyAccount/Orders/Orders";
import { useNavigate, useRoutes } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import Cart from "./components/Cart/Cart";
import Brands from "./components/Brands/Brands";
import Checkout from "./components/Checkout/Checkout";
import ContactUs from "./components/ContactUs/ContactUs";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Favourites from "./components/MyAccount/Favourites/Favourites";
import PersonalInfo from "./components/MyAccount/PersonalInfo.tsx/PersonalInfo";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Register from "./components/Register/Register";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import RequireAuth from "./functions/RequireAuth";
import MyAdresses from "./components/MyAccount/MyAddresses/MyAdresses";
import { useSelector } from "react-redux";
import { getBrandFilters } from "./app/features/filter/actions/getBrandFilters";
import { getCategoryFilters } from "./app/features/filter/actions/getCategoryFilters";
import { getColorFilters } from "./app/features/filter/actions/getColorFilters";
import { getMemoryFilters } from "./app/features/filter/actions/getMemoryFilters";
import { getPriceFilters } from "./app/features/filter/actions/getPriceFilters";
import { validateToken } from "./app/features/user/actions/validateToken";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import BecomePartner from "./components/become-partner/BecomePartner";
import CsvOrderUpload from "./components/csv-order-upload/CsvOrderUpload";
import Wholesale from "./components/wholesale/Wholesale";
import LogisticEnquiries from "./components/logistic-enquiries/LogisticEnquiries";
import Dropshipping from "./components/dropshipping/Dropshipping";
import Address from "./components/Checkout/mobile/Address";
import Payment from "./components/Checkout/mobile/Payment";
import Shipping from "./components/Checkout/mobile/Shipping";
import Conformation from "./components/Checkout/mobile/Conformation";
import ProductSection from "./components/Checkout/mobile/ProductSection";
import Wallet from "./components/MyAccount/wallet/Wallet";
import TopUpAmount from "./components/MyAccount/wallet/TopUpAmount";
import PaymentConfirmation from "./components/MyAccount/wallet/PaymentConfirmation";
import PaymentMethods from "./components/MyAccount/payment-methods/PaymentMethods";
import PaymentAddCard from "./components/MyAccount/payment-methods/PaymentAddCard";
import RightSideBar from "./components/SideBars/RightSideBar";
import { getAddress } from "./app/features/user/actions/getAdress";
import { getUserInfo } from "./app/features/user/actions/getUserInfo";
import MobileSearchPage from "./components/mobile-search-page/MobileSearchPage";
import { getBrandsWithLetter } from "./app/features/filter/actions/getBrandsWithLetter";
import { getBestSellerProducts } from "./app/features/product/actions/getBestSellerProducts";
import { setVATprice } from "./app/features/cart/slices/cartSlice";
import CalculateVAT from "./app/features/cart/CalculateVAT";
import MobileSideBar from "./components/SideBars/MobileSideBar";
import CheckoutConfirmation from "./components/Checkout/CheckoutConfirmation";
import CheckoutCancelled from "./components/Checkout/CheckoutCancelled";
import Cookies from "js-cookie";
import { notify } from "./functions/notify";
import { revokeToken } from "./app/features/user/actions/revokeToken";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);
  const user = useSelector((state: any) => state.userReducer);
  const cart = useSelector((state: any) => state.cartReducer.products);
  const token = Cookies.get("sp-token");

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      if (!token) {
        notify("Your session is expired, please login again", "error");
        logout();
      }
    }
  }, [token]);

  const logout = () => {
    Cookies.remove("sp-token");
    Cookies.remove("sp-user");
    dispatch(revokeToken());
    navigate("/");
  };

  useEffect(() => {
    let token = null,
      user = null;
    if (Cookies.get("sp-token")) {
      // @ts-ignore
      token = Cookies.get("sp-token");
      // @ts-ignore
      user = JSON.parse(Cookies.get("sp-user"));
    }
    if (token) {
      dispatch(validateToken({ token, user }));
      dispatch(getUserInfo());
      dispatch(getAddress());
    }

    dispatch(getCategoryFilters());
    dispatch(getBrandFilters());
    dispatch(getColorFilters());
    dispatch(getMemoryFilters());
    dispatch(getPriceFilters());
    dispatch(getBrandsWithLetter());
    dispatch(getBestSellerProducts());
  }, []);
  

  
  useEffect(() => {
    if (user.loggedIn) {
      if (Cookies.get("sp-token")) {
        let token = Cookies.get("sp-token");
        dispatch(getUserInfo());
        dispatch(validateToken({ token }));
      }
    }
  }, [user.loggedIn]);

  useEffect(() => {
    const rate = CalculateVAT(user?.user);
    dispatch(setVATprice(rate));
  }, [cart, user]);

  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/shop/:category/:id",
      element: <Shop />,
    },
    {
      path: "/shop/:id",
      element: <SingleProduct />,
    },
    {
      path: "/brands",
      element: <Brands />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
      path: "/checkout",
      element: (
        <Checkout number={0}>
          {" "}
          <ProductSection />{" "}
        </Checkout>
      ),
    },
    {
      path: "/checkout/product",
      element: (
        <Checkout number={0}>
          {" "}
          <ProductSection />{" "}
        </Checkout>
      ),
    },
    {
      path: "/checkout/address",
      element: (
        <Checkout number={1}>
          {" "}
          <Address />{" "}
        </Checkout>
      ),
    },
    {
      path: "/checkout/payment",
      element: (
        <Checkout number={3}>
          {" "}
          <Payment />{" "}
        </Checkout>
      ),
    },
    {
      path: "/checkout/shipping",
      element: (
        <Checkout number={2}>
          {" "}
          <Shipping />{" "}
        </Checkout>
      ),
    },
    {
      path: "/checkout/confirmation",
      element: (
        <Checkout number={4}>
          {" "}
          <Conformation />{" "}
        </Checkout>
      ),
    },
    {
      path: "/single-product",
      element: <SingleProduct />,
    },
    {
      path: "/become-partner",
      element: <BecomePartner />,
    },
    {
      path: "/csv-order-upload",
      element: <CsvOrderUpload />,
    },
    {
      path: "/filters",
      element: <MobileSearchPage />,
    },
    {
      path: "/wholesale",
      element: <Wholesale />,
    },
    {
      path: "/logistic-enquiries",
      element: <LogisticEnquiries />,
    },
    {
      path: "/dropshipping",
      element: <Dropshipping />,
    },
    {
      path: "/my-account",
      element: (
        <RequireAuth>
          <PersonalInfo />
        </RequireAuth>
      ),
    },
    {
      path: "/my-account/orders",

      element: (
        <RequireAuth>
          <Orders />
        </RequireAuth>
      ),
    },
    {
      path: "/my-account/orders/completed",

      element: <CheckoutConfirmation />,
    },
    {
      path: "/my-account/orders/cancelled",

      element: <CheckoutCancelled />,
    },
    {
      path: "/my-account/wallet",
      element: (
        <RequireAuth>
          <Wallet />
        </RequireAuth>
      ),
    },
    {
      path: "/my-account/top-up-amount",
      element: (
        <RequireAuth>
          <TopUpAmount />
        </RequireAuth>
      ),
    },

    {
      path: "/my-account/payment-confirmation",
      element: (
        <RequireAuth>
          <PaymentConfirmation />
        </RequireAuth>
      ),
    },
    /*     {
      path: "/my-account/payment-methods",
      element: (
        <RequireAuth>
          <PaymentMethods />
        </RequireAuth>
      ),
    }, */
    {
      path: "/my-account/payment-cards",
      element: (
        <RequireAuth>
          <PaymentAddCard />
        </RequireAuth>
      ),
    },
    {
      path: "/my-account/addresses",
      element: (
        <RequireAuth>
          <MyAdresses />
        </RequireAuth>
      ),
    },
    {
      path: "/my-account/favourites",
      element: (
        <RequireAuth>
          <Favourites />
        </RequireAuth>
      ),
    },

    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },

    {
      path: "/terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
  ]);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        setIsMenuOpen(false);
      }
      if (isRightSwipe) {
        setIsMenuOpen(true);
      }
    }
    // add your conditional logic here
  };

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      className="bg-gray overflow-x-hidden z-0"
    >
      <div className="mt-20 lg:mt-0"></div>
      <NavBar setIsMenuOpen={setIsMenuOpen} />

      <MobileSideBar open={isMenuOpen} setOpen={setIsMenuOpen} />
      <RightSideBar />

      {routes}
      <Footer />
    </div>
  );
}

export default App;
