import React from "react";
import { BsDash, BsPlus } from "react-icons/bs";
import PriceInfo from "./PriceInfo";

type Props = {
  product: any;
  getProductQuantity: any;
  setProductQuantity: any;
  setIsActivated: any;
  ProductQuantity: any;
  className?: string;
  stockQuantity: any;
};

const QuantityInput = ({
  product,
  getProductQuantity,
  setProductQuantity,
  setIsActivated,
  ProductQuantity,
  stockQuantity,
  className,
}: Props) => {
  const textColor = stockQuantity > 0 ? "text-[#33B07B]" : "text-[#FF994E]";
  return (
    <div
      className={`flex flex-row w-fit items-center rounded-100 bg-white h-10 py-2 px-2 gap-2 border relative ${className}`}
    >
      <PriceInfo
        className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-5 !h-fit !w-fit !px-0 !bg-transparent lg:flex xl:hidden hidden"
        price={product?.price}
      />
      <button
        onClick={() => {
          setIsActivated(false);
          setProductQuantity((quantity: any) =>
            quantity == 1 ? 1 : quantity - 1
          );
        }}
        data-action="decrement"
        className="flex outline-none"
      >
        <BsDash className="text-md hover:text-brown" />
      </button>
      <input
        type="number"
        key={getProductQuantity(product?.id)}
        className="flex focus:outline-none text-center w-6 h-full text-sm focus:text-black outline-none"
        name="custom-input-number"
        onChange={(e) => {
          setIsActivated(false);
          setProductQuantity(
            parseInt(e.target.value) < 1
              ? 1
              : parseInt(e.target.value) >= stockQuantity
              ? stockQuantity
              : parseInt(e.target.value)
          );
        }}
        value={ProductQuantity}
      ></input>
      <button
        onClick={() => {
          setIsActivated(false);
          setProductQuantity((quantity: any) =>
            quantity >= stockQuantity ? stockQuantity : quantity + 1
          );
        }}
        data-action="increment"
        className="flex outline-none"
      >
        <BsPlus className="text-md hover:text-brown" />
      </button>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-5 w-full flex items-center">
        <p
          className={`hidden lg:flex 2xl:hidden text-xs font-semibold line-clamp-1 mx-auto ${textColor}`}
        >
          {stockQuantity > 0 ? stockQuantity + " in stock" : "Out of stock"}
        </p>
      </div>
    </div>
  );
};

export default QuantityInput;
