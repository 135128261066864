import React from "react";

type Props = {
  title: string;
  subtitle: string;
  content: React.ReactNode | string;
  icon?: React.ReactNode;
  className?: string;
};

const Badge = ({ title, subtitle, content, icon, className }: Props) => {
  return (
    <div className={`flex flex-col gap-8 w-full ${className}`}>
      <div className="flex justify-between w-full border-black border-l-3">
        <div className="flex flex-col pl-4 gap-0.5">
          <p className="text-xl text-black font-medium">{title}</p>
          <p className="text-base text-black text-justify">{subtitle}</p>
        </div>
        {icon && icon}
      </div>
      <div className="flex flex-col w-full">
        <p className="text-base text-black text-justify w-full leading-7">
          {content}
        </p>
      </div>
    </div>
  );
};

export default Badge;

type OrderBadgeProps = {
  title: string;
  content: React.ReactNode | string;
  number: string;
  className?: string;
  innerClassName?: string;
};

export const OrderBadge = ({
  title,
  content,
  number,
  className,
  innerClassName,
}: OrderBadgeProps) => {
  return (
    <div className={`flex flex-col gap-3 ${className}`}>
      <div className="flex gap-12">
        <p className="text-xl text-black font-bold uppercase">{number}</p>
        <p className="text-lg text-black font-medium">{title}</p>
      </div>
      <div
        className={`border-l-10 border-gray ml-2.5 pl-14 py-4 text-justify text-base ${innerClassName}`}
      >
        {content}
      </div>
    </div>
  );
};
