import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import PhoneInput from "react-phone-number-input";
import useState from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import { fetchPlace } from "../../app/features/product/functions/fetchPlace";
import { signUpUser } from "../../app/features/user/actions/signUpUser";
import { useAppDispatch } from "../../app/hooks";
import countries, {
  european_countries,
  european_countries_vat,
} from "../../constants/countries";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { notify } from "../../functions/notify";

function Register() {
  const [number, setNumber] = React.useState<string | undefined>("");
  const [differentAddress, setDifferentAddress] = React.useState(false);

  const verifyToken = async (token) => {
    try {
      return await axios.post(
        `https://stockperfume.com/wp/wp-json/wp/v3/captcha`,
        {
          reCAPTCHA_TOKEN: token,
        }
      );
    } catch (error) {
      return false;
    }
  };
  const recaptchaRef = useRef();

  const [user, setUser] = React.useState({
    email: "",
    address_1: "",
    state: "",
    address_2: "",
    company: "",
    postcode: "",
    phone: "",
    first_name: "",
    last_name: "",
    country: "",
    city: "'s Gravenmoer",
    vat_number: "",
    vat_country: "",
    house_number: "",
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_phone: "",
    shipping_email: "",
    shipping_address_1: "",
    shipping_address_2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_postcode: "",
    shipping_country: "",
    shipping_company: "",
    shipping_house_number: "",
  });
  const userReg = useSelector((state: any) => state.userReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const error = useSelector((state: any) => state.userReducer.error);
  const errorMessage = useSelector((state: any) => state.userReducer.message);
  useEffect(() => {
    if (userReg.loggedIn) return navigate(state?.path || "/");
  }, [userReg.loggedIn]);

  interface FormInterface {
    [key: string]: string;
  }

  const handleRegister = async (e: any) => {
    e.preventDefault();
    //@ts-ignore
    let token = recaptchaRef.current.getValue();
    //@ts-ignore
    recaptchaRef.current.reset();
    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token) {
        let user_meta = {
          billing_first_name: user.first_name,
          billing_last_name: user.last_name,
          billing_address_1: user.address_1,
          billing_address_2: user.address_2,
          billing_company: user.company,
          billing_state: user.state,
          billing_house_number: user.house_number,
          billing_city: user.city,
          billing_postcode: user.postcode,
          billing_phone: user.phone,
          billing_email: user.email,
          billing_country: user.country,

          shipping_first_name: user.first_name,
          shipping_last_name: user.last_name,
          shipping_phone: user.phone,
          shipping_email: user.email,
          shipping_address_1: user.address_1,
          shipping_address_2: user.address_2,
          shipping_company: user.company,
          shipping_state: user.state,
          shipping_house_number: user.house_number,
          shipping_city: user.city,
          shipping_postcode: user.postcode,
          shipping_country: user.country,

          vat_number: user.vat_number,
          vat_country: user.vat_country,
        };

        if (differentAddress) {
          user_meta = {
            ...user_meta,
            shipping_first_name: user.shipping_first_name,
            shipping_last_name: user.shipping_last_name,
            shipping_phone: user.shipping_phone,
            shipping_email: user.shipping_email,
            shipping_address_1: user.shipping_address_1,
            shipping_address_2: user.shipping_address_2,
            shipping_company: user.shipping_company,
            shipping_state: user.shipping_state,
            shipping_house_number: user.shipping_house_number,
            shipping_city: user.shipping_city,
            shipping_postcode: user.shipping_postcode,
            shipping_country: user.shipping_country,
          };
        }

        const { vat_number, vat_country, ...rest } = user;
        dispatch(signUpUser({ ...rest, user_meta: JSON.stringify(user_meta) }));
        notify("Submit is successful. Your account will be confirmed soon");
      } else {
        notify("Please verify that you are not a robot");
      }
    } else {
      notify("Please verify that you are not a robot");
    }
  };

  const handleChange = (event: any) => {
    let name = event.target?.getAttribute("name");
    let value = event.target?.value;
    if (name === "checkbox_terms") {
      value = event.target.checked;
    }
    if (event.name === "city") {
      name = event.name;
      value = event.value;
    }
    if (name !== null && value !== null) {
      setUser((prevState: any) => ({ ...prevState, [name]: value }));
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] min-h-[163px]  mt-16  bg-no-repeat grid grid-cols-12 py-8 pt-16 lg:pt-36 lg:py-0 lg:px-12">
        <div className="lg:col-start-1 lg:col-end-5 col-start-8   col-end-13  lg:mx-10 ">
          <p className="lg:text-2xl text-xl  font-semibold">Welcome to</p>
          <p className="lg:text-2xl text-xl font-[200]">Stockperfume</p>
        </div>

        <div className="hidden lg:block col-start-10 col-end-13  ">
          <p className="text-md font-[300]">
            Use the following form to login or create new account with
            Stockperfume for free..
          </p>
        </div>
      </div>

      <div className="lg:mx-72 mb-20 lg:-mt-36 md:mx-20 sm:mx-20 mx-5 mt-8 ">
        <form
          onChange={handleChange}
          onSubmit={(e) => handleRegister(e)}
          className="grid grid-cols-12 rounded-20  md:gap-x-8 md:gap-y-4 bg-white shadow md:px-20 py-8  "
        >
          <div className="col-span-full  text-center mb-4">Sign up</div>
          {error ? (
            <div className="col-span-full text-red-500 text-center">
              {errorMessage}
            </div>
          ) : null}
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Name*</p>
            <input
              required
              placeholder="Name"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="first_name"
              autoComplete="off"
              aria-autocomplete="none"
            />
          </div>

          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Surname*</p>
            <input
              required
              placeholder="Surname"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="last_name"
              autoComplete="off"
              aria-autocomplete="none"
            />
          </div>

          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Email*</p>
            <input
              required
              placeholder="Email"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="email"
              name="email"
              autoComplete="off"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Password*</p>
            <input
              required
              placeholder="password"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="password"
              name="password"
              autoComplete="off"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3  text-sm font-medium">Company</p>
            <input
              placeholder="Company"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="company"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3  text-sm font-medium">State</p>
            <input
              placeholder="State"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="state"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0 relative">
            <p className="mb-3 text-sm font-medium">VAT Country</p>
            <select
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              name="vat_country"
            >
              {european_countries_vat?.map((country: any) => {
                return <option value={country.code}>{country.name}</option>;
              })}
            </select>
          </div>
          <div className="md:col-span-6 pb-5 col-span-12 px-4 md:px-0 relative">
            <p className="mb-3 text-sm font-medium">VAT Number</p>
            <input
              placeholder="VAT Number"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="vat_number"
            />
            <p className="text-xs ml-4 mt-2  absolute">
              The VAT number you entered will be checked from the VIES system.
            </p>
          </div>
          <div className=" col-span-6 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Address 1*</p>
            <input
              required
              placeholder="Address"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="address_1"
            />
          </div>
          <div className=" col-span-6 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Address 2</p>
            <input
              placeholder="Address"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="address_2"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Post code*</p>
            <input
              required
              placeholder="Post code"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="postcode"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">House Number*</p>
            <input
              required
              placeholder="House Number"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="house_number"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">City*</p>
            <input
              required
              placeholder="City"
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              type="text"
              name="city"
            />
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0 relative">
            <p className="mb-3 text-sm font-medium">Country</p>
            <select
              className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
              name="country"
              defaultValue={"NL"}
            >
              {countries.map((country: any) => {
                return <option value={country?.[1]}>{country?.[0]}</option>;
              })}
            </select>
          </div>
          <div className="md:col-span-6 col-span-12 px-4 md:px-0">
            <p className="mb-3 text-sm font-medium">Phone</p>
            <PhoneInput
              name="phone"
              international
              value={number}
              className="text-xs"
              onChange={(e) => {
                setNumber(e);
              }}
            />
          </div>
          <div className=" col-span-full grid justify-items-center lg:justify-items-start ">
            <div className="flex gap-4">
              <input
                onChange={(e) => {
                  setDifferentAddress(e.target.checked);
                }}
                type="checkbox"
              />
              <p className="">Enter different delivery address</p>
            </div>
          </div>

          {differentAddress && (
            <>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Name*</p>
                <input
                  required
                  placeholder="Name"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_first_name"
                />
              </div>

              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Surname*</p>
                <input
                  required
                  placeholder="Surname"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_last_name"
                />
              </div>

              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Email*</p>
                <input
                  required
                  placeholder="Email"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="email"
                  name="shipping_email"
                />
              </div>

              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3  text-sm font-medium">Company</p>
                <input
                  placeholder="Company"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_company"
                />
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3  text-sm font-medium">State*</p>
                <input
                  required
                  placeholder="State"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_state"
                />
              </div>

              <div className=" col-span-6 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Address 1*</p>
                <input
                  required
                  placeholder="Address"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_address_1"
                />
              </div>
              <div className=" col-span-6 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Address 2</p>
                <input
                  placeholder="Address"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_address_2"
                />
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Post code*</p>
                <input
                  required
                  placeholder="Post code"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_postcode"
                />
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">House Number*</p>
                <input
                  required
                  placeholder="House Number"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_house_number"
                />
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">City*</p>
                <input
                  required
                  placeholder="City"
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  type="text"
                  name="shipping_city"
                />
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0 relative">
                <p className="mb-3 text-sm font-medium">Shipping Country</p>
                <select
                  className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                  name="shipping_country"
                >
                  {european_countries.map((country: any) => {
                    return <option value={country.code}>{country.name}</option>;
                  })}
                </select>
              </div>
              <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                <p className="mb-3 text-sm font-medium">Phone</p>
                <PhoneInput
                  name="shipping_phone"
                  international
                  value={number}
                  className=""
                  onChange={(e) => {
                    setNumber(e);
                  }}
                />
              </div>
            </>
          )}
          <div className="mx-auto col-span-full">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={"6LdXUponAAAAAJhwtGcX_N1lRv6LZAlOVeF5tt_F"}
            />
          </div>
          <div className="col-span-full grid justify-items-center  my-5">
            <button
              type="submit"
              className="bg-black text-white py-2 md:px-40 px-20 rounded-full"
            >
              Sign up
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}

export default Register;
