import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import Select, {
  DropdownIndicatorProps,
  StylesConfig,
  components,
} from "react-select";
const Pagination = ({
  showNextPages,
  setShowNextPages,
  page,
  setPage,
  showPrevPages,
  total_pages,
  className,
}: any) => {
  type PaginationSelectProps = {};
 
  const PaginationSelectOptions = Array.from(Array(total_pages).keys()).map(
    (i) => ({
      value: i + 1,
      label: i + 1,
    })
  );
  const handleSelectPage = (page: number) => {
    setPage(page);
  };
  return (
    <div
      className={`self-end my-auto max-w-[60%] flex-row flex-wrap justify-end  text-xs md:text-md flex gap-4 md:-space-x-2 ${className}`}
    >
      <button
        className="bg-white rounded-full w-10 h-10 flex hover:bg-brown items-center place-content-center group shadow"
        onClick={() => {
          setShowNextPages(false);
          setPage((page: number) => 1);
        }}
      >
        <RxDoubleArrowLeft className="text-black text-md group-hover:text-white" />
      </button>

      <button
        className="bg-white rounded-full w-10 h-10 flex hover:bg-brown items-center place-content-center group shadow"
        onClick={() => {
          setShowNextPages(false);
          setPage((page: number) => (page !== 1 ? page - 1 : page));
        }}
      >
        <RiArrowLeftSLine className="text-black text-md group-hover:text-white" />
      </button>
      <div className="relative w-fit h-fit">
        <MdKeyboardArrowDown className="absolute top-1/2 transform -translate-y-1/2 right-5 z-20" />
        <Select
          onChange={(newValue: any) => {
            handleSelectPage(newValue.value);
          }}
          options={PaginationSelectOptions}
          styles={
            {
              control: (provided) => ({
                ...provided,
                margin: 0,
                width: "174px",
                height: "40px",
                textAlign: "center",
                fontSize: "12px",
                boxShadow: "2px 4px 8px rgba(211, 211, 211, 0.50)",
                fontWeight: 500,
                border: "none",
                borderColor: "transparent",
                borderRadius: "100px",
                "::placeholder": {
                  textAlign: "center",
                  alignItems: "center",
                },
                ":focus": {
                  outline: "none",
                  border: "none",
                  borderColor: "transparent",
                },
                "&:hover": {
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                },
              }),
              menu: () => ({
                background: "#ffffff",
                border: "1px solid rgba(174, 195, 214, 0.30)",
                borderRadius: "15px",
                padding: "8px",
                fontSize: "12px",
                zIndex: 100,
                textAlign: "center",
              }),
              option: (base: any, state: any) => ({
                ...base,
                borderRadius: "8px",
                backgroundColor: state.isSelected ? "#F5F5F5" : "#ffffff",
                color: "#000000",
                "&:hover": {
                  background: state.isSelected ? "#F5F5F5" : "#F5F5F5",
                  cursor: "pointer",
                },
                "&:active": {
                  background: "#F5F5F5",
                },
              }),
              indicatorsContainer(base, props) {
                return { ...base, display: "none" };
              },
              input(base, props) {
                return { ...base, textAlign: "center" };
              },
            } as StylesConfig<PaginationSelectProps, false>
          }
          value={{
            value: page,
            label: "Page " + page,
          }}
          placeholder="10"
          menuPosition="fixed"
          isSearchable={true}
        />
      </div>
      {/*    {showPrevPages &&
        page > 3 &&
        Array.from({ length: 3 }, (_, i) => {
          return page - (3 - i) < total_pages ? (
            <button
              onClick={() => {
                setShowNextPages(false);
                setPage((page: number) =>
                  page + i + 1 < total_pages ? page - (3 - i) : page
                );
              }}
              className="relative"
            >
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_236_1971)">
                  <circle cx="25" cy="25" r="17" fill="white" />
                </g>

                <defs>
                  <filter
                    id="filter0_d_236_1971"
                    x="0"
                    y="0"
                    width="56"
                    height="56"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feMorphology
                      radius="3"
                      operator="dilate"
                      in="SourceAlpha"
                      result="effect1_dropShadow_236_1971"
                    />
                    <feOffset dx="3" dy="3" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0.3 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_236_1971"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_236_1971"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <span
                className={`absolute left-[37%] top-0.5 translate-y-2/3  z-20 $text-brown"  text-sm`}
              >
                {page - (3 - i)}
              </span>
            </button>
          ) : null;
        })}

      {page >= 2 && (
        <button
          onClick={() => {
            setPage((page: number) => page - 1);
          }}
          className="flex w-10 h-10 bg-white rounded-full items-center place-content-center"
        >
          <span className="flex text-sm">{page - 1}</span>
        </button>
      )}

      <button
        onClick={() => {
          setShowNextPages(false);
          setPage((page: number) => page);
        }}
        className="flex w-10 h-10 bg-white rounded-full items-center place-content-center"
      >
        <span
          className={
            page?.toString().length == 3
              ? "flex text-sm"
              : "flex text-brown  text-sm"
          }
        >
          {page}
        </span>
      </button>
      {page < total_pages && (
        <button
          onClick={() => {
            setPage((page: number) => page + 1);
          }}
          className="flex w-10 h-10 bg-white rounded-full items-center place-content-center"
        >
          <span className="flex text-sm">{page + 1}</span>
        </button>
      )}

       {showNextPages &&
        Array.from({ length: 3 }, (_, i) => {
          return page + i + 1 < total_pages ? (
            <button
              onClick={() => {
                setShowNextPages(false);
                setPage((page: number) =>
                  page + i + 1 < total_pages ? page + i + 1 : page
                );
              }}
              className="relative"
            >
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_236_1971)">
                  <circle cx="25" cy="25" r="17" fill="white" />
                </g>

                <defs>
                  <filter
                    id="filter0_d_236_1971"
                    x="0"
                    y="0"
                    width="56"
                    height="56"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feMorphology
                      radius="3"
                      operator="dilate"
                      in="SourceAlpha"
                      result="effect1_dropShadow_236_1971"
                    />
                    <feOffset dx="3" dy="3" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0.3 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_236_1971"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_236_1971"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <span
                className={`absolute left-[37%] top-0.5 translate-y-2/3  z-20 $text-brown"  text-sm`}
              >
                {page + i + 1}
              </span>
            </button>
          ) : null;
        })}  */}

      <button
        className="bg-white rounded-full w-10 h-10 flex hover:bg-brown items-center place-content-center group shadow"
        onClick={() => {
          setShowNextPages(false);
          setPage((page: number) => (page !== total_pages ? page + 1 : page));
        }}
      >
        <RiArrowRightSLine className="text-black text-md group-hover:text-white" />
      </button>
      <button
        className="bg-white rounded-full w-10 h-10 flex hover:bg-brown items-center place-content-center group shadow"
        onClick={() => {
          setShowNextPages(false);
          setPage((page: number) =>
            page !== total_pages ? total_pages : total_pages
          );
        }}
      >
        <RxDoubleArrowRight className="text-black text-md group-hover:text-white" />
      </button>
    </div>
  );
};

export default Pagination;
