import { motion } from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {nanoid} from "@reduxjs/toolkit";

function NotActiveRightNow({text}: {text?: string}) {
    const [showComponent, setShowComponent] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowComponent(false);
        }, 3000);
    }
    , []);

    return (
        <>
            {
                showComponent ? (
                    <motion.div className="absolute top-0 -translate-y-10 bg-gray-100 border rounded-3xl text-center p-3 text-sm"
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        {text ? text : "Not active right now."}
                    </motion.div>)
                    : null
            }
        </>
    );
}

export default NotActiveRightNow;