import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Brands() {
  let brandList = useSelector(
    (state: any) => state.filterReducer?.["brand_list"]
  );
  function fixAmpersand(data) {
    return data.map((item) => {
      return { ...item, name: item.name.replace(/&amp;/g, "&") };
    });
  }
  brandList = fixAmpersand(brandList);

  let newObject = {};

  brandList?.forEach((brand: any) => {
    const firstLetter = brand?.name.charAt(0).toUpperCase(); // İlk harfi büyük harfe çevir
    if (firstLetter.match(/[A-Z]/)) {
      // Eğer ilk harf A-Z arasında ise
      if (!newObject[firstLetter]) {
        newObject[firstLetter] = [];
      }
      newObject[firstLetter].push(brand);
    }
  });

  const scrollToLetter = (letter) => {
    const element = document.getElementById(letter);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offset = elementRect.top - 100;
      window.scrollBy({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="mt-24"
    >
      <p className="text-center block pt-10 pb-1 md:py-3 lg:py-6 lg:text-lg md:text-md text-black font-semibold tracking-widest">
        PERFUME BRANDS A TO Z
      </p>
      <div className="flex justify-center flex-wrap gap-3 w-10/12 mx-auto mt-2 border-y py-2">
        {Object?.entries(newObject).map(([letter, brands]: any) => (
          <button
            key={letter}
            onClick={() => scrollToLetter(letter)}
            className="w-10 h-10 hover:bg-brown border hover:text-white rounded-full
                        hover:border-0"
          >
            {letter}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 w-10/12 mx-auto mt-6">
        {Object?.entries(newObject).map(([letter, brands]: any) => (
          <div key={letter} id={letter} className="border-b-2">
            <div className="text-start text-white text-lg font-bold mb-4 bg-brown pl-4 py-2 rounded-18 mt-10">
              {letter}
            </div>
            <div className="flex flex-wrap justify-start gap-3">
              {brands?.map((brand: any) => (
                <Link
                  to={`/shop/pa_brand/${brand?.id}`}
                  key={brand?.id}
                  className="flex mb-4 p-3 bg-white shadow-secondary rounded-18
                                            hover:bg-brown hover:text-white"
                >
                  {brand?.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
}
