import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const getUserInfo = createAsyncThunk(
    "user/getUserInfo",
    async (thunkAPI) => {
        try {
            const state = store.getState();

            const token = Cookies.get("sp-token");
            const user = state.userReducer.user;
       
            
            // @ts-ignore
            const response = await axios.post(`https://stockperfume.com/wp/wp-json/wp/v3/customer/info?JWT=${token}`);
       
            return response.data;

        } catch (error: any) {
          // @ts-ignore
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });