export function convertArrayToCSV(array: any[]) {
    let csv = '';

    // Add the headers
    const headers = Object.keys(array[0]);
    delete headers[headers.indexOf('related_products')];
    csv += headers.join(',') + '\n';

    // Add the rows
    array.forEach((row) => {
        let rowValues = headers.map((header) => {
            if (typeof row[header] === "object") {
                return null
            }
            const escapedValue = ('' + row[header]).replace(/"/g, '""');

            return `"${escapedValue}"`;

        });

        csv += rowValues.join(',') + '\n';
    });

    return csv;
}
