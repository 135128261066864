import React from "react";
import { useAppSelector } from "../../app/hooks";
import { FiUser } from "react-icons/fi";

function ProfileIcon({ size, bgColor }: { size?: string; bgColor?: any }) {
  const username = useAppSelector((state) => state?.userReducer?.user?.name);
  const isLogin = useAppSelector((state) => state.userReducer.loggedIn);

  return (
    <div
      className={` rounded-full ${
        size ? size : "w-10 h-10"
      } flex items-center justify-center gap-6 text-white ${
        bgColor ? bgColor : "bg-brown"
      }`}
    >
      {isLogin ? (
        username ? (
          username?.toUpperCase()[0]
        ) : (
          <FiUser />
        )
      ) : (
        <FiUser />
      )}
    </div>
  );
}

export default ProfileIcon;
