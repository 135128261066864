import React from "react";
import { motion } from "framer-motion";

import Accordion, { Description } from "../MyAccount/Accordion";

import { Link, redirect, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Title } from "../MyAccount/Accordion";
import { nanoid } from "@reduxjs/toolkit";
import AddressUserInfo from "./AddressUserInfo";
import CartList from "./CartList";
import OrderInfo from "./OrderInfo";
import LoadingSpinner from "../Common/LoadingSpinner";
import { useEffect } from "react";

function Checkout({ children, number }: any) {
  const [isShippingSameAsBilling, setIsShippingSameAsBilling] =
    React.useState(false);
  const navigate = useNavigate();
  const pages = ["Product", "Address", "Shipping", "Payment", "Confirmation"];

  const [address, setAddress] = React.useState();
  const loading = useAppSelector((state) => state.userReducer.loading);

  const payment_link = useAppSelector(
    (state) => state.ordersReducer.payment_link
  );
  const order_loading = useAppSelector((state) => state.ordersReducer.loading);

  useEffect(() => {
    if (payment_link != "") {
      window.location.replace(payment_link);
    }
  }, [payment_link]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div>
        {loading || (order_loading && <LoadingSpinner />)}
        <div className="  md:hidden absolute top-5 left-5 z-20">
          <button
            onClick={() => navigate(-1)}
            className="bg-white w-8 h-8 flex justify-center items-center rounded-full"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.92868 5.07144L5.07153 8.92859M5.07153 5.07144L8.92868 8.92859M13.4287 7.00001C13.4287 10.5504 10.5505 13.4286 7.00011 13.4286C3.4497 13.4286 0.571533 10.5504 0.571533 7.00001C0.571533 3.44961 3.4497 0.571442 7.00011 0.571442C10.5505 0.571442 13.4287 3.44961 13.4287 7.00001Z"
                stroke="#202020"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        {/* Mobile */}
        <div className="flex flex-col space-y-5 md:hidden relative">
          <p className="text-center text-sm font-medium mt-8">Order Details</p>
          <div className="flex items-center mx-auto">
            {[...Array(5)].map((_, index) => {
              return (
                <div id={index + ""} className="flex ">
                  <Link
                    to={`/checkout/${pages[index]}`}
                    className="flex flex-col items-center justify-center"
                  >
                    <button
                      className={`rounded-full w-8 h-8 flex items-center justify-center gap-6${
                        number >= index
                          ? "bg-brown text-white"
                          : "border border-black"
                      }`}
                    >
                      {index + 1}
                    </button>
                    <p className="text-xxs text-center">{pages[index]}</p>
                  </Link>
                  {number >= index + 1 ? (
                    <svg
                      className="mt-4"
                      width="34"
                      height="1"
                      viewBox="0 0 34 1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line y1="0.5" x2="34" y2="0.5" stroke="#B59679" />
                    </svg>
                  ) : index != 4 ? (
                    <svg
                      className="mt-4"
                      width="34"
                      height="1"
                      viewBox="0 0 34 1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="0.75"
                        x2="34"
                        y2="0.75"
                        stroke="#202020"
                        stroke-width="0.5"
                      />
                    </svg>
                  ) : null}
                </div>
              );
            })}
          </div>

          <div className="">{children}</div>
        </div>

        {/* Desktop */}
        <div className="hidden md:block">
          <p className="text-lg leading-6 mt-32 mb-8 text-center font-semibold">
            Checkout
          </p>
          <div className="grid grid-cols-12 lg:mx-20 mx-10 gap-4  ">
            <div className="col-span-12 lg:col-span-9 flex flex-col gap-4">
              <AddressUserInfo
                className="h-fit"
                address={address}
                setAddress={setAddress}
                isShippingSameAsBilling={isShippingSameAsBilling}
                setIsShippingSameAsBilling={() =>
                  setIsShippingSameAsBilling((prev) => !prev)
                }
              />
              <CartList />
            </div>

            <OrderInfo
              className=""
              isShippingSameAsBilling={isShippingSameAsBilling}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Checkout;
