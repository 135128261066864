const europeanCountries = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];
export type PaymentMethodsType = {
  banktransfer: boolean;
  amex: boolean;
  mastercard: boolean;
  visa: boolean;
  maestro: boolean;
  ideal: boolean;
  giropay: boolean;
  sofort: boolean;
  bancontact: boolean;
  mybank: boolean;
}

export default function filterPaymentMethods(country: string): PaymentMethodsType {
  const paymentMethods: PaymentMethodsType = {
    banktransfer: false,
    amex: true,
    mastercard: true,
    visa: true,
    maestro: true,
    ideal: false,
    giropay: false,
    sofort: false,
    bancontact: false,
    mybank: false,
  };

  if (europeanCountries.includes(country)) {
    paymentMethods.banktransfer = true;
  }
  if (country === "DE") {
    paymentMethods.giropay = true;
  }
  if (country === "NL") {
    paymentMethods.ideal = true;
  }
  if (country === "IT") {
    paymentMethods.mybank = true;
  }
  if (country === "BE") {
    paymentMethods.bancontact = true;
  }
  if (
    country === "AT" ||
    country === "BE" ||
    country === "FR" ||
    country === "DE" ||
    country === "IT" ||
    country === "PL" ||
    country === "ES" ||
    country === "CH" ||
    country === "GB"
  ) {
    paymentMethods.sofort = true;
  }

  // Diğer ülkelere özel ödeme yöntemleri burada ayarlanabilir

  return paymentMethods;
}
