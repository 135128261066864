import {toast} from "react-toastify";

export const toastOptions = (theme = "light") => ({
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
   theme,
});

export const notify = (message, status="success") =>  
{
    const theme = status === "success" ? "light" : "dark";
    toast(message, toastOptions(theme))
};