import { createSlice } from "@reduxjs/toolkit";
import { searchProductsList } from "../actions/searchProductList";
import { searchProductsInline } from "../actions/searchProductsInline";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchResult: [],
    category: null,
    isLoading: false,
  },
  reducers: {
    clearSearch: (state) => {
      state.searchResult = [];
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(searchProductsInline.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchProductsInline.fulfilled, (state, { payload, meta }) => {
        state.searchResult = payload;
        state.isLoading = false;
      })
      .addCase(searchProductsInline.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { clearSearch, setCategory, setLoading } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
