import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const updateAddress = createAsyncThunk(
    "user/updateAddress",
    async (args: any,thunkAPI) => {
        try {
            const state = store.getState();

            const token = Cookies.get("sp-token");
            const user = state.userReducer.user;
          
            // @ts-ignore
            const response = await axios.post(`https://www.stockperfume.com/wp/wp-json/wp/v3/customer/address?JWT=${token}`, args);

            return { addresses : response.data.all_addresses, id: args.id }

        } catch (error: any) {
       
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });