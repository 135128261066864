import { store } from "../../../../index";
import { Product } from "../productType";
import { setSingleProduct } from "../slices/singleProductSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { productsReducer } from "../slices/productSlice";

export const getSingleProductFromAPI = createAsyncThunk(
  "products/getSingleProductFromAPI",
  async (url: any, thunkAPI) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSingleProduct = (id: number) => {
  const state = store.getState();
  const products = state.productsReducer.products;
  const productInList = products.find((product: Product) => product.id === id);
  if (productInList) store.dispatch(setSingleProduct(productInList));
  else
    store.dispatch(
      getSingleProductFromAPI(
        `https://www.stockperfume.com/wp/wp-json/wp/v3/products?product_slug=${id}`
      )
    );
};
