import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios"

export const getBestSellerProducts = createAsyncThunk(
    "products/getBestSellerProducts",
    async (args,thunkAPI) => {
        try {
            const url = 'https://www.stockperfume.com/wp/wp-json/wp/v3/products/?per_page=20&orderby=popularity';
            const response = await axios.get(url);

            return response.data;
        } catch (error: any ) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });