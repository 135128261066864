const paymentRates = {
  AMEX: 0.0329,
  MASTERCARD: 0.0249,
  VISA: 0.0249,
  BANKTRANS: 0,
  /*  KLARNA: 0.02, */

  IDEAL: 0,
  
  DIRECTBANK: 0.009, //SOFORT id DIRECTBANK
  GIROPAY: 0.0125,
  /*  MYBANK: 0, */
  MISTERCASH: 0, // BANCONTACT id MISTERCASH
  MAESTRO: 0.024,
  MYBANK: 0,
};

export default paymentRates;
