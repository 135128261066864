import React from "react";
import { Link } from "react-router-dom";
import Accordion, { Title, Description } from "../../MyAccount/Accordion";
import { useAppSelector } from "../../../app/hooks";
import { BsPencilSquare, BsXLg } from "react-icons/bs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  emptyCart,
  removeFromCart,
} from "../../../app/features/cart/slices/cartSlice";
import EditQuantityModal from "../EditQuantityModal";
function ProductSection() {
  const cart = useAppSelector((state) => state.cartReducer);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const dispatch = useDispatch();
  return (
    <>
      <EditQuantityModal
        product={selectedProduct}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="flex flex-col space-y-5 mx-4">
        <Accordion buttonPosition="right-5" className="bg-white rounded-20">
          <Title className="bg-white  py-5 px-3 rounded-20  shadow w-full">
            <div className="flex items-center gap-4 ">
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1H4.04762L6.08952 11.2019C6.1592 11.5527 6.35003 11.8678 6.62861 12.092C6.90719 12.3163 7.25577 12.4354 7.61333 12.4286H15.019C15.3766 12.4354 15.7252 12.3163 16.0038 12.092C16.2824 11.8678 16.4732 11.5527 16.5429 11.2019L17.7619 4.80952H4.80952M7.85714 16.2381C7.85714 16.6589 7.51603 17 7.09524 17C6.67445 17 6.33333 16.6589 6.33333 16.2381C6.33333 15.8173 6.67445 15.4762 7.09524 15.4762C7.51603 15.4762 7.85714 15.8173 7.85714 16.2381ZM16.2381 16.2381C16.2381 16.6589 15.897 17 15.4762 17C15.0554 17 14.7143 16.6589 14.7143 16.2381C14.7143 15.8173 15.0554 15.4762 15.4762 15.4762C15.897 15.4762 16.2381 15.8173 16.2381 16.2381Z"
                  stroke="#202020"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Products</p>
            </div>
          </Title>
          <Description className="bg-white rounded-20 ">
            <div className=" mt-4 flex flex-col gap-y-8 mx-4 pb-4">
              {cart.products?.map((item: any) => {
                return (
                  <div className="flex relative" key={item?.id}>
                    <div className="absolute top-0 right-1 flex items-center gap-5">
                      <div
                        onClick={() => {
                          setSelectedProduct(item);
                          setShowModal(true);
                        }}
                      >
                        <BsPencilSquare className="text-black text-base hover:text-brown hover:cursor-pointer" />
                      </div>
                      <div
                        onClick={() => {
                          dispatch(removeFromCart(item));
                        }}
                      >
                        <BsXLg className="text-black text-base hover:text-brown hover:cursor-pointer" />
                      </div>
                    </div>
                    <div className="basis-4/5 sm:basis-7/12 flex-shrink flex">
                      <div className="basis-[88%] flex lg:gap-8 gap-6 items-center">
                        <img
                          className="w-10 h-12 "
                          src={item.images[0]}
                          alt=""
                        />
                        <div className="text-sm ">
                          <p className="text-black ">{item.name}</p>
                          <p className="text-gray-dark">EAN: {item.ean}</p>
                        </div>
                      </div>
                    </div>
                    <div className="basis-1/5 sm:basis-5/12 flex justify-around items-center">
                      <div className="text-sm ">{item.quantity + " x"}</div>

                      <div className="text-sm ">€{item.price?.toFixed(2)}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Description>
        </Accordion>
        <Link to={"/checkout/address"} className="flex justify-center">
          <button className="bg-black text-white rounded-100 px-9 py-2  text-xs">
            Proceed to payment
          </button>
        </Link>
      </div>
    </>
  );
}

export default ProductSection;
