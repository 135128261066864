import PhoneInput from "react-phone-number-input";
import { updateAddress } from "../../app/features/user/actions/updateAddress";
import { useAppDispatch } from "../../app/hooks";
import countries, { european_countries } from "../../constants/countries";
import { updateUserInfo } from "../../app/features/user/actions/updateUserInfo";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { setQuantity } from "../../app/features/cart/slices/cartSlice";
import validator from "validator";
import toInt = validator.toInt;
import { getType } from "@reduxjs/toolkit";
import { notify } from "../../functions/notify";

interface FormInterface {
  [key: string]: string;
}

function EditQuantityModal({ product, setShowModal, showModal }: any) {
  const dispatch = useAppDispatch();
  const [productQuantity, setProductQuantity] = React.useState(
    product?.quantity
  );

  function enforceMinMax(e: any) {
    let value = parseInt(e.value);
    if (isNaN(value)) {
      e.value = e.min;
    }
    if (value < parseInt(e.min)) {
      e.value = e.min;
    } else if (value > parseInt(e.max)) {
      notify(
        `${product?.stock_quantity} is maximum quantity of this product`,
        "error"
      );
      e.value = e.max;
    }
  }
  useEffect(() => {
    setProductQuantity(product?.quantity);
  }, [product, showModal, setShowModal]);

  return (
    <div className={showModal ? "" : "hidden"}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <p className="text-xl font-semibold">Edit Product Quantity</p>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}

            <div className="flex justify-center my-4">
              <input
                defaultValue={product?.quantity}
                max={product?.stock_quantity}
                value={productQuantity}
                onChange={(e) => {
                  enforceMinMax(e.target);
                  setProductQuantity(e.target.value);
                }}
                type="number"
                className="w-full mx-5 border px-5 rounded-20 py-1"
              />
            </div>

            {/*footer*/}
            <div className="flex items-center justify-center gap-6 p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-white bg-brown font-medium rounded-20 px-6 py-3 uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>

              <button
                className="bg-black text-white font-semibold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  // @ts-ignore
                  dispatch(
                    setQuantity({ product: product, quantity: productQuantity })
                  );
                  setShowModal(false);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default EditQuantityModal;
