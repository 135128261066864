import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {nanoid} from "@reduxjs/toolkit";
import {forgotPassword} from "../../app/features/user/actions/forgotPassword";
import {toast} from "react-toastify";
import {resetPassword} from "../../app/features/user/actions/resetPassword";
import {AuthenticationAPI} from "../../app/API/Routes";

function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const [resetpasswordShown, setresetPasswordShown] = useState([false, false]);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const resettogglePasswordVisiblity = (index: number) => {
        const temp = resetpasswordShown;
        temp[index] = !temp[index];
        setresetPasswordShown([...temp]);
    };

    const [passwordReset, setPasswordReset] = useState(false);
    const error = useSelector((state: any) => state.userReducer.error);
    const [username, setUserName] = useState("");
    const [resetEmail, setResetEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState(["",""]);
    const [resetCode, setResetCode] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state} = useLocation();
    const {loggedIn, message, loading, resetEmailSended, passwordChanged} = useSelector((state: any) => state.userReducer);

    const handleLogin = (e: any) => {
        e.preventDefault();
      if(passwordReset === false) {
        // @ts-ignore
        dispatch(AuthenticationAPI.login({username, password}));
      } else {
          if(!resetEmailSended) {
              // @ts-ignore
              dispatch(forgotPassword({email: resetEmail}))
          }
          else{
              if(newpassword[0] !== newpassword[1]) {
                  toast("Passwords do not match", {type: "error"})
                  return
              }
                // @ts-ignore
                dispatch(resetPassword({email: resetEmail, code: resetCode, password: newpassword[0]}))
          }
      }
    };

    useEffect(() => {
        if (loggedIn) {
            return navigate(state?.path || "/");
        }
    }, [loggedIn]);

    useEffect(() => {
        if (passwordChanged) {
            window.location.replace("https://stockperfume.com/login");
        }
    }, [passwordChanged]);

    return (
        <motion.div
            initial={{opacity: 0}}
            exit={{opacity: 0}}
            animate={{opacity: 1}}
            className="min-h-screen lg:mb-96 md:mb-86"
        >
            <div
                className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] min-h-[163px]  mt-16 lg:-mt-10   bg-no-repeat grid grid-cols-12 py-8 pt-16 lg:pt-52 lg:py-0 lg:px-12">
                <div className="lg:col-start-1 lg:col-end-5 col-start-8   col-end-13  lg:mx-10">
                    <p className="lg:text-3xl text-black text-xl font-bold">
                        Welcome to
                    </p>
                    <p className="lg:text-3xl text-black lg:text-xl font-thin">Stockperfume</p>
                </div>

                <div className="hidden lg:block col-start-10 col-end-13  ">
                    <p className="text-xl text-black">
                        Use the following form to login or create new account with
                        Stockperfume for free.
                    </p>
                </div>
            </div>
            <div
                className="lg:w-2/6 sm:w-2/3 sm:absolute sm:left-1/2 sm:transition sm:-translate-x-1/2 mb-18 mx-4 sm:mx-0 lg:-mt-36    mt-8 ">
                <form
                    onSubmit={(e) => {
                        handleLogin(e);
                    }}
                    className="grid grid-cols-12 rounded-20 gap-5  bg-white shadow md:px-10 px-6 py-8   "
                >
                    <div className="col-span-full">
                        <p className="text-center text-xl">{passwordReset ? "Password Reset" :  "Sign in"}</p>
                    </div>
                    {
                        error && (
                            <div className="col-span-full">
                                <p className="text-center text-red">Incorrect login information. Please try again.</p>
                            </div>
                        )
                    }
                    <div className="col-span-12  md:px-0">
                        <input
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="Type your new email address*"
                            className={"border rounded-20 w-full py-2 px-5" + (passwordReset ? " hidden": "") }
                            type="email"
                            name="email" id="email"
                            disabled={passwordReset}
                        />

                        <input
                            onChange={(e) => setResetEmail(e.target.value)}
                            placeholder="Type your email address*"
                            className={"border rounded-20 w-full py-2 px-5" + (passwordReset && !resetEmailSended ? "": " hidden")}
                            type="email"
                            name="email" id="email"
                            disabled={!passwordReset}
                        />

                        <input
                            required={passwordReset && resetEmailSended}
                            onChange={(e) => setResetCode(e.target.value)}
                            placeholder="Type the CODE sent to your e-mail*"
                            className={"border rounded-20 w-full py-2 px-5" + (passwordReset && resetEmailSended ? "": " hidden")}
                            type="text"
                            name="code" id="code"
                            disabled={!passwordReset || loading || !resetEmailSended}
                        />

                        <div className={"relative py-5 " + (passwordReset && resetEmailSended ? "": " hidden")}>
                            <input required={passwordReset && resetEmailSended}
                                   onChange={(e) => setNewPassword([e.target.value, newpassword[1]])}
                                   name="resetpassword"
                                   placeholder="New password*"
                                   className="border rounded-20 placeholder:text-black text-xs text-black lg:w-full py-3 px-5"
                                   type={resetpasswordShown[0] ? "text" : "password"}
                                   disabled={!passwordReset || loading || !resetEmailSended}
                            />
                            <div
                                onClick={() => {
                                    resettogglePasswordVisiblity(0);
                                }}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            >
                                <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                                        stroke="#202020"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                                        stroke="#202020"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className={"relative " + (passwordReset && resetEmailSended ? "": " hidden")}>
                            <input required={passwordReset && resetEmailSended}
                                   onChange={(e) => setNewPassword([newpassword[0], e.target.value])}
                                   name="resetpassword2"
                                   placeholder="New password again*"
                                   className={"border rounded-20 lg:w-full placeholder:text-black text-xs text-black py-3 px-5"
                                                + (passwordReset && resetEmailSended ? "": " hidden")}
                                   type={resetpasswordShown[1] ? "text" : "password"}
                                   disabled={!passwordReset || loading || !resetEmailSended}
                            />
                            <div
                                onClick={() => {
                                    resettogglePasswordVisiblity(1);
                                }}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            >
                                <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                                        stroke="#202020"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                                        stroke="#202020"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>

                    </div>
                    <div className={"col-span-12  md:px-0 relative" + (passwordReset ? " hidden " :"")}>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password*"
                            className="border placeholder:text-black rounded-20 w-full py-2 px-5"
                            type={passwordShown ? "text" : "password"}
                            name="password" id="password"
                        />
                        <div
                            onClick={() => {
                                togglePasswordVisiblity();
                            }}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        >
                            <svg
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                                    stroke="#202020"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                                    stroke="#202020"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="col-span-full text-xs justify-between flex gap-4">
                        <div className={`flex gap-4 ` }>
                            <input className={(passwordReset ? "hidden" : "")} type="checkbox" name="" id=""/>
                            <label className={(passwordReset ? "hidden" : "")} htmlFor="">Remember me</label>
                        </div>
                        <label onClick={()=>setPasswordReset((prevState)=> !prevState)} className="hover:text-gray-500 cursor-pointer" htmlFor="">
                          {passwordReset ? "I want to login." : "I forgot my password."}
                        </label>
                    </div>

                    <div className="col-span-full ">
                        <button className="bg-black text-white rounded-20 w-full py-2 px-5">
                            {
                                loading ? (<div
                                    className="flex w-full h-full place-items-center justify-center object-contain spinner-container">
                                    <div className="loading-spinner w-[20px] h-[20px]">
                                    </div>
                                </div>) : ( passwordReset ? " Reset Password " : "Sign in")
                            }
                        </button>
                    </div>
                {/*     <div className="col-span-full flex justify-center">
                        <p id={nanoid()}
                           className="font-medium text-center underline text-brown">{message ? message : null}</p>
                    </div> */}
                    <div className="col-span-full flex items-center py-1">
                        <div className="flex-grow h-px bg-gray-400"></div>
                        <span className="flex-shrink text-xs text-black px-4 font-light">
              OR
            </span>

                        <div className="flex-grow h-px bg-gray-400"></div>
                    </div>

                    <Link to={"/register"} className="col-span-full mb-6 mt-3">
                        <button className="border text-lg rounded-20 w-full py-2 px-5">
                            Sign up
                        </button>
                    </Link>
                </form>
            </div>
        </motion.div>
    );
}

export default Login;
