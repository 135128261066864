import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRelatedProducts = createAsyncThunk(
  "products/getRelatedProducts",
  async (id: any, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://www.stockperfume.com/wp/wp-json/wp/v3/products?product_slug=${id}`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
