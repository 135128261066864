import { createSlice, current } from "@reduxjs/toolkit";
import { signUpUser } from "./actions/signUpUser";
import { revokeToken } from "./actions/revokeToken";
import { validateToken } from "./actions/validateToken";
import { updateUserInfo } from "./actions/updateUserInfo";
import { updateAddress } from "./actions/updateAddress";
import { addAddress } from "./actions/addAddress";
import { deleteAddress } from "./actions/deleteAddress";
import { getAddress } from "./actions/getAdress";
import { updateCargoInfo } from "./actions/updateCargoInfo";
import { getUserInfo } from "./actions/getUserInfo";
import { changePassword } from "./actions/changePassword";
import { forgotPassword } from "./actions/forgotPassword";
import { resetPassword } from "./actions/resetPassword";
import { AuthenticationAPI } from "../../API/Routes";
import { validateVAT } from "./actions/validateVAT";
import Cookies from "js-cookie";
import { notify } from "../../../functions/notify";

const initialState = {
  message: "",
  user: (Cookies.get("sp-user") && JSON.parse(Cookies.get("sp-user"))) || null,
  token: Cookies.get("sp-token") || null,
  loading: false,
  error: false,
  loggedIn: Cookies.get("sp-token") ? true : false,
  resetEmailSended: false,
  passwordChanged: false,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(AuthenticationAPI.login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      AuthenticationAPI.login.fulfilled,
      (state, { payload: { error, success, data }, meta }) => {
        state.loading = false;
        if (error) {
          state.error = error;
        } else {
          state.message = "You have successfully logged in.";
          state.token = data.jwt;

          state.user = {
            id: 0,
            name: "",
            surname: "",
            email: "",
            VATnumber: {
              number: "",
              country: "",
              isValid: "false",
            },
            billing: "",
            shipping: "",
            savedAddresses: [],
          };
        }
        state.loggedIn = true;
        notify("You have successfully logged in!");
        Cookies.set("sp-message", success, { expires: 7 });
        Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
        Cookies.set("sp-token", data?.jwt, { expires: 1 });
      }
    );
    // @ts-ignore
    builder.addCase(
      AuthenticationAPI.login.rejected,
      (state, { payload, meta }: any) => {
        state.loading = false;
        state.token = "";
        state.error = true;
        state.message = payload.message;
        state.loggedIn = false;
        state.user = {
          id: 0,
          name: "",
          email: "",
          surname: "",
          VATnumber: {
            number: "",
            country: "",
            isValid: "false",
          },
          billing: "",
          shipping: "",
          savedAddresses: [],
        };
      }
    );

    // UPDATE USER INFO
    builder.addCase(updateUserInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.user.name = payload.first_name;
        state.user.surname = payload.last_name;
        state.user.VATnumber = {
          number: payload.vat_number?.number || "",
          country: payload.vat_number?.country || "",
          isValid: payload.vat_is_valid || "false",
        };
      }
      state.loggedIn = true;
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });

    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.loading = false;
    });

    // GET USER INFO

    builder.addCase(getUserInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload?.error;
      } else {
        state.user.name = payload?.first_name;
        state.user.surname = payload?.last_name;
        state.user.VATnumber = state.user.VATnumber || {}; // VATnumber nesnesi yoksa boş bir nesne atayın
        state.user.VATnumber.number = payload?.vat_number?.number || "";
        state.user.VATnumber.country = payload?.vat_number?.country || "";
        state.user.VATnumber.isValid = payload?.vat_is_valid || "false";
        state.user.billing = payload?.billing;
        state.user.shipping = payload?.shipping;
        state.user.email = payload?.email;
        state.user.id = payload?.user_id;
      }
      state.loggedIn = true;
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });

    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;

      state.message = "You entered incorrectly. Try again.";
    });

    // UPDATE CARGO INFO
    builder.addCase(updateCargoInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCargoInfo.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        if (meta.arg.change == "billing") {
          // @ts-ignore
          state.user.billing = current(state.user.savedAddresses).find(
            (item: any) => item.id === payload.address_ids.billing
          );
          state.user.shipping = payload.shipping;
        } else {
          // @ts-ignore
          state.user.shipping = current(state.user.savedAddresses).find(
            (item: any) => item.id === payload.address_ids.shipping
          );
          state.user.billing = payload.billing;
        }
      }
      state.loggedIn = true;
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });
    builder.addCase(updateCargoInfo.rejected, (state, action) => {
      state.loading = false;

      state.message = "You entered incorrectly. Try again.";
    });

    // Get Adress
    builder.addCase(getAddress.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAddress.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        // @ts-ignore
        state.user.savedAddresses = [];
        Object.keys(payload.all_addresses).forEach((key) => {
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...payload.all_addresses[key],
          });
        });
      }

      state.loggedIn = true;
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });
    builder.addCase(getAddress.rejected, (state, action) => {
      state.loading = false;
    });

    // Add Adress
    builder.addCase(addAddress.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addAddress.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.user.savedAddresses = [];
        Object.keys(payload.all_addresses).forEach((key) => {
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...payload.all_addresses[key],
            house_number: meta.arg.house_number,
            company: meta.arg.company,
          });
        });
      }

      state.loggedIn = true;
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });
    builder.addCase(addAddress.rejected, (state, action) => {
      state.loading = false;
    });

    // UPDATE Adress
    builder.addCase(updateAddress.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateAddress.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      state.user.savedAddresses = [];
      Object.keys(payload.addresses).forEach((key) => {
        if (meta.arg.address_id === key) {
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...payload.addresses[key],
            house_number: meta.arg.house_number,
            company: meta.arg.company,
          });
        } else {
          // @ts-ignore
          const house_number = state.user.savedAddresses.find(
            (item: any) => item.id === key
          )?.house_number;
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...payload.addresses[key],
            house_number: house_number,
          });
        }
      });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });
    builder.addCase(updateAddress.rejected, (state, action) => {
      state.loading = false;
    });

    // DELETE Adress
    builder.addCase(deleteAddress.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteAddress.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      } else {
        state.user.savedAddresses = [];
        Object.keys(payload.all_addresses).forEach((key) => {
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...payload.all_addresses[key],
          });
        });
      }
      Cookies.set("sp-message", payload.success, { expires: 7 });
      Cookies.set("sp-user", JSON.stringify(state.user), { expires: 7 });
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.loading = false;
    });

    // REGISTER
    builder.addCase(signUpUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signUpUser.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (!payload.success) {
        state.error = payload.success;
      } else {
        state.message = payload.message;
        state.token = payload.jwt;
        state.loggedIn = true;
        state.user.VATnumber.isValid = payload.user_info.vat_is_valid;
        state.user.VATnumber.number = payload.user_info.vat_number.number;
        state.user.VATnumber.country = payload.user_info.vat_number.country;

        state.user.savedAddresses = [];
        Object.keys(payload.addresses).forEach((key) => {
          const address = { ...payload.addresses[key] };
          // @ts-ignore
          state.user.savedAddresses.push({
            id: key,
            ...address,
            house_number: meta.arg.house_number,
          });
        });
        notify("You have successfully registered!");
        Cookies.set("sp-message", payload.message, { expires: 7 });
        Cookies.set("sp-user", JSON.stringify(payload.user), { expires: 7 });
        Cookies.set("sp-token", payload.jwt, { expires: 1 });
      }
    });

    // @ts-ignore
    builder.addCase(signUpUser.rejected, (state, { payload, meta }: any) => {
      state.loading = false;
      state.message = payload.error.data.data.message;
      state.error = true;
      Cookies.set("sp-error", JSON.stringify(state.error), { expires: 7 });
    });
    // VALIDATE TOKEN

    builder.addCase(validateToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(validateToken.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (!payload.success) {
        state.error = payload.message;
      } else {
        state.token = meta.arg.token;
        //state.user = payload.data.user;
        Cookies.set("sp-user", JSON.stringify(payload.data.user), {
          expires: 7,
        });
        state.loggedIn = true;
      }
    });
    builder.addCase(validateToken.rejected, (state, action) => {
      state.token = "";
      state.loggedIn = false;
      state.loading = false;
      state.user = {
        id: 0,
        email: "",
        name: "",
        surname: "",

        VATnumber: {
          number: "",
          country: "",
          isValid: "false",
        },
        billing: "",
        shipping: "",
        savedAddresses: [],
      };
      Cookies.set("sp-error", "", { expires: -1 });
      Cookies.set("sp-message", "", { expires: -1 });
      Cookies.set("sp-user", "", { expires: -1 });
      Cookies.set("sp-token", "", { expires: -1 });
    });
    // REVOKE TOKEN
    builder.addCase(revokeToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(revokeToken.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      if (!payload.success) {
        state.error = payload.message;
      } else {
        state.message = "";
        state.token = "";
        state.loggedIn = false;
        state.user = {
          id: 0,
          name: "",
          email: "",
          surname: "",
          VATnumber: {
            number: "",
            country: "",
            isValid: "false",
          },
          billing: "",
          shipping: "",
          savedAddresses: [],
        };
        Cookies.set("sp-error", "", { expires: -1 });
        Cookies.set("sp-message", "", { expires: -1 });
        Cookies.set("sp-user", "", { expires: -1 });
        Cookies.set("sp-token", "", { expires: -1 });
      }
    });
    builder.addCase(revokeToken.rejected, (state, action) => {
      state.token = "";
      state.loggedIn = false;
      state.loading = false;
      state.message = "";
      state.user = {
        id: 0,
        email: "",
        name: "",
        surname: "",
        billing: "",
        shipping: "",
        VATnumber: {
          number: "",
          country: "",
          isValid: "false",
        },
        savedAddresses: [],
      };
      Cookies.set("sp-error", "", { expires: -1 });
      Cookies.set("sp-message", "", { expires: -1 });
      Cookies.set("sp-user", "", { expires: -1 });
      Cookies.set("sp-token", "", { expires: -1 });
    });
    builder.addCase(changePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload, meta }) => {
      state.loading = false;
      state.message = payload.message;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.message = "An unexpected error was encountered.";
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.resetEmailSended = action.payload.success;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      // @ts-ignore
      state.message = action.payload?.error?.data?.data?.message;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.passwordChanged = action.payload.success;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      // @ts-ignore
      state.message = action.payload?.error?.data?.data?.message;
    });
    builder.addCase(validateVAT.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(validateVAT.fulfilled, (state, action) => {
      state.loading = false;
      state.user.VATnumber.isValid = action.payload;
    });
    builder.addCase(validateVAT.rejected, (state, action) => {
      state.loading = false;
      state.message = "An unexpected error was encountered.";
    });
  },
});

// @ts-ignore
export const { addUserInfo } = userSlice.actions;
export const userReducer = userSlice.reducer;
