import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function CheckoutConfirmation() {
    const navigate = useNavigate();
    return (
        <div className="block">
            <p className="text-lg leading-6 mt-32 mb-8 text-center">
                Checkout
            </p>
            <div className="w-7/12 mx-auto bg-white flex flex-col items-center pt-[87px] pb-[69px]"
                style={{
                    borderRadius: '20px',
                    boxShadow: '3px 3px 15px 3px rgba(211, 211, 211, 0.40)'
                }}>
                <div className="flex bg-black w-20 h-20 rounded-full items-center">
                    <svg className='mx-auto' width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.0909 4L13.0909 24L4 14.9091" stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <p className="text-xl mt-[38px]">Thank you!</p>
                <p className="text-sm text-center mt-2">
                    We start processing your order and you will receive a confirmation to <br />
                    your email address test@stockperfume.com. Contact us for any <br />
                    additional information.
                </p>
                <button className="text-base w-[270px] bg-black text-white mt-10 h-10 hover:bg-brown"
                    onClick={() => navigate('/my-account/orders')}
                    style={{ borderRadius: '100px' }}>
                    View order details
                </button>
                <button className="text-base w-[270px] bg-brown text-white mt-[18px] h-10 hover:bg-black"
                    onClick={() => navigate('/')}
                    style={{ borderRadius: '100px' }}>
                    Back to home page
                </button>
            </div>
        </div >
    )
}
