import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from "../../../hooks";
import { store } from "../../../..";

export const getGenderFilters = createAsyncThunk(
  "filters/getGenderFilters",
  async (args, thunkAPI) => {
    const state = store.getState();
    const selected_filters = state.filterReducer.selectedFilter;

    try {
      const response = await axios.get(
        "https://www.stockperfume.com/wp/wp-json/wp/v3/filters?filterType=gender",
        { params: { selected_filters } }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
