import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { authKey, baseApiURL } from "./baseApiURLS";
import { IAPIProps, defaultAPIProps } from "./IAPI";
import Cookies from "js-cookie";

export const generateURL = (API: IAPIProps) => {
  let url = baseApiURL + API.apiRoute + API.path;
  API = { ...defaultAPIProps, ...API };

  if (API?.jwtRequired) {
    const token = Cookies.get("sp-token");
    url += `&JWT=${token}`;
  }
  if (API?.authKeyRequired) {
    url += `&AUTH_KEY=${authKey}`;
  }
  return url;
};

export const APIRequest = (API: IAPIProps) =>
  createAsyncThunk(API.path, async (args, thunkAPI) => {
    try {
      const url = generateURL(API);
      const response = await axios({
        method: API.method,
        url: url,
        data: args,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  });
