import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppSelector } from "../../app/hooks";
import { getSingleProduct } from "../../app/features/product/actions/getSingleProduct";
import { useParams } from "react-router-dom";
import RelatedProducts from "./RelatedProducts";
import ProductDataTabs from "./ProductDataTabs";
import Images from "./Images";
import { useDispatch } from "react-redux";
import { setQuantity } from "../../app/features/cart/slices/cartSlice";
import { toast } from "react-toastify";

function SingleProduct() {
  const [quantity, setProductQuantity] = useState(1);
  const product = useAppSelector(
    (state: any) => state.singleProductReducer.current_product
  );
  const params = useParams();
  const cart = useAppSelector((state: any) => state.cartReducer.products);
  useEffect(() => {
    if (params.id) getSingleProduct(params.id);
  }, [params.id]);
  const isInCart = (id: number) => {
    return cart.filter((item: any) => item.id === id);
  };

  const getProductQuantity = (id: number) => {
    const inProduct = isInCart(id);
    return inProduct.length > 0 ? inProduct[0].quantity : 1;
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setProductQuantity(getProductQuantity(product.id));
  }, [product.id]);

  console.log(product);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <p className="text-lg leading-6 mt-32 mb-8 text-center font-semibold">
        Product details
      </p>
      <div className="grid lg:grid-cols-3 w-[95%] lg:w-[87%] mx-auto lg:bg-white lg:shadow lg:rounded-20">
        <div className="text-xs py-8 bg-white lg:bg-transparent rounded-20 w-full flex flex-col items-center shadow lg:shadow-none">
          <div className="hidden lg:block ml-16">
            {product?.categories?.[0]?.name} {">"}{" "}
            <span className="text-black">{product?.categories?.[1]?.name}</span>{" "}
            {">"}{" "}
            <span className="text-brown">{product?.categories?.[2]?.name}</span>
          </div>
          <Images product={product} images={product?.images} />
        </div>
        <div className="flex flex-col lg:pt-24 lg:pb-20 rounded-20 bg-white lg:bg-transparent mt-4 lg:mt-0 pt-6 h-full px-12 shadow lg:shadow-none">
          <p className="lg:text-md text-base px-4 font-medium">
            {product?.name}
          </p>
          <div className="flex flex-col justify-center items-center lg:mt-20 mt-10 bg-gray rounded-20 mx-auto py-6 w-full">
            <img src="/barcode.png" alt="" />
            <p className="text-sm mt-1">
              EAN: <span className="font-semibold">{product?.ean}</span>{" "}
            </p>
          </div>
          <div className="flex lg:mt-20 mt-10">
            <ul className="text-sm flex flex-col gap-2">
              <li className="text-start">
                Brand:{" "}
                <span className="font-semibold">
                  {" "}
                  {product?.brand?.name || " No brand data"}
                </span>
              </li>
              <li className="text-start">
                SKU: <span className="font-semibold">{product?.sku}</span>
              </li>
              <li className="text-start">
                Gender:{" "}
                <span className="font-semibold">
                  {product?.gender ? product?.gender : "Unisex"}
                </span>
              </li>
              <li className="text-start">
                Size:{" "}
                <span className="font-semibold">
                  {product?.size?.name || " No size data"}
                </span>
              </li>
              <li className="text-start">
                Processing time:{" "}
                <span className="font-semibold">
                  {product?.delivery_time ? product?.delivery_time : "5 days"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white lg:bg-transparent rounded-20 flex flex-col place-content-center shadow lg:shadow-none mt-8 lg:mt-0 p-4 md:p-12 lg:p-0">
          <div className="flex flex-col place-content-center rounded-20 border py-8 px-4 xl:px-12 w-full lg:w-[80%] m-auto h-full lg:h-[75%]">
            <div className="grid grid-cols-2">
              <span className="text-base font-medium pb-5 border-black border-b-[3px] w-full text-end">
                Available Stock:
              </span>
              <span
                className={`${
                  product?.stock_quantity > 0 ? "text-green" : "text-red"
                } text-base pl-2.5 pb-5 border-b w-full text-start`}
              >
                {product?.stock_quantity > 0
                  ? product?.stock_quantity + " in stock"
                  : "Out of stock"}
              </span>
            </div>
            <div className="font-bold text-xl  text-center mt-10">
              {"€ " + product?.price?.toFixed(2)}
            </div>

            <div className="flex flex-col gap-3.5 justify-between mt-6 w-[75%] sm:w-[50%] mx-auto lg:w-full">
              <p className="text-base text-center lg:text-start">Quantity:</p>
              <div className="custom-number-input rounded-full border  lg:py-2 py-1 flex justify-between w-full  relative bg-transparent ">
                <button
                  onClick={() =>
                    setProductQuantity(quantity <= 1 ? 1 : quantity - 1)
                  }
                  className="   h-full lg:w-20 w-12 rounded-l  outline-none"
                >
                  <span className="m-auto text-lg font-thin">−</span>
                </button>
                <input
                  type="number"
                  key={getProductQuantity(product.id)}
                  className=" focus:outline-none text-center w-12 lg:w-full text-sm focus:text-black outline-none"
                  name="custom-input-number"
                  onChange={(e) => {
                    setProductQuantity(
                      parseInt(e.target.value) < 1
                        ? 1
                        : parseInt(e.target.value) >= product?.stock_quantity
                        ? product?.stock_quantity
                        : parseInt(e.target.value)
                    );
                  }}
                  value={quantity}
                ></input>
                <button
                  onClick={() =>
                    setProductQuantity(
                      quantity >= product.stock_quantity
                        ? product.stock_quantity
                        : quantity + 1
                    )
                  }
                  className="h-full lg:w-20 w-12 "
                >
                  <span className="m-auto text-lg font-thin">+</span>
                </button>
              </div>
              <button
                onClick={() => {
                  dispatch(
                    // @ts-ignore
                    setQuantity({ product: product, quantity: quantity })
                  );
                }}
                className="bg-black hover:bg-brown text-white  w-full h-full lg:py-4 py-3 text-xs font-normal rounded-full"
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </div>
      <ProductDataTabs product={product} />
      <RelatedProducts related_products={product?.related_products} />
    </motion.div>
  );
}

export default SingleProduct;
