import React from 'react';
import {addToWishlist, removeFromWishlist} from "../../app/features/wishlist/wishlistSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";

function WishListButton({product, ...props} : any) {
    const wishlist = useAppSelector((state: any) => state.wishlistReducer.products)

    const isInWishlist = () => {
        return wishlist.filter((item: any) => item.id === product?.id).length > 0;
    };

    const [isHovered, setIsHovered] = React.useState(false);
    const dispatch = useDispatch();

    return (
        <div  {...props} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)}
             onClick={()=> {
                 isInWishlist() ? dispatch(removeFromWishlist(product)) :  dispatch(addToWishlist(product))}}>
            {
                isInWishlist() ?  (<svg  width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.49994 2.23273L5.91738 1.63392C4.54994 0.228292 2.04256 0.713355 1.13744 2.48054C0.712501 3.31173 0.616626 4.51179 1.39256 6.04335C2.14006 7.51804 3.69519 9.28442 6.49994 11.2084C9.30469 9.28442 10.859 7.51804 11.6073 6.04335C12.3833 4.51098 12.2882 3.31173 11.8624 2.48054C10.9573 0.713355 8.44994 0.22748 7.0825 1.6331L6.49994 2.23273ZM6.49994 12.1875C-5.95812 3.95523 2.66413 -2.47002 6.35694 0.928667C6.40569 0.973355 6.45363 1.01967 6.49994 1.0676C6.54578 1.01971 6.59348 0.973634 6.64294 0.92948C10.3349 -2.47165 18.958 3.95442 6.49994 12.1875Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49994 1.0676C10.1059 -2.63912 19.1217 3.84694 6.49994 12.1875C-6.12178 3.84775 2.89397 -2.63912 6.49994 1.0676Z" fill="#B59679"/>
                    </svg>)
                    :   (<svg className={isHovered ? "stroke-brown stroke-[1px]" : ""} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.49991 2.54187L5.91735 1.94306C4.54991 0.537434 2.04254 1.0225 1.13741 2.78968C0.712477 3.62087 0.616602 4.82093 1.39254 6.3525C2.14004 7.82718 3.69516 9.59356 6.49991 11.5176C9.30466 9.59356 10.859 7.82718 11.6073 6.3525C12.3832 4.82012 12.2882 3.62087 11.8624 2.78968C10.9573 1.0225 8.44991 0.536622 7.08248 1.94225L6.49991 2.54187ZM6.49991 12.4966C-5.95815 4.26437 2.6641 -2.16088 6.35691 1.23781C6.40566 1.2825 6.4536 1.32881 6.49991 1.37675C6.54576 1.32885 6.59346 1.28278 6.64291 1.23862C10.3349 -2.1625 18.958 4.26356 6.49991 12.4966Z" fill="#202020"/>
                    </svg>)
            }
        </div>
    );
}

export default WishListButton;