import { updateCargoInfo } from "../../app/features/user/actions/updateCargoInfo";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ProfileIcon from "../Common/ProfileIcon";
import React from "react";
import AddUserInfoModal from "./AddUserInfoModal";
import { useEffect } from "react";
import EditVATModal from "./EditVATModal";
import { AiFillCloseCircle } from "react-icons/ai";
import { addressTitle } from "../../functions/addressTitle.js";
import AddressModal from "../MyAccount/MyAddresses/AddressModal";
import { BsCheckCircleFill, BsGeoAltFill } from "react-icons/bs";
import { addUserInfo } from "../../app/features/user/userSlice";

function AddressUserInfo({
  setAddress,
  address,
  className,
  isShippingSameAsBilling,
  setIsShippingSameAsBilling,
}: {
  setAddress: any;
  address: any;
  className?: string;
  isShippingSameAsBilling: boolean;
  setIsShippingSameAsBilling: any;
}) {
  const user = useAppSelector((state) => state.userReducer.user);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [showAddressModal, setShowAddressModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [showEditModal, setShowEditModal] = React.useState(false);
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const [addresses, setAddresses] = React.useState({
    billing: null,
    shipping: null,
  });
  const [selectedAddress, setSelectedAddress] = React.useState({});

  useEffect(() => {}, [user?.savedAddresses]);

  useEffect(() => {
    setAddresses({
      billing: { title: "Billing", ...user?.billing },
      shipping: { title: "Shipping", ...user?.shipping },
    });
  }, [user?.billing, user?.shipping]);

  function handleAddressChange(e: any) {
    console.log(e.target.value);
    if (e.target.value.length === 0) {
      if (e.target.name === "billing") {
        setAddresses({
          billing: null,
          shipping: { title: "Shipping", ...user?.shipping },
        });
      } else if (e.target.name === "shipping") {
        setAddresses({
          billing: { title: "Billing", ...user?.billing },
          shipping: null,
        });
      }
    } else {
      if (e.target.name === "billing") {
        dispatch(
          updateCargoInfo({ change: "billing", address_id: e.target.value })
        );
        setAddress(e.target.value);
      } else {
        dispatch(
          updateCargoInfo({ change: "shipping", address_id: e.target.value })
        );
        setAddress(e.target.value);
      }
    }
  }

  function handleShippingSameAsBilling() {
    dispatch(addUserInfo({ shipping: user?.billing }));
    setIsShippingSameAsBilling((prev) => !prev);
  }

  return (
    <>
      <AddressModal
        address={{ ...selectedAddress, id: address }}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <AddUserInfoModal
        key={showAddressModal}
        type={modalType}
        showAddressModal={showAddressModal}
        setShowAddressModal={setShowAddressModal}
      />
      <EditVATModal
        loggedIn={loggedIn}
        address={address}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div
        className={`lg:col-span-9 col-span-12 grid grid-cols-4 gap-4 rounded-20 bg-white shadow py-4 px-6 ${className}`}
      >
        {loggedIn ? (
          <div className="flex py-2 flex-col col-span-1">
            <div className="mx-auto">
              <ProfileIcon size="w-11 h-11" />
            </div>
            <div className="flex flex-col space-y-3 text-xs mt-6 self-center">
              {user?.billing?.company ? (
                <div className="flex items-center justify-start  space-x-4">
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.6 3.8H2.4C1.6268 3.8 1 4.4268 1 5.2V12.2C1 12.9732 1.6268 13.6 2.4 13.6H13.6C14.3732 13.6 15 12.9732 15 12.2V5.2C15 4.4268 14.3732 3.8 13.6 3.8Z"
                      fill="#202020"
                    />
                    <path
                      d="M10.8 13.6V2.4C10.8 2.0287 10.6525 1.6726 10.3899 1.41005C10.1274 1.1475 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.1475 5.61005 1.41005C5.3475 1.6726 5.2 2.0287 5.2 2.4V13.6"
                      fill="#202020"
                    />
                    <path
                      d="M10.8 13.6V2.4C10.8 2.0287 10.6525 1.6726 10.3899 1.41005C10.1274 1.1475 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.1475 5.61005 1.41005C5.3475 1.6726 5.2 2.0287 5.2 2.4V13.6M2.4 3.8H13.6C14.3732 3.8 15 4.4268 15 5.2V12.2C15 12.9732 14.3732 13.6 13.6 13.6H2.4C1.6268 13.6 1 12.9732 1 12.2V5.2C1 4.4268 1.6268 3.8 2.4 3.8Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>{user?.billing?.company}</p>
                </div>
              ) : null}
              <div className="flex  items-center justify-start space-x-4">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1ZM6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6Z"
                    fill="#202020"
                  />
                </svg>

                <p>{user?.billing?.first_name}</p>
              </div>
              <div className="flex  items-center justify-start space-x-4">
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.04375 1.36063C0.131966 0.974088 0.348823 0.628961 0.658806 0.38177C0.96879 0.134578 1.35352 -2.66826e-05 1.75 3.96737e-09H12.25C12.6465 -2.66826e-05 13.0312 0.134578 13.3412 0.38177C13.6512 0.628961 13.868 0.974088 13.9563 1.36063L7 5.61225L0.04375 1.36063ZM0 2.35988V8.57588L5.07762 5.46263L0 2.35988ZM5.91588 5.97625L0.167125 9.49988C0.309161 9.79937 0.533336 10.0524 0.813559 10.2294C1.09378 10.4065 1.41853 10.5003 1.75 10.5H12.25C12.5814 10.5001 12.906 10.406 13.1861 10.2288C13.4662 10.0516 13.6902 9.79853 13.832 9.499L8.08325 5.97538L7 6.63775L5.91588 5.97538V5.97625ZM8.92237 5.4635L14 8.57588V2.35988L8.92237 5.46263V5.4635Z"
                    fill="#202020"
                  />
                </svg>
                <a href={"mailto:" + user?.email}>
                  <p className="underline">{user?.email?.substring(0, 20)}</p>
                </a>
              </div>
              <div className="flex  items-center justify-start space-x-4">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.41406 0.382645C1.54529 0.251606 1.70286 0.149953 1.87634 0.0844241C2.04982 0.0188949 2.23525 -0.00901466 2.42033 0.00254503C2.60541 0.0141047 2.78593 0.0648697 2.94991 0.151476C3.11389 0.238082 3.25759 0.358551 3.3715 0.504901L4.71771 2.23448C4.96445 2.55174 5.05145 2.96501 4.95395 3.35503L4.54371 4.99761C4.5225 5.08268 4.52365 5.1718 4.54704 5.2563C4.57043 5.3408 4.61527 5.41782 4.67721 5.47988L6.5199 7.32271C6.58202 7.38478 6.65916 7.4297 6.74381 7.4531C6.82845 7.47649 6.9177 7.47757 7.00288 7.45622L8.64458 7.04595C8.83704 6.99783 9.03791 6.99409 9.23202 7.03502C9.42614 7.07595 9.6084 7.16048 9.76505 7.28221L11.4945 8.62777C12.1162 9.11155 12.1732 10.0303 11.6167 10.5861L10.8413 11.3616C10.2863 11.9167 9.45681 12.1604 8.68358 11.8882C6.70453 11.1918 4.90765 10.0587 3.42625 8.57302C1.94076 7.09171 0.807784 5.29496 0.11135 3.31603C-0.160142 2.54349 0.0836006 1.71321 0.638583 1.15818L1.41406 0.382645Z"
                    fill="#202020"
                  />
                </svg>

                <p>{user?.billing?.phone}</p>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`grid grid-cols-3 gap-4 text-sm w-full ${
            loggedIn ? "col-span-3" : "col-span-full"
          }`}
        >
          <div
            className={
              "flex flex-col justify-between items-start border rounded-20 pt-4 pb-2 px-2.5 min-h-40"
            }
          >
            <div className="flex self-center items-center gap-4">
              <BsGeoAltFill className="text-lg" />

              <p className="text-black font-medium">Billing Address</p>
              {/*        <svg onClick={() => {

                      setShowEditModal(true)
                      // @ts-ignore
                      setSelectedAddress(addresses?.billing)}
                      } width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0013 1.19202C12.0786 1.26958 12.1221 1.37463 12.1221 1.48415C12.1221 1.59366 12.0786 1.69872 12.0013 1.77628L11.1382 2.64025L9.48305 0.985126L10.3462 0.12115C10.4238 0.0435776 10.529 0 10.6387 0C10.7485 0 10.8537 0.0435776 10.9313 0.12115L12.0013 1.19119V1.19202ZM10.5531 3.22451L8.89796 1.56939L3.25977 7.20841C3.21423 7.25394 3.17994 7.30948 3.15964 7.37061L2.49345 9.36835C2.48137 9.40477 2.47965 9.44383 2.4885 9.48117C2.49734 9.51851 2.51639 9.55265 2.54352 9.57978C2.57066 9.60692 2.6048 9.62597 2.64214 9.63481C2.67948 9.64365 2.71854 9.64194 2.75496 9.62986L4.7527 8.96367C4.81374 8.94361 4.86928 8.90961 4.9149 8.86436L10.5531 3.22534V3.22451Z" fill="#202020"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.7587C0 11.0879 0.130784 11.4036 0.363582 11.6364C0.596379 11.8692 0.91212 12 1.24135 12H10.3445C10.6738 12 10.9895 11.8692 11.2223 11.6364C11.4551 11.4036 11.5859 11.0879 11.5859 10.7587V5.79327C11.5859 5.68353 11.5423 5.57828 11.4647 5.50068C11.3871 5.42308 11.2819 5.37949 11.1721 5.37949C11.0624 5.37949 10.9571 5.42308 10.8795 5.50068C10.8019 5.57828 10.7583 5.68353 10.7583 5.79327V10.7587C10.7583 10.8684 10.7147 10.9736 10.6371 11.0512C10.5595 11.1288 10.4543 11.1724 10.3445 11.1724H1.24135C1.1316 11.1724 1.02636 11.1288 0.948758 11.0512C0.871159 10.9736 0.827564 10.8684 0.827564 10.7587V1.65545C0.827564 1.54571 0.871159 1.44046 0.948758 1.36286C1.02636 1.28527 1.1316 1.24167 1.24135 1.24167H6.62051C6.73025 1.24167 6.8355 1.19808 6.9131 1.12048C6.9907 1.04288 7.03429 0.937631 7.03429 0.827889C7.03429 0.718147 6.9907 0.6129 6.9131 0.535301C6.8355 0.457702 6.73025 0.414107 6.62051 0.414107H1.24135C0.91212 0.414107 0.596379 0.544892 0.363582 0.777689C0.130784 1.01049 0 1.32623 0 1.65545V10.7587Z" fill="#202020"/>
                    </svg> */}
            </div>
            {user?.billing != "" && user?.billing ? (
              <>
                <div className="flex flex-col text-sm">
                  <p>
                    {user?.billing?.first_name + " " + user?.billing?.last_name}
                  </p>
                  <p>{user?.billing?.address_1}</p>
                  <p>
                    {(user?.billing?.postcode ? user?.billing?.postcode : "") +
                      " " +
                      user?.billing?.city +
                      " " +
                      user?.billing?.country}
                  </p>
                </div>
                <div className="flex flex-col relative w-full">
                  {loggedIn ? (
                    <>
                      <select
                        onChange={handleAddressChange}
                        required
                        defaultValue={address?.id}
                        name="billing"
                        className="border rounded-full flex items-center justify-center gap-6 space-x-4 w-full h-8 text-center"
                      >
                        <option value="">Select Address</option>
                        {user?.savedAddresses?.map((address: any) => {
                          return (
                            <option
                              value={address.id}
                              className="text-xxs  lg:text-sm"
                            >
                              {addressTitle(address?.title)}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setShowAddressModal(true);
                        setModalType("billing");
                      }}
                      className="
                     border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full pl-4 pr-8 py-1.5"
                    >
                      Edit address
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <p className="whitespace-normal text-center px-10">
                  {" "}
                  You are not logged in, if you want to continue without logging
                  in, please add an address.
                </p>

                <button
                  onClick={() => {
                    setShowAddressModal(true);
                    setModalType("billing");
                  }}
                  className="border rounded-full flex items-center justify-center gap-6 w-full h-8"
                >
                  Add address
                </button>
              </>
            )}
          </div>

          <div
            className={
              "flex flex-col justify-between items-start border rounded-20 pt-4 pb-2 px-2.5"
            }
          >
            <div className="flex self-center items-center gap-4">
              <BsGeoAltFill className="text-lg" />

              <p className="text-black font-medium text-sm">Shipping Address</p>
              {/* <svg onClick={() => {

setShowEditModal(true)
// @ts-ignore
setSelectedAddress(addresses?.shipping)}
} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0013 1.19202C12.0786 1.26958 12.1221 1.37463 12.1221 1.48415C12.1221 1.59366 12.0786 1.69872 12.0013 1.77628L11.1382 2.64025L9.48305 0.985126L10.3462 0.12115C10.4238 0.0435776 10.529 0 10.6387 0C10.7485 0 10.8537 0.0435776 10.9313 0.12115L12.0013 1.19119V1.19202ZM10.5531 3.22451L8.89796 1.56939L3.25977 7.20841C3.21423 7.25394 3.17994 7.30948 3.15964 7.37061L2.49345 9.36835C2.48137 9.40477 2.47965 9.44383 2.4885 9.48117C2.49734 9.51851 2.51639 9.55265 2.54352 9.57978C2.57066 9.60692 2.6048 9.62597 2.64214 9.63481C2.67948 9.64365 2.71854 9.64194 2.75496 9.62986L4.7527 8.96367C4.81374 8.94361 4.86928 8.90961 4.9149 8.86436L10.5531 3.22534V3.22451Z" fill="#202020"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.7587C0 11.0879 0.130784 11.4036 0.363582 11.6364C0.596379 11.8692 0.91212 12 1.24135 12H10.3445C10.6738 12 10.9895 11.8692 11.2223 11.6364C11.4551 11.4036 11.5859 11.0879 11.5859 10.7587V5.79327C11.5859 5.68353 11.5423 5.57828 11.4647 5.50068C11.3871 5.42308 11.2819 5.37949 11.1721 5.37949C11.0624 5.37949 10.9571 5.42308 10.8795 5.50068C10.8019 5.57828 10.7583 5.68353 10.7583 5.79327V10.7587C10.7583 10.8684 10.7147 10.9736 10.6371 11.0512C10.5595 11.1288 10.4543 11.1724 10.3445 11.1724H1.24135C1.1316 11.1724 1.02636 11.1288 0.948758 11.0512C0.871159 10.9736 0.827564 10.8684 0.827564 10.7587V1.65545C0.827564 1.54571 0.871159 1.44046 0.948758 1.36286C1.02636 1.28527 1.1316 1.24167 1.24135 1.24167H6.62051C6.73025 1.24167 6.8355 1.19808 6.9131 1.12048C6.9907 1.04288 7.03429 0.937631 7.03429 0.827889C7.03429 0.718147 6.9907 0.6129 6.9131 0.535301C6.8355 0.457702 6.73025 0.414107 6.62051 0.414107H1.24135C0.91212 0.414107 0.596379 0.544892 0.363582 0.777689C0.130784 1.01049 0 1.32623 0 1.65545V10.7587Z" fill="#202020"/>
</svg> */}
            </div>
            {user?.shipping != "" && user?.shipping ? (
              <>
                <div className="flex flex-col text-sm">
                  {!isShippingSameAsBilling ? (
                    <>
                      <p>
                        {user?.shipping?.first_name +
                          " " +
                          user?.shipping?.last_name}
                      </p>
                      <p>{user?.shipping?.address_1}</p>
                      <p>
                        {(user?.shipping?.postcode
                          ? user?.shipping?.postcode
                          : "") +
                          " " +
                          user?.shipping?.city +
                          " " +
                          user?.shipping?.country}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        {user?.billing?.first_name +
                          " " +
                          user?.billing?.last_name}
                      </p>
                      <p>{user?.billing?.address_1}</p>
                      <p>
                        {(user?.billing?.postcode
                          ? user?.billing?.postcode
                          : "") +
                          " " +
                          user?.billing?.city +
                          " " +
                          user?.billing?.country}
                      </p>
                    </>
                  )}
                </div>
                <div className="flex flex-col relative w-full">
                  {loggedIn ? (
                    <>
                      <select
                        onChange={handleAddressChange}
                        defaultValue={address?.id}
                        name="shipping"
                        required
                        className="border  rounded-full flex text-center w-full h-8"
                      >
                        <option value="">Select Address</option>
                        {user.savedAddresses.map((address: any) => {
                          return (
                            <option
                              value={address.id}
                              className="text-[8px]  lg:text-[12px]"
                            >
                              {addressTitle(address.title)}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setShowAddressModal(true);
                        setModalType("shipping");
                      }}
                      className="border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full pl-4 pr-8 py-1.5"
                    >
                      Edit address
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                {loggedIn ? (
                  <>
                    {!isShippingSameAsBilling && user?.billing ? (
                      <div className="flex  gap-3">
                        <input
                          className=""
                          type="checkbox"
                          checked={isShippingSameAsBilling}
                          onChange={setIsShippingSameAsBilling}
                        />
                        <p className="text-xs text-black">
                          Click if the shipping address is the same as the
                          billing address
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : !isShippingSameAsBilling && user?.billing ? (
                  <>
                    <div className="flex gap-3">
                      <input
                        className=""
                        type="checkbox"
                        checked={isShippingSameAsBilling}
                        onChange={handleShippingSameAsBilling}
                      />
                      <p className="text-xs text-black">
                        Click if the shipping address is the same as the billing
                        address
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        setShowAddressModal(true);
                        setModalType("shipping");
                      }}
                      className="border rounded-full flex items-center justify-center w-full h-8"
                    >
                      Add address
                    </button>
                  </>
                ) : (
                  <>
                    <p className="whitespace-normal text-center px-10 py-10">
                      {" "}
                      You are not logged in, if you want to continue without
                      logging in, please add an address.
                    </p>
                    <button
                      onClick={() => {
                        setShowAddressModal(true);
                        setModalType("shipping");
                      }}
                      className="border rounded-full flex items-center justify-center w-full h-8"
                    >
                      Add address
                    </button>
                  </>
                )}
              </>
            )}
          </div>

          <div className="flex flex-col justify-between border rounded-20 pt-4 pb-2 px-2.5">
            <div className="flex gap-4 self-center items-center">
              {user?.VATnumber?.isValid === "1" ||
              user?.VATnumber?.isValid === "true" ||
              user?.VATnumber?.isValid === true ? (
                <BsCheckCircleFill className="text-lg text-green" />
              ) : (
                <AiFillCloseCircle className="text-lg text-red" />
              )}

              <p className="text-black font-medium text-sm">VAT status</p>
            </div>
            {loggedIn ? (
              <>
                <div className="flex flex-col">
                  <p
                    className={`text-sm ${
                      user?.VATnumber?.isValid !== "1" &&
                      user?.VATnumber?.isValid !== "true" &&
                      user?.VATnumber?.isValid !== true
                        ? " text-black "
                        : " text-black "
                    }`}
                  >
                    {user?.VATnumber?.country + user?.VATnumber?.number}{" "}
                  </p>
                  <p
                    className={`text-xs mt-2 ${
                      user?.VATnumber?.isValid !== "1" &&
                      user?.VATnumber?.isValid !== "true" &&
                      user?.VATnumber?.isValid !== true
                        ? " text-black "
                        : " text-black "
                    }`}
                  >
                    {user?.VATnumber?.isValid === "1" ||
                    user?.VATnumber?.isValid === "true" ||
                    user?.VATnumber?.isValid === true
                      ? null
                      : "Not "}
                    Valid VAT number following{" "}
                  </p>
                  <p
                    className={`text-xs ${
                      user?.VATnumber?.isValid !== "1" &&
                      user?.VATnumber?.isValid !== "true" &&
                      user?.VATnumber?.isValid !== true
                        ? " text-black "
                        : " text-black "
                    }`}
                  >
                    the <span className="text-brown">VIES CHECK</span> wesbite.
                  </p>
                </div>
                <div className="flex flex-col relative px-2">
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="border whitespace-nowrap  rounded-full flex items-center justify-center gap-6 space-x-4 w-full pl-4 pr-8 py-1.5"
                  >
                    Edit VAT Number
                  </button>
                </div>
              </>
            ) : user?.VATnumber?.number != "" && user?.VATnumber ? (
              <>
                <div className="flex flex-col space-y-1 lg:ml-8 mt-6 mb-8">
                  <p className="text-xs">
                    {user?.VATnumber?.country + user?.VATnumber?.number}{" "}
                  </p>
                  <p className="text-xs">Valid VAT number following </p>
                  <p className="text-xs">
                    the <span className="text-brown">VIES CHECK</span> wesbite.
                  </p>
                </div>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className=" border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full pl-4 pr-8 py-1.5"
                >
                  Edit VAT number
                </button>
              </>
            ) : (
              <>
                <p className="whitespace-normal text-center px-10">
                  VAT address not added
                </p>

                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="border rounded-full flex items-center justify-center w-full h-8"
                >
                  Add VAT number
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressUserInfo;
