import React from "react";

function Accordion({ children, className, buttonPosition, ...props }: any) {
    const [open, setOpen] = React.useState(props.default == false ? false : true);

    const [Title, Description] = children;

    return (
        <div {...props} className={className + " relative"}>
            <div {...Title.props} onClick={() => setOpen(!open)} >
                {Title.props.children}
            </div>
            {open ? Description : null}
            <div className={"absolute bottom-0 " + buttonPosition}>
                <button onClick={() => {
                    setOpen((state: any) => !state)
                }} className={"bg-black hover:bg-brown px-6 py-[0.4rem] rounded-t-md "}>
                    {
                        open ? <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.887 6.27238C10.8513 6.30819 10.8089 6.3366 10.7621 6.35599C10.7154 6.37538 10.6653 6.38536 10.6147 6.38536C10.5642 6.38536 10.5141 6.37538 10.4673 6.35599C10.4206 6.3366 10.3782 6.30819 10.3425 6.27238L5.9999 1.92904L1.65733 6.27238C1.58512 6.34459 1.48718 6.38516 1.38506 6.38516C1.28293 6.38516 1.18499 6.34459 1.11278 6.27238C1.04057 6.20016 1 6.10222 1 6.0001C1 5.89798 1.04057 5.80004 1.11278 5.72782L5.72762 1.11298C5.76335 1.07717 5.80578 1.04875 5.85251 1.02937C5.89923 1.00998 5.94932 1 5.9999 1C6.05048 1 6.10057 1.00998 6.14729 1.02937C6.19401 1.04875 6.23645 1.07717 6.27217 1.11298L10.887 5.72782C10.9228 5.76355 10.9512 5.80599 10.9706 5.85271C10.99 5.89943 11 5.94952 11 6.0001C11 6.05068 10.99 6.10077 10.9706 6.14749C10.9512 6.19421 10.9228 6.23665 10.887 6.27238Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                            />
                        </svg> : <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.949453 0.727624C0.993681 0.69181 1.04622 0.663396 1.10407 0.644009C1.16191 0.624621 1.22393 0.614642 1.28656 0.614642C1.34918 0.614642 1.4112 0.624621 1.46904 0.644009C1.52689 0.663396 1.57943 0.69181 1.62366 0.727624L7.00017 5.07096L12.3767 0.727623C12.4661 0.655411 12.5873 0.614844 12.7138 0.614844C12.8402 0.614844 12.9615 0.655411 13.0509 0.727623C13.1403 0.799835 13.1905 0.897776 13.1905 0.999899C13.1905 1.10202 13.1403 1.19996 13.0509 1.27218L7.33727 5.88702C7.29305 5.92283 7.2405 5.95125 7.18266 5.97063C7.12481 5.99002 7.0628 6 7.00017 6C6.93754 6 6.87553 5.99002 6.81768 5.97063C6.75984 5.95125 6.7073 5.92283 6.66307 5.88702L0.949453 1.27218C0.905112 1.23645 0.869932 1.19401 0.845929 1.14729C0.821926 1.10057 0.80957 1.05048 0.80957 0.9999C0.80957 0.949315 0.821926 0.899228 0.845929 0.852506C0.869932 0.805785 0.905112 0.763347 0.949453 0.727624Z"
                                fill="white" stroke="white" stroke-width="0.5" />
                        </svg>

                    }

                </button>
            </div>
        </div>
    );
}

export function Title({ children, props }: any) {
    return <div {...props}>{children}</div>;
}

export function Description({ children, ...props }: any) {

    return <div {...props}>{children}</div>;
}


export default Accordion;

export class AccordionButton {
}