import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import countries from "../../../constants/countries";
import PhoneInput from "react-phone-number-input";
import { updateAddress } from "../../../app/features/user/actions/updateAddress";
import { useEffect } from "react";
import { addressTitle } from "../../../functions/addressTitle.js";

interface FormInterface {
  [key: string]: string;
}

function AddressModal({ address, setShowModal, showModal }: any) {
  const [number, setNumber] = React.useState<string | undefined>(
    address?.phone
  );
  const dispatch = useAppDispatch();
  const [isSaved, setIsSaved] = React.useState(false);
  const [title, setTitle] = React.useState(address?.title);
  const [country, setCountry] = React.useState(address?.country);
  const user = useAppSelector((state) => state.userReducer.user);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let formObj: FormInterface = {};
    for (let [key, value] of Array.from(formData.entries())) {
      if (key !== "phoneCountry") {
        formObj[key] = value.toString();
      }
    }

    const obj = { ...formObj, address_id: address?.id, country: country };
    dispatch(updateAddress(obj));
  };

  useEffect(() => {
    setIsSaved(false);
  }, [showModal]);

  useEffect(() => {
    setCountry(address?.country);
  }, [address?.country]);
  useEffect(() => {
    setNumber(address?.phone);
  }, [address?.phone]);
  return (
    <div className={showModal ? " " : " hidden  z-40"}>
      <div className=" overscroll-contain justify-center h-full w-full mt-10 overflow-y-auto  items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl z-50">
          {/*content*/}
          <form
            onSubmit={(e: any) => {
              handleSubmit(e);
            }}
            className="border-0 z-50 rounded-lg shadow relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            {/*header*/}
            <div className=" z-40 flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <p className="text-xl font-semibold">Edit Address</p>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex">
              <div className=" grid grid-cols-12  gap-4">
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Title</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={addressTitle(address?.title)}
                    name="title"
                    type="text"
                    onChange={(e) => setTitle(e.target.value.slice(0, 20))}
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">First Name </p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.first_name}
                    name="first_name"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Last Name </p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.last_name}
                    name="last_name"
                    type="text"
                  />
                </div>

                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Company</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.company}
                    name="company"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Address 1 </p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.address_1}
                    name="address_1"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Address 2 </p>
                  <input
                    className="border rounded-20 px-4 h-8 w-60"
                    value={address?.address_2}
                    name="address_2"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">City</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.city}
                    name="city"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">State</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.state}
                    name="state"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Post Code</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.postcode}
                    name="postcode"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">House Number</p>
                  <input
                    required
                    className="border rounded-20 px-4 h-8 w-60"
                    defaultValue={address?.house_number}
                    name="house_number"
                    type="text"
                  />
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Country</p>
                  <select
                    className="border w-60 rounded-20 px-4 h-8"
                    defaultValue={address?.country}
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries.map((country, index) => {
                      return (
                        <option key={index} value={country[1]}>
                          {country[0]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="flex flex-col col-span-12 md:col-span-6 gap-2 ">
                  <p className="text-sm font-normal">Phone</p>
                  <PhoneInput
                    name="phone"
                    placeHolder={address?.phone}
                    international
                    value={number}
                    className="text-xs"
                    onChange={(e) => {
                      setNumber(e);
                    }}
                  />
                </div>
              </div>
            </div>
            {/*footer*/}

            <p
              className={` text-center my-2 text-lg  ${
                isSaved ? "" : "hidden"
              }`}
            >
              Address saved!
            </p>

            <div className="flex items-center justify-center gap-6 p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-white bg-brown font-medium rounded-20 px-6 py-3 uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>

              <button
                className="bg-black text-white font-semibold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={() => {
                  setIsSaved(true);
                  setTimeout(() => {
                    setIsSaved(false);
                  }, 2000);
                }}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default AddressModal;
