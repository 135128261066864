import React from "react";
import Accordion, { Description, Title } from "../Accordion";
import Moment from "react-moment";
import {
  BsCheck,
  BsCheckCircleFill,
  BsFileEarmarkArrowDown,
  BsFileEarmarkCheck,
  BsGeoAltFill,
  BsX,
} from "react-icons/bs";
import Popper from "../../Common/Popper";
import { notify } from "../../../functions/notify";
import axios from "axios";
import { FiLoader } from "react-icons/fi";

function OrderCard({ order }: any) {
  function downloadPDF(blob: any, name = "file.txt") {
    const navigatorAny: any = window.navigator;
    if (navigatorAny.msSaveOrOpenBlob) {
      return navigatorAny.msSaveOrOpenBlob(blob);
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
  const handleInvoiceDownload = () => {
    const res = axios
      .post(
        "https://stockperfume.com/wp/wp-json/wp/v3/invoice",
        { order_id: order?.id },
        { responseType: "blob" }
      )
      .then((res) => {
        downloadPDF(res.data, "invoice.pdf");
      })
      .catch((err) => {
        notify("You can not download before shipping!", "warning");
      });
  };
  let totalQuantity = 0;
  order.products.forEach((product) => {
    totalQuantity += product.quantity;
  });

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case "cancelled":
      case "refunded":
      case "failed":
        return "bg-red";
      case "processing":
      case "completed":
        return "bg-green";
      case "pending":
      case "on-hold":
        return "bg-orange";
      default:
        return "bg-orange";
    }
  };
  const getShipmentStatusColor = (status) => {
    switch (status) {
      case "cancelled":
      case "refunded":
      case "failed":
        return "bg-red";
      case "processing":
      case "pending":
      case "on-hold":
        return "bg-orange";
      case "completed":
        return "bg-green";
      default:
        return "bg-orange"; // Varsayılan bir renk veya sınıf ekleyebilirsiniz
    }
  };
  return (
    <Accordion default={false} buttonPosition={"right-14 lg:right-28"}>
      <Title className=" bg-white rounded-30 py-3 shadow">
        <div className="flex md:pl-10 md:pr-2 px-2">
          <div className="flex sm:flex-row flex-col justify-around  basis-4/5 flex-wrap px-5 sm:px-0 ">
            <div className="flex flex-row  sm:flex-col md:gap-2.5 items-center">
              <p className="text-sm">Order {order.id}</p>
              <OrderCardItem className="ml-auto sm:ml-0">
                <Moment format="DD MMMM YYYY">{order.date.date}</Moment>
              </OrderCardItem>
            </div>

            <div className="flex flex-row sm:flex-col md:gap-2.5 items-center">
              <p className="text-sm">Total order amount</p>
              <OrderCardItem className="ml-auto sm:ml-0">
                {order.total} €
              </OrderCardItem>
            </div>

            <div className="flex flex-row sm:flex-col md:gap-2.5 items-center">
              <p className="text-sm">Total ordered items</p>
              <OrderCardItem className="ml-auto sm:ml-0">
                {totalQuantity} pieces
              </OrderCardItem>
            </div>

            <div className="flex flex-row sm:flex-col md:gap-2.5 items-center">
              <p className="text-sm">Payment</p>
              <OrderCardItem className="ml-auto sm:ml-0" isCircle>
                <div
                  className={`flex items-center place-content-center rounded-full w-5 h-5 p-0.5 
                  ${getPaymentStatusColor(order?.status)} 
                  `}
                >
                  {getPaymentStatusColor(order?.status) === "bg-green" && (
                    <BsCheck className="text-white text-md" />
                  )}
                  {getPaymentStatusColor(order?.status) === "bg-orange" && (
                    <FiLoader className="text-white text-md" />
                  )}
                  {getPaymentStatusColor(order?.status) === "bg-red" && (
                    <BsX className="text-white text-md" />
                  )}
                </div>
              </OrderCardItem>
            </div>
            <div className="flex flex-row sm:flex-col md:gap-2.5 items-center">
              <p className="text-sm">Shipment</p>
              <OrderCardItem className="ml-auto sm:ml-0" isCircle>
                <div
                  className={`flex items-center place-content-center rounded-full w-5 h-5 p-0.5 
                  ${getShipmentStatusColor(order?.status)} 
                  `}
                >
                  {getShipmentStatusColor(order?.status) === "bg-green" && (
                    <BsCheck className="text-white text-md" />
                  )}
                  {getShipmentStatusColor(order?.status) === "bg-orange" && (
                    <FiLoader className="text-white text-md" />
                  )}
                  {getShipmentStatusColor(order?.status) === "bg-red" && (
                    <BsX className="text-white text-md" />
                  )}
                </div>
              </OrderCardItem>
            </div>
          </div>
          <div className="sm:ml-auto self-center flex flex-row gap-3">
            {/*  <button className="w-11 h-11 group hover:bg-brown grid items-center justify-items-center border rounded-full">
              <BsFileEarmarkArrowDown className="group-hover:text-white" />
            </button> */}
            <Popper id="invoice" content="Download invoice" place="top" />
            <button
              onClick={handleInvoiceDownload}
              data-tooltip-id="invoice"
              className={`w-11 h-11 group hover:bg-brown grid items-center justify-items-center border rounded-full
              ${
                order.status === "pending" || order.status === "on-hold"
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <BsFileEarmarkCheck className="group-hover:text-white" />
            </button>
          </div>
        </div>
      </Title>
      <Description className="px-4 bg-white shadow rounded-30 mt-2.5 pb-8">
        <div className="flex flex-col">
          {order.products?.map((product: any) => {
            return (
              <div className="flex py-2 border-b">
                <div className="basis-3/5 sm:basis-4/6 flex-shrink flex">
                  <div className="basis-[12%] text-center my-auto text-sm ">
                    {product.quantity + " x"}
                  </div>
                  <div className="basis-[88%] flex lg:gap-8 gap-2 items-center">
                    <img className="w-10 h-12 " src={product.image} alt="" />
                    <div className="text-sm">
                      <p className="text-black ">{product.name}</p>
                      <p className="text-gray-dark">EAN: {product.ean}</p>
                    </div>
                  </div>
                </div>
                <div className="basis-2/5 sm:basis-2/6 flex justify-around items-center">
                  <div className="text-sm ">€ {product.price?.toFixed(2)}</div>
                  <div className="text-sm ">€ {product.total}</div>
                  <div className="text-sm ">21%</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-3 gap-2 md:gap-4 mt-10">
          <div className="sm:col-span-1 col-span-3 bg-white rounded-30 border p-8 flex flex-col justify-between">
            <div className="flex items-center self-center gap-2">
              <BsGeoAltFill className="text-black text-lg" />
              <p className="text-base">Billing Address</p>
            </div>
            <div className="px-2">
              <p className="text-sm mt-2">
                {order.billing.first_name + " " + order.billing.last_name}{" "}
                <br />
                {order.billing.address_1} <br />
                {order.billing.postcode + " " + order.billing.city} <br />
                {order.billing.country} <br />
              </p>
            </div>
          </div>
          <div className="sm:col-span-1 col-span-3 bg-white rounded-30 border p-8 flex flex-col justify-between">
            <div className="flex items-center self-center gap-2">
              <BsGeoAltFill className="text-black text-lg" />
              <p className="text-base font-medium">Delivery Address</p>
            </div>
            <div className="px-2">
              <p className="text-sm mt-2">
                {order.billing.first_name + " " + order.billing.last_name}{" "}
                <br />
                {order.billing.address_1} <br />
                {order.billing.postcode + " " + order.billing.city} <br />
                {order.billing.country} <br />
              </p>
            </div>
          </div>
          <div className="sm:col-span-1 col-span-3 bg-white rounded-30 border p-8 space-y-3">
            <div className="flex">
              <p className="mr-auto text-sm">Shipping Cost</p>
              <p className="ml-auto text-sm">{order.shipping_price}€</p>
            </div>
            <div className="flex">
              <p className="mr-auto text-sm">Items subtotal: </p>
              <p className="ml-auto text-sm">€ {order.subtotal}</p>
            </div>
            <div className="flex">
              <p className="mr-auto text-sm">VAT (21%):</p>
              <p className="ml-auto text-sm">€ {order.tax}</p>
            </div>
            <div className=" hidden">
              <p className="mr-auto text-brown text-sm">Wallet used</p>
              <p className="ml-auto text-brown text-sm">€ 80,40</p>
            </div>
            <div className="flex">
              <p className="mr-auto text-base font-semibold">Order Total</p>
              <p className="ml-auto text-base font-semibold">€ {order.total}</p>
            </div>
          </div>
        </div>
      </Description>
    </Accordion>
  );
}

export default OrderCard;

const OrderCardItem = ({
  children,
  isCircle,
  className,
}: {
  children: React.ReactNode;
  isCircle?: boolean;
  className?: string;
}) => {
  const classNames = isCircle
    ? "w-10 h-10 rounded-full"
    : "w-32 h-10 rounded-100";
  return (
    <div
      className={`flex items-center place-content-center bg-gray text-black text-sm font-semibold ${classNames} ${className}`}
    >
      {children}
    </div>
  );
};
