import React, { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import WishListButton from "../Common/WishListButton";
import PriceInfo from "../Common/PriceInfo";
import { BsCart, BsCartCheck } from "react-icons/bs";
import QuantityInput from "../Common/QuantityInput";
import StockInfo from "../Common/StockInfo";
import { setQuantity } from "../../app/features/cart/slices/cartSlice";

const MobileProductCard = ({ product }: any) => {
  const cart = useAppSelector((state: any) => state.cartReducer.products);
  const getProductQuantity = (id: number) => {
    const inProduct = isInCart(id);
    return inProduct.length > 0 ? inProduct[0].quantity : 1;
  };
  const [ProductQuantity, setProductQuantity] = React.useState(1);
  const isInCart = (id: number) => {
    return cart.filter((item: any) => item.id === id);
  };
  const [isActivated, setIsActivated] = React.useState(
    isInCart(product.id).length ? true : false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setProductQuantity(getProductQuantity(product.id));
  }, []);

  const handleClick = () => {
    if (!isActivated) {
      // @ts-ignore
      dispatch(setQuantity({ product: product, quantity: ProductQuantity }));
    } else {
      // @ts-ignore
      dispatch(setQuantity({ product: product, quantity: 0 }));
      setProductQuantity(1);
    }
    setIsActivated(!isActivated);
  };

  return (
    <div className="flex lg:hidden flex-col relative py-6 border-b">
      <WishListButton
        product={product}
        className="hover:cursor-pointer absolute right-5 top-4"
      />
      <Link className="flex gap-4" to={`/shop/${product.slug}`}>
        <img
          className="w-12 h-14  object-contain"
          src={product?.images[0]}
          alt=""
        />
        <div className="flex flex-col">
          <p className="text-sm font-bold text-black mt-1 pr-10">
            {product?.name}
          </p>
          <p className="text-xs text-black mt-1">
            {product?.ean +
              " | " +
              product?.size?.name +
              " | " +
              product?.color?.name}
          </p>
        </div>
      </Link>
      <div className="flex justify-between mt-1.5">
        <StockInfo stockQuantity={product?.stock_quantity} isCircle />
        <PriceInfo price={product?.price} className="!w-24" />
        <QuantityInput
          className="!px-1.5"
          product={product}
          getProductQuantity={getProductQuantity}
          setProductQuantity={setProductQuantity}
          setIsActivated={setIsActivated}
          ProductQuantity={ProductQuantity}
          stockQuantity={product?.stock_quantity}
        />
        <button
          /*   disabled={product?.stock_quantity > 0 ? false : true} */
          // @ts-ignore
          onClick={() => {
            handleClick();
          }}
          className={`flex flex-shrink-0 items-center place-content-center w-10 h-10 rounded-full border hover:bg-brown group ${
            isActivated ? "bg-brown" : "bg-white"
          }`}
        >
          {isActivated ? (
            <BsCartCheck className="text-white text-md" />
          ) : (
            <BsCart className="text-md group-hover:text-white" />
          )}
        </button>
      </div>
    </div>
  );
};

export default MobileProductCard;
