import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const newsletterAPI = createAsyncThunk(
    "newsletterAPI",
    async (args: any,thunkAPI) => {
        try {
            const response = await axios.post(
                `https://stockperfume.com/wp/wp-json/wp/v3/newsletter`,args
            );
            
            return response.data;
        } catch (error:any) {
        
            return thunkAPI.rejectWithValue({ message: error.response.data.message });
        }
    });