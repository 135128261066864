function priceFormatter(
  price?: number | string,
  currency: string = "EUR",
  locale: string = "de-DE"
) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(Number(price || 0));
}

export default priceFormatter;
