import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../productType";
import { getProducts } from "../actions/getProducts";
import {searchProductsList} from "../actions/searchProductList";
import { setOffset } from "../actions/setOffset";
import {getBestSellerProducts} from "../actions/getBestSellerProducts";


type InitialState = {
    products: Product[]
    best_seller : Product[]
    page : number
    loading : boolean
    total_products : number
    total_pages : number
}


const initialState: InitialState = {
    products : [],
    best_seller : [],
    page : 1,
    loading : false,
    total_products : 0,
    total_pages : 0
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers:{
        updateProducts : (state,action) =>{
            state.products = action.payload
        },
        sortProducts : (state,action) =>{
            const sortType = action.payload
            if(sortType === "brand" || sortType === "color" || sortType === "size" || sortType === "gender"){
                // @ts-ignore

                state.products = state.products.sort((a,b) => (a?.[sortType as keyof typeof a]?.name
                    // @ts-ignore
                    > b?.[sortType as keyof typeof b]?.name) ? 1 : ((b?.[sortType as keyof typeof b]?.name > a?.[sortType as keyof typeof a]?.name) ? -1 : 0))
            }
            else if(sortType === "id"){
                state.products = state.products.sort((a,b) => (a?.id > b?.id) ? -1 : ((b?.id > a?.id) ? 1 : 0))
            }
            else if(sortType === "stock_quantity"){
                state.products = state.products.sort((a : any,b : any) => (a?.stock_quantity > b?.stock_quantity) ? -1 : ((b?.stock_quantity > a?.stock_quantity) ? 1 : 0))
            }
            else {
                state.products = state.products.sort((a,b) => (a?.[sortType as keyof typeof a]
                    > b?.[sortType as keyof typeof b]) ? 1 : ((b?.[sortType as keyof typeof b] > a?.[sortType as keyof typeof a]) ? -1 : 0))
            }
        },
        filterByBrand : (state,action) =>{
            const letter = action.payload.letter
            state.products = state.products.filter(product => product.brand.letter === letter)
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(getProducts.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getProducts.fulfilled, (state, action  ) => {
                state.products = action.payload.products
                state.total_products = action.payload.total_products
                state.total_pages = action.payload.total_pages
                state.loading = false
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = true
            })
            .addCase(searchProductsList.fulfilled, (state, action ) => {
              
                state.products = action.payload.products
                state.total_products = action.payload.total_products
                state.total_pages = action.payload.total_pages
                state.loading = false
            })
            .addCase(setOffset.fulfilled, (state, action ) => {
                state.products = action.payload.products
                state.total_products = action.payload.total_products
                state.total_pages = action.payload.total_pages
                state.loading = false
            })
            .addCase(getBestSellerProducts.fulfilled, (state, action  ) => {
                state.best_seller = action.payload.products
            })
    },
    
})
export const { updateProducts, sortProducts} = productSlice.actions;
export const productsReducer = productSlice.reducer;