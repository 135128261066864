import { Link } from "react-router-dom";
import {
  emptyCart,
  removeFromCart,
  sortCartProducts,
} from "../../app/features/cart/slices/cartSlice";
import IconWithText from "../Common/IconWithText";
import Accordion, { Title, Description } from "../MyAccount/Accordion";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import EditQuantityModal from "./EditQuantityModal";
import { AiOutlineClose } from "react-icons/ai";
import { convertArrayToCSV } from "../../functions/convertArrayToCSV";
import SortBatch from "../Common/SortBatch";
import { BsPencilSquare, BsX, BsXLg } from "react-icons/bs";

function CartList({ className }: { className?: string }) {
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cartReducer.products);
  const vatRate = useAppSelector((state) => state.cartReducer.vatRate);
  const [sort, setSort] = React.useState("default");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const handleSort = (sortType: string) => {
    if (sort !== sortType) {
      dispatch(sortCartProducts(sortType));
      setSort(sortType);
    } else {
      dispatch(sortCartProducts("id"));
      setSort("default");
    }
  };
  function exportCSV() {
    if (cart.length === 0 || !cart) return;
    const csv = convertArrayToCSV(cart);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <>
      <EditQuantityModal
        product={selectedProduct}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className={`lg:col-span-9 col-span-12 w-full ${className}`}>
        <Accordion buttonPosition={"right-14 lg:right-28"} className="">
          <Title className=" bg-white rounded-50 py-6 px-4 shadow">
            <div className="flex flex-nowrap">
              <div className="basis-3/5 sm:basis-7/12 flex-shrink items-center flex">
                <div className="basis-[12%]"></div>
                <SortBatch
                  onClick={() => handleSort("name")}
                  active={sort === "name"}
                  type="Product"
                />
              </div>
              <div className="basis-2/5 sm:basis-5/12 flex items-center justify-around">
                <SortBatch
                  onClick={() => handleSort("price")}
                  active={sort === "price"}
                  type="Cost"
                />
                <SortBatch
                  onClick={() => handleSort("total")}
                  active={sort === "total"}
                  type="Total"
                />
                <div className="text-sm font-semibold translate-x-1">VAT</div>
                <div></div>
                <div></div>
              </div>
            </div>
          </Title>
          <Description>
            <div className="py-6 text-xs text-black flex flex-col gap-y-8 rounded-30 mt-2 bg-white shadow">
              {cart?.length ? (
                cart?.map((item: any, index: any) => {
                  return (
                    <div className="flex">
                      <div className="basis-3/5 sm:basis-7/12 flex-shrink flex">
                        <div className="basis-[12%] text-xs text-center my-auto">
                          {item?.quantity + " x"}
                        </div>
                        <div className="basis-[88%] flex lg:gap-8 gap-2 items-center">
                          <Link
                            className="flex w-fit h-fit"
                            to={`/shop/${item?.id}`}
                          >
                            <img
                              className="w-12 h-14  object-contain"
                              src={item?.images[0]}
                              alt=""
                            />
                          </Link>

                          <Link
                            className="text-sm  sm:text-sm"
                            to={`/shop/${item?.id}`}
                          >
                            <p className="text-black text-xs ">{item?.name}</p>
                            <p className="text-gray-dark text-xs ">
                              EAN: {item?.ean}
                            </p>
                          </Link>
                        </div>
                      </div>
                      <div className="basis-2/5 sm:basis-5/12 flex justify-around items-center">
                        <div className="text-sm text-black">
                          € {item.price?.toFixed(2)}
                        </div>
                        <div className="text-sm text-black">
                          € {(item?.price * item.quantity)?.toFixed(2)}
                        </div>
                        <div className="text-sm text-black">
                          {vatRate * 100}%
                        </div>
                        <div className="flex items-center gap-4">
                          <div
                            onClick={() => {
                              setSelectedProduct(item);
                              setShowModal(true);
                            }}
                          >
                            <BsPencilSquare className="text-black text-md hover:text-brown hover:cursor-pointer" />
                          </div>
                          <div
                            onClick={() => {
                              dispatch(removeFromCart(item));
                            }}
                          >
                            <BsXLg className="text-black text-md hover:text-brown hover:cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No items added to the cart</div>
              )}
            </div>
          </Description>
        </Accordion>
        <div className="flex items-center gap-4 mt-4">
          <Link to={"/shop"}>
            <button className="bg-white  rounded-100 whitespace-nowrap px-6 py-1.5 text-xs flex items-center gap-8 shadow">
              <p>Add new item</p>

              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1V6.5336M1 3.7668H7"
                  stroke="#202020"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </Link>

          <button
            onClick={exportCSV}
            className="bg-white  rounded-100 whitespace-nowrap px-6 py-1.5 text-xs flex items-center gap-8 shadow"
          >
            <p>Export order lines</p>
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4 4L7 1"
                stroke="#202020"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            onClick={() => dispatch(emptyCart())}
            className="bg-white  rounded-100 whitespace-nowrap px-6 py-1.5 text-xs flex items-center gap-8 shadow"
          >
            <p>Clear All Products</p>
            <AiOutlineClose />
          </button>
        </div>
      </div>
    </>
  );
}

export default CartList;
