import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from '../../../hooks';
import { store } from "../../../..";

export const getBrandsWithLetter = createAsyncThunk(
    "brands/getBrandsWithLetter",
    async (args,thunkAPI) => {
        const state = store.getState();


        try {
            const response = await axios.get(
                "https://www.stockperfume.com/wp/wp-json/wp/v3/filters?filterType=brand",
                { params: { "letters" : true }  }
            );

            return response.data;
        } catch (error:any) {

            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });