import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Filters from "./Filters";
import ProductCard from "./ProductCard";
import Sort from "./Sort";
import ProfileIcon from "../Common/ProfileIcon";
import { useSelector } from "react-redux";
import filterGenerator from "../../app/features/filter/actions/filterGenerator";
import { setOffset } from "../../app/features/product/actions/setOffset";
import { getProducts } from "../../app/features/product/actions/getProducts";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateFilter } from "../../app/features/filter/slices/filterSlice";
import { FileUploader } from "react-drag-drop-files";
import NotActiveRightNow from "../Common/NotActiveRightNow";
import { parse } from "papaparse";
import LoadingSpinner from "../Common/LoadingSpinner";
import { getWholesaleOrder } from "../../app/features/cart/getWholesaleOrder";
import { convertArrayToCSV } from "../../functions/convertArrayToCSV";
import Cookies from "js-cookie";
import {
  BsClipboardCheck,
  BsFileEarmarkTextFill,
  BsCartFill,
  BsCreditCard2FrontFill,
  BsPersonFill,
  BsHeartFill,
  BsFileEarmarkPlusFill,
  BsDownload,
} from "react-icons/bs";
import Pagination from "./Pagination";
import MobileProductCard from "./MobileProductCard";
import { notify } from "../../functions/notify";

function Shop() {
  const user = useAppSelector((state) => state.userReducer.user);
  const isLogin = useAppSelector((state) => state.userReducer.loggedIn);
  console.log(isLogin);
  const { products, total_pages, total_products } = useSelector(
    (state: any) => state.productsReducer
  );
  const navigate = useNavigate();
  const { category, id } = useParams();
  const selectedFilters = useSelector(
    (state: any) => state.filterReducer.selectedFilter
  );
  console.log(selectedFilters);
  const loading = useSelector((state: any) => state.productsReducer.loading);
  const [showNextPages, setShowNextPages] = useState(false);
  const [showPrevPages, setShowPrevPages] = useState(false);
  const fileTypes = ["CSV", "XLS", "XLSX"];
  const [file, setFile] = useState(null);

  const handleChange = (file: React.SetStateAction<null>) => {
    setFile(file);
    // @ts-ignore
    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        const sku_list = results.data.map((item: any) => {
          return item["SKU"];
        });

        dispatch(
          getWholesaleOrder({
            sku_list,
            product_list: results.data,
          })
        );
        navigate("/checkout");
      },
    });
  };

  const [fileTypeError, setFileTypeError] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  function handleDownloadPricelist() {
    if (isLogin) {
      window.location.href =
        "https://stocklist.stockperfume.com/9e97va0rz8x8fkbd";
    } else {
      notify("Please login to download the pricelist.");
    }
  }

  function exportCSV() {
    const csv = convertArrayToCSV(products);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  useEffect(() => {
    if (!(products?.length > 0) && !Cookies.get("sp-isSaved")) {
      if (category && id) {
        dispatch(updateFilter({ filterType: category, value: id }));
      } else {
        dispatch(
          getProducts(
            "https://www.stockperfume.com/wp/wp-json/wp/v3/products/?per_page=20"
          )
        );
      }
    }
    if (category && id) {
      dispatch(updateFilter({ filterType: category, value: id }));
    } else {
      dispatch(
        getProducts(
          "https://www.stockperfume.com/wp/wp-json/wp/v3/products/?per_page=20"
        )
      );
    }
  }, [category, id]);

  useEffect(() => {
    dispatch(setOffset({ url: filterGenerator(selectedFilters), page }));
  }, [page]);

  useEffect(() => {
    dispatch(getProducts(filterGenerator(selectedFilters)));
    setShowNextPages(false);
    setPage(1);
  }, [selectedFilters]);

  const [open, setOpen] = React.useState(true);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="md:mt-24"
    >
      {loading && <LoadingSpinner />}

      <p className="text-center hidden md:block -mb-14 lg:mb-0 lg:mt-0 -mt-2 pb-1 md:py-3 lg:py-6 lg:text-lg md:text-md text-black">
        Product catalogue
      </p>

      <Filters />
      <div className="flex md:flex-row w-full justify-end my-4 flex-row-reverse lg:px-16 xl:px-24 md:px-12 px-4">
        <div className="flex place-self-end space-x-4 text-sm">
          <span className="flex place-items-center text-xs md:text-sm border px-8 h-9 rounded-3xl">
            <BsClipboardCheck /> &nbsp; {total_products} results
          </span>
          <button
            onClick={handleDownloadPricelist}
            className="bg-white rounded-full py-2 px-5 shadow flex items-center place-content-center gap-2"
          >
            Download pricelist
            <BsDownload />
          </button>
          <button
            onClick={exportCSV}
            className="bg-white rounded-full py-2 px-5 shadow flex items-center place-content-center gap-2"
          >
            Export founded results
            <BsDownload />
          </button>
          <select
            onChange={(e) => {
              dispatch(
                updateFilter({ filterType: "per_page", value: e.target.value })
              );
            }}
            className="bg-white rounded-full py-2 px-6 shadow hidden md:inline"
          >
            <option id={"1"} value="20" selected>
              Show 20 items per page
            </option>
            <option id={"2"} value="40">
              Show 40 items per page
            </option>
            <option id={"3"} value="60">
              Show 60 items per page
            </option>
            <svg
              className="my-auto ml-3 inline -mt-1"
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.34042L4 4.34042L7 1.34042"
                stroke="#202020"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </select>
        </div>
      </div>
      <div className="flex flex-row lg:space-x-4 lg:px-16 xl:px-24 md:px-12 px-4 ">
        <div className="hidden lg:flex flex-col bg-white h-max  rounded-3xl shadow">
          <div
            className={
              "flex flex-row space-x-5 px-5 pr-8 py-9 relative " +
              (!open ? " px-0 pr-0 " : "")
            }
          >
            <span className={!open ? "flex mx-auto translate-x-4" : ""}>
              <Link to="/my-account">
                <ProfileIcon />
              </Link>
            </span>

            {open && (
              <div className="flex flex-col">
                {isLogin ? (
                  <span className="text-sm text-black">Hello</span>
                ) : (
                  "Stockperfume"
                )}

                <span className=" font-semibold text-md text-black">
                  {user?.name}
                </span>
              </div>
            )}

            <div className="w-4"></div>
            <svg
              onClick={() => {
                setOpen((state) => !state);
              }}
              className={
                "absolute right-0 hover:cursor-pointer" +
                (!open ? " transform rotate-180 -right-2" : "")
              }
              width="16"
              height="42"
              viewBox="0 0 16 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 5C0 2.23858 2.23858 0 5 0H16V42H5C2.23858 42 0 39.7614 0 37V5Z"
                fill="#202020"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.2724 16.113C10.3082 16.1487 10.3366 16.1911 10.356 16.2379C10.3754 16.2846 10.3854 16.3347 10.3854 16.3853C10.3854 16.4358 10.3754 16.4859 10.356 16.5327C10.3366 16.5794 10.3082 16.6218 10.2724 16.6575L5.92904 21.0001L10.2724 25.3427C10.3446 25.4149 10.3852 25.5128 10.3852 25.6149C10.3852 25.7171 10.3446 25.815 10.2724 25.8872C10.2002 25.9594 10.1022 26 10.0001 26C9.89798 26 9.80004 25.9594 9.72782 25.8872L5.11298 21.2724C5.07717 21.2367 5.04875 21.1942 5.02937 21.1475C5.00998 21.1008 5 21.0507 5 21.0001C5 20.9495 5.00998 20.8994 5.02937 20.8527C5.04875 20.806 5.07717 20.7635 5.11298 20.7278L9.72782 16.113C9.76355 16.0772 9.80599 16.0488 9.85271 16.0294C9.89943 16.01 9.94952 16 10.0001 16C10.0507 16 10.1008 16.01 10.1475 16.0294C10.1942 16.0488 10.2367 16.0772 10.2724 16.113Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
              />
            </svg>
          </div>
          <div className=" px-5">
            <hr className="self-center   " />
            <div
              className={
                "flex flex-col space-y-6 text-sm " +
                (!open ? " place-items-center " : null)
              }
            >
              <ShopMenuItem
                className="mt-5"
                open={open}
                icon={
                  <BsFileEarmarkTextFill className="text-lg group-hover:text-white" />
                }
                to={"/my-account/orders"}
                text={"Orders history"}
              />
              <ShopMenuItem
                open={open}
                icon={<BsCartFill className="text-lg group-hover:text-white" />}
                to={"/cart"}
                text={"View cart"}
              />
              <ShopMenuItem
                open={open}
                icon={
                  <BsCreditCard2FrontFill className="text-lg group-hover:text-white" />
                }
                to={"/checkout"}
                text={"Checkout"}
              />
              <ShopMenuItem
                open={open}
                icon={
                  <BsPersonFill className="text-lg group-hover:text-white" />
                }
                to={"/my-account"}
                text={"My account"}
              />
              <ShopMenuItem
                open={open}
                icon={
                  <BsHeartFill className="text-lg group-hover:text-white" />
                }
                to={"/my-account/favourites"}
                text={"Favourites"}
              />
            </div>
            <hr className="self-center    my-10" />
            <ShopMenuItem
              className={`${!open ? " flex-col " : null}`}
              open={open}
              icon={
                <BsFileEarmarkPlusFill className="text-lg group-hover:text-white" />
              }
              to={"/csv-order-upload"}
              text={"Upload CSV order"}
            />
            <FileUploader
              children={
                <div
                  className={
                    "flex flex-col text-xs space-y-4 place-items-center my-10 py-10 group hover:bg-gray px-3 rounded-3xl hover:cursor-pointer" +
                    (open ? " border-dashed border-black border" : " -mt-10 ")
                  }
                >
                  <svg
                    className={
                      "group-hover:stroke-gray-900 group-hover:fill-gray " +
                      (!open ? " order-2 " : null)
                    }
                    width="42"
                    height="43"
                    viewBox="0 0 42 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.42">
                      <path
                        d="M42 21.0492C42 32.6472 32.598 42.0492 21 42.0492C9.40202 42.0492 0 32.6472 0 21.0492C0 9.45121 9.40202 0.0491943 21 0.0491943C32.598 0.0491943 42 9.45121 42 21.0492Z"
                        fill="white"
                      />
                      <path
                        d="M41.5 21.0492C41.5 32.371 32.3218 41.5492 21 41.5492C9.67816 41.5492 0.5 32.371 0.5 21.0492C0.5 9.72736 9.67816 0.549194 21 0.549194C32.3218 0.549194 41.5 9.72736 41.5 21.0492Z"
                        stroke="#202020"
                        strokeOpacity="0.5"
                      />
                    </g>
                    <path
                      d="M21 13.4918V28.6148M15 21.0533H27"
                      stroke="#202020"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span
                    className={
                      "text-center " + (!open ? " order-1 pb-4 " : null)
                    }
                  >
                    Upload CSV order here
                  </span>
                  <span
                    className={
                      "text-xs text-center " + (!open ? " order-3 " : null)
                    }
                  >
                    or change file
                  </span>
                  {fileTypeError && (
                    <NotActiveRightNow
                      text={"You can only upload CSV files."}
                    />
                  )}
                </div>
              }
              multiple={false}
              handleChange={handleChange}
              name="file"
              onTypeError={() => {
                setFileTypeError(true);
                setTimeout(() => {
                  setFileTypeError(false);
                }, 3000);
              }}
              types={fileTypes}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Sort />
          <div
            className={`flex flex-col bg-white rounded-30 shadow mt-3.5 px-3 md:px-4 ${
              !products && "h-full"
            }`}
          >
            {products ? (
              products?.map((product: any) => (
                <ProductCard product={product} open={open} key={product?.id} />
              ))
            ) : (
              <p className="hidden lg:flex m-auto text-2xl font-semibold">
                No products found
              </p>
            )}
            {products ? (
              products?.map((product: any) => (
                <MobileProductCard product={product} key={product?.id} />
              ))
            ) : (
              <p className="flex lg:hidden m-auto text-lg font-semibold py-20">
                No products found
              </p>
            )}
          </div>
          {/* Pagination */}
          <Pagination
            className={`mt-3.5 ${!products && "hidden"}`}
            showNextPages={showNextPages}
            setShowNextPages={setShowNextPages}
            page={page}
            setPage={setPage}
            showPrevPages={showPrevPages}
            total_pages={total_pages}
            setShowPrevPages={setShowPrevPages}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Shop;

const ShopMenuItem = ({ icon, to, text, open, className }: any) => {
  return (
    <Link
      to={to}
      className={`flex place-items-center space-x-2 group ${className}`}
    >
      <div className="w-10 h-10 flex items-center place-content-center flex-shrink-0 rounded-full bg-gray group-hover:bg-brown">
        {icon}
      </div>
      {open && <span className="text-sm text-black">{text}</span>}
    </Link>
  );
};
