import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { getSingleProductFromAPI} from "../actions/getSingleProduct";
import { Product } from "../productType";
import { getRelatedProducts } from '../actions/getRelatedProducts';


const singleProductSlice = createSlice({
    name: 'product',
    initialState: {
        current_product: {},
        related_products:[],
        loading: false
    },
    reducers:{
        setSingleProduct: (state, action) => {
            state.current_product = action.payload
        },
        emptyRelatedProducts : state => {
            state.related_products = []
        }
       
    },
    extraReducers: function (builder) {
        builder
            .addCase(getSingleProductFromAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getSingleProductFromAPI.fulfilled, (state, action : PayloadAction<Product> ) => {
                
                state.current_product = action.payload
                state.loading = false
                
            })
            .addCase(getSingleProductFromAPI.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getRelatedProducts.fulfilled, (state, {payload, meta} ) => {
                // @ts-ignore
                state.related_products.push(payload)
                state.loading = false
            })
    },
    
})
export const { setSingleProduct, emptyRelatedProducts} = singleProductSlice.actions
export const singleProductReducer = singleProductSlice.reducer;