import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getBrandsWithLetter } from "../../app/features/filter/actions/getBrandsWithLetter";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
function BrandMenu() {
  const navigate = useNavigate();
  const brandList = useSelector(
    (state: any) => state.filterReducer?.["brand_list"]
  );

  let newObject = {};
  brandList?.forEach((brand: any) => {
    const firstLetter = brand?.name.charAt(0).toUpperCase(); // İlk harfi büyük harfe çevir
    if (firstLetter.match(/[A-Z]/)) { // Eğer ilk harf A-Z arasında ise
      if (!newObject[firstLetter]) {
        newObject[firstLetter] = [];
      }
      newObject[firstLetter].push(brand);
    }
  });

  const handleNavigate = (letter) => {
    navigate(`/brands`);

    setTimeout(() => {
      const element = document.getElementById(letter);
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const offset = elementRect.top - 100;
        window.scrollBy({
          top: offset,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  return (
    <div className="absolute pt-14 mt-4  -left-80 group-hover:block">
      <svg
        className="absolute -translate-y-2 translate-x-[21rem]"
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 0L15.7942 10.5H0.205771L8 0Z" fill="white" />
      </svg>
      <div className="flex flex-col whitespace-nowrap bg-white p-9 rounded-20 shadow">
        <h1 className=" font-medium text-sm">Popular Brands</h1>
        <div className="flex flex-wrap justify-between lg:w-[730px]  md:w-[640px] font-normal mt-5 gap-y-4">
          <Link to={"shop/pa_brand/46892"} className="w-1/5">
            Armani
          </Link>
          <Link to={"shop/pa_brand/60365"} className="w-1/5">
            Dolce & Gabnana
          </Link>
          <Link to={"shop/pa_brand/52886"} className="w-1/5">
            Burberry
          </Link>
          <Link to={"shop/pa_brand/68926"} className="w-1/5">
            Guerlain
          </Link>
          <Link to={"shop/pa_brand/52960"} className="w-1/5">
            Bvlgari
          </Link>
          <Link to={"shop/pa_brand/71453"} className="w-1/5">
            Hugo Boss
          </Link>
          <Link to={"shop/pa_brand/53201"} className="w-1/5">
            Calvin Klein
          </Link>
          <Link to={"shop/pa_brand/67343"} className="w-1/5">
            Giorgio Armani
          </Link>
          <Link to={"shop/pa_brand/55113"} className="w-1/5">
            Christian Dior
          </Link>
          <Link to={"shop/pa_brand/67486"} className="w-1/5">
            Givenchy
          </Link>
        </div>

        <h1 className=" font-medium mt-8">
          Alphabetical brand Index{" "}
          <span className="text-xs text-brown">
            &nbsp; &nbsp; &nbsp;(indexed only for existing brands)
          </span>{" "}
        </h1>
        <div className="flex flex-wrap gap-2 mt-5">
          {Object?.entries(newObject).map(([letter, brands]: any) => (
            <button
              key={letter}
              onClick={() => handleNavigate(letter)}
              className="w-10 h-10 hover:bg-brown border hover:text-white rounded-full
                        hover:border-0"
            >
              {letter}
            </button>
          ))}
          <Link
            to={"/brands"}
            className="group border px-4 rounded-full flex items-center justify-center gap-6 bg-white"
          >
            <h1 className="text-black">View all brands</h1>
            <RiArrowRightSLine className=" text-black" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BrandMenu;
