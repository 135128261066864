import React from "react";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import WishListButton from "../Common/WishListButton";
import { setQuantity } from "../../app/features/cart/slices/cartSlice";
import { useEffect } from "react";
import priceFormatter from "../../functions/priceFormatter";
import StockInfo from "../Common/StockInfo";
import PriceInfo from "../Common/PriceInfo";
import QuantityInput from "../Common/QuantityInput";
import { BsCart, BsCartCheck } from "react-icons/bs";

function ProductCard({ product }: any) {
  const cart = useAppSelector((state: any) => state.cartReducer.products);
  const getProductQuantity = (id: number) => {
    const inProduct = isInCart(id);
    return inProduct.length > 0 ? inProduct[0].quantity : 1;
  };
  const [ProductQuantity, setProductQuantity] = React.useState(1);
  const isInCart = (id: number) => {
    return cart.filter((item: any) => item.id === id);
  };
  const [isActivated, setIsActivated] = React.useState(
    isInCart(product.id).length ? true : false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setProductQuantity(getProductQuantity(product.id));
  }, []);

  const handleClick = () => {
    if (!isActivated) {
      // @ts-ignore
      dispatch(setQuantity({ product: product, quantity: ProductQuantity }));
    } else {
      // @ts-ignore
      dispatch(setQuantity({ product: product, quantity: 0 }));
      setProductQuantity(1);
    }
    setIsActivated(!isActivated);
  };
  return (
    <div className="hidden lg:grid lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 py-3 border-b">
      <ProductCardItem className="!col-span-2 !place-content-start gap-2">
        <WishListButton product={product} className="hover:cursor-pointer" />
        <Link className="flex gap-2 items-center" to={`/shop/${product.slug}`}>
          <img
            className="w-12 h-14  object-contain"
            src={product?.images[0]}
            alt=""
          />
          <p className="text-sm">{product?.name}</p>
        </Link>
      </ProductCardItem>

      <ProductCardItem>
        <p className="text-sm">{product?.brand.name || "no brand"}</p>
      </ProductCardItem>

      <ProductCardItem>
        <p className="text-sm">{product?.ean || "no ean"}</p>
      </ProductCardItem>

      <ProductCardItem>
        <p className="text-sm">{product?.size?.name || "no size"}</p>
      </ProductCardItem>

      <ProductCardItem className="hidden 2xl:flex">
        <StockInfo stockQuantity={product?.stock_quantity} />
      </ProductCardItem>

      <ProductCardItem className="lg:hidden xl:flex flex">
        <PriceInfo price={product?.price} />
      </ProductCardItem>

      <ProductCardItem className="lg:gap-0 xl:gap-2">
        <QuantityInput
          product={product}
          getProductQuantity={getProductQuantity}
          setProductQuantity={setProductQuantity}
          setIsActivated={setIsActivated}
          ProductQuantity={ProductQuantity}
          stockQuantity={product?.stock_quantity}
        />
        <button
          /*   disabled={product?.stock_quantity > 0 ? false : true} */
          // @ts-ignore
          onClick={() => {
            handleClick();
          }}
          className={`flex flex-shrink-0 items-center place-content-center lg:w-10 lg:h-10 w-8 h-8 rounded-full border hover:bg-brown group ${
            isActivated ? "bg-brown" : "bg-white"
          }`}
        >
          {isActivated ? (
            <BsCartCheck className="text-white text-md" />
          ) : (
            <BsCart className="text-md group-hover:text-white" />
          )}
        </button>
      </ProductCardItem>
    </div>
  );
}

export default ProductCard;

const ProductCardItem = ({ children, className }: any) => {
  return (
    <div
      className={`flex items-center place-content-center col-span-1 ${className}`}
    >
      {children}
    </div>
  );
};
