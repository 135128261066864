import React from 'react'
import LeftSideBar from "../../SideBars/LeftSideBar";
import {nanoid} from "@reduxjs/toolkit";

function PaymentConfirmation(){
    const [open, setOpen] = React.useState(true);

    return (
        <div className="flex flex-col lg:mx-20 md:mt-20">
            <div className="text-center my-8 font-normal text-lg">Wallet</div>
            <div className="flex lg:space-x-5 mx-4">
                <LeftSideBar key={nanoid()} setOpen={setOpen} open={open}/>
                <div className={`w-full flex flex-col `}>
                    <div
                        className="flex flex-col relative bg-white checkout-block  text-center p-1 lg:p-0 rounded-20">
                        <div className="py-10">
                            <p className="text-xl font-medium">
                                Top up amount
                            </p>
                            <p className="text-2xl lg:mt-8 font-normal">
                                € 200,00
                            </p>
                            <div className="border-b-[2px] lg:mt-8 border-brown lg:mx-24">

                            </div>
                            <p className="text-xs mt-3">
                                Enter the amount you want to add to your balance
                            </p>

                        </div>
                        <button
                            className="w-11 h-11 absolute lg:right-8 right-6 top-4 group hover:bg-brown grid items-center justify-items-center border rounded-full">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.7 7.3L7.3 12.7M7.3 7.3L12.7 12.7M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                                    stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <div
                        className="flex flex-col mt-5 relative bg-white checkout-block  text-center p-1 lg:p-0 rounded-20">
                        <div className="px-32 py-16">
                            <p className="text-sm mt-10 ">Payment confirmation</p>
                            <p className="text-xs mt-16">
                                You just added the amount of <span className="font-medium">€ 200,00</span> to your existing balance of <span className="font-medium">€ 12,000.00.</span>
                                Your current balance is <span className="font-medium">€ 12,200.00.</span> You can see the Wallet history in your Wallet page.
                            </p>
                            <h6 className="text-sm font-medium mt-16">Transaction ID 54639</h6>
                            <button
                                className="bg-brown  inline-block relative hover:bg-brown items-start  py-3.5  px-7 md:py-2 lg:py-3.5 rounded-100 lg:px-14 mt-16  text-white ">
                                <p className="lg:text-sm ml-1  text-xs">View history </p>
                                <svg className="absolute lg:top-4 top-3.5 md:top-1.5 left-2  lg:left-6" width="18"
                                     height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8 7.9145L6.70764 9.20747L6.70755 9.20755C6.5199 9.39521 6.26538 9.50063 6 9.50063C5.73462 9.50063 5.4801 9.39521 5.29245 9.20755C5.10479 9.0199 4.99937 8.76538 4.99937 8.5C4.99937 8.23462 5.10479 7.9801 5.29245 7.79245L8.292 4.79289L8 7.9145ZM8 7.9145L8 12.5C8 12.7652 8.10536 13.0196 8.29289 13.2071C8.48043 13.3946 8.73478 13.5 9 13.5C9.26522 13.5 9.51957 13.3946 9.70711 13.2071C9.89464 13.0196 10 12.7652 10 12.5L10 7.9145L11.2924 9.20747L11.2924 9.20755C11.4801 9.39521 11.7346 9.50063 12 9.50063C12.2654 9.50063 12.5199 9.39521 12.7076 9.20755C12.8952 9.0199 13.0006 8.76538 13.0006 8.5C13.0006 8.23462 12.8952 7.9801 12.7076 7.79245L9.708 4.79289C9.70793 4.79282 9.70786 4.79275 9.70778 4.79268C9.61494 4.69965 9.50466 4.62584 9.38327 4.57547C9.26178 4.52506 9.13153 4.49911 9 4.49911C8.86846 4.49911 8.73822 4.52506 8.61673 4.57547C8.49544 4.6258 8.38525 4.69953 8.29245 4.79245L8 7.9145ZM13.5962 4.40381C14.8152 5.62279 15.5 7.27609 15.5 9C15.5 10.7239 14.8152 12.3772 13.5962 13.5962C12.3772 14.8152 10.7239 15.5 9 15.5C7.27609 15.5 5.62279 14.8152 4.40381 13.5962C3.18482 12.3772 2.5 10.7239 2.5 9C2.5 7.27609 3.18482 5.62279 4.40381 4.40381C5.62279 3.18482 7.27609 2.5 9 2.5C10.7239 2.5 12.3772 3.18482 13.5962 4.40381ZM2.98959 2.98959C1.39553 4.58365 0.499999 6.74566 0.499999 9C0.499999 11.2543 1.39553 13.4163 2.98959 15.0104C4.58365 16.6045 6.74566 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 6.74566 16.6045 4.58365 15.0104 2.98959C13.4163 1.39553 11.2543 0.500001 9 0.500001C6.74566 0.500001 4.58365 1.39553 2.98959 2.98959Z"
                                        fill="white" stroke="#B59679"/>
                                </svg>
                            </button>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}
export default PaymentConfirmation