import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { notify } from "../../../functions/notify";

type InitialState = {
  products: [];
};

const initialState: InitialState = {
  // @ts-ignore
  products: Cookies.get("sp-wishlist")
    ? JSON.parse(Cookies.get("sp-wishlist"))
    : [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action: any) => {
      notify(
        `${action.payload.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} added to favourites`
      );
      let item = state.products.find(
        (item: any) => item.id === action.payload.id
      );

      if (typeof item === "undefined") {
        // @ts-ignore
        state.products?.push({ ...action.payload });
      }
      Cookies.set("sp-wishlist", JSON.stringify(state.products), {
        expires: 365,
      });
    },

    removeFromWishlist: (state, action) => {
      notify(
        `${action.payload.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} removed from favourites`,
        "error"
      );
      let item = state.products.find(
        (item: any) => item.id === action.payload.id
      );

      if (typeof item !== "undefined") {
        // @ts-ignore
        state.products = state.products.filter(
          (item: any) => item.id !== action.payload.id
        );
      }
      Cookies.set("sp-wishlist", JSON.stringify(state.products), {
        expires: 365,
      });
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export const wishlistReducer = wishlistSlice.reducer;
