import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const sendEmail = createAsyncThunk(
    "sendEmail",
    async (args: any,thunkAPI) => {
        try {
            const response = await axios.post(
                `https://www.stockperfume.com/wp/wp-json/wp/v3/contact`,args
            );
         
            return response.data;
        } catch (error:any) {

            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });