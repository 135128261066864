import React from "react";
import PhoneInput from "react-phone-number-input";
import {Link} from "react-router-dom";
import NotActiveRightNow from "../Common/NotActiveRightNow";

function Dropshipping() {
    const [number, setNumber] = React.useState<string | undefined>("");

    return (
        <div>
            {/* BANNER START */}
            <div
                className="lg:bg-homeBanner bg-center  bg-homeBannerMobile bg-cover lg:min-h-[504px] -mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
                <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-28 col-end-13 self-center lg:mx-10 relative">
                    <p className="lg:text-2xl text-2xl text-black font-medium tracking-normal -translate-x-24 md:translate-x-0">Dropshipping</p>

                    <div className="h-24 pt-20 -translate-x-24 md:translate-x-0">
                        <a href="https://perfumedropshipment.com" target={"_blank"}>
                        <button
                            className="bg-black  inline-block relative hover:bg-brown items-start  py-1.5  px-7 md:py-2 md:px-12 lg:py-3.5 rounded-100 lg:px-12   text-white ">
                            <p className="lg:text-sm mr-4  text-xs">Perfumedropshipment.com </p>
                            <svg className="absolute lg:top-5 top-2 md:top-2.5 right-2 md:right-6 lg:right-6"
                                 width="7" height="12" viewBox="0 0 7 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M1.11239 10.887C1.07658 10.8513 1.04816 10.8089 1.02877 10.7621C1.00939 10.7154 0.999408 10.6653 0.999408 10.6147C0.999408 10.5642 1.00939 10.5141 1.02877 10.4673C1.04816 10.4206 1.07658 10.3782 1.11239 10.3425L5.45573 5.9999L1.11239 1.65733C1.04018 1.58512 0.99961 1.48718 0.99961 1.38506C0.99961 1.28293 1.04018 1.18499 1.11239 1.11278C1.1846 1.04057 1.28254 1 1.38467 1C1.48679 1 1.58473 1.04057 1.65694 1.11278L6.27178 5.72762C6.3076 5.76335 6.33601 5.80578 6.3554 5.85251C6.37479 5.89923 6.38477 5.94932 6.38477 5.9999C6.38477 6.05048 6.37479 6.10057 6.3554 6.14729C6.33601 6.19401 6.3076 6.23645 6.27178 6.27217L1.65694 10.887C1.62122 10.9228 1.57878 10.9512 1.53206 10.9706C1.48534 10.99 1.43525 11 1.38467 11C1.33408 11 1.28399 10.99 1.23727 10.9706C1.19055 10.9512 1.14811 10.9228 1.11239 10.887Z"
                                      fill="white" stroke="white" stroke-width="0.5"/>
                            </svg>
                        </button>
                        </a>
                    </div>
                </div>

                <div className="hidden lg:block col-start-9 col-end-13  self-center mt-16 ">
                    <p className=" lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">High
                        quality perfume and </p>
                    <p className="lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">wide
                        range of beauty products</p>
                </div>
            </div>
            {/* BANNER END */}
            <div
                className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 mt-3 -mt-14">
                <div className="basis-1/1 lg:px-44 px-10 relative">
                    <p className="font-medium mt-16 text-center lg:text-xl text-black">Dropshipping with
                        <a href="https://perfumedropshipment.com" target={"_blank"} className="underline">Perfumedropshipment.com</a></p>
                    <p className="text-sm text-black text-center mt-8 px-5 ">
                        “Dropshipping is a tripartite system where the customer places an order on the website of the
                        merchant, which forwards it to the supplier (StockPerfume), who ensures delivery and manages
                        stocks.”
                    </p>
                    <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">Why dropshipping?</p>
                    <p className="text-sm text-black mt-8 ">
                        Firstly, dropshipping is easy and risk-free through a reliable supplier. It’s one of the easiest
                        ways to fulfill your orders.
                        <br/>
                        <br/>
                        If you are a new company, then your goal in the first step will be to make as many sales as
                        possible. You can achieve this by working with a company that provides guarantees and easy
                        supply.
                        <br/>
                        <br/>
                        Lastly, As Stock Perfume, we offer the best wholesale prices in our dropshipping sales. Remember
                        that Stock Perfume dropshipping values your customers as if they were their own.
                    </p>

                    <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">Are you interested in
                        selling without investing in stock?</p>
                    <p className="text-sm text-black mt-8 ">
                        Discover the Stock Perfume’s Dropshipping solution!
                        We make all of our Perfumery & Beauty products available to your customers. Your customers will
                        buy on your sales channels by choosing among the many products that we make available for you.
                        Without any physical stock, you will be able to offer them more than 60,000 references and 1,200
                        brands.

                        <br/>
                        <br/>
                        Once your customers have placed orders, you will need to provide us with the order information.
                        Export your orders by CSV file then import them into our Perfumedropshipment.com website. It’s
                        simple! just use the template and tool provided. You can upload all your orders and their
                        related delivery addresses at once! This is a unique and the easiest way to upload your orders.
                        <br/>
                        <br/>
                        Your orders will finally be integrated into our system you have nothing more to do, we take care
                        of the rest. Your orders will be carefully prepared and shipped to your customers. We will
                        deliver your customers for you.
                    </p>
                    <p className="font-medium mt-8  lg:text-start lg:text-lg text-black">How does it
                        works?</p>
                    <ol id="number-list" className="mt-8  ">
                        <li className=" text-sm ">
                            Register to our Perfumedropshipment.com website.
                        </li>
                        <li className="text-sm ">
                            We will share with you the product data feed as CSV format.

                        </li>
                        <li className="text-sm ">
                            Integrate the CSV file to your sale channel and start receiving orders.
                        </li>
                        <li className="text-sm ">
                            Export your orders from your sale channel.
                        </li>
                        <li className="text-sm ">
                            Fill the dropshipping order upload template in Perfumedropshipment.com.
                        </li>
                        <li className="text-sm  ">
                            Each order will be uploaded with its own delivery address, you can now finalize the payment.
                        </li>
                    </ol>
                    <p className="text-lg text-black mt-8 ">
                        And there you go! The easiest and fastest way to dropship.
                    </p>

                    <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">Subscribe and start
                        Dropshipping!</p>
                    <div className="py-8">
                        <Link to="/register">
                        <button
                            className="bg-black  inline-block relative hover:bg-brown items-start  py-3.5  px-7 md:py-2 md:px-12 lg:py-4 rounded-100 lg:px-10  text-white ">
                            <p className="lg:text-sm mr-4  text-xs">Register now </p>
                            <svg className="absolute lg:top-5 top-4 md:top-3.5 right-4 md:right-6 lg:right-6"
                                 width="7" height="12" viewBox="0 0 7 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M1.11239 10.887C1.07658 10.8513 1.04816 10.8089 1.02877 10.7621C1.00939 10.7154 0.999408 10.6653 0.999408 10.6147C0.999408 10.5642 1.00939 10.5141 1.02877 10.4673C1.04816 10.4206 1.07658 10.3782 1.11239 10.3425L5.45573 5.9999L1.11239 1.65733C1.04018 1.58512 0.99961 1.48718 0.99961 1.38506C0.99961 1.28293 1.04018 1.18499 1.11239 1.11278C1.1846 1.04057 1.28254 1 1.38467 1C1.48679 1 1.58473 1.04057 1.65694 1.11278L6.27178 5.72762C6.3076 5.76335 6.33601 5.80578 6.3554 5.85251C6.37479 5.89923 6.38477 5.94932 6.38477 5.9999C6.38477 6.05048 6.37479 6.10057 6.3554 6.14729C6.33601 6.19401 6.3076 6.23645 6.27178 6.27217L1.65694 10.887C1.62122 10.9228 1.57878 10.9512 1.53206 10.9706C1.48534 10.99 1.43525 11 1.38467 11C1.33408 11 1.28399 10.99 1.23727 10.9706C1.19055 10.9512 1.14811 10.9228 1.11239 10.887Z"
                                      fill="white" stroke="white" stroke-width="0.5"/>
                            </svg>
                        </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* FORM START*/}
            <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto mt-3">
                <div className="flex-1">
                    <div
                        className="grid grid-cols-12 rounded-20  md:gap-x-8 md:gap-y-4 bg-white shadow md:px-20 py-8  ">
                        <div className="col-span-12 text-center">
                            <h6 className="text-sm text-black">
                                For further questions, including partnership opportunities,<br/>
                                please email us or contact using our contact form.
                            </h6>
                        </div>
                        <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                            <p className="mb-3 text-sm font-medium text-black">Name*</p>
                            <input
                                placeholder="Name"
                                className="border rounded-20 w-full text-xs py-3 px-5"
                                type="text"
                            />
                        </div>
                        <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                            <p className="mb-3 text-sm font-medium text-black">Surname*</p>
                            <input
                                placeholder="Surname"
                                className="border rounded-20 w-full text-xs py-3 px-5"
                                type="text"
                            />
                        </div>
                        <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                            <p className="mb-3 text-sm font-medium text-black">Company</p>
                            <input
                                placeholder="Company"
                                className="border rounded-20 w-full text-xs py-3 px-5"
                                type="text"
                            />
                        </div>

                        <div className="md:col-span-6 col-span-12 px-4 md:px-0">
                            <p className="mb-3 text-sm font-medium text-black">Phone*</p>
                            <PhoneInput
                                international
                                value={number}
                                className="text-xs"
                                onChange={(e) => {
                                    setNumber(e);
                                }}
                            />
                        </div>


                        <div className=" col-span-12 px-4 md:px-0">
                            <p className="mb-3 text-sm font-medium text-black">Message*</p>
                            <input
                                placeholder="Text"
                                className="border rounded-20 w-full pb-44 pt-4 text-xs  placeholder:text-black  px-5"
                                type="text"
                            />
                        </div>

                        <div className="col-span-12 mt-6 lg:mt-5 grid justify-items-center">

                                <button
                                    className="bg-black hover:bg-brown text-white px-32 py-2 text-xs font-normal rounded-full">
                                    Send
                                </button>

                        </div>
                    </div>
                </div>

            </div>
            {/*FORM END*/}

        </div>
    )
}

export default Dropshipping