import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { sendEmail } from "../../app/features/contact/sendEmail";
import { notify } from "../../functions/notify";
import LoadingSpinner from "../Common/LoadingSpinner";
import PhoneInput from "react-phone-number-input";

function ContactForm() {
  const [number, setNumber] = React.useState<string | undefined>("");
  const loading = useAppSelector((state) => state.contactReducer.loading);
  const sended = useAppSelector((state) => state.contactReducer.sended);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = React.useState({
    name: false,
    phone: false,
    address: false,
    mail: false,
    company: false,
  });

  const [formErrors, setFormErrors] = React.useState({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors: any = {};
    if (!formValues.name) {
      errors.name = "Name is required";
    }
    if (!formValues.phone) {
      errors.email = "Phone is required";
    }
    if (!formValues.address) {
      errors.message = "Message is required";
    }
    if (!formValues.mail) {
      errors.mail = "Mail is required";
    }
    return errors;
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      notify("Email sended successfully");
      dispatch(sendEmail(formValues));
    }
  };

  return (
    <form
      onChange={handleInputChange}
      onSubmit={handleFormSubmit}
      className="grid grid-cols-12 rounded-20  md:gap-x-8 md:gap-y-4 bg-white shadow md:px-20 py-8  "
    >
      <div className="col-span-12 text-center">
        <h6 className="text-base text-black">
          For further questions, including partnership opportunities,
          <br />
          please email us or contact using our contact form.
        </h6>
      </div>
      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-base font-medium text-black">Name*</p>
        <input
          placeholder="Name"
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="text"
          name={"name"}
        />
      </div>
      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-base font-medium text-black">Mail*</p>
        <input
          placeholder="Mail"
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="email"
          name={"mail"}
        />
      </div>
      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-base font-medium text-black">Company</p>
        <input
          placeholder="Company"
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="text"
          name={"company"}
        />
      </div>

      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-base font-medium text-black">Phone*</p>
        <PhoneInput
          international
          value={number}
          className="text-sm h-11"
          onChange={(e) => {
            setNumber(e);
          }}
          name={"phone"}
        />
      </div>

      <div className=" col-span-12 px-4 md:px-0">
        <p className="mb-3 text-base font-medium text-black">Message*</p>
        <input
          placeholder="Text"
          className="border rounded-20 w-full pb-44 pt-4 text-sm px-5"
          type="text"
          name={"address"}
        />
      </div>
      {formErrors && (
        <div className="col-span-12 text-center">
          <p className="text-red-500 text-xs">
            {Object.entries(formErrors).map(([key, value]: any) => {
              return <p key={key}>{value}</p>;
            })}
          </p>
        </div>
      )}
      <div className="col-span-12 mt-6 lg:mt-5 grid justify-items-center">
        <button
          type="submit"
          className="bg-black hover:bg-brown text-white px-32 py-2 text-xs font-normal rounded-full"
        >
          {loading ? <LoadingSpinner /> : <span>Send</span>}
        </button>
      </div>
    </form>
  );
}

export default ContactForm;
