import React from "react";
import PhoneInput from "react-phone-number-input";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { userReducer } from "../../../app/features/user/userSlice";
import { updateUserInfo } from "../../../app/features/user/actions/updateUserInfo";
import countries from "../../../constants/countries";
import { nanoid } from "@reduxjs/toolkit";
import PersonalInfo from "./PersonalInfo";
import { updateCargoInfo } from "../../../app/features/user/actions/updateCargoInfo";
import { useEffect } from "react";
import { european_countries_vat } from "../../../constants/countries";
import { addressTitle } from "../../../functions/addressTitle";

interface FormInterface {
  [key: string]: string;
}

function PersonalInfoForm() {
  let user = useAppSelector((state) => state.userReducer.user);

  const [VATcountry, setVATcountry] = React.useState(
    user.VATnumber?.country || "BE"
  );
  const [billing, setBilling] = React.useState(
    user.billing_address?.id || nanoid()
  );
  const [shipping, setShipping] = React.useState(
    user.shipping_address?.id || nanoid()
  );
  const dispatch = useAppDispatch();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let formObj: FormInterface = {};
    for (let [key, value] of Array.from(formData.entries())) {
      formObj[key] = value.toString();
    }
    const { first_name, last_name, VATnumber, VATcountry, billing, shipping } =
      formObj;
    dispatch(
      updateUserInfo({
        first_name,
        last_name,
        vat_number: { number: VATnumber, country: VATcountry },
      })
    );
    dispatch(updateCargoInfo({ change: "shipping", address_id: shipping }));
    dispatch(updateCargoInfo({ change: "billing", address_id: billing }));
  }

  useEffect(() => {
    setVATcountry(user.VATnumber?.country || "BE");
  }, [user]);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="grid grid-cols-12 rounded-20  md:gap-x-8 gap-y-6 bg-white shadow md:px-20 py-8  "
    >
      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-sm font-semibold text-black">Name*</p>
        <input
          name="first_name"
          defaultValue={user?.name}
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="text"
        />
      </div>
      <div className="md:col-span-6 col-span-12 px-4 md:px-0">
        <p className="mb-3 text-sm font-semibold text-black">Surname*</p>
        <input
          name="last_name"
          defaultValue={user?.surname}
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="text"
        />
      </div>
      <div className="md:col-span-6 col-span-12 px-4 md:px-0 relative">
        <p className="mb-3 text-sm font-semibold">VAT Country</p>

        <select
          value={VATcountry}
          onChange={(e) => {
            // @ts-ignore
            setVATcountry(e.target.value);
          }}
          required
          className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
          name="VATcountry"
        >
          {european_countries_vat.map((country: any) => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className=" md:col-span-6 col-span-12  px-4 md:px-0 relative">
        <p className="mb-3 text-sm font-semibold text-black">VAT Number*</p>
        <input
          name="VATnumber"
          defaultValue={user?.VATnumber?.number}
          className="border rounded-20 w-full text-sm py-3 px-5"
          type="text"
        />
        <p
          className={`text-sm pl-3  absolute left-0 ${
            user?.VATnumber?.isValid !== "1" ||
            user?.VATnumber?.isValid !== "true" ||
            user?.VATnumber?.isValid !== true
              ? " text-red-900 "
              : " text-black "
          }`}
        >
          {user?.VATnumber?.isValid === "1" ||
          user?.VATnumber?.isValid === "true" ||
          user?.VATnumber?.isValid === true
            ? null
            : "Not "}
          Valid VAT number following the{" "}
          <span className="text-brown">VIES CHECK</span> wesbite.
        </p>
      </div>

      <div className=" md:col-span-6 col-span-12 px-4 mt-2 lg:mt-0 md:px-0">
        <p className="mb-3 text-sm font-semibold text-black">
          Billing Address*
        </p>
        <select
          defaultValue={billing}
          onChange={(e) => setBilling(e.target.value)}
          value={billing}
          className="border rounded-20 w-full text-sm py-3 px-5"
          name="billing"
          id=""
        >
          <option disabled value="">
            {user?.savedAddresses?.length === 0
              ? "No saved addresses"
              : "Select address"}
          </option>
          {user?.savedAddresses?.map((address: any) => {
            return (
              <option key={nanoid()} value={address.id}>
                {addressTitle(address.title)}
              </option>
            );
          })}
        </select>
      </div>
      <div className="md:col-span-6  col-span-12 px-4 md:px-0">
        <p className="mb-3 text-sm font-semibold text-black">
          Shipping Address*
        </p>
        <select
          defaultValue={shipping}
          onChange={(e) => setShipping(e.target.value)}
          value={shipping}
          className="border rounded-20 w-full text-sm py-3 px-5"
          name="shipping"
          id=""
        >
          <option disabled>
            {user?.savedAddresses?.length === 0
              ? "No saved addresses"
              : "Select address"}
          </option>
          {user?.savedAddresses?.map((address: any) => {
            return (
              <option key={nanoid()} value={address.id}>
                {addressTitle(address.title)}
              </option>
            );
          })}
        </select>
      </div>

      <div className="col-span-12 mt-6 lg:mt-5 grid justify-items-center">
        <button
          type="submit"
          className="bg-black hover:bg-brown text-white px-14 h-10 text-sm font-normal rounded-full"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
}

export default PersonalInfoForm;
