import React from "react";
import MegaMenu from "../components/NavBar/MegaMenu";
import { Link } from "react-router-dom";
import BrandMenu from "../components/NavBar/BrandMenu";

export type Page = {
  name: string;
  path?: string;
  dropDownComponent?: React.ReactNode;
};

export const menu: Array<Page> = [
  {
    name: "PRODUCTS",
    path: "/shop",
    dropDownComponent: <MegaMenu />,
  },
  {
    name: "The Company",
    path: "/about-us",
    dropDownComponent: (
      <div className="absolute pt-14 mt-4 left-0 group-hover:block">
        <svg
          className="absolute -translate-y-2 translate-x-10"
          width="16"
          height="11"
          viewBox="0 0 16 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 0L15.7942 10.5H0.205771L8 0Z" fill="white" />
        </svg>
        <div className="flex bg-white rounded-20 shadow">
          <ul className="whitespace-nowrap  py-3 px-2">
            <li className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15">
              <Link to="/about-us">
                <h1 className="mr-12">About Us</h1>
              </Link>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </li>
            <li className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15">
              <Link to="/become-partner">
                <h1 className="mr-12">Become Partner</h1>
              </Link>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </li>
            <li className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15">
              <Link to="/wholesale">
                <h1 className="mr-12">Wholesale</h1>
              </Link>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </li>
            <li className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15">
              <Link to="/logistic-enquiries">
                <h1 className="mr-12">Logistic</h1>
              </Link>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    name: "Brands",
    path: "/brands",
    dropDownComponent: <BrandMenu />,
  },
  {
    name: "Contact",
    path: "/contact-us",
  },
  {
    name: "CSV Order Upload",
    path: "/csv-order-upload",
  },
];
