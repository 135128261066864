import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../product/productType";
import { getCargoCompanies } from "../getCargoCompanies";
import { getShippingPrice } from "../getShippingPrice";
import { getWholesaleOrder } from "../getWholesaleOrder";
import Cookies from "js-cookie";
import { notify } from "../../../../functions/notify";

type InitialState = {
  products: Product[];
  vatRate: number | null;
  vatPrice: number | null;
  shippingPrice: number | null;
  subTotalPrice: number | null;
  totalPrice: number | null;
  selectedCargoCompany: string | null;
  cargoCompanies: [];
  paymentFee: number | null;
  selectedPaymentMethodMobile?: string | null;
  selectedShipmentMobile?: {
    shipment_id: string | null;
    shipment_name: string | null;
  } | null;
};
const calculateSubTotal = (cart: Product[]) => {
  let subtotal = 0;
  cart.forEach((item) => {
    subtotal += item.price * item.quantity;
  });

  return subtotal;
};

const initialState: InitialState = {
  // @ts-ignore

  products: Cookies.get("sp-cart") ? JSON.parse(Cookies.get("sp-cart")) : [],
  vatRate: 0,
  vatPrice: 0,
  shippingPrice: 0,
  subTotalPrice: 0,
  totalPrice: 0,
  selectedCargoCompany: null,
  cargoCompanies: [],
  paymentFee: 0,
  selectedPaymentMethodMobile: null,
  selectedShipmentMobile: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      notify(
        `${action.payload.name.split(" ").slice(0, 3).join(" ")} added to cart`
      );
      let item = state.products.find((item) => item.id === action.payload.id);
      const quantity = action.payload?.quantity ? action.payload.quantity : 1;

      if (typeof item === "undefined") {
        state.products.push({ quantity, ...action.payload });
      } else {
        item.quantity += quantity;
      }
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
      state.subTotalPrice = calculateSubTotal(state.products);
      // @ts-ignore
      state.totalPrice =
        state.subTotalPrice +
        parseFloat(state.shippingPrice) +
        parseFloat(state.vatPrice) +
        state.paymentFee;
    },
    removeFromCart: (state, action) => {
      notify(
        `${action.payload.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} removed from cart`,
        "error"
      );
      let item = state.products.find((item) => item.id === action.payload.id);

      if (typeof item !== "undefined") {
        state.products = state.products.filter(
          (item) => item.id !== action.payload.id
        );
        state.subTotalPrice = calculateSubTotal(state.products);
        // @ts-ignore
        state.totalPrice =
          state.subTotalPrice +
          parseFloat(state.shippingPrice) +
          parseFloat(state.vatPrice) +
          state.paymentFee;

        if (state.products.length === 0) {
          state.subTotalPrice = 0;
          state.totalPrice = 0;
        }
      }
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
    },
    incrementQuantity: (state, action) => {
      let item = state.products.find((item) => item.id === action.payload.id);
      notify(
        `${action.payload.name.split(" ").slice(0, 3).join(" ")} added to cart`
      );
      if (typeof item === "undefined") {
        state.products.push({ quantity: 1, ...action.payload });
        item = state.products.find((item) => item.id === action.payload.id);
      }
      // @ts-ignore
      item.quantity++;
      state.subTotalPrice = calculateSubTotal(state.products);
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
    },
    decrementQuantity: (state, action) => {
      let item = state.products.find((item) => item.id === action.payload.id);

      if (typeof item !== "undefined") {
        if (item.quantity === 1) item.quantity = 1;
        else {
          notify(
            `${action.payload.name
              .split(" ")
              .slice(0, 3)
              .join(" ")} removed from cart`,
            "error"
          );
          item.quantity--;
        }
      }
      state.subTotalPrice = calculateSubTotal(state.products);
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
    },
    setQuantity: (state, action: any) => {
      let item = state.products.find(
        (item) => item.id === action.payload.product.id
      );
      if (typeof item === "undefined") {
        state.products.push({
          quantity: action.payload.quantity,
          ...action.payload.product,
        });
        item = state.products.find(
          (item) => item.id === action.payload.product.id
        );
      }
      // @ts-ignore
      item.quantity = action.payload.quantity;
      state.subTotalPrice = calculateSubTotal(state.products);
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
      notify(
        `${action.payload.quantity}X ${action.payload.product.name
          .split(" ")
          .slice(0, 3)
          .join(" ")} added to cart`
      );
    },
    emptyCart: (state) => {
      state.products = [];
      state.subTotalPrice = 0;
      state.totalPrice = 0;
      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
    },
    sortCartProducts: (state, action) => {
      const sortType = action.payload;
      if (sortType === "brand" || sortType === "color" || sortType === "size") {
        // @ts-ignore

        state.products = state.products.sort((a, b) =>
          a?.[sortType as keyof typeof a]?.name >
          // @ts-ignore
          b?.[sortType as keyof typeof b]?.name
            ? 1
            : b?.[sortType as keyof typeof b]?.name >
              a?.[sortType as keyof typeof a]?.name
            ? -1
            : 0
        );
      } else if (sortType === "id") {
        state.products = state.products.sort((a, b) =>
          a?.id > b?.id ? -1 : b?.id > a?.id ? 1 : 0
        );
      } else if (sortType === "total") {
        state.products = state.products.sort((a, b) =>
          a?.quantity * a?.price > b?.quantity * b?.price
            ? -1
            : b?.quantity * b?.price > a?.quantity * a?.price
            ? 1
            : 0
        );
      } else {
        state.products = state.products.sort((a, b) =>
          a?.[sortType as keyof typeof a] > b?.[sortType as keyof typeof b]
            ? 1
            : b?.[sortType as keyof typeof b] > a?.[sortType as keyof typeof a]
            ? -1
            : 0
        );
      }
    },
    setSelectedCargoCompany: (state, action) => {
      state.selectedCargoCompany = action.payload;
    },
    setCartInfo: (state) => {
      state.subTotalPrice = calculateSubTotal(state.products);
      state.totalPrice =
        (state.subTotalPrice ?? 0) +
        parseFloat(state.shippingPrice ?? 0) +
        parseFloat(state.vatPrice ?? 0) +
        (state.paymentFee ?? 0); // Burada düzeltme yapıldı
    },
    setVATprice: (state, action) => {
      state.vatRate = action.payload;
      console.log(state.vatRate);
      state.vatPrice = (state.subTotalPrice ?? 0) * (state.vatRate ?? 0);
      state.totalPrice =
        (state.subTotalPrice ?? 0) +
        parseFloat(state.shippingPrice ?? 0) +
        parseFloat(state.vatPrice ?? 0) +
        (state.paymentFee ?? 0); // Burada düzeltme yapıldı
    },
    setPaymentFee: (state, action) => {
      state.paymentFee = action.payload;
    },
    setSelectedPaymentMethodMobile: (state, action) => {
      state.selectedPaymentMethodMobile = action.payload;
    },
    setSelectedShipmentMobile: (state, action) => {
      state.selectedShipmentMobile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCargoCompanies.pending, (state, action) => {});
    builder.addCase(getCargoCompanies.fulfilled, (state, action) => {
      state.cargoCompanies = action.payload;
      // @ts-ignore
      state.totalPrice =
        state.subTotalPrice +
        parseFloat(state.shippingPrice) +
        parseFloat(state.vatPrice) +
        state.paymentFee;
    });
    builder.addCase(getCargoCompanies.rejected, (state, action) => {});

    builder.addCase(getShippingPrice.pending, (state, action) => {});
    builder.addCase(getShippingPrice.fulfilled, (state, action) => {
      state.shippingPrice = action.payload?.price ?? 0;
      state.selectedCargoCompany = action.meta.arg.shipping_method;
      // @ts-ignore
      state.totalPrice =
        state.subTotalPrice +
        parseFloat(state.shippingPrice) +
        parseFloat(state.vatPrice) +
        state.paymentFee;
    });
    builder.addCase(getShippingPrice.rejected, (state, action) => {});

    builder.addCase(getWholesaleOrder.pending, (state, action) => {});
    builder.addCase(getWholesaleOrder.fulfilled, (state, action) => {
      let wholesale_products: any = [];
      action.payload?.products.forEach((item: any) => {
        console.log(item);
        const requestedQuantity = parseFloat(
          action.meta.arg?.product_list?.find(
            (product: any) => product["SKU"] === item?.sku
          )?.["QUANTITY"]
        );
        console.log(requestedQuantity);
        if (item?.stock_quantity >= requestedQuantity) {
          wholesale_products.push({
            ...item,
            quantity: requestedQuantity,
          });
        } else {
          notify(
            `${item.name} only has ${item.stock_quantity} in stock, ${
              requestedQuantity - item.stock_quantity
            } could not be added.`,
            "error"
          );
          console.log(item.stock_quantity);
          if (item.stock_quantity > 0) {
            wholesale_products.push({
              ...item,
              quantity: item.stock_quantity,
            });
          }
        }
      });

      wholesale_products.forEach((product: any) => {
        console.log(product);
        let existingItem = state.products.find((p) => p.id === product.id);
        if (existingItem) {
          existingItem.quantity += product.quantity;
        } else {
          state.products.push(product);
        }
        notify(
          `${product.quantity} x ${product.name} added to cart.`,
          "success"
        );
      });

      Cookies.set("sp-cart", JSON.stringify(state.products), { expires: 7 });
      state.subTotalPrice = calculateSubTotal(state.products);
      state.totalPrice =
        state.subTotalPrice +
        parseFloat(state.shippingPrice) +
        parseFloat(state.vatPrice) +
        state.paymentFee;
    });
    builder.addCase(getWholesaleOrder.rejected, (state, action) => {});
  },
});

export const {
  setQuantity,
  emptyCart,
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  sortCartProducts,
  setCartInfo,
  setVATprice,
  setPaymentFee,
  setSelectedPaymentMethodMobile,
  setSelectedShipmentMobile,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
