import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import orderDataType from "./orderType";
import {store} from "../../../index";
import {emptyCart} from "../cart/slices/cartSlice";

export const createOrder = createAsyncThunk(
    "orders/createOrder",
    async (args: any,thunkAPI) => {
            console.log(args)   
        try {
            const response = await axios.post(
                `https://stockperfume.com/wp/wp-json/wp/v3/orders/create`,args
            );

            if (response.status == 200){
                store.dispatch(emptyCart())
            }
   
      
            return response.data;
        } catch (error:any) {
           
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });