import {baseAPI} from "./baseApiURLS";

export interface  IAPIProps {
    path : string,
    method : string,
    apiRoute : baseAPI
    jwtRequired ?: boolean
    authKeyRequired ?: boolean
}


export const defaultAPIProps : IAPIProps = {
    path : "",
    method : "get",
    apiRoute : baseAPI.customAPIRoute,
    jwtRequired : false,
    authKeyRequired : true
}

export type IAPIRoutes = { [k: string]: IAPIProps }

export type IAPI = { [k: string]: any }