import React from "react";
import QuestionAccordion from "./QuestionAccordion";

function FAQ() {
    return (
        <div id="faq" className="grid grid-cols-12 gap-7 bg-white px-2 lg:px-24 pb-10">
            <h1 className="col-span-full text-center py-10 font-semibold">Frequently Asked Questions</h1>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"How to create an account?"}
                                   answer={"First of all, thank you for your interest in Stock Perfume. \n" +
                                       "It is a great pleasure to see you among us! By creating an account at Stock Perfume, you can place an order on our site in a very short time and in a very practical way. All you have to do is click on the \"register\" button on our site and register with your email and the password you set. After your account is activated within 24 hours, you can place an order by adding the product you want to your cart. It’s that easy!\n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"Can I pickup my order?"}
                                   answer={"You can pick up your order with your contracted shipping company. If you would like to make a delivery this way, please contact us and let us know the details after placing your order.\n" +
                                       "If you choose the pickup option, you will not be charged any shipping fees."}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"How to order?"}
                                   answer={"After opening your account, the firs step is to add products that are in stock to your chart. If the product you are looking for is not in stock, you can be informed when the out-of-stock products are back in stock with the \"get notified\" option.\n" +
                                       "It is also possible to create your order by uploading the Excel file you created in CSV format. You can find the template by clicking the link, and then fill it with the necessary information.\n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"To which countries can I send my orders?"}
                                   answer={"We, Stock Perfume, use Fedex, UPS or DPD couriers to ship to almost every country in the EU and countries outside of the EU.\n" +
                                       "You can view the delivery options offered to you right in your cart by entering the delivery address during the checkout process.\n" +
                                       "It's possible that the shipping companies we've worked with don't deliver to the country you want to order from if there isn't a delivery option available. In such cases, you can contact us and give us the opportunity to offer the best option for you.\n"}/>
            </div>

            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"What are the shipping costs and the processing time?"}
                                   answer={"We do our best to ship the goods you have ordered as soon as possible. Depending on the purchased item, delivery timeframes vary, but we typically ship between 4–7 business days. The shipment is done by UPS, DPD, and FedEx. They deliver packages Monday through Friday between the hours of 08:00 and 18:00.\n" +
                                       "Shipping costs are calculated according to the quantity purchased and the delivery address; you can see them directly in the cart during the checkout process.\n" +
                                       "The delivery time is between 4 and 7 business days.\n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"What about delivery issues?"}
                                   answer={"It may happen that the customer is not present at the address when our driver comes along to deliver the package. In this case, the carrier will return the following day to try again, or your package will be placed at a relay point near the delivery address. "}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"What are the customs duties and export taxes?"}
                                   answer={"For shipments to a country outside the European Union, the customs service may apply a tax (known as customs duties). In this case, it is important to know that Stock Perfume is not responsible for the customs fees. If there will be any charge, it should be paid by the buyer."}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"Will I be informed about the process of my order? "}
                                   answer={"As soon as your order is completed and shipped, our team will send you all the details about the delivery of your order by email. This email will contain your shipment’s tracking number and photos of your products."}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"Lost package! What now?"}
                                   answer={"Please contact us immediately when you encounter this kind of problem. This can be done via the contact form, email, or phone.\n" +
                                       "By default, all packages are registered and insured by registered mail.\n" +
                                       "We start an investigation after receiving a report of loss or theft during the shipment. \n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"How to find an item?"}
                                   answer={"Stock Perfume offers more than 60.000 products in its inventory. If you are looking for a specific product, we invite you to use:\n" +
                                       "The search bar by typing:\n" +
                                       "The product name\n" +
                                       "EAN number\n" +
                                       "SKU number\n" +
                                       "You can click on the category of your choice to reduce the offers and get closer to your target. Filters are also available to make your search easier! We can also send you our offers in Excel format. All products are listed with complete information, including the brand name, item description, EAN, SKU, price, stock, and so on. \n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"Still not finding it?"}
                                   answer={"Please get in touch with us via email at info@stockperfume.com or on Live Chat, and we will be happy to try to help you find the item you are looking for.\n" +
                                       "\n" +
                                       "EU with a valid VAT number. Otherwise, your VAT amount will be reflected when placing your order.\n" +
                                       "If you have a question or problem regarding this topic, please contact us.\n"}/>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
                <QuestionAccordion question={"What are the payment options?"}
                                   answer={"After you place your order, you need to complete the payment process in order for your product to be shipped within a short time. Stock Perfume places a high value on the protection of personal data so that you can make purchases with complete confidence and peace of mind. \n" +
                                       "\n" +
                                       "Our payment options:\n" +
                                       "Credit card\n" +
                                       "IDEAL\n" +
                                       "SOFORT Banking\n" +
                                       "Giropay\n" +
                                       "American Express\n" +
                                       "Direct Debit\n" +
                                       "Bank transfer\n" +
                                       "Bancontact\n" +
                                       "Belfius\n" +
                                       "KBC\n" +
                                       "Bank card: Any transmission of information related to payment by bank card (card number, cryptogram, validity date) takes place in a secure and approved context on the internet, directly on the server of our banking partner Multisafepay (encryption process SSL).\n" +
                                       "All payment-related information is never communicated to Stock Perfume.\n" +
                                       "Please contact us by email at info@stockperfume.com or live chat if you have any questions.\n" +
                                       "Thank you for your trust!\n"}/>
            </div>

        </div>
    );
}

export default FAQ;
