export const customerReviews: Array<Object> = [
    {   customerName: 'Viorel Aenasoaiei',
        customerReview:'Hi everyone. I would like to share my experience here with StockPerfume. To be honest on beginning I went a bit skeptical, but a little by little after few dialogues and a phone call I lunched a order, on 16.01 and after few day\'s on 21.01 I\'ve received the parcel in Germany. So, I had a positive experience. I looking forward to prepare a new order as soon I have a good deal from them !',
        country: 'Germany'
    },
    {   customerName: 'Berlise kameni',
        customerReview:'very great quality service and agents are available fully to address your concerns',
        country: 'France'
    },
    {   customerName: 'Rimantas Vlasenka',
        customerReview:'This company is the best for all.',
        country: 'Lithuania'
    },
    {   customerName: 'Bettina Kotzur',
        customerReview:'The store is totally serious and did a great job.',
        country: 'Netherlands'
    },
]