import React from 'react';
import {motion} from "framer-motion";
import Banner from "../Home/Banner";

function PrivacyPolicy() {
    return (
        <motion.div initial={{opacity: 0}} exit={{opacity: 0}} animate={{opacity: 1}}>
            <div>
                <div
                    className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] lg:-mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
                    <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-2 col-end-13 self-center lg:mx-10">
                        <p className="lg:text-2xl lg:text-2xl text-black font-medium tracking-normal">Privacy
                            Policy
                        </p>
                    </div>
                </div>

                <div
                    className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 mt-3 lg:-mt-16">
                    <div className="basis-1/1 lg:px-44 px-10">
                        <p className="text-sm text-black mt-8 px-3">
                            This website (“website”) is operated by Stockperfume.com. This privacy policy only relates
                            to information collected on this website. We respect that you do not want the personal
                            information you provide to us to be randomly distributed and this policy explains how we
                            collect information about you, what we do with it, and what controls you have.
                            <br/>
                            <br/>
                            <h6 className="text-md font-medium mt-10">PERSONAL INFORMATION THAT WE COLLECT ABOUT
                                YOU </h6>
                            <br/>
                            <br/>

                            Your order (goods and services) can be delivered only if you issue us with your personal
                            information. To provide them, we gather personal information about our customers, such as
                            name, address, and e-mail address. We will use the information you gave for the purposes
                            described in this privacy policy that you agreed to when your information was obtained. Your
                            personal information will be used to deliver the information, goods, and services offered to
                            you through our website, for billing and order processing. We may use your e-mail address to
                            send you information about products or services that are similar to or similar to those you
                            have ordered from us and that we think you may find useful. We only do this if you have
                            indicated that you have no objection to being contacted for these purposes.
                            <br/>
                            <br/>
                            At any stage, you have the right to request us to use your personal information for direct
                            marketing purposes by using the unsubscribe function at the end of the marketing email. Our
                            website aims to protect your personal data during data migration and uses encryption
                            technology such as Secure Sockets Layer (SSL) for this purpose.
                            <br/>
                            <br/>
                            SSL encrypts order information such as your name, address and credit card number. Our
                            Customer Care Center and stores also operate via a private, secure network. Please note that
                            e-mail is not encrypted and is not considered a secure means of sending credit card
                            information.
                        </p>


                        <p className="text-sm text-black mt-8 ">
                            <h6 className="text-md font-medium mt-10">THE INFORMATION WE COLLECT </h6>
                            <br/>
                            <br/>
                            You can browse the StockPerfume website without providing any personally identifiable
                            information. However, we may ask you to provide personally identifiable information on this
                            website at different times and places. In some cases, if you choose not to provide us with
                            the requested information, you may not be able to access all parts of this website or
                            participate in all functions.
                            <br/>
                            <br/>
                            We may collect the information listed below
                            <br/>
                            <br/>
                            <ol className="list-disc text-sm mt-2 space-y-4 ml-3">
                                <li>
                                    Name

                                </li>
                                <li>
                                    Contact details including e-mail address

                                </li>
                                <li>
                                    Demographic information such as zip code, preferences, and interests
                                </li>
                                <li>
                                    Other information that is relevant to customer ordersFrom your purchases and other
                                    interactions with us, we obtain information about the specific products or services
                                    that you purchase or use. When you visit this website, our web server automatically
                                    collects anonymous information such as log data and IP addresses and may collect
                                    general information about your location. We may use the information collected
                                    automatically for a number of purposes, such as improving our site design, product
                                    ranges, customer service and special promotions.
                                </li>
                            </ol>
                        </p>
                        <p className="text-sm text-black mt-8 ">
                            <h6 className="text-md font-medium mt-10">SAFETY </h6>
                            <br/>
                            <br/>
                            We make every effort to ensure the security of your information. In order to prevent this
                            and to prevent unauthorized access and leaks, we protect the information we collect online
                            with reliable technologies.
                        </p>
                        <p className="text-sm text-black mt-8 ">
                            <h6 className="text-md font-medium mt-10">COOKIES, WEB BEACONS, AND HOW WE USE
                                THEM </h6>
                            <br/>
                            <br/>
                            A cookie is a small file that requests permission to be placed on your computer’s hard
                            drive. When you accept, a file is added to your computer browser and the cookie helps
                            analyze internet traffic or notifies you when you visit a particular site.
                            <br/>
                            <br/>
                            Cookies enable web applications to respond to you as individuals. The web application can
                            tailor the process to your needs, preferences, and dislikes by collecting and remembering
                            information about your preferences.
                            <br/>
                            <br/>
                            We use traffic log cookies to identify which pages are being used. This helps us analyze
                            data about web traffic and improve our website to adapt it to customer needs. We only use
                            this information for statistical analysis purposes and then the data is removed from the
                            system.
                            <br/>
                            <br/>
                            In general, cookies help us to offer you a better website, by enabling us to check which
                            pages you find useful and which you do not. A cookie in no way gives us access to your
                            computer or any other information about you, other than the data you want to share with us.
                            You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
                            but you can usually change your browser settings to refuse cookies if you wish. This may
                            prevent you from taking full advantage of the website.
                            <br/>
                            <br/>

                            A ‘web beacon’ or ‘pixel tag’ or ‘clear gif’ is usually an image of one pixel, which is used
                            to pass on information from your computer or mobile device to a website.
                            <br/>
                            <br/>

                            We use cookies and web beacons to keep track of what you have in your shopping cart and to
                            remember when you return to the website and to identify the pages that you click during your
                            visit to our site and the name of the website that you visit visited directly before
                            clicking on the website StockPerfume.
                            <br/>
                            <br/>

                            We use this information to improve our site design, product range, customer service and
                            special offers. You can of course disable cookies and web beacons on your computer by
                            indicating this in the preferences or option menus in your browser. However, it is possible
                            that some parts of our website will not work correctly if you disable cookies. We may also
                            use web beacons and other technologies to track whether our communications reach you, to
                            measure their effectiveness, or to collect certain non-personal information about your
                            computer, device, or browser to enable us to better design future communication with you.
                            <br/>
                            <br/>

                            We may enter into a contract with third parties who may use cookies and web beacons and may
                            collect information or provide services on our behalf such as credit card processing,
                            shipping, promotional services or data management. We call them our Customer Care Partners.
                            These third parties are prohibited by our contract with them from sharing this information
                            with anyone other than us.
                        </p>
                        <p className="text-sm text-black mt-8 ">
                            <h6 className="text-md font-medium mt-10">LIST OF COOKIES THAT WE COLLECT </h6>
                            The table below lists the cookies we collect and the information they store
                            <table className="mt-8 mb-8">
                                <thead>
                                <tr >
                                    <th><h6 className="text-lg font-medium text-start ">COOKIE NAME </h6></th>
                                    <th><h6 className="text-lg font-medium text-start ">COOKIE DESCRIPTION </h6></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CART</h6></th>
                                    <th><h6 className="text-sm font-normal  text-start">The association with your
                                        shopping cart. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CATEGORY_INFO </h6></th>
                                    <th><h6 className="text-sm font-normal text-start   ">Allows pages to be displayed
                                        faster. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">COMPARE </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The items that you have in the
                                        Compare Products list. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CUSTOMER </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">An encrypted version of your
                                        customer ID. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CUSTOMER_AUTH </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">An indicator if you are
                                        registered at the store. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CUSTOMER_INFO </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">An encrypted version of the
                                        customer group to which you belong. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">CUSTOMER_SEGMENT_IDS </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">Stores your customer segment
                                        ID. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">EXTERNAL_NO_CACHE </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">A flag that indicates whether
                                        caching is on or off. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">FRONTEND </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">Your session ID on the
                                        server. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">GUEST-VIEW </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">This allows guests to edit
                                        their orders. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">LAST_CATEGORY </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The last category you
                                        visited. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">LAST_PRODUCT </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The last product you looked
                                        at. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">NEWMESSAGE </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">Indicates whether a new message
                                        has been received. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">NO_CACHE </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">Indicates whether the cache can
                                        be used. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">PERSISTENT_SHOPPING_CART </h6>
                                    </th>
                                    <th><h6 className="text-sm font-normal text-start ">A link to information about
                                        your shopping cart and viewing history if you have requested the site. </h6>
                                    </th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">RECENTLYCOMPARED </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The items that you have
                                        recently compared. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">STF </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">Information about products that
                                        you have emailed to friends. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">STORE </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The store view or language you
                                        have selected. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">USER_ALLOWED_SAVE_COOKIE </h6>
                                    </th>
                                    <th><h6 className="text-sm font-normal text-start ">Indicates whether a customer
                                        has authorized cookies. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">VIEWED_PRODUCT_IDS </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The products you have recently
                                        looked at. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">WISHLIST </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">A coded list of products added
                                        to your wish list. </h6></th>
                                </tr>
                                <tr>
                                    <th><h6 className="text-sm text-start font-medium">WISHLIST_CNT </h6></th>
                                    <th><h6 className="text-sm font-normal text-start ">The number of items in your
                                        wish list. </h6></th>
                                </tr>


                                </tbody>

                            </table>
                        </p>


                    </div>
                </div>
            </div>

        </motion.div>
    );
}

export default PrivacyPolicy;