import React from "react";
import countries from "../../constants/countries";
import PhoneInput from "react-phone-number-input";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addUserInfo } from "../../app/features/user/userSlice";

interface FormInterface {
  [key: string]: string
}

function AddUserInfoModal({ showAddressModal, setShowAddressModal, type }: any) {
  const [isSaved, setIsSaved] = React.useState(false);
  const [number, setNumber] = React.useState<string | undefined>("");
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state?.userReducer?.user);
  function handleSubmit(e: any) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget)
    let formObj: FormInterface = {}
    
    for (let [key, value] of Array.from(formData.entries())) {

   if (key !== 'phoneCountry') {
    formObj[key] = value.toString()
   }
   console.log(formObj);
    }
    if (type === "shipping") {
      dispatch(addUserInfo({shipping : formObj}))
    }else {
      dispatch(addUserInfo({billing : formObj}))
    }
  
   

  }
  
  
  return (
    <div className={showAddressModal ? "" : "hidden"}>

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto overflow-y-scroll my-6 mx-auto max-w-3xl">
          {/*content*/}
          <form
            onSubmit={(e: any) => {
              handleSubmit(e);
            }}
            className=" rounded-lg shadow relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t md:pt-0 pt-96">
              <p className="text-xl font-semibold">Add New Address</p>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowAddressModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex">
              <div className=" flex flex-wrap  gap-4">
               
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">First Name </p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="first_name"
                    type="text"
                    defaultValue={user?.[type]?.first_name}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Last Name </p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="last_name"
                    type="text"
                    defaultValue={user?.[type]?.last_name}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Email </p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="email"
                    type="email"
                    defaultValue={user?.[type]?.email}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Company </p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="company"
                    type="text"
                    defaultValue={user?.[type]?.company}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Address 1 </p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="address_1"
                    type="text"
                    defaultValue={user?.[type]?.address_1}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Address 2 </p>
                  <input 
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="address_2"
                    type="text"
                    defaultValue={user?.[type]?.address_2}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">City</p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="city"
                    type="text"
                    defaultValue={user?.[type]?.city}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">State</p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="state"
                    type="text"
                    defaultValue={user?.[type]?.state}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Post Code</p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="postcode"
                    type="text"
                    defaultValue={user?.[type]?.postcode}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">House Number</p>
                  <input required
                    className="border rounded-20 px-4 h-8 lg:w-60"
                    name="house_number"
                    type="text"
                    defaultValue={user?.[type]?.house_number}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Country</p>
                  <select
                    className="border lg:w-60 rounded-20 px-4 h-8"
                    name="country"
                  >
                    {countries.map((country, index) => {
                      return (
                        <option key={index} value={country[1]}>
                          {country[0]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="flex flex-col gap-2 w-full md:w-auto sm:mx-8">
                  <p className="text-sm font-normal">Phone</p>
                  <PhoneInput
                    name="phone"
                    placeholder={"Enter phone number"}
                    international
                    value={number}
                    className="text-xs"
                    onChange={(e) => {
                      setNumber(e);
                    }}
                  />
                </div>

                <p
                  className={` text-center my-2 text-lg  ${
                    isSaved ? "" : "hidden"
                  }`}
                >
                  Address added!
                </p>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center gap-6 p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-white bg-brown font-medium rounded-20 px-6 py-3 uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowAddressModal(false)}
              >
                Close
              </button>
              <button
                className="bg-black text-white font-semibold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={() => {
                  setIsSaved(true);
                  setTimeout(() => {
                    setIsSaved(false);
                  }, 2000);
                }}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default AddUserInfoModal;
