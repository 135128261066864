import React from "react";
import Accordion, { Description, Title } from "../MyAccount/Accordion";
import priceFormatter from "../../functions/priceFormatter";

type Props = {
  className?: string;
  cart: any;
};

const CartInfo = ({ className, cart }: Props) => {
  let totalQuantity = 0;
  cart.products.forEach((product) => {
    totalQuantity += product.quantity;
  });

  return (
    <div
      className={`col-span-12 flex flex-col h-fit lg:col-span-3 ${className}`}
    >
      <div className={`flex rounded-20 bg-white shadow w-full h-fit`}>
        <Accordion
          buttonPosition={"hidden "}
          className="  rounded-20 bg-white w-full "
        >
          <Title
            className={
              "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
            }
          >
            <div className="flex  items-center">
              <svg
                width="15"
                height="20"
                viewBox="0 0 15 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.91367 0H2.44444C1.79614 0 1.17438 0.263392 0.715961 0.732233C0.257539 1.20107 0 1.83696 0 2.5V17.5C0 18.163 0.257539 18.7989 0.715961 19.2678C1.17438 19.7366 1.79614 20 2.44444 20H12.2222C12.8705 20 13.4923 19.7366 13.9507 19.2678C14.4091 18.7989 14.6667 18.163 14.6667 17.5V5.88375C14.6666 5.55226 14.5378 5.23437 14.3086 5L9.77778 0.36625C9.54862 0.131813 9.23779 7.07968e-05 8.91367 0ZM9.16667 4.375V1.875L12.8333 5.625H10.3889C10.0647 5.625 9.75386 5.4933 9.52465 5.25888C9.29544 5.02446 9.16667 4.70652 9.16667 4.375ZM3.05556 11.25C2.89348 11.25 2.73804 11.1842 2.62343 11.0669C2.50883 10.9497 2.44444 10.7908 2.44444 10.625C2.44444 10.4592 2.50883 10.3003 2.62343 10.1831C2.73804 10.0658 2.89348 10 3.05556 10H11.6111C11.7732 10 11.9286 10.0658 12.0432 10.1831C12.1578 10.3003 12.2222 10.4592 12.2222 10.625C12.2222 10.7908 12.1578 10.9497 12.0432 11.0669C11.9286 11.1842 11.7732 11.25 11.6111 11.25H3.05556ZM2.44444 13.125C2.44444 12.9592 2.50883 12.8003 2.62343 12.6831C2.73804 12.5658 2.89348 12.5 3.05556 12.5H11.6111C11.7732 12.5 11.9286 12.5658 12.0432 12.6831C12.1578 12.8003 12.2222 12.9592 12.2222 13.125C12.2222 13.2908 12.1578 13.4497 12.0432 13.5669C11.9286 13.6842 11.7732 13.75 11.6111 13.75H3.05556C2.89348 13.75 2.73804 13.6842 2.62343 13.5669C2.50883 13.4497 2.44444 13.2908 2.44444 13.125ZM3.05556 16.25C2.89348 16.25 2.73804 16.1842 2.62343 16.0669C2.50883 15.9497 2.44444 15.7908 2.44444 15.625C2.44444 15.4592 2.50883 15.3003 2.62343 15.1831C2.73804 15.0658 2.89348 15 3.05556 15H7.94444C8.10652 15 8.26196 15.0658 8.37656 15.1831C8.49117 15.3003 8.55556 15.4592 8.55556 15.625C8.55556 15.7908 8.49117 15.9497 8.37656 16.0669C8.26196 16.1842 8.10652 16.25 7.94444 16.25H3.05556Z"
                  fill="#202020"
                />
              </svg>

              <p className="ml-4 text-xs text-black font-medium">
                Cart Total
              </p>
              <p className="ml-auto text-base font-medium text-black"> </p>
            </div>
          </Title>
          <Description className={"px-6 py-7"}>
            <div className="flex items-center  text-xs">
              <p className="text-xs font-normal text-black">Total items</p>
              <p className="ml-auto text-xs font-normal text-black">
                {totalQuantity} pcs
              </p>
            </div>
            <div className="flex items-center  text-xs mt-4">
              <p className="text-xs font-normal text-black">Items subtotal: </p>
              <p className="ml-auto text-xs font-normal text-black">
                {priceFormatter(cart.subTotalPrice)}
              </p>
            </div>
            <div className="flex items-center  text-xs mt-4">
              <p className="text-base font-medium text-black">Cart total:</p>
              <p className="ml-auto text-base font-medium text-black">
                {priceFormatter(cart.subTotalPrice)}
              </p>
            </div>
          </Description>
        </Accordion>
      </div>
      <button
        onClick={() => (window.location.href = "/checkout")}
        className="bg-black hover:bg-brown text-white flex items-center mx-auto
      lg:px-8 px-6 py-3 lg:py-4 mt-4 rounded-full gap-14 w-fit"
      >
        <p>Go to checkout</p>
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.11337 10.887C1.07755 10.8513 1.04914 10.8089 1.02975 10.7621C1.01036 10.7154 1.00038 10.6653 1.00038 10.6147C1.00038 10.5642 1.01036 10.5141 1.02975 10.4673C1.04914 10.4206 1.07755 10.3782 1.11337 10.3425L5.4567 5.9999L1.11337 1.65733C1.04115 1.58512 1.00059 1.48718 1.00059 1.38506C1.00059 1.28293 1.04115 1.18499 1.11337 1.11278C1.18558 1.04057 1.28352 1 1.38564 1C1.48777 1 1.58571 1.04057 1.65792 1.11278L6.27276 5.72762C6.30857 5.76335 6.33699 5.80578 6.35638 5.85251C6.37576 5.89923 6.38574 5.94932 6.38574 5.9999C6.38574 6.05048 6.37576 6.10057 6.35638 6.14729C6.33699 6.19401 6.30857 6.23645 6.27276 6.27217L1.65792 10.887C1.62219 10.9228 1.57976 10.9512 1.53303 10.9706C1.48631 10.99 1.43623 11 1.38564 11C1.33506 11 1.28497 10.99 1.23825 10.9706C1.19153 10.9512 1.14909 10.9228 1.11337 10.887Z"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
        </svg>
      </button>
    </div>
  );
};

export default CartInfo;
