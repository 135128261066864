import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Accordion, { Description } from "../Accordion";
import { Title } from "../Accordion";
import OrderCard from "./OrderCard";
import LeftSideBar from "../../SideBars/LeftSideBar";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../../app/features/order/getOrders";

function Orders() {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userReducer.user);
  const orders = useSelector((state: any) => state.ordersReducer.orders);
  const filteredOrders = useSelector(
    (state: any) => state.ordersReducer.filteredOrders
  );
  const showFiltered = useSelector(
    (state: any) => state.ordersReducer.showFiltered
  );
  const [newOrder, setNewOrder] = useState("");
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [datedOrders, setOrders] = useState(orders);

  useEffect(() => {
    if (showFiltered) {
      setOrders(ordersToDate(filteredOrders));
    } else {
      setOrders(ordersToDate(orders));
    }
  }, [orders, showFiltered]);

  const ordersToDate = (orders: any) => {
    let dateWithOrder: any = {};
    orders.map((order: any) => {
      const current = new Date(order.date.date);
      const month = capitalizeFirstLetter(
        String(current.toLocaleDateString("nl-nl", { month: "long" }))
      );
      if (dateWithOrder[month] === undefined) {
        dateWithOrder[month] = [];
      }
      dateWithOrder[month].push(order);
    });
    return dateWithOrder;
  };

  useEffect(() => {
    if (user.id) {
      // @ts-ignore
      dispatch(getOrders(user.id));
    }
  }, [user.id]);

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get("success")) {
      setNewOrder("New order placed");
    } else if (new URLSearchParams(window.location.search).get("cancel")) {
      setNewOrder("Order cancelled");
    }
    setTimeout(() => {
      setNewOrder("");
    }, 5000);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="flex flex-col lg:mx-20 md:mt-20">
        <div className="col-span-full text-center py-8 font-normal text-lg">
          Order History
        </div>
        <div className="flex flex-row space-x-5">
          <LeftSideBar key={nanoid()} setOpen={setOpen} open={open} />
          <div className={`w-full flex flex-col space-y-5`}>
            {newOrder && (
              <div className="flex flex-row justify-center">
                <div
                  className="flex flex-col justify-center items-center bg-white px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{newOrder}</span>
                </div>
              </div>
            )}
            {orders.map((order: any) => {
              return <OrderCard key={nanoid()} order={order} />;
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Orders;
