import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion, { Title, Description } from "../../MyAccount/Accordion";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateCargoInfo } from "../../../app/features/user/actions/updateCargoInfo";
import { getCargoCompanies } from "../../../app/features/cart/getCargoCompanies";
import { getShippingPrice } from "../../../app/features/cart/getShippingPrice";
import AddUserInfoModal from "../AddUserInfoModal";
import EditVATModal from "../EditVATModal";

function Address() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userReducer.user);
  const [showModal, setShowModal] = React.useState(false);
  const [showAddressModal, setShowAddressModal] = React.useState(false);
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const [modalType, setModalType] = React.useState("");
  function handleAddressChange(e: any) {
    if (e?.target?.name == "billing") {
      dispatch(
        updateCargoInfo({ change: "billing", address_id: e?.target?.value })
      );
    } else {
      dispatch(
        updateCargoInfo({ change: "shipping", address_id: e?.target?.value })
      );
    }
  }
  function checkInputsFilled() {
    if (
      user?.shipping &&
      user?.billing &&
      user?.VATnumber?.number &&
      user?.VATnumber?.country
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className="flex flex-col space-y-5 mx-4">
      <AddUserInfoModal
        key={showAddressModal}
        type={modalType}
        showAddressModal={showAddressModal}
        setShowAddressModal={setShowAddressModal}
      />
      <EditVATModal
        loggedIn={loggedIn}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Accordion buttonPosition="right-5" className="bg-white rounded-20 pb-6">
        <Title className="bg-white  py-5 px-6 rounded-20  shadow w-full">
          <div className="flex items-center space-x-5">
            <svg
              width="15"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 20C7.5 20 15 12.8925 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 2.96403e-08 5.51088 0 7.5C0 12.8925 7.5 20 7.5 20ZM7.5 11.25C6.50544 11.25 5.55161 10.8549 4.84835 10.1517C4.14509 9.44839 3.75 8.49456 3.75 7.5C3.75 6.50544 4.14509 5.55161 4.84835 4.84835C5.55161 4.14509 6.50544 3.75 7.5 3.75C8.49456 3.75 9.44839 4.14509 10.1517 4.84835C10.8549 5.55161 11.25 6.50544 11.25 7.5C11.25 8.49456 10.8549 9.44839 10.1517 10.1517C9.44839 10.8549 8.49456 11.25 7.5 11.25Z"
                fill="black"
              />
            </svg>

            <p className="text-black  font-medium text-xs ">Billing Address</p>
          </div>
        </Title>
        <Description className="bg-white rounded-20 ">
          {user?.billing != "" && user?.billing ? (
            <div className="flex flex-col space-y-1 mt-6 mb-4 px-6 ">
              <p>{user?.billing?.first_name}</p>
              <p>{user?.billing?.address_1}</p>
              <p>{user?.billing?.postcode + " " + user?.billing?.city}</p>
            </div>
          ) : (
            <p className="whitespace-normal mx-6 mt-4 text-center">
              {" "}
              You are not logged in, if you want to continue without logging in,
              please add an address.
            </p>
          )}
          {loggedIn ? (
            <div className="flex flex-col relative px-2 w-40 mx-auto">
              <select
                name="billing"
                onChange={handleAddressChange}
                className="border text-xs   rounded-full flex items-center justify-center gap-6 space-x-4  pl-4 pr-8 py-1.5"
              >
                <option selected disabled className="text-xs ">
                  Change Address
                </option>
                {user?.savedAddresses.map((address: any) => {
                  return (
                    <option
                      value={address?.id}
                      className="text-xxs  lg:text-sm"
                    >
                      {address?.title}
                    </option>
                  );
                })}
              </select>
              <svg
                className="absolute right-6 top-3"
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.02051 1L3.7282 4L6.4359 1"
                  stroke="#202020"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setShowAddressModal(true);
                  setModalType("billing");
                }}
                className="mt-6 border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full  py-1 mx-20"
              >
                {user?.billing != "" && user?.billing ? "Edit" : "Add"} address
              </button>
            </div>
          )}
        </Description>
      </Accordion>
      <Accordion buttonPosition="right-5" className="bg-white rounded-20 pb-6">
        <Title className="bg-white  py-5 px-6 rounded-20  shadow w-full">
          <div className="flex items-center space-x-5">
            <svg
              width="15"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 20C7.5 20 15 12.8925 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 2.96403e-08 5.51088 0 7.5C0 12.8925 7.5 20 7.5 20ZM7.5 11.25C6.50544 11.25 5.55161 10.8549 4.84835 10.1517C4.14509 9.44839 3.75 8.49456 3.75 7.5C3.75 6.50544 4.14509 5.55161 4.84835 4.84835C5.55161 4.14509 6.50544 3.75 7.5 3.75C8.49456 3.75 9.44839 4.14509 10.1517 4.84835C10.8549 5.55161 11.25 6.50544 11.25 7.5C11.25 8.49456 10.8549 9.44839 10.1517 10.1517C9.44839 10.8549 8.49456 11.25 7.5 11.25Z"
                fill="black"
              />
            </svg>

            <p className="text-black  font-medium text-xs ">Shipping Address</p>
          </div>
        </Title>
        <Description className="bg-white rounded-20 ">
          {user?.shipping != "" && user?.shipping ? (
            <div className="flex flex-col space-y-1 mt-6 mb-4 px-6">
              <p>{user?.shipping?.first_name}</p>
              <p>{user?.shipping?.address_1}</p>
              <p>{user?.shipping?.postcode + " " + user?.shipping?.city}</p>
            </div>
          ) : (
            <p className="whitespace-normal mx-6 mt-4 text-center">
              {" "}
              You are not logged in, if you want to continue without logging in,
              please add an address.
            </p>
          )}
          {loggedIn ? (
            <div className="flex flex-col relative px-2 w-40 mx-auto">
              <select
                name="shipping"
                onChange={handleAddressChange}
                className="border text-xs   rounded-full flex items-center justify-center gap-6 space-x-4  pl-4 pr-8 py-1.5"
              >
                <option selected disabled className="text-xs ">
                  Change Address
                </option>
                {user?.savedAddresses.map((address: any) => {
                  return (
                    <option
                      value={address?.id}
                      className="text-xxs  lg:text-sm"
                    >
                      {address?.title}
                    </option>
                  );
                })}
              </select>
              <svg
                className="absolute right-6 top-3"
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.02051 1L3.7282 4L6.4359 1"
                  stroke="#202020"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setShowAddressModal(true);
                  setModalType("shipping");
                }}
                className="mt-6 border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full  py-1 mx-20"
              >
                {user?.shipping != "" && user?.shipping ? "Edit" : "Add"}{" "}
                address
              </button>
            </div>
          )}
        </Description>
      </Accordion>
      <Accordion buttonPosition="right-5" className="bg-white rounded-20 pb-6">
        <Title className="bg-white  py-5 px-6 rounded-20  shadow w-full">
          <div className="flex items-center space-x-5">
            <svg
              width="15"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 20C7.5 20 15 12.8925 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 2.96403e-08 5.51088 0 7.5C0 12.8925 7.5 20 7.5 20ZM7.5 11.25C6.50544 11.25 5.55161 10.8549 4.84835 10.1517C4.14509 9.44839 3.75 8.49456 3.75 7.5C3.75 6.50544 4.14509 5.55161 4.84835 4.84835C5.55161 4.14509 6.50544 3.75 7.5 3.75C8.49456 3.75 9.44839 4.14509 10.1517 4.84835C10.8549 5.55161 11.25 6.50544 11.25 7.5C11.25 8.49456 10.8549 9.44839 10.1517 10.1517C9.44839 10.8549 8.49456 11.25 7.5 11.25Z"
                fill="black"
              />
            </svg>

            <p className="text-black  font-medium text-xs ">VAT number</p>
          </div>
        </Title>
        <Description className="bg-white rounded-20 ">
          {user?.VATnumber?.number != "" && user?.VATnumber?.number ? (
            <div className="flex flex-col space-y-1 mt-6 mb-4 px-6 ">
              VAT number : {user?.VATnumber?.country + user?.VATnumber?.number}
            </div>
          ) : (
            <p className="whitespace-normal mx-6 mt-4 text-center">
              {" "}
              You are not logged in, if you want to continue without logging in,
              please add an VAT number.
            </p>
          )}

          <div className="flex justify-center">
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="mt-6 border  rounded-full flex items-center justify-center gap-6 space-x-4 w-full  py-1 mx-20"
            >
              {user?.billing != "" && user?.billing ? "Edit" : "Add"} VAT
              address
            </button>
          </div>
        </Description>
      </Accordion>

      {loggedIn ? (
        <>
          <Accordion
            buttonPosition="right-5"
            className="bg-white rounded-20 pb-6"
          >
            <Title className="bg-white  py-5 px-6 rounded-20  shadow w-full">
              <div className="flex items-center ">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1ZM6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6Z"
                    fill="#202020"
                  />
                </svg>

                <p className="text-black  font-medium text-xs ml-5">
                  Contact details
                </p>
              </div>
            </Title>
            <Description className="bg-white rounded-20 ">
              <div className="flex flex-col space-y-3 text-xs mx-6">
                {user?.billing != "" && user?.billing ? (
                  <div className="flex items-center justify-start  space-x-4">
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6 3.8H2.4C1.6268 3.8 1 4.4268 1 5.2V12.2C1 12.9732 1.6268 13.6 2.4 13.6H13.6C14.3732 13.6 15 12.9732 15 12.2V5.2C15 4.4268 14.3732 3.8 13.6 3.8Z"
                        fill="#202020"
                      />
                      <path
                        d="M10.8 13.6V2.4C10.8 2.0287 10.6525 1.6726 10.3899 1.41005C10.1274 1.1475 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.1475 5.61005 1.41005C5.3475 1.6726 5.2 2.0287 5.2 2.4V13.6"
                        fill="#202020"
                      />
                      <path
                        d="M10.8 13.6V2.4C10.8 2.0287 10.6525 1.6726 10.3899 1.41005C10.1274 1.1475 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.1475 5.61005 1.41005C5.3475 1.6726 5.2 2.0287 5.2 2.4V13.6M2.4 3.8H13.6C14.3732 3.8 15 4.4268 15 5.2V12.2C15 12.9732 14.3732 13.6 13.6 13.6H2.4C1.6268 13.6 1 12.9732 1 12.2V5.2C1 4.4268 1.6268 3.8 2.4 3.8Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>{user?.billing?.company}</p>
                  </div>
                ) : null}
                <div className="flex  items-center justify-start space-x-4">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1ZM6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6Z"
                      fill="#202020"
                    />
                  </svg>

                  <p>{user?.name}</p>
                </div>
                <div className="flex  items-center justify-start space-x-4">
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.04375 1.36063C0.131966 0.974088 0.348823 0.628961 0.658806 0.38177C0.96879 0.134578 1.35352 -2.66826e-05 1.75 3.96737e-09H12.25C12.6465 -2.66826e-05 13.0312 0.134578 13.3412 0.38177C13.6512 0.628961 13.868 0.974088 13.9563 1.36063L7 5.61225L0.04375 1.36063ZM0 2.35988V8.57588L5.07762 5.46263L0 2.35988ZM5.91588 5.97625L0.167125 9.49988C0.309161 9.79937 0.533336 10.0524 0.813559 10.2294C1.09378 10.4065 1.41853 10.5003 1.75 10.5H12.25C12.5814 10.5001 12.906 10.406 13.1861 10.2288C13.4662 10.0516 13.6902 9.79853 13.832 9.499L8.08325 5.97538L7 6.63775L5.91588 5.97538V5.97625ZM8.92237 5.4635L14 8.57588V2.35988L8.92237 5.46263V5.4635Z"
                      fill="#202020"
                    />
                  </svg>

                  <p>{user?.email}</p>
                </div>
                <div className="flex  items-center justify-start space-x-4">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.41406 0.382645C1.54529 0.251606 1.70286 0.149953 1.87634 0.0844241C2.04982 0.0188949 2.23525 -0.00901466 2.42033 0.00254503C2.60541 0.0141047 2.78593 0.0648697 2.94991 0.151476C3.11389 0.238082 3.25759 0.358551 3.3715 0.504901L4.71771 2.23448C4.96445 2.55174 5.05145 2.96501 4.95395 3.35503L4.54371 4.99761C4.5225 5.08268 4.52365 5.1718 4.54704 5.2563C4.57043 5.3408 4.61527 5.41782 4.67721 5.47988L6.5199 7.32271C6.58202 7.38478 6.65916 7.4297 6.74381 7.4531C6.82845 7.47649 6.9177 7.47757 7.00288 7.45622L8.64458 7.04595C8.83704 6.99783 9.03791 6.99409 9.23202 7.03502C9.42614 7.07595 9.6084 7.16048 9.76505 7.28221L11.4945 8.62777C12.1162 9.11155 12.1732 10.0303 11.6167 10.5861L10.8413 11.3616C10.2863 11.9167 9.45681 12.1604 8.68358 11.8882C6.70453 11.1918 4.90765 10.0587 3.42625 8.57302C1.94076 7.09171 0.807784 5.29496 0.11135 3.31603C-0.160142 2.54349 0.0836006 1.71321 0.638583 1.15818L1.41406 0.382645Z"
                      fill="#202020"
                    />
                  </svg>

                  <p>{user?.billing?.phone}</p>
                </div>
              </div>
            </Description>
          </Accordion>
        </>
      ) : (
        <></>
      )}

      <Link to={"/checkout/payment"} className="flex justify-center">
        <button
          disabled={!checkInputsFilled()}
          className="bg-black text-white rounded-100 px-9 py-2 w-44  text-xs"
        >
          Continue
        </button>
      </Link>
    </div>
  );
}

export default Address;
