import React from "react";
import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";

type Props = {
  onClick: () => void;
  active: boolean;
  type: string;
};

const SortBatch = ({ onClick, active, type }: Props) => {
  return (
    <button className={"gap-1 items-center flex-nowrap flex"} onClick={onClick}>
      {active ? (
        <BsSortAlphaUpAlt className="text-brown" />
      ) : (
        <BsSortAlphaDown className="text-black" />
      )}
      <span
        className={`text-sm font-semibold ${
          active ? " text-brown " : " text-black "
        }`}
      >
        {type} {type === "Price" && "(excl. VAT)"}
      </span>
    </button>
  );
};

export default SortBatch;
