import {motion} from "framer-motion";
import React, {useEffect} from "react";
import Banner from "./Banner";
import InfoCards from "./InfoCards";
import ProductSlider from "../Product Slider/ProductSlider";
import NavBar from "../NavBar/NavBar";
import DetailedInfoCards from "./DetailedInfoCards";
import Access from "./Access";
import FAQ from "../FAQ/FAQ";
import CustomerReviews from "./CustomerReviews";
import RightSideBar from "../SideBars/RightSideBar";
import {useLocation} from "react-router-dom";

function Home() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' } );
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    return (
        <motion.div
            initial={{opacity: 0}}
            exit={{opacity: 0}}
            animate={{opacity: 1}}
        >

            <Banner/>


            <InfoCards/>
            <ProductSlider/>
            <DetailedInfoCards/>
            <Access/>
            <FAQ/>
            <CustomerReviews/>
        </motion.div>
    );
}

export default Home;
