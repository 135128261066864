import React from "react";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";

function Wholesale() {
  const [number, setNumber] = React.useState<string | undefined>("");
  return (
    <div>
      {/* BANNER START */}
      <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] -mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
        <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-2 col-end-13 self-center hidden lg:flex lg:mx-10">
          <h1 className="lg:text-2xl text-2xl text-black font-medium tracking-normal">
            Wholesale
          </h1>
        </div>

        <div className="hidden lg:block col-start-9 col-end-13  self-center mt-16 ">
          <p className=" lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">
            High quality perfume and{" "}
          </p>
          <p className="lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">
            wide range of beauty products
          </p>
        </div>
      </div>
      {/* BANNER END */}

      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 -mt-3 lg:-mt-16 relative">
        <h1 className="text-xl absolute top-2 flex lg:hidden">Wholesale</h1>
        <div className="basis-1/1 lg:px-44 px-10">
          <p className="font-medium mt-16 lg:text-center lg:text-xl text-black">
            Wolesale - Large stock of beauty products
          </p>
          <p className="text-sm text-black mt-8 ">
            Stock Perfume is a high quality perfume wholesaler that offers a
            wide range of beauty products. We offer the ability to deal with the
            management of multi-products and multi-references. So you can find
            all the products you are looking for in one website.
            <br />
            <br />
            You no longer have to place orders on several websites!
            <br />
            <br />
            We aim and try to serve all countries with the reliable references
            of our clients with whom we work around the world, especially in
            Europe, and our growing network of clients. At Stock Perfume, our
            assurance is our reliable and prompt sourcing process.
          </p>
          <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">
            Shop wholesale products
          </p>
          <p className="text-sm text-black mt-8 ">
            Stock Perfume is a wholesale website for cosmetics and perfumes. We
            aim to provide the best prices and service to our customers with
            wholesale opportunities.
            <br />
            <br />
            Through wholesale, perfume vendors can supply and sell our original
            products at affordable prices with no disruption in supply.
          </p>

          <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">
            How can I know the price and stock information?
          </p>
          <p className="text-sm text-black mt-8 ">
            As a wholesaler, staying informed about prices and stock
            availability is crucial for your business. That's why our platform
            is tailored to meet your wholesale needs, offering seamless access
            to essential stock information. Here's how we simplify the wholesale
            experience:
            <br />
            <br />
            1. Efficient Product Search: Our intuitive search feature
            streamlines the process of finding wholesale products. Whether
            you're looking for specific items or browsing categories, our
            platform makes it easy to discover what you need.
            <br /> <br />
            2. Real-Time Stock Updates: We understand the importance of
            inventory management in wholesale operations. With our platform, you
            can access real-time stock information for every product, ensuring
            you stay informed about availability.
            <br /> <br />
            3. Downloadable Stocklist: For wholesale buyers managing large
            orders, our downloadable stocklist provides a comprehensive overview
            of available products. Easily download and reference the list to
            streamline your purchasing process.
            <br /> <br />
            4. Dedicated Support: Need assistance finding a particular product
            or navigating our platform? Our dedicated support team is here to
            help. Reach out to us for personalized assistance, and we'll ensure
            you have the information you need to make informed buying decisions.
            <br /> <br />
            Shop smarter and stay ahead of the competition with StockPerfume!
          </p>
          <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">
            Payment options
          </p>
          <p className="text-sm text-black mt-8 ">
            Once registered on the site you can begin the ordering process. We
            offer different payment methods as indicated below.
            <br />
            <br />
            For bank transfer you must follow the bank information visible on
            the proforma that you will receive.
          </p>
          <p className="text-sm mt-8">Our payment methods are :</p>
          <ol className="list-disc text-sm mt-2 space-y-4 ml-3">
            <li>D Creditcard</li>
            <li>iDEAL</li>
            <li>SOFORT Banking</li>
            <li>Giropay</li>
            <li>American Express</li>
            <li>Direct Debit</li>
            <li>Bank transfer</li>
            <li>Bancontact</li>
            <li>Belfius</li>
            <li>KBC</li>
            <li>Klarna</li>
          </ol>
          <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">
            Wholesale fragrances, cosmetics and health care
          </p>
          <p className="text-sm text-black mt-8 ">
            Wholesale perfume, wholesale cosmetics, wholesale fragrance are only
            some examples of the wide variety of products Stock Perfume has to
            offer. With so many options to choose from, there is something
            available for each business owner. Fragrance wholesale and cosmetic
            wholesale is an important industry nowadays, even for other sectors
            such as fashion and sports.
            <br />
            <br />
            The wholesale cosmetic and wholesale fragrance is important to both
            big department stores as boutiques, where a variety of these
            products can be found but also expected to be found. E-commerce has
            made it easier for businesses to branch out to other sectors and
            since perfumes make great gifts, the addition of fragrances to one’s
            stock is inevitable.
            <br />
            <br />
            Business owners therefore actively search for suppliers of fragrance
            wholesale and cosmetic wholesale. Business is booming even in an era
            where most of us work from home. Stock Perfume can provide in the
            needs for not only perfumes but also in wholesale cosmetics. The
            best wholesale perfume is included in the stock and made available
            for individual buyers but also for businesses. A fragrance wholesale
            is not an exception to this.
          </p>
          <p className="font-medium mt-8 lg:text-start lg:text-lg text-black">
            Subscribe and start to order!
          </p>
          <div className="py-8">
            <Link to="/register">
              <button className="bg-black  inline-block relative hover:bg-brown items-start  py-3.5  px-7 md:py-2 md:px-12 lg:py-4 rounded-100 lg:px-10  text-white ">
                <p className="lg:text-sm mr-4  text-xs">Register now </p>
                <svg
                  className="absolute lg:top-5 top-4 md:top-3.5 right-4 md:right-6 lg:right-6"
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.11239 10.887C1.07658 10.8513 1.04816 10.8089 1.02877 10.7621C1.00939 10.7154 0.999408 10.6653 0.999408 10.6147C0.999408 10.5642 1.00939 10.5141 1.02877 10.4673C1.04816 10.4206 1.07658 10.3782 1.11239 10.3425L5.45573 5.9999L1.11239 1.65733C1.04018 1.58512 0.99961 1.48718 0.99961 1.38506C0.99961 1.28293 1.04018 1.18499 1.11239 1.11278C1.1846 1.04057 1.28254 1 1.38467 1C1.48679 1 1.58473 1.04057 1.65694 1.11278L6.27178 5.72762C6.3076 5.76335 6.33601 5.80578 6.3554 5.85251C6.37479 5.89923 6.38477 5.94932 6.38477 5.9999C6.38477 6.05048 6.37479 6.10057 6.3554 6.14729C6.33601 6.19401 6.3076 6.23645 6.27178 6.27217L1.65694 10.887C1.62122 10.9228 1.57878 10.9512 1.53206 10.9706C1.48534 10.99 1.43525 11 1.38467 11C1.33408 11 1.28399 10.99 1.23727 10.9706C1.19055 10.9512 1.14811 10.9228 1.11239 10.887Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* FORM START*/}
      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto mt-3">
        <div className="flex-1">
          <div className="grid grid-cols-12 rounded-20  md:gap-x-8 md:gap-y-4 bg-white shadow md:px-20 py-8  ">
            <div className="col-span-12 text-center">
              <h6 className="text-sm text-black">
                For further questions, including partnership opportunities,
                <br />
                please email us or contact using our contact form.
              </h6>
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Name*</p>
              <input
                placeholder="Name"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="text"
              />
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Surname*</p>
              <input
                placeholder="Surname"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="text"
              />
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Company</p>
              <input
                placeholder="Company"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="text"
              />
            </div>

            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Phone*</p>
              <PhoneInput
                international
                value={number}
                className="text-xs"
                onChange={(e) => {
                  setNumber(e);
                }}
              />
            </div>

            <div className=" col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Message*</p>
              <input
                placeholder="Text"
                className="border rounded-20 w-full pb-44 pt-4 text-xs  placeholder:text-black  px-5"
                type="text"
              />
            </div>

            <div className="col-span-12 mt-6 lg:mt-5 grid justify-items-center">
              <button className="bg-black hover:bg-brown text-white px-32 py-2 text-xs font-normal rounded-full">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*FORM END*/}
    </div>
  );
}

export default Wholesale;
