import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getWholesaleOrder = createAsyncThunk(
  "cart/getWholesaleOrder",
  async (args: any, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://www.stockperfume.com/wp/wp-json/wp/v3/products/`,
        {
          params: {
            sku_list: args.sku_list,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
