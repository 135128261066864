import React from "react";
import { motion } from "framer-motion";
import CartList from "../Checkout/CartList";
import { useAppSelector } from "../../app/hooks";
import CartInfo from "./CartInfo";

function Cart() {
  const cart = useAppSelector((state) => state.cartReducer);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {/* Desktop */}
      <div className="hidden md:block">
        <p className="text-lg leading-6 mt-32 mb-8 text-center font-semibold">Cart</p>
        <div className="grid grid-cols-12 lg:mx-20 mx-10 gap-4  ">
          <CartList />
          <CartInfo cart={cart} />
        </div>
      </div>
    </motion.div>
  );
}

export default Cart;
