import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import NotActiveRightNow from "../Common/NotActiveRightNow";
import { useSelector } from "react-redux";
import { parse } from "papaparse";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getProducts } from "../../app/features/product/actions/getProducts";
import { setOffset } from "../../app/features/product/actions/setOffset";
import filterGenerator from "../../app/features/filter/actions/filterGenerator";
import "../../index.css";
import PhoneInput from "react-phone-number-input";
import { getWholesaleOrder } from "../../app/features/cart/getWholesaleOrder";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { sendEmail } from "../../app/features/contact/sendEmail";
import LoadingSpinner from "../Common/LoadingSpinner";
import { notify } from "../../functions/notify";

function CsvOrderUpload() {
  const [number, setNumber] = React.useState<string | undefined>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const products = useSelector((state: any) => state.productsReducer.products);
  const cart = useSelector((state: any) => state.cartReducer.products);
  const selectedFilters = useSelector(
    (state: any) => state.filterReducer.selectedFilter
  );

  const token = useSelector((state: any) => state.userReducer.token);

  const fileTypes = ["CSV", "XLS", "XLSX"];
  const [file, setFile] = useState(null);
  const handleChange = (file: React.SetStateAction<null>) => {
    setFile(file);
    // @ts-ignore
    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        console.log(results?.data);
        const sku_list = results.data.map((item: any) => {
          console.log(item["SKU"]);
          return item["SKU"];
        });
        console.log(sku_list);
        dispatch(
          getWholesaleOrder({
            sku_list,
            product_list: results.data,
          })
        );
        navigate("/checkout");
      },
    });
  };

  const [fileTypeError, setFileTypeError] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!(products.length > 0) && !Cookies.get("sp-isSaved")) {
      dispatch(
        getProducts(
          "https://www.stockperfume.com/wp/wp-json/wp/v3/products/?per_page=20"
        )
      );
    }
  }, []);

  useEffect(() => {
    if (page > 1)
      dispatch(setOffset({ url: filterGenerator(selectedFilters), page }));
  }, [page]);

  useEffect(() => {
    dispatch(getProducts(filterGenerator(selectedFilters)));
    setPage(1);
  }, [selectedFilters]);

  const [open, setOpen] = React.useState(true);
  const loading = useAppSelector((state) => state.contactReducer.loading);
  const [formErrors, setFormErrors] = React.useState({});
  const [formValues, setFormValues] = React.useState({
    name: false,
    phone: false,
    address: false,
    mail: false,
    company: false,
  });
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors: any = {};
    if (!formValues.name) {
      errors.name = "Name is required";
    }
    if (!formValues.phone) {
      errors.email = "Phone is required";
    }
    if (!formValues.address) {
      errors.message = "Message is required";
    }
    if (!formValues.mail) {
      errors.mail = "Mail is required";
    }
    if (!formValues.company) {
      errors.company = "Company is required";
    }

    return errors;
  };
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      notify("Email sended successfully");
      dispatch(sendEmail(formValues));
    }
  };
  return (
    <div>
      {/* BANNER START */}
      <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] lg:-mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
        <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-2 col-end-13 self-center lg:mx-10">
          <p className="lg:text-2xl lg:text-2xl text-black font-medium tracking-normal">
            CSV order upload
          </p>
        </div>

        <div className="hidden lg:block col-start-9 col-end-13  self-center mt-8 ">
          <p className=" lg:text-xl text-sm black font-thin text-end tracking-[0.015em]">
            The easiest way to place your order
          </p>
        </div>
      </div>
      {/* BANNER END */}

      {/*TRANSACTION BLOCK START */}
      <div className="flex flex-row lg:w-9/12 w-11/12  checkout-block mx-auto checkout-block lg:pt-7 lg:pb-7 pt-4 pb-4 bg-white rounded-20 lg:-mt-16">
        <div className="basis-1/4 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-20 lg:h-20 h-14 w-14 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-9 lg:h-9 w-6 h-6"
                viewBox="0 0 27 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.625 2.8125H3.75C2.75544 2.8125 1.80161 3.20759 1.09835 3.91085C0.395088 4.61411 0 5.56794 0 6.5625V26.25C0 27.2446 0.395088 28.1984 1.09835 28.9016C1.80161 29.6049 2.75544 30 3.75 30H22.5C23.4946 30 24.4484 29.6049 25.1516 28.9016C25.8549 28.1984 26.25 27.2446 26.25 26.25V6.5625C26.25 5.56794 25.8549 4.61411 25.1516 3.91085C24.4484 3.20759 23.4946 2.8125 22.5 2.8125H20.625V4.6875H22.5C22.9973 4.6875 23.4742 4.88504 23.8258 5.23667C24.1775 5.58831 24.375 6.06522 24.375 6.5625V26.25C24.375 26.7473 24.1775 27.2242 23.8258 27.5758C23.4742 27.9275 22.9973 28.125 22.5 28.125H3.75C3.25272 28.125 2.77581 27.9275 2.42417 27.5758C2.07254 27.2242 1.875 26.7473 1.875 26.25V6.5625C1.875 6.06522 2.07254 5.58831 2.42417 5.23667C2.77581 4.88504 3.25272 4.6875 3.75 4.6875H5.625V2.8125Z"
                  fill="white"
                />
                <path
                  d="M15.9375 1.875C16.1861 1.875 16.4246 1.97377 16.6004 2.14959C16.7762 2.3254 16.875 2.56386 16.875 2.8125V4.6875C16.875 4.93614 16.7762 5.1746 16.6004 5.35041C16.4246 5.52623 16.1861 5.625 15.9375 5.625H10.3125C10.0639 5.625 9.8254 5.52623 9.64959 5.35041C9.47377 5.1746 9.375 4.93614 9.375 4.6875V2.8125C9.375 2.56386 9.47377 2.3254 9.64959 2.14959C9.8254 1.97377 10.0639 1.875 10.3125 1.875H15.9375ZM10.3125 0C9.56658 0 8.85121 0.296316 8.32376 0.823762C7.79632 1.35121 7.5 2.06658 7.5 2.8125V4.6875C7.5 5.43342 7.79632 6.14879 8.32376 6.67624C8.85121 7.20368 9.56658 7.5 10.3125 7.5H15.9375C16.6834 7.5 17.3988 7.20368 17.9262 6.67624C18.4537 6.14879 18.75 5.43342 18.75 4.6875V2.8125C18.75 2.06658 18.4537 1.35121 17.9262 0.823762C17.3988 0.296316 16.6834 0 15.9375 0H10.3125Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-lg text-sm font-medium text-black pt-3">
            Download
          </p>
          <p className="lg:text-sm text-xs font-thin text-black">template</p>
        </div>
        <div className="basis-1/4 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-20 lg:h-20 h-14 w-14 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-9 lg:h-9 w-6 h-6"
                viewBox="0 0 27 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.4763 13.3988C18.5636 13.4858 18.6328 13.5893 18.6801 13.7032C18.7273 13.8171 18.7517 13.9392 18.7517 14.0625C18.7517 14.1858 18.7273 14.3079 18.6801 14.4218C18.6328 14.5357 18.5636 14.6392 18.4763 14.7263L12.8513 20.3513C12.7642 20.4386 12.6607 20.5078 12.5468 20.5551C12.4329 20.6023 12.3108 20.6267 12.1875 20.6267C12.0642 20.6267 11.9421 20.6023 11.8282 20.5551C11.7143 20.5078 11.6108 20.4386 11.5238 20.3513L8.71125 17.5388C8.62409 17.4516 8.55494 17.3481 8.50777 17.2342C8.4606 17.1203 8.43632 16.9983 8.43632 16.875C8.43632 16.7517 8.4606 16.6297 8.50777 16.5158C8.55494 16.4019 8.62409 16.2984 8.71125 16.2113C8.88729 16.0352 9.12605 15.9363 9.375 15.9363C9.49827 15.9363 9.62033 15.9606 9.73422 16.0078C9.84811 16.0549 9.95159 16.1241 10.0388 16.2113L12.1875 18.3619L17.1488 13.3988C17.2358 13.3114 17.3393 13.2422 17.4532 13.1949C17.5671 13.1477 17.6892 13.1233 17.8125 13.1233C17.9358 13.1233 18.0579 13.1477 18.1718 13.1949C18.2857 13.2422 18.3892 13.3114 18.4763 13.3988Z"
                  fill="white"
                />
                <path
                  d="M5.625 2.8125H3.75C2.75544 2.8125 1.80161 3.20759 1.09835 3.91085C0.395088 4.61411 0 5.56794 0 6.5625V26.25C0 27.2446 0.395088 28.1984 1.09835 28.9016C1.80161 29.6049 2.75544 30 3.75 30H22.5C23.4946 30 24.4484 29.6049 25.1516 28.9016C25.8549 28.1984 26.25 27.2446 26.25 26.25V6.5625C26.25 5.56794 25.8549 4.61411 25.1516 3.91085C24.4484 3.20759 23.4946 2.8125 22.5 2.8125H20.625V4.6875H22.5C22.9973 4.6875 23.4742 4.88504 23.8258 5.23667C24.1775 5.58831 24.375 6.06522 24.375 6.5625V26.25C24.375 26.7473 24.1775 27.2242 23.8258 27.5758C23.4742 27.9275 22.9973 28.125 22.5 28.125H3.75C3.25272 28.125 2.77581 27.9275 2.42417 27.5758C2.07254 27.2242 1.875 26.7473 1.875 26.25V6.5625C1.875 6.06522 2.07254 5.58831 2.42417 5.23667C2.77581 4.88504 3.25272 4.6875 3.75 4.6875H5.625V2.8125Z"
                  fill="white"
                />
                <path
                  d="M15.9375 1.875C16.1861 1.875 16.4246 1.97377 16.6004 2.14959C16.7762 2.3254 16.875 2.56386 16.875 2.8125V4.6875C16.875 4.93614 16.7762 5.1746 16.6004 5.35041C16.4246 5.52623 16.1861 5.625 15.9375 5.625H10.3125C10.0639 5.625 9.8254 5.52623 9.64959 5.35041C9.47377 5.1746 9.375 4.93614 9.375 4.6875V2.8125C9.375 2.56386 9.47377 2.3254 9.64959 2.14959C9.8254 1.97377 10.0639 1.875 10.3125 1.875H15.9375ZM10.3125 0C9.56658 0 8.85121 0.296316 8.32376 0.823762C7.79632 1.35121 7.5 2.06658 7.5 2.8125V4.6875C7.5 5.43342 7.79632 6.14879 8.32376 6.67624C8.85121 7.20368 9.56658 7.5 10.3125 7.5H15.9375C16.6834 7.5 17.3988 7.20368 17.9262 6.67624C18.4537 6.14879 18.75 5.43342 18.75 4.6875V2.8125C18.75 2.06658 18.4537 1.35121 17.9262 0.823762C17.3988 0.296316 16.6834 0 15.9375 0L10.3125 0Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-lg text-sm font-medium text-black pt-3">
            Upload
          </p>
          <p className="text-xs lg:text-sm font-thin text-black">
            your .csv file
          </p>
        </div>
        <div className="basis-1/4 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-20 lg:h-20 h-14 w-14 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-9 lg:h-9 w-6 h-6"
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1H6.45455L10.1091 19.2591C10.2338 19.8869 10.5753 20.4509 11.0739 20.8522C11.5725 21.2536 12.1964 21.4668 12.8364 21.4545H26.0909C26.7309 21.4668 27.3548 21.2536 27.8533 20.8522C28.3519 20.4509 28.6935 19.8869 28.8182 19.2591L31 7.81818H7.81818M13.2727 28.2727C13.2727 29.0258 12.6622 29.6364 11.9091 29.6364C11.156 29.6364 10.5455 29.0258 10.5455 28.2727C10.5455 27.5196 11.156 26.9091 11.9091 26.9091C12.6622 26.9091 13.2727 27.5196 13.2727 28.2727ZM28.2727 28.2727C28.2727 29.0258 27.6622 29.6364 26.9091 29.6364C26.156 29.6364 25.5455 29.0258 25.5455 28.2727C25.5455 27.5196 26.156 26.9091 26.9091 26.9091C27.6622 26.9091 28.2727 27.5196 28.2727 28.2727Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-lg text-sm font-medium text-black pt-3">
            Finalize
          </p>
          <p className="lg:text-sm text-xs font-thin text-black">
            the checkout
          </p>
        </div>
        <div className="basis-1/4 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-20 lg:h-20 h-14 w-14 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-9 lg:h-9 w-6 h-6"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 27.1875C11.1351 27.1875 7.90795 25.8508 5.52858 23.4714C3.14921 21.092 1.8125 17.8649 1.8125 14.5C1.8125 11.1351 3.14921 7.90795 5.52858 5.52858C7.90795 3.14921 11.1351 1.8125 14.5 1.8125C17.8649 1.8125 21.092 3.14921 23.4714 5.52858C25.8508 7.90795 27.1875 11.1351 27.1875 14.5C27.1875 17.8649 25.8508 21.092 23.4714 23.4714C21.092 25.8508 17.8649 27.1875 14.5 27.1875ZM14.5 29C18.3456 29 22.0338 27.4723 24.753 24.753C27.4723 22.0338 29 18.3456 29 14.5C29 10.6544 27.4723 6.96623 24.753 4.24695C22.0338 1.52767 18.3456 0 14.5 0C10.6544 0 6.96623 1.52767 4.24695 4.24695C1.52767 6.96623 0 10.6544 0 14.5C0 18.3456 1.52767 22.0338 4.24695 24.753C6.96623 27.4723 10.6544 29 14.5 29Z"
                  fill="white"
                />
                <path
                  d="M19.8831 9.00812C19.8702 9.02064 19.8581 9.03396 19.8469 9.048L13.5521 17.0683L9.7585 13.2729C9.50081 13.0328 9.15997 12.9021 8.8078 12.9083C8.45563 12.9145 8.11962 13.0572 7.87056 13.3062C7.6215 13.5553 7.47883 13.8913 7.47262 14.2435C7.46641 14.5957 7.59713 14.9365 7.83725 15.1942L12.6331 19.9919C12.7623 20.1208 12.9162 20.2225 13.0855 20.2907C13.2548 20.3589 13.4361 20.3923 13.6187 20.3889C13.8012 20.3855 13.9811 20.3454 14.1478 20.271C14.3145 20.1965 14.4645 20.0893 14.5888 19.9556L21.8243 10.9113C22.0707 10.6527 22.2054 10.3075 22.1993 9.95039C22.1932 9.59327 22.0468 9.2529 21.7918 9.00286C21.5367 8.75282 21.1935 8.6132 20.8364 8.61418C20.4792 8.61517 20.1368 8.75668 19.8831 9.00812Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-lg text-sm font-medium text-black pt-3">
            Order
          </p>
          <p className="text-xs lg:text-sm font-thin text-black">is placed!</p>
        </div>
      </div>
      {/*TRANSACTION BLOCK END */}

      {/*CSV ORDER UPLOAD INFO START*/}
      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 mt-3">
        <div className="basis-1/1 lg:px-44 px-10">
          <p className="font-medium mt-16 lg:text-center lg:text-xl text-black">
            Make your purchase process easier!
          </p>
          <br />

          <p className="text-sm text-black mt-8 ">
            This is the best way to order when you prepare your order based on
            the pricelist. You can always download our Stock offer and select
            your products.
          </p>
          <h6 className="font-medium mt-8 lg:text-start lg:text-xl text-black">
            How does it works?
          </h6>
          <ol id="number-list" className="mt-8 space-y-4  ">
            <li className=" text-sm ">
              Download the sample and file the document as shown in the example
              (.csv format need to be conserved).
            </li>
            <li className="text-sm ">
              Upload the completed document in the section below.
            </li>
            <li className="text-sm ">
              The products available in the file are added to the cart. You just
              have to go to the "checkout" page to finalize your order.
            </li>
          </ol>
          <p className=" text-sm font-medium mt-10">
            Use the section below to upload your order
          </p>

          {/* CSV ORDER UPLOAD START */}
          <div>
            <FileUploader
              children={
                <div
                  className={
                    "flex flex-col text-xs space-y-4 place-items-center my-10 py-10 group hover:bg-gray px-3 rounded-3xl  " +
                    (open ? " border-dashed border-black border" : " -mt-10 ")
                  }
                >
                  <svg
                    className={
                      "group-hover:stroke-gray-900 group-hover:fill-[#F1F1F1] " +
                      (!open ? " order-2 " : null)
                    }
                    width="42"
                    height="43"
                    viewBox="0 0 42 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.42">
                      <path
                        d="M42 21.0492C42 32.6472 32.598 42.0492 21 42.0492C9.40202 42.0492 0 32.6472 0 21.0492C0 9.45121 9.40202 0.0491943 21 0.0491943C32.598 0.0491943 42 9.45121 42 21.0492Z"
                        fill="white"
                      />
                      <path
                        d="M41.5 21.0492C41.5 32.371 32.3218 41.5492 21 41.5492C9.67816 41.5492 0.5 32.371 0.5 21.0492C0.5 9.72736 9.67816 0.549194 21 0.549194C32.3218 0.549194 41.5 9.72736 41.5 21.0492Z"
                        stroke="#202020"
                        strokeOpacity="0.5"
                      />
                    </g>
                    <path
                      d="M21 13.4918V28.6148M15 21.0533H27"
                      stroke="#202020"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span
                    className={
                      "text-center " + (!open ? " order-1 pb-4 " : null)
                    }
                  >
                    Upload CSV order here
                  </span>
                  <span
                    className={
                      "text-xs text-center " + (!open ? " order-3 " : null)
                    }
                  >
                    or change file
                  </span>
                  {fileTypeError && (
                    <NotActiveRightNow
                      text={"You can only upload CSV files."}
                    />
                  )}
                </div>
              }
              multiple={false}
              handleChange={handleChange}
              name="file"
              onTypeError={() => {
                setFileTypeError(true);
                setTimeout(() => {
                  setFileTypeError(false);
                }, 3000);
              }}
              types={fileTypes}
            />
          </div>
          {/* DOWNLOAD BUTTON START */}
          <ul>
            <li>
              <div className="flex flex-row space-x-6 pb-2 lg:py-8">
                <div>
                  <a href="https://stockperfume.com/wp/wp-content/uploads/2024/05/quickorder-csv.csv">
                    <button className="bg-brown  inline-block relative hover:bg-brown items-start  py-1.5 px-5 md:py-2 lg:py-3.5 rounded-100 lg:px-8  text-white ">
                      <p className="lg:text-sm ml-4  text-xs">
                        Download template{" "}
                      </p>
                      <svg
                        className="absolute lg:top-4 top-3.5 md:top-1.5 left-2  lg:left-6"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 10.0855L11.2924 8.79253L11.2924 8.79245C11.4801 8.60479 11.7346 8.49937 12 8.49937C12.2654 8.49937 12.5199 8.60479 12.7076 8.79245C12.8952 8.9801 13.0006 9.23462 13.0006 9.5C13.0006 9.76538 12.8952 10.0199 12.7076 10.2076L9.708 13.2071L10 10.0855ZM10 10.0855V5.5C10 5.23478 9.89464 4.98043 9.70711 4.79289C9.51957 4.60536 9.26522 4.5 9 4.5C8.73478 4.5 8.48043 4.60536 8.29289 4.79289C8.10536 4.98043 8 5.23478 8 5.5V10.0855L6.70764 8.79253L6.70755 8.79245C6.5199 8.60479 6.26539 8.49937 6 8.49937C5.73462 8.49937 5.4801 8.60479 5.29245 8.79245C5.10479 8.9801 4.99937 9.23462 4.99937 9.5C4.99937 9.76538 5.10479 10.0199 5.29245 10.2076L8.292 13.2071C8.29207 13.2072 8.29214 13.2073 8.29221 13.2073C8.38506 13.3003 8.49534 13.3742 8.61673 13.4245C8.73822 13.4749 8.86847 13.5009 9 13.5009C9.13153 13.5009 9.26178 13.4749 9.38327 13.4245C9.50456 13.3742 9.61475 13.3005 9.70755 13.2076L10 10.0855ZM4.40381 13.5962C3.18482 12.3772 2.5 10.7239 2.5 9C2.5 7.27609 3.18482 5.62279 4.40381 4.40381C5.62279 3.18482 7.27609 2.5 9 2.5C10.7239 2.5 12.3772 3.18482 13.5962 4.40381C14.8152 5.62279 15.5 7.27609 15.5 9C15.5 10.7239 14.8152 12.3772 13.5962 13.5962C12.3772 14.8152 10.7239 15.5 9 15.5C7.27609 15.5 5.62279 14.8152 4.40381 13.5962ZM15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 6.74566 16.6045 4.58365 15.0104 2.98959C13.4163 1.39553 11.2543 0.5 9 0.5C6.74566 0.5 4.58365 1.39553 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C4.58365 16.6045 6.74566 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104Z"
                          fill="white"
                          stroke="0.5"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
                <div>
                  <button className="bg-black  inline-block relative hover:bg-brown items-start  py-1.5  px-7 md:py-2 md:px-12 lg:py-3.5 rounded-100 lg:px-12  text-white ">
                    <Link to={"/checkout"} className="lg:text-sm mr-4  text-xs">
                      Process now{" "}
                    </Link>
                    <svg
                      className="absolute lg:top-5 top-3.5 md:top-2.5 right-2 md:right-6 lg:right-6"
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.11239 10.887C1.07658 10.8513 1.04816 10.8089 1.02877 10.7621C1.00939 10.7154 0.999408 10.6653 0.999408 10.6147C0.999408 10.5642 1.00939 10.5141 1.02877 10.4673C1.04816 10.4206 1.07658 10.3782 1.11239 10.3425L5.45573 5.9999L1.11239 1.65733C1.04018 1.58512 0.99961 1.48718 0.99961 1.38506C0.99961 1.28293 1.04018 1.18499 1.11239 1.11278C1.1846 1.04057 1.28254 1 1.38467 1C1.48679 1 1.58473 1.04057 1.65694 1.11278L6.27178 5.72762C6.3076 5.76335 6.33601 5.80578 6.3554 5.85251C6.37479 5.89923 6.38477 5.94932 6.38477 5.9999C6.38477 6.05048 6.37479 6.10057 6.3554 6.14729C6.33601 6.19401 6.3076 6.23645 6.27178 6.27217L1.65694 10.887C1.62122 10.9228 1.57878 10.9512 1.53206 10.9706C1.48534 10.99 1.43525 11 1.38467 11C1.33408 11 1.28399 10.99 1.23727 10.9706C1.19055 10.9512 1.14811 10.9228 1.11239 10.887Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          {/* DOWNLOAD BUTTON END */}
          {/*CSV ORDER UPLOAD INFO END*/}
          <p className="font-medium mt-8 lg:text-start lg:text-xl text-black">
            Order confirmation
          </p>
          <p className="text-sm text-black mt-8 pb-8">
            Order confirmation is sent via e-mail to you once the order process
            has started by us. If you need any change in your order you should
            inform us as soon as possible. Once the order is invoiced we can no
            longer make any change.
            <br />
            <br />
            As StockPerfume we offer you a free partnership with many
            advantages. We are the largest wholesaler of perfume and cosmetics
            in Europe providing you a wide scale of products. Become a partner
            now and enjoy the benefits! In some cases of delivery, the shipping
            costs may not be indicated on the order confirmation. The shipping
            costs will be communicated to you later.
            <br />
            <br />
            <span className="text-base font-medium"> Order cancelation</span>
            <br />
            <br />
            As a wholesale company, we are not able to provide the option of
            cancellation after we received the order and its process has been
            started.
            <br />
            <br />
            <br />
            Please, bear in mind, that the minimum order value is € 500,00.
          </p>
        </div>
      </div>
      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto mt-3">
        <div className="flex-1">
          <form
            onChange={handleInputChange}
            onSubmit={handleFormSubmit}
            className="grid grid-cols-12 rounded-20  md:gap-x-8 md:gap-y-4 bg-white shadow md:px-20 py-8  "
          >
            <div className="col-span-12 text-center">
              <h6 className="text-sm text-black">
                For further questions, including partnership opportunities,
                <br />
                please email us or contact using our contact form.
              </h6>
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Name*</p>
              <input
                placeholder="Name"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="text"
                name={"name"}
              />
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Mail*</p>
              <input
                placeholder="Mail"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="email"
                name={"mail"}
              />
            </div>
            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Company</p>
              <input
                placeholder="Company"
                className="border rounded-20 w-full text-xs py-3 px-5"
                type="text"
                name={"company"}
              />
            </div>

            <div className="md:col-span-6 col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Phone*</p>
              <PhoneInput
                international
                value={number}
                className="text-xs"
                onChange={(e) => {
                  setNumber(e);
                }}
                name={"phone"}
              />
            </div>

            <div className=" col-span-12 px-4 md:px-0">
              <p className="mb-3 text-sm font-medium text-black">Message*</p>
              <input
                placeholder="Text"
                className="border rounded-20 w-full pb-44 pt-4 text-xs  placeholder:text-black  px-5"
                type="text"
                name={"address"}
              />
            </div>
            {formErrors && (
              <div className="col-span-12 text-center">
                <p className="text-red-500 text-xs">
                  {Object.entries(formErrors).map(([key, value]: any) => {
                    return <p key={key}>{value}</p>;
                  })}
                </p>
              </div>
            )}
            <div className="col-span-12 mt-6 lg:mt-5 grid justify-items-center">
              <button
                type="submit"
                className="bg-black hover:bg-brown text-white px-32 py-2 text-xs font-normal rounded-full"
              >
                {loading ? <LoadingSpinner /> : <span>Send</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CsvOrderUpload;
