import {IAPI, IAPIRoutes} from "./IAPI";
import {baseAPI} from "./baseApiURLS";
import {APIRequest} from "./APIRequest";

const AuthenticationRoutes : IAPIRoutes  = {
    login : {
        path : "/auth",
        method : "post",
        apiRoute : baseAPI.jwtAPIRoute,
        jwtRequired : false,
        authKeyRequired : true
    } ,
    logout  : {
        path : "/addAdress",
        method : "post",
        apiRoute : baseAPI.jwtAPIRoute,
        jwtRequired : true
    } ,
    validate  : {
        path : "/addAdress",
        method : "post",
        apiRoute : baseAPI.jwtAPIRoute,
        jwtRequired : true
    } ,
    register  : {
        path : "/addAdress",
        method : "post",
        apiRoute : baseAPI.jwtAPIRoute,
        jwtRequired : true
    } ,
}

export const AuthenticationAPI : IAPI  = {
    login : APIRequest(AuthenticationRoutes.login),
    logout  : APIRequest(AuthenticationRoutes.logout),
    validate  : APIRequest(AuthenticationRoutes.validate) ,
    register  : APIRequest(AuthenticationRoutes.register) ,
}


