import React from "react";

type Props = {
  stockQuantity: number;
  className?: string;
  isCircle?: boolean;
};

const StockInfo = ({ stockQuantity, className, isCircle }: Props) => {
  const bgColor = stockQuantity > 0 ? "bg-[#42D395]/40" : "bg-[#FFE9D9]";
  const textColor = stockQuantity > 0 ? "text-[#33B07B]" : "text-[#FF994E]";

  return (
    <div
      className={`flex items-center place-content-center h-10  ${bgColor} ${className}
      ${isCircle ? "rounded-full w-10" : "rounded-100 w-24"}`}
    >
      <p className={`hidden lg:flex text-sm font-semibold ${textColor}`}>
        {stockQuantity > 0 ? stockQuantity + " in stock" : "Out of stock"}
      </p>
      <p className={`flex lg:hidden text-xs font-semibold ${textColor}`}>
        {stockQuantity > 0 ? stockQuantity : "0"}
      </p>
    </div>
  );
};

export default StockInfo;
