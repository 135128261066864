import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Banner() {
 
  const user = useSelector((state: any) => state.userReducer);
  return (
    <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover bg-center     lg:min-h-[504px]   bg-no-repeat grid grid-cols-12 py-8 pt-16  lg:pt-44 lg:py-0 lg:px-12 ">
      <div className="lg:col-start-1 lg:col-end-5 col-start-9   col-end-13  lg:mx-10">
        <h1 className="lg:text-2xl text-sm text-black font-semibold tracking-normal">
          Fragrance & Beauty
        </h1>
        <h1 className="lg:text-2xl text-sm text-black font-thin tracking-[0.015em]">
          Wholesaler
        </h1>
        <Link to={"/shop"}>
          <button className="bg-black  hover:bg-brown flex items-center gap-2 py-3 px-6 mt-4 lg:gap-12 lg:py-5 rounded-100 lg:px-7 lg:mt-16 text-black ">
            <p className="lg:text-md lg:ml-3 text-sm text-white">Shop Now </p>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.113 10.887C1.07719 10.8513 1.04877 10.8089 1.02938 10.7621C1.01 10.7154 1.00002 10.6653 1.00002 10.6147C1.00002 10.5642 1.01 10.5141 1.02938 10.4673C1.04877 10.4206 1.07719 10.3782 1.113 10.3425L5.45634 5.9999L1.113 1.65733C1.04079 1.58512 1.00022 1.48718 1.00022 1.38506C1.00022 1.28293 1.04079 1.18499 1.113 1.11278C1.18521 1.04057 1.28315 1 1.38528 1C1.4874 1 1.58534 1.04057 1.65755 1.11278L6.27239 5.72762C6.30821 5.76335 6.33662 5.80578 6.35601 5.85251C6.3754 5.89923 6.38538 5.94932 6.38538 5.9999C6.38538 6.05048 6.3754 6.10057 6.35601 6.14729C6.33662 6.19401 6.30821 6.23645 6.27239 6.27217L1.65755 10.887C1.62183 10.9228 1.57939 10.9512 1.53267 10.9706C1.48595 10.99 1.43586 11 1.38528 11C1.33469 11 1.2846 10.99 1.23788 10.9706C1.19116 10.9512 1.14872 10.9228 1.113 10.887Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
              />
            </svg>
          </button>
        </Link>
      </div>

      <div className="hidden lg:block col-start-9 col-end-13 lg:px-10 lg:mt-2 ">
        <p className="text-md text-black font-light text-justify">
          Discover our entire product catalogue with more than 60.000 products
          in Fragrance & Beauty category. Subscribe to take full advantage of
          our advantages.
        </p>
        <div className={`mt-16 group justify-items-center ${user.loggedIn ? "hidden" : "grid"}`}>
          {/*onClick={handleClickScroll*/}
          <Link to="/register">
            <button className="flex items-center gap-14 ">
              <h1 className="text-2xl text-black group-hover:text-brown font-medium">
                Become a partner
              </h1>
              <svg
                className=" fill-black group-hover:fill-brown "
                width="81"
                height="42"
                viewBox="0 0 81 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M-5.70329e-06 21C-5.76004e-06 21.6187 0.304758 22.2122 0.847238 22.6497C1.38972 23.0872 2.12548 23.333 2.89266 23.333L71.1192 23.333L52.9128 38.0123C52.6438 38.2292 52.4305 38.4867 52.2849 38.7701C52.1394 39.0535 52.0644 39.3573 52.0644 39.664C52.0644 39.9708 52.1394 40.2746 52.2849 40.558C52.4305 40.8414 52.6438 41.0989 52.9128 41.3158C53.1817 41.5327 53.501 41.7048 53.8524 41.8222C54.2038 41.9396 54.5804 42 54.9608 42C55.3411 42 55.7178 41.9396 56.0692 41.8222C56.4206 41.7048 56.7398 41.5327 57.0088 41.3158L80.1502 22.6518C80.4196 22.4351 80.6333 22.1776 80.7791 21.8942C80.9249 21.6107 81 21.3069 81 21C81 20.6931 80.9249 20.3893 80.7791 20.1058C80.6333 19.8224 80.4196 19.565 80.1502 19.3482L57.0088 0.684191C56.7398 0.467276 56.4206 0.29521 56.0692 0.17782C55.7178 0.0604265 55.3411 5.4913e-06 54.9608 5.45961e-06C54.5804 5.42791e-06 54.2038 0.0604264 53.8524 0.17782C53.501 0.29521 53.1817 0.467275 52.9128 0.68419C52.6438 0.901102 52.4305 1.15862 52.2849 1.44203C52.1394 1.72544 52.0645 2.0292 52.0645 2.33596C52.0645 2.64272 52.1394 2.94648 52.2849 3.22989C52.4305 3.5133 52.6438 3.77081 52.9128 3.98773L71.1192 18.667L2.89266 18.667C2.12548 18.667 1.38972 18.9128 0.847239 19.3503C0.304758 19.7878 -5.64654e-06 20.3812 -5.70329e-06 21Z"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;
