import React from "react";
import { motion } from "framer-motion";
import PhoneInput from "react-phone-number-input";
import LoadingSpinner from "../Common/LoadingSpinner";
import { info } from "../../constants/websiteInfo";
import ContactForm from "./ContactForm";

function ContactUs() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px]   bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
        <div className="lg:col-start-1 lg:col-end-5 col-start-1  mt-2 col-end-13 self-center lg:mx-10">
          <p className="lg:text-2xl text-sm text-black font-medium tracking-normal">
            Contact us
          </p>
        </div>

        <div className="hidden lg:block col-start-9 col-end-13 lg:px-10 self-center lg:mt-16 lg:ml-5">
          <p className="lg:text-2xl text-sm text-black font-medium text-start  tracking-normal">
            Got a question?
          </p>
          <p className=" text-sm text-sm text-black font-thin text-start tracking-[0.015em]">
            We'd like to talk more about what you need
          </p>
        </div>
      </div>
      <div className="flex flex-row lg:w-9/12 w-11/12  checkout-block mx-auto checkout-block lg:pt-7 lg:pb-7 pt-3 pb-3 bg-white rounded-20 lg:-mt-16">
        <div className="basis-1/3 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-16 lg:h-16 w-9 h-9 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-7 lg:h-7 w-4 h-4"
                viewBox="0 0 22 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 28C11 28 22 18.0495 22 10.5C22 7.71523 20.8411 5.04451 18.7782 3.07538C16.7153 1.10625 13.9174 0 11 0C8.08262 0 5.28473 1.10625 3.22183 3.07538C1.15893 5.04451 4.34724e-08 7.71523 0 10.5C0 18.0495 11 28 11 28ZM11 15.75C9.54131 15.75 8.14236 15.1969 7.11091 14.2123C6.07946 13.2277 5.5 11.8924 5.5 10.5C5.5 9.10761 6.07946 7.77226 7.11091 6.78769C8.14236 5.80312 9.54131 5.25 11 5.25C12.4587 5.25 13.8576 5.80312 14.8891 6.78769C15.9205 7.77226 16.5 9.10761 16.5 10.5C16.5 11.8924 15.9205 13.2277 14.8891 14.2123C13.8576 15.1969 12.4587 15.75 11 15.75Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-base font-medium text-black pt-3">Address</p>
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/place/${info.address}`}
            className="lg:text-sm text-xs font-thin text-black"
          >
            Orteliuslaan 850, 3528BB Utrecht, <br />
            The Netherlands
          </a>
        </div>
        <div className="basis-1/3 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-16 lg:h-16 w-9 h-9 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-7 lg:h-7 w-4 h-4"
                viewBox="0 0 26 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.08125 2.46208C0.24508 1.76263 0.647815 1.13812 1.2235 0.690821C1.79918 0.243522 2.51369 -4.82828e-05 3.25 7.17905e-09H22.75C23.4863 -4.82828e-05 24.2008 0.243522 24.7765 0.690821C25.3522 1.13812 25.7549 1.76263 25.9187 2.46208L13 10.1555L0.08125 2.46208ZM0 4.27025V15.5182L9.42987 9.88475L0 4.27025ZM10.9866 10.8142L0.310375 17.1902C0.574156 17.7322 0.990481 18.19 1.5109 18.5104C2.03131 18.8308 2.63441 19.0006 3.25 19H22.75C23.3655 19.0001 23.9684 18.83 24.4885 18.5093C25.0086 18.1886 25.4246 17.7307 25.688 17.1887L15.0117 10.8126L13 12.0112L10.9866 10.8126V10.8142ZM16.5701 9.88633L26 15.5182V4.27025L16.5701 9.88475V9.88633Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-base font-medium text-black pt-3">Email</p>
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={`mailto:${info.email}`}
            className="lg:text-sm text-xs font-thin text-black"
          >
            {info.email}
          </a>
        </div>
        <div className="basis-1/3 text-center">
          <div className="grid justify-items-center">
            <div className="bg-black lg:w-16 lg:h-16 w-9 h-9 grid justify-items-center items-center rounded-full">
              <svg
                className="lg:w-7 lg:h-7 w-4 h-4"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.59244 0.701516C2.83302 0.461277 3.12191 0.274914 3.43996 0.154777C3.75801 0.0346406 4.09796 -0.0165269 4.43727 0.00466588C4.77659 0.0258586 5.10753 0.118928 5.40816 0.277705C5.7088 0.436483 5.97225 0.657344 6.18108 0.925652L8.64913 4.09655C9.10149 4.6782 9.26098 5.43586 9.08224 6.15089L8.33014 9.16228C8.29125 9.31825 8.29335 9.48163 8.33624 9.63655C8.37912 9.79147 8.46133 9.93267 8.57488 10.0464L11.9531 13.425C12.067 13.5388 12.2085 13.6211 12.3636 13.664C12.5188 13.7069 12.6825 13.7089 12.8386 13.6697L15.8484 12.9176C16.2012 12.8294 16.5695 12.8225 16.9254 12.8975C17.2812 12.9726 17.6154 13.1275 17.9026 13.3507L21.0732 15.8176C22.2131 16.7045 22.3176 18.389 21.2974 19.4079L19.8757 20.8297C18.8582 21.8472 17.3375 22.2941 15.9199 21.795C12.2916 20.5183 8.99736 18.441 6.28145 15.7172C3.55806 13.0015 1.48094 9.70744 0.204141 6.07939C-0.293593 4.66307 0.153268 3.14088 1.17074 2.12333L2.59244 0.701516Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <p className="lg:text-base font-medium text-black pt-3">Phone</p>
          <a
            href={`tel:${info.phone}`}
            className="lg:text-sm text-xs font-thin text-black"
          >
            {info.formattedPhone}
          </a>
        </div>
      </div>

      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto mt-3">
        <div className="flex-1">
          <ContactForm />
        </div>
      </div>
    </motion.div>
  );
}

export default ContactUs;
