import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios"

export const setOffset = createAsyncThunk(
    "products/setOffset",
    async (args: any,thunkAPI) => {
        try {
          
            const response = await axios.get(args.url + "&offset=" + args.page);
            return response.data;
        } catch (error: any ) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });