import React from "react";
import LeftSideBar from "../../SideBars/LeftSideBar";
import {nanoid} from "@reduxjs/toolkit";

function PaymentAddCard() {
    const [open, setOpen] = React.useState(true);
    return (
        <div className="flex flex-col lg:mx-20 md:mt-20">
            <div className="text-center my-8 font-normal text-lg">Payment methods</div>
            <div className="flex lg:space-x-5 mx-4">
                <LeftSideBar key={nanoid()} setOpen={setOpen} open={open}/>
                <div className={`w-full flex flex-col `}>
                    <div
                        className="flex flex-row  relative  space-x-2 lg:p-0 ">
                        <div className=" basis-1/2 bg-white checkout-block rounded-20">
                            <div className="flex flex-row">
                                <div className=" basis-1/4 items-center justify-self-center">
                                    <img className="w-12 h-12" src="/img_1.png" alt=""/>
                                </div>
                                <div>
                                    <h6 className="text-sm basis-2/4 text-start">
                                        Mastercard °°°° 2453
                                    </h6>
                                </div>
                                <div className="basis-1/4">
                                    aa
                                </div>
                            </div>
                        </div>
                        <div className="basis-1/2 bg-white checkout-block rounded-20">
                            y
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentAddCard