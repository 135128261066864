export default function filterGenerator(selectedFilters: any){
    let base_url = "https://www.stockperfume.com/wp/wp-json/wp/v3/products/?per_page=20"
    for (const [key, value] of Object.entries(selectedFilters)) {
        if(value != null){
            if( key === "category" ||  key === "orderby"  ||  key === "order" || key === "per_page"){
                base_url += `&${key}=${value}`
            }
            else
                base_url += `&filter[${key}]=${value}`
        }

    }

    return base_url
}