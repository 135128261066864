import PhoneInput from "react-phone-number-input";
import { updateAddress } from "../../app/features/user/actions/updateAddress";
import { useAppDispatch } from "../../app/hooks";
import countries, { european_countries_vat } from "../../constants/countries";
import { updateUserInfo } from "../../app/features/user/actions/updateUserInfo";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { addUserInfo } from "../../app/features/user/userSlice";
import { validateVAT } from "../../app/features/user/actions/validateVAT";

interface FormInterface {
  [key: string]: string;
}

function EditVATModal({ setShowModal, showModal, loggedIn }: any) {
  const dispatch = useAppDispatch();
  const user = useSelector((state: any) => state?.userReducer?.user);
  const [VATcountry, setVATcountry] = React.useState(
    user?.VATnumber?.country || "NL"
  );
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let formObj: FormInterface = {};
    for (let [key, value] of Array.from(formData.entries())) {
      formObj[key] = value.toString();
    }
    const { VATnumber } = formObj;
    if (loggedIn) {
      dispatch(
        updateUserInfo({
          first_name: user?.name,
          last_name: user?.surname,
          vat_number: { number: VATnumber, country: VATcountry },
        })
      );
    } else {
      dispatch(
        addUserInfo({ VATnumber: { number: VATnumber, country: VATcountry } })
      );
      dispatch(validateVAT({ vat_number: VATcountry + VATnumber }));
    }
  };

  useEffect(() => {
    setVATcountry(user?.VATnumber?.country || "NL");
  }, [user]);

  return (
    <div className={showModal ? "" : "hidden"}>
      <div
        className={`justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <form
            onSubmit={(e: any) => {
              handleSubmit(e);
            }}
            className="border-0 rounded-lg shadow relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <p className="text-xl font-semibold">Edit VAT Number</p>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex gap-4">
              <div className=" flex flex-wrap  gap-4">
                <div className="flex flex-col gap-2 ">
                  <p className="text-sm font-normal">VAT Country</p>
                  <select
                    value={VATcountry}
                    onChange={(e) => {
                      // @ts-ignore
                      setVATcountry(e.target.value);
                    }}
                    required
                    className="border text-sm placeholder:text-sm placeholder:text-black rounded-20 w-full py-2 px-5"
                    name="VATcountry"
                  >
                    {european_countries_vat.map((country: any) => {
                      return (
                        <option value={country.code}>{country.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className=" flex flex-wrap  gap-4">
                <div className="flex flex-col gap-2 ">
                  <p className="text-sm font-normal">VAT Number</p>
                  <input
                    className="border rounded-20 px-4 h-8 w-60"
                    placeholder=""
                    name="VATnumber"
                    defaultValue={user?.VATnumber?.number}
                    type="text"
                  />
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center gap-6 p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-white bg-brown font-medium rounded-20 px-6 py-3 uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>

              <button
                className="bg-black text-white font-semibold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}

export default EditVATModal;
