import React from "react";
import ProductCard from "../../Product Slider/ProductCard";
import LeftSideBar from "../../SideBars/LeftSideBar";
import { nanoid } from "@reduxjs/toolkit";
import { useAppSelector } from '../../../app/hooks';
import { wishlistReducer } from '../../../app/features/wishlist/wishlistSlice';

function Favourites() {
  const [open, setOpen] = React.useState(true);
  const products = useAppSelector((state) => state.wishlistReducer.products)
  return (
    <div className="flex flex-col gap-4 mx-3 md:mx-10 lg:mx-20 md:mt-20">
      <div className="text-center mt-8 mb-4 font-normal text-lg">Favourites</div>
      <div className="flex flex-row sm:space-x-5">
      <LeftSideBar key={nanoid()} setOpen={setOpen} open={open} />
      <div className={`w-full flex flex-col space-y-5`}>
        <div className="grid gap-4 grid-cols-12">
         {
            products?.map((product: any) => {
              return  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 max-w-10  ">
              <ProductCard key={nanoid()} defaultValue={true} product={product} />
            </div>
            })
         }
        
        </div>
      </div>
      </div>
    </div>
  );
}

export default Favourites;
