import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const updateUserInfo = createAsyncThunk(
    "user/updateUserInfo",
    async (args: any,thunkAPI) => {
        try {
            const state = store.getState();

            const token = Cookies.get("sp-token");
            const user = state.userReducer.user;
            // Eğer vat_number.country 'GR' ise, 'EL' ile değiştir
            if (args.vat_number && args.vat_number.country === 'GR') {
                args.vat_number.country = 'EL';
            }
            console.log(args);

            // @ts-ignore
            const response = await axios.post(`https://www.stockperfume.com/wp/wp-json/wp/v3/customer/info/update?JWT=${token}`, args);
            
            console.log(args);

            console.log(response);

            console.log(response.data);
            
            
            return response.data;

        } catch (error: any) {
   
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });