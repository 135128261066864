import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const deleteAddress = createAsyncThunk(
    "user/deleteAddress",
    async (args: any,thunkAPI) => {
        try {
        
            const state = store.getState();

            const token = Cookies.get("sp-token");
            const user = state.userReducer.user;

            // @ts-ignore
            const response = await axios.delete(`https://www.stockperfume.com/wp/wp-json/wp/v3/customer/address?JWT=${token}`, { data: args});

            return response.data;

        } catch (error: any) {
 
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });