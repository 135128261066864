import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const searchProductsList = createAsyncThunk(
    "search/searchProductsList",
    async (search: any,thunkAPI) => {
        try {

            const response = await axios.get(
                `https://www.stockperfume.com/wp/wp-json/wp/v3/products?search_product=${search}`
            );
        
            return response.data;
        } catch (error:any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });