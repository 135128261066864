import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const searchProductsInline = createAsyncThunk(
    "search/searchProductsInline",
    async (args: any,thunkAPI) => {
        try {
            let url = `https://www.stockperfume.com/wp/wp-json/wp/v3/products?search_product=${args?.search}`
            if(args?.category)
                url += `&category=${args?.category}`
            const response = await axios.get(
                url
            );
        
            return response.data;
        } catch (error:any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });