import { motion } from "framer-motion";
import React from "react";
import LeftSideBar from "../../SideBars/LeftSideBar";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../../Common/Modal";
import { Title } from '../Accordion';
import AddressModal from './AddressModal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { userReducer } from '../../../app/features/user/userSlice';
import AddNewAddress from './AddNewAddres';
import { useEffect } from 'react';
import { getAddress } from "../../../app/features/user/actions/getAdress";
import { deleteAddress } from "../../../app/features/user/actions/deleteAddress";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { addressTitle } from "../../../functions/addressTitle.js";

function MyAdresses() {
  const [open, setOpen] = React.useState(true);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.userReducer.loading);
  const savedAddresses = useAppSelector((state) => state.userReducer.user.savedAddresses);

  useEffect(() => {
    dispatch(getAddress());
  }, [])
  console.log(savedAddresses)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
     <div className="overflow-auto">
     <AddressModal address={selectedAddress} showModal={showModal} setShowModal={setShowModal} />
      <AddNewAddress showModal={showAddModal} setShowModal={setShowAddModal} />
       {
        loading && <LoadingSpinner />
       }
      
      <div className="flex flex-col md:mt-20 lg:mx-20 ">
        <div className=" text-center my-8 font-normal text-lg">My Adresses</div>
        <div className="flex space-x-4">
        <LeftSideBar key={nanoid()} setOpen={setOpen} open={open} />
        <div className="w-full">
          <div
          className={`flex flex-row  flex-wrap  w-full h-fit  `}
        >
          {
            savedAddresses?.map((address: any) => {
                return <div key={nanoid()} className=" p-1.5 relative">
                   <button type="button" onClick={() => { dispatch(deleteAddress({address_id: address.id}))}} className=" absolute top-5 right-5">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8776 1.1224C10.9164 1.1611 10.9472 1.20707 10.9682 1.25768C10.9892 1.3083 11 1.36256 11 1.41736C11 1.47216 10.9892 1.52642 10.9682 1.57703C10.9472 1.62765 10.9164 1.67362 10.8776 1.71232L1.71231 10.8778C1.63408 10.9561 1.52798 11 1.41735 11C1.30672 11 1.20062 10.9561 1.12239 10.8778C1.04417 10.7996 1.00022 10.6935 1.00022 10.5829C1.00022 10.4722 1.04417 10.3661 1.12239 10.2879L10.2877 1.1224C10.3264 1.0836 10.3724 1.05282 10.423 1.03181C10.4736 1.01081 10.5279 1 10.5827 1C10.6374 1 10.6917 1.01081 10.7423 1.03181C10.7929 1.05282 10.8389 1.0836 10.8776 1.1224Z" fill="#202020"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.12239 1.1224C1.0836 1.1611 1.05282 1.20707 1.03181 1.25768C1.01081 1.3083 1 1.36256 1 1.41736C1 1.47216 1.01081 1.52642 1.03181 1.57703C1.05282 1.62765 1.0836 1.67362 1.12239 1.71232L10.2877 10.8778C10.3659 10.9561 10.472 11 10.5827 11C10.6933 11 10.7994 10.9561 10.8776 10.8778C10.9558 10.7996 10.9998 10.6935 10.9998 10.5829C10.9998 10.4722 10.9558 10.3661 10.8776 10.2879L1.71231 1.1224C1.67361 1.0836 1.62763 1.05282 1.57702 1.03181C1.52641 1.01081 1.47215 1 1.41735 1C1.36255 1 1.30829 1.01081 1.25768 1.03181C1.20706 1.05282 1.16109 1.0836 1.12239 1.1224Z" fill="#202020"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8776 1.1224C10.9164 1.1611 10.9472 1.20707 10.9682 1.25768C10.9892 1.3083 11 1.36256 11 1.41736C11 1.47216 10.9892 1.52642 10.9682 1.57703C10.9472 1.62765 10.9164 1.67362 10.8776 1.71232L1.71231 10.8778C1.63408 10.9561 1.52798 11 1.41735 11C1.30672 11 1.20062 10.9561 1.12239 10.8778C1.04417 10.7996 1.00022 10.6935 1.00022 10.5829C1.00022 10.4722 1.04417 10.3661 1.12239 10.2879L10.2877 1.1224C10.3264 1.0836 10.3724 1.05282 10.423 1.03181C10.4736 1.01081 10.5279 1 10.5827 1C10.6374 1 10.6917 1.01081 10.7423 1.03181C10.7929 1.05282 10.8389 1.0836 10.8776 1.1224Z" stroke="#202020" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.12239 1.1224C1.0836 1.1611 1.05282 1.20707 1.03181 1.25768C1.01081 1.3083 1 1.36256 1 1.41736C1 1.47216 1.01081 1.52642 1.03181 1.57703C1.05282 1.62765 1.0836 1.67362 1.12239 1.71232L10.2877 10.8778C10.3659 10.9561 10.472 11 10.5827 11C10.6933 11 10.7994 10.9561 10.8776 10.8778C10.9558 10.7996 10.9998 10.6935 10.9998 10.5829C10.9998 10.4722 10.9558 10.3661 10.8776 10.2879L1.71231 1.1224C1.67361 1.0836 1.62763 1.05282 1.57702 1.03181C1.52641 1.01081 1.47215 1 1.41735 1C1.36255 1 1.30829 1.01081 1.25768 1.03181C1.20706 1.05282 1.16109 1.0836 1.12239 1.1224Z" stroke="#202020" stroke-width="0.5"/>
</svg>
</button>
                <div className=" flex flex-col gap-y-2 bg-white whitespace-nowrap rounded-20 shadow px-2 md:px-8 py-4">
                 <div className="flex  lg:px-0  gap-4">
                    <svg
                      width="15"
                      height="20"
                      viewBox="0 0 15 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 20C7.5 20 15 12.8925 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 2.96403e-08 5.51088 0 7.5C0 12.8925 7.5 20 7.5 20ZM7.5 11.25C6.50544 11.25 5.55161 10.8549 4.84835 10.1517C4.14509 9.44839 3.75 8.49456 3.75 7.5C3.75 6.50544 4.14509 5.55161 4.84835 4.84835C5.55161 4.14509 6.50544 3.75 7.5 3.75C8.49456 3.75 9.44839 4.14509 10.1517 4.84835C10.8549 5.55161 11.25 6.50544 11.25 7.5C11.25 8.49456 10.8549 9.44839 10.1517 10.1517C9.44839 10.8549 8.49456 11.25 7.5 11.25Z"
                        fill="#202020"
                      />
                    </svg>
                    <p className=" font-medium text-xs">{addressTitle(address.title)?.substring(0,20)}</p>
                  </div>
                  <div className="flex flex-col gap-y-1 text-xs ">
                    <p>{address.first_name + " " + address.last_name }</p>
                    { address.company && <p>{address.company}</p> }
                    <p>{address.city}</p>
                    <p>{address.state}</p>
                    <p>{address.country}</p>
                  
    
                  </div>
                  <button onClick={() => { setSelectedAddress(address); setShowModal(true)}} className="border rounded-100 flex items-center justify-center gap-6 gap-x-4 mx-4 py-1.5 px-2">
                    <p className="text-xs">Change Address</p>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.47287 2.05427H1.99397C1.73035 2.05427 1.47753 2.15899 1.29113 2.34539C1.10472 2.5318 1 2.78462 1 3.04824V10.006C1 10.2696 1.10472 10.5225 1.29113 10.7089C1.47753 10.8953 1.73035 11 1.99397 11H8.95176C9.21538 11 9.4682 10.8953 9.65461 10.7089C9.84101 10.5225 9.94573 10.2696 9.94573 10.006V6.52713M9.20026 1.30879C9.39797 1.11107 9.66613 1 9.94573 1C10.2253 1 10.4935 1.11107 10.6912 1.30879C10.8889 1.5065 11 1.77466 11 2.05426C11 2.33387 10.8889 2.60203 10.6912 2.79974L5.96985 7.5211L3.98191 8.01809L4.4789 6.03015L9.20026 1.30879Z" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
                  </button>
                </div>
              </div>
            })
          }
         
      
        </div>
          <div className="ml-5">
            <button onClick={ () => { setShowAddModal(true)}} className="text-xs bg-black text-white py-1.5 px-12 rounded-full mt-4">
              Add New Address +
            </button>
          </div>
        </div>
        </div>
      </div>
     </div>
    </motion.div>
  );
}

export default MyAdresses;
