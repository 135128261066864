import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotActiveRightNow from "../Common/NotActiveRightNow";

function Access() {
  return (
    <div className="my-8 lg:py-5 lg:px-32">
      <p className="text-center my-4 sm:hidden">Dropshipping</p>
      <div className="grid grid-cols-2 bg-white shadow rounded-20 mx-2 px-3 sm:mx-20   lg:mx-auto lg:pl-12 lg:pr-0 lg:w-5/6  ">
        <div className="col-span-1 pt-16 ">
          <h1 className="lg:text-2xl text-black font-semibold lg:tracking-normal ">
            Fragrance & Beauty{" "}
          </h1>
          <h2 className="lg:text-2xl font-extralight text-black lg:tracking-tight">
            Dropshipper
          </h2>
          <p className="mt-5 text-justify text-base lg:text-sm lg:font-thin lg:pr-14">
            By choosing dropshipping option,{" "}
            <span className="font-semibold">
              we become your storage partner.{" "}
            </span>{" "}
            No longer need stock,{" "}
            <span className="font-semibold">our stock becomes yours.</span> You
            don't need to invest to store, cheaper and easier.{" "}
            <span className="font-semibold">
              We send your orders directly to your customers
            </span>
          </p>

          <div className="relative">
            <a href="https://perfumedropshipment.com" target={"_blank"}>
              <button className="bg-black hover:bg-brown text-white flex items-center lg:px-8 px-6 py-3 mb-12 lg:mb-0 lg:py-4 mt-10 lg:mt-16 rounded-full gap-14">
                <p>Access</p>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.11337 10.887C1.07755 10.8513 1.04914 10.8089 1.02975 10.7621C1.01036 10.7154 1.00038 10.6653 1.00038 10.6147C1.00038 10.5642 1.01036 10.5141 1.02975 10.4673C1.04914 10.4206 1.07755 10.3782 1.11337 10.3425L5.4567 5.9999L1.11337 1.65733C1.04115 1.58512 1.00059 1.48718 1.00059 1.38506C1.00059 1.28293 1.04115 1.18499 1.11337 1.11278C1.18558 1.04057 1.28352 1 1.38564 1C1.48777 1 1.58571 1.04057 1.65792 1.11278L6.27276 5.72762C6.30857 5.76335 6.33699 5.80578 6.35638 5.85251C6.37576 5.89923 6.38574 5.94932 6.38574 5.9999C6.38574 6.05048 6.37576 6.10057 6.35638 6.14729C6.33699 6.19401 6.30857 6.23645 6.27276 6.27217L1.65792 10.887C1.62219 10.9228 1.57976 10.9512 1.53303 10.9706C1.48631 10.99 1.43623 11 1.38564 11C1.33506 11 1.28497 10.99 1.23825 10.9706C1.19153 10.9512 1.14909 10.9228 1.11337 10.887Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
        <div className="col-span-1  lg:py-10 grid justify-items-center lg:justify-items-end lg:pr-12 items-center">
          <img
            className="w-40 h-56 lg:w-auto lg:h-96 lg:pl-0 pl-2"
            src="/Rectangle 150.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Access;
