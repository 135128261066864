import React from 'react';
import {AiFillHeart, AiOutlineHeart} from 'react-icons/ai';

function ReviewCard({review,zoom} : {review:any,zoom: boolean}) {
    return (
        <div  className={'border bg-white rounded-25  shadow   relative transition-all ' + (zoom ? " py-12 " : " px-5 mt-5 py-12 ")}>

            <div className='grid justify-items-center'>
                <p className={' text-center ' + (zoom ? " text-md " : " text-xs ")}>“ {review?.customerReview} ”</p>
                <h1 className={'mt-4 font-medium ' + (zoom ? " text-md pt-2" : " text-xs ")}>{review?.customerName}</h1>
                <p className='text-xs '>From {review?.country}</p>
            </div>
        </div>
    );
}

export default ReviewCard;