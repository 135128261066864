import {createSlice} from "@reduxjs/toolkit"
import {getOrders} from "../getOrders";
import {createOrder} from "../createOrder";


interface orderDataType {

    billing: {
        email: "",
        city: "",
        postcode: 0,
        first_name: "",
        last_name: "",
        phone: 0,
        address_1: "",
    },
    shipping: {
        email: "",
        city: "",
        postcode: 0,
        first_name: "",
        last_name: "",
        phone: 0,
        address_1: "",
    },
    paid : 0,
    unpaid : 0,
    products : [],
    quantities: [],
    created_order: {}
    payment_link: ""
}

const orderData: orderDataType = {
    billing: {
        email: "",
        city: "",
        postcode: 0,
        first_name: "",
        last_name: "",
        phone: 0,
        address_1: "",
    },
    shipping: {
        email: "",
        city: "",
        postcode: 0,
        first_name: "",
        last_name: "",
        phone: 0,
        address_1: "",
    },
    paid : 0,
    unpaid : 0,
    products: [],
    quantities: [],
    created_order: {},
    payment_link: ""
}

export const orderSlice = createSlice({
    name: "orderSlice",
    initialState: {
        orders: [],
        total: 0,
        new_order : orderData,
        loading: false,
        paid : 0,
        unpaid : 0,
        created_order: {},
        payment_link: ""
    },
    reducers: {
       AddOrderInfo: (state, action) => {
            state.new_order = {
                ...state.new_order,
                ...action.payload
            }
       }
    },
    extraReducers: function (builder) {
        builder
        .addCase(getOrders.pending, (state, action) => {
            state.loading = true
        })
        .addCase(getOrders.fulfilled, (state, {payload, meta}) => {
            state.orders = payload
            let total = 0
            let paid = 0
            let unpaid = 0
            payload.map((order: any)=>{
                total += parseFloat(order?.total)
                if(order?.status==="on-hold" || order?.status==="pending" ){
                    unpaid += parseFloat(order?.total)
                }
                else if(order?.status === "completed" || order?.status === "processing"){
                    paid += parseFloat(order?.total)
                }
            })
            state.total = total
            state.paid = paid
            state.unpaid = unpaid
            state.loading = false
        })
        .addCase(getOrders.rejected, (state, action) => {
            state.loading = false
            state.total = 0
            state.paid = 0
            state.unpaid = 0
            state.orders = []
        })
        .addCase(createOrder.pending, (state, action) => {
            state.loading = true
        })
        .addCase(createOrder.fulfilled, (state, {payload, meta}) => {
            state.loading = false
            state.created_order = payload
            state.payment_link = payload?.payment_link


        })
        .addCase(createOrder.rejected, (state, action) => {
            state.loading = false
        })
    },
})
export const {AddOrderInfo} = orderSlice.actions
export const ordersReducer = orderSlice.reducer;
