import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const revokeToken = createAsyncThunk(
    "user/revokeToken",
    async (token,thunkAPI) => {
        try {
            
            const response = await axios.post(
                "https://www.stockperfume.com/wp/wp?rest_route=/simple-jwt-login/v1/auth/revoke&AUTH_KEY=tCV!^654i8IcMB^", token
            );
           
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });