import React, { useEffect } from "react";
import Accordion, { Description, Title } from "../../MyAccount/Accordion";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { AddOrderInfo } from "../../../app/features/order/slices/orderSlice";
import Cookies from "js-cookie";
import { createOrder } from "../../../app/features/order/createOrder";
import paymentRates from "../../../constants/paymentRates";
import {
  setCartInfo,
  setPaymentFee,
} from "../../../app/features/cart/slices/cartSlice";

function Conformation() {
  const dispatch = useAppDispatch();
  const userToken = useAppSelector((state: any) => state.userReducer.token);
  const cart = useAppSelector((state) => state.cartReducer);
  const user = useAppSelector((state) => state.userReducer.user);
  const payment_link = useAppSelector(
    (state) => state.ordersReducer.payment_link
  );
  const { paymentFee } = useAppSelector((state) => state.cartReducer);
  console.log(paymentFee);
  const [inputErrors, setInputErrors] = React.useState("");
  console.log(inputErrors);
  const [isCheck, setIsCheck] = React.useState(false);
  console.log(cart);

  const checkInputsFilled = () => {
    let errors = "";

    if (!user?.billing) {
      errors += "Please fill in the billing address. ";
    }
    if (!user?.shipping) {
      errors += "Please fill in the shipping address. ";
    }
    if (cart.totalPrice < 500) {
      errors += "To proceed, make sure your order total is above 500 euros. ";
    }
    console.log(errors);
    setInputErrors(errors);
    return errors === ""; // Hata yoksa true dner
  };

  function handleClick() {
    const isFormValid = checkInputsFilled(); // Form geçerliyse true dner
    console.log(isFormValid);
    if (!isFormValid) {
      return; // Form geçerli değilse, işlemi durdur
    }
    let token = null;

    if (Cookies.get("sp-token")) {
      // @ts-ignore
      token = Cookies.get("sp-token");
    } else {
      token = userToken;
    }
    const products = cart.products?.map((product: any) => product.id);
    const quantities = cart.products?.map((product: any) => product.quantity);

    const billing = user?.billing;
    const shipping = user?.shipping;
    if (cart.totalPrice < 500) {
      setInputErrors("Minimum order amount is 500€");
      return;
    }

    if (products.length != 0) {
      if (inputErrors == "") {
        dispatch(
          createOrder({
            billing: billing,
            shipping: shipping,
            products: products,
            quantities: quantities,
            jwt: token,
            shipment_name: cart?.selectedShipmentMobile?.shipment_name,
            shipment_id: cart?.selectedShipmentMobile?.shipment_id,
            payment_method: cart?.selectedPaymentMethodMobile,
            shipping_price: cart?.shippingPrice,
            vat_number:
              user?.VATnumber.country == "GR"
                ? "EL" + user?.VATnumber.number
                : user?.VATnumber.country + user?.VATnumber.number,
          })
        );
      }
    }
  }

  useEffect(() => {
    const rate = paymentRates[cart?.selectedPaymentMethodMobile] || 0;
    const fee =
      (parseFloat(cart?.vatPrice ?? 0) +
        parseFloat(cart?.subTotalPrice ?? 0) +
        parseFloat(cart?.shippingPrice ?? 0)) *
      rate;
    if (!isNaN(fee)) {
      dispatch(setPaymentFee(fee));
    } else {
      dispatch(setPaymentFee(0));
    }
    dispatch(setCartInfo());
  }, []);
  console.log(paymentFee);
  /*  useEffect(() => {
    if (payment_link != "") {
      window.location.replace(payment_link);
    }
  }, [payment_link]); */

  return (
    <div className="flex flex-col space-y-5 mx-4">
      <Accordion buttonPosition="right-5" className="bg-white rounded-20">
        <Title className="bg-white  py-5 px-3 rounded-20  shadow w-full">
          <div className="flex items-center gap-4 ">
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H4.04762L6.08952 11.2019C6.1592 11.5527 6.35003 11.8678 6.62861 12.092C6.90719 12.3163 7.25577 12.4354 7.61333 12.4286H15.019C15.3766 12.4354 15.7252 12.3163 16.0038 12.092C16.2824 11.8678 16.4732 11.5527 16.5429 11.2019L17.7619 4.80952H4.80952M7.85714 16.2381C7.85714 16.6589 7.51603 17 7.09524 17C6.67445 17 6.33333 16.6589 6.33333 16.2381C6.33333 15.8173 6.67445 15.4762 7.09524 15.4762C7.51603 15.4762 7.85714 15.8173 7.85714 16.2381ZM16.2381 16.2381C16.2381 16.6589 15.897 17 15.4762 17C15.0554 17 14.7143 16.6589 14.7143 16.2381C14.7143 15.8173 15.0554 15.4762 15.4762 15.4762C15.897 15.4762 16.2381 15.8173 16.2381 16.2381Z"
                stroke="#202020"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Products</p>
          </div>
        </Title>
        <Description className="bg-white rounded-20 ">
          <div className=" mt-4 flex flex-col gap-y-8 mx-4 pb-4">
            {cart.products?.map((item: any) => {
              return (
                <div className="flex">
                  <div className="basis-4/5 sm:basis-7/12 flex-shrink flex">
                    <div className="basis-[88%] flex lg:gap-8 gap-6 items-center">
                      <img className="w-10 h-12 " src={item.images[0]} alt="" />
                      <div className="text-sm sm:text-sm">
                        <p className="text-black ">{item.name}</p>
                        <p className="text-gray">EAN: {item.ean}</p>
                      </div>
                    </div>
                  </div>
                  <div className="basis-1/5 sm:basis-5/12 flex justify-around items-center">
                    <div className="text-sm sm:text-base">
                      {item.quantity + " x"}
                    </div>

                    <div className="text-sm sm:text-base">
                      €{item.price?.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Description>
      </Accordion>
      <Accordion
        buttonPosition={"right-5 "}
        className="  rounded-20 bg-white w-full "
      >
        <Title
          className={
            "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
          }
        >
          <div className="flex  items-center">
            <svg
              width="15"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.91367 0H2.44444C1.79614 0 1.17438 0.263392 0.715961 0.732233C0.257539 1.20107 0 1.83696 0 2.5V17.5C0 18.163 0.257539 18.7989 0.715961 19.2678C1.17438 19.7366 1.79614 20 2.44444 20H12.2222C12.8705 20 13.4923 19.7366 13.9507 19.2678C14.4091 18.7989 14.6667 18.163 14.6667 17.5V5.88375C14.6666 5.55226 14.5378 5.23437 14.3086 5L9.77778 0.36625C9.54862 0.131813 9.23779 7.07968e-05 8.91367 0ZM9.16667 4.375V1.875L12.8333 5.625H10.3889C10.0647 5.625 9.75386 5.4933 9.52465 5.25888C9.29544 5.02446 9.16667 4.70652 9.16667 4.375ZM3.05556 11.25C2.89348 11.25 2.73804 11.1842 2.62343 11.0669C2.50883 10.9497 2.44444 10.7908 2.44444 10.625C2.44444 10.4592 2.50883 10.3003 2.62343 10.1831C2.73804 10.0658 2.89348 10 3.05556 10H11.6111C11.7732 10 11.9286 10.0658 12.0432 10.1831C12.1578 10.3003 12.2222 10.4592 12.2222 10.625C12.2222 10.7908 12.1578 10.9497 12.0432 11.0669C11.9286 11.1842 11.7732 11.25 11.6111 11.25H3.05556ZM2.44444 13.125C2.44444 12.9592 2.50883 12.8003 2.62343 12.6831C2.73804 12.5658 2.89348 12.5 3.05556 12.5H11.6111C11.7732 12.5 11.9286 12.5658 12.0432 12.6831C12.1578 12.8003 12.2222 12.9592 12.2222 13.125C12.2222 13.2908 12.1578 13.4497 12.0432 13.5669C11.9286 13.6842 11.7732 13.75 11.6111 13.75H3.05556C2.89348 13.75 2.73804 13.6842 2.62343 13.5669C2.50883 13.4497 2.44444 13.2908 2.44444 13.125ZM3.05556 16.25C2.89348 16.25 2.73804 16.1842 2.62343 16.0669C2.50883 15.9497 2.44444 15.7908 2.44444 15.625C2.44444 15.4592 2.50883 15.3003 2.62343 15.1831C2.73804 15.0658 2.89348 15 3.05556 15H7.94444C8.10652 15 8.26196 15.0658 8.37656 15.1831C8.49117 15.3003 8.55556 15.4592 8.55556 15.625C8.55556 15.7908 8.49117 15.9497 8.37656 16.0669C8.26196 16.1842 8.10652 16.25 7.94444 16.25H3.05556Z"
                fill="#202020"
              />
            </svg>

            <p className="ml-4">Order Total</p>
            <p className="ml-auto text-xs">
              {" "}
              € {(Number(cart?.totalPrice)?.toFixed(2) || 0) + "€"}
            </p>
          </div>
        </Title>
        <Description className={"px-6 py-7"}>
          <div className="flex items-center  text-xs">
            <p>Total items</p>
            <p className="ml-auto text-xs">
              {cart.products.reduce(
                (total, item) => total + parseInt(item.quantity, 10),
                0
              )}{" "}
              pcs
            </p>
          </div>

          <div className="flex items-center  text-xs mt-4">
            <p>Shipping cost</p>
            <p className="ml-auto text-xs">€{cart?.shippingPrice}</p>
          </div>
          <div className="flex items-center  text-xs mt-4">
            <p>Items subtotal: </p>
            <p className="ml-auto text-xs">
              €{cart?.subTotalPrice?.toFixed(2)}
            </p>
          </div>
          <div className="flex items-center  text-xs mt-4">
            <p>VAT </p>
            <p className="ml-auto text-xs">€{cart?.vatPrice?.toFixed(2)}</p>
          </div>
          <div className="flex items-center  text-xs mt-4">
            <p>Payment Fee </p>
            <p className="ml-auto text-xs">€{paymentFee?.toFixed(2)}</p>
          </div>
          <div className="flex items-center  text-xs mt-4">
            <p>Order total:</p>
            <p className="ml-auto text-xs">
              € {(Number(cart?.totalPrice)?.toFixed(2) || 0) + "€"}
            </p>
          </div>
        </Description>
      </Accordion>
      <div className="flex items-center gap-4 mx-4">
        <input
          onChange={() => {
            setIsCheck((state: any) => {
              return !state;
            });
          }}
          required
          type="checkbox"
          name=""
          id=""
        />
        <p className="text-xs">
          I confirm that I have read and understood the Terms and condition
        </p>
      </div>
      {inputErrors && (
        <p className=" bg-red p-4 rounded-lg text-sm text-center text-white">
          {inputErrors}
        </p>
      )}
      <div className="flex justify-center">
        <button
          onClick={handleClick}
          disabled={!isCheck || inputErrors != ""}
          className="bg-black  text-white rounded-100 px-9 py-2  text-xs"
        >
          Proceed to payment
        </button>
      </div>
    </div>
  );
}

export default Conformation;
