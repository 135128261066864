import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { newsletterAPI } from "../../app/features/contact/newsletterAPI";
import { useLocation } from "react-router-dom";
import { notify } from "../../functions/notify";

function PriceList() {
  const dispatch = useAppDispatch();
  const { newsletter, loading } = useAppSelector(
    (state) => state.contactReducer
  );
  const location = useLocation();
  const handleNewsletter = async (e: any) => {
    e.preventDefault();
    try {
      const response = await dispatch(
        newsletterAPI({ email: e.target["newsletteremail"].value })
      );
      if (response.payload.message == "Email address subscribed successfully") {
        notify("Email address subscribed successfully", "success");
      } else {
        notify("Email address already subscribed", "error");
      }
    } catch (error) {
      notify("Something went wrong", "error");
    }
  };

  return (
    <div
      className={
        "z-0 relative " +
        (location.pathname == "/checkout" ||
        location.pathname == "/my-account/addresses"
          ? "hidden"
          : null)
      }
    >
      <form
        id="newsletter"
        onSubmit={handleNewsletter}
        className="grid grid-cols-10 -z-[999]  lg:p-16 md:p-12 lg:p-8 p-6  rounded-20 bg-white lg:mx-44 md:mx-[12%] mx-[10%] -translate-y-1/2 shadow "
      >
        <div className="lg:col-span-7 col-span-10 ">
          <div className="flex items-center gap-3 lg:flex-row flex-col">
            <h1 className="lg:text-lg lg:font-semibold ">Join our Pricelist</h1>
            <p className="font-light text-center text-xs lg:text-lg lg:text-left">
              Get the best deals and discounts on fragrances and more.
            </p>
          </div>
          <div className="my-5 lg:mb-0  flex">
            <input
              name="newsletteremail"
              className="lg:w-[92%] md:w-[75%] w-full mx-auto lg:mx-0 bg-white placeholder-black text-sm border border-[#F1F1F1] rounded-full py-3 pl-7 pr-4 focus:outline-none"
              placeholder="Enter your email address"
              type="email"
            />
          </div>
        </div>
        <div className="col-span-10 lg:col-span-3 grid justify-items-center items-center">
          <div className="">
            <button
              type="submit"
              className="flex items-center gap-8 bg-black rounded-100 lg:px-9 lg:py-5 px-5 py-3.5"
            >
              {loading ? (
                <div className="flex w-full h-full place-items-center justify-center object-contain spinner-container">
                  <div className="loading-spinner w-5 h-5"></div>
                </div>
              ) : (
                <>
                  <p className="text-white   font-normal lg:text-md">
                    Subscribe
                  </p>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.113 10.887C1.07719 10.8513 1.04877 10.8089 1.02938 10.7621C1.01 10.7154 1.00002 10.6653 1.00002 10.6147C1.00002 10.5642 1.01 10.5141 1.02938 10.4673C1.04877 10.4206 1.07719 10.3782 1.113 10.3425L5.45634 5.9999L1.113 1.65733C1.04079 1.58512 1.00022 1.48718 1.00022 1.38506C1.00022 1.28293 1.04079 1.18499 1.113 1.11278C1.18521 1.04057 1.28315 1 1.38528 1C1.4874 1 1.58534 1.04057 1.65755 1.11278L6.27239 5.72762C6.30821 5.76335 6.33662 5.80578 6.35601 5.85251C6.3754 5.89923 6.38538 5.94932 6.38538 5.9999C6.38538 6.05048 6.3754 6.10057 6.35601 6.14729C6.33662 6.19401 6.30821 6.23645 6.27239 6.27217L1.65755 10.887C1.62183 10.9228 1.57939 10.9512 1.53267 10.9706C1.48595 10.99 1.43586 11 1.38528 11C1.33469 11 1.2846 10.99 1.23788 10.9706C1.19116 10.9512 1.14872 10.9228 1.113 10.887Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.5"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
          {newsletter && loading === false ? (
            <div className="text-xs mx-auto mt-6 py-3 px-3 text-center bg-green-600 text-white rounded-lg">
              {newsletter.error ? newsletter.error : newsletter.message}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default PriceList;
