import { createSlice } from "@reduxjs/toolkit";
import {sendEmail} from "./sendEmail";
import {newsletterAPI} from "./newsletterAPI";


type InitialState = {
    sended : boolean
    loading : boolean
    newsletter : string | unknown | null
}


const initialState: InitialState = {
    sended : false,
    loading :false,
    newsletter : null
}

const  contactSlice = createSlice({
    name : 'contactSlice',
    initialState,
    reducers : {
    },
    extraReducers: (builder) => {

        // LOGIN
        builder.addCase(sendEmail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(sendEmail.fulfilled, (state,action) => {
            state.loading = false
            state.sended = !!action.payload;
        })
        builder.addCase(sendEmail.rejected, (state, action) => {
            state.loading = false
            state.sended = false
        })
        builder.addCase(newsletterAPI.pending, (state) => {
            state.loading = true
        })
        builder.addCase(newsletterAPI.fulfilled, (state,action) => {
            state.loading = false
            state.newsletter = action.payload;
        })
        builder.addCase(newsletterAPI.rejected, (state, action) => {
            state.loading = false
            state.newsletter = action.payload;
        })
    },

})

// @ts-ignore
export const contactReducer = contactSlice.reducer;
