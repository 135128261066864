import React from "react";
import { Link } from "react-router-dom";

function MegaMenu() {
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const category = {
    men_fragrance: [
      { title: "Eau de Toilette", img: "men_1.png", category: "108707" },
      { title: "Eau de parfum", img: "men_2.png", category: "117497" },
      { title: "Eau intense", img: "men_3.png", category: "117749" },
    ],
    women_fragrance: [
      {
        title: "Eau de Toilette",
        img: "2.SP-category-eau-de-toilette-women-1.png",
        category: "43506",
      },
      {
        title: "Eau de parfum",
        img: "1.-SP-category-eau-de-parfum-women-1.png",
        category: "112233",
      },
      {
        title: "Eau intense",
        img: "3.SP-category-Intense-Fragrance-1.png",
        category: "112416",
      },
    ],

    bath_body: [
      { title: "Deodorants", img: "bath_3.png", category: "46018" },
      { title: "Body Care", img: "bath_2.png", category: "91115" },
      { title: "Shower", img: "bath_1.png", category: "47897" },
    ],

    makeup: [
      { title: "Eyes", img: "makeup_1.png", category: "86511" },
      { title: "Lips", img: "makeup_2.png", category: "49535" },
      { title: "Skin", img: "makeup_3.png", category: "115368" },
    ],

    personal_care: [
      { title: "Hair Care", img: "personal_1.png", category: "45425" },
      { title: "Oral Care", img: "personal_2.png", category: "53858" },
      { title: "Skin Care", img: "personal_3.png", category: "44668" },
    ],
  };

  const handleHover = (e: any) => {
    // @ts-ignore
    setSelectedCategory(category[e.target.id]);
  };
  return (
    <div className="absolute pt-14 mt-4  left-0 group-hover:block">
      <svg
        className="absolute -translate-y-2 translate-x-10"
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 0L15.7942 10.5H0.205771L8 0Z" fill="white" />
      </svg>

      <div className="flex bg-white rounded-20 shadow">
        <ul className="whitespace-nowrap rounded-20 shadow py-3 px-2">
          <li
            id="men_fragrance"
            onMouseEnter={(e) => {
              handleHover(e);
            }}
            className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15"
          >
            <Link
              className="justify-between flex items-center w-full"
              to={`shop/category/${111170}`}
            >
              <h1 className="mr-12">Men Fragrance</h1>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </Link>
          </li>
          <li
            id="women_fragrance"
            onMouseEnter={(e) => {
              handleHover(e);
            }}
            className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15"
          >
            <Link
              className="justify-between flex items-center w-full"
              to={`shop/category/${108590}`}
            >
              <h1 className="mr-12">Women Fragrance</h1>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </Link>
          </li>
          <li
            id="bath_body"
            onMouseEnter={(e) => {
              handleHover(e);
            }}
            className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15"
          >
            <Link
              className="justify-between flex items-center w-full"
              to={`shop/category/${43508}`}
            >
              <h1 className="mr-12">Bath & Body</h1>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </Link>
          </li>
          <li
            id="makeup"
            onMouseEnter={(e) => {
              handleHover(e);
            }}
            className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15"
          >
            <Link
              className="justify-between flex items-center w-full"
              to={`shop/category/${111219}`}
            >
              <h1 className="mr-12">Makeup</h1>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </Link>
          </li>
          <li
            id="personal_care"
            onMouseEnter={(e) => {
              handleHover(e);
            }}
            className="w-full justify-between flex items-center py-2.5 px-4 hover:bg-gray rounded-15"
          >
            <Link
              className="justify-between flex items-center w-full"
              to={`shop/category/${43506}`}
            >
              <h1 className="mr-12">Personal care</h1>
              <svg
                className=""
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.16735 8.90961C1.1387 8.88104 1.11597 8.84709 1.10046 8.80971C1.08495 8.77233 1.07697 8.73226 1.07697 8.69179C1.07697 8.65133 1.08495 8.61126 1.10046 8.57388C1.11597 8.5365 1.1387 8.50255 1.16735 8.47397L4.64202 4.99992L1.16735 1.52586C1.10958 1.46809 1.07713 1.38974 1.07713 1.30804C1.07713 1.22635 1.10958 1.14799 1.16735 1.09022C1.22512 1.03245 1.30348 1 1.38517 1C1.46687 1 1.54523 1.03245 1.603 1.09022L5.29487 4.7821C5.32352 4.81068 5.34625 4.84463 5.36176 4.882C5.37727 4.91938 5.38525 4.95945 5.38525 4.99992C5.38525 5.04039 5.37727 5.08046 5.36176 5.11783C5.34625 5.15521 5.32352 5.18916 5.29487 5.21774L1.60299 8.90961C1.57442 8.93826 1.54047 8.961 1.50309 8.97651C1.46571 8.99202 1.42564 9 1.38517 9C1.34471 9 1.30464 8.99202 1.26726 8.97651C1.22988 8.961 1.19593 8.93826 1.16735 8.90961Z"
                  fill="white"
                  stroke="#202020"
                  stroke-width="0.5"
                />
              </svg>
            </Link>
          </li>
        </ul>
        {selectedCategory &&
          // @ts-ignore
          selectedCategory.map((subcategory: any) => {
            return (
              <div className="flex flex-col items-center lg:gap-0 gap-4 justify-center w-ffull lg:px-8 px-1">
                <div className="lg:w-32  lg:h-40 h-16 w-16">
                  <img src={subcategory.img} alt="" />
                </div>

                <Link
                  to={`shop/category/${subcategory.category}`}
                  className="border rounded-full flex items-center gap-3 w-full px-4 py-1 whitespace-nowrap "
                >
                  <p>
                    {
                      // @ts-ignore
                      subcategory.title
                    }
                  </p>
                  <svg
                    width="4"
                    height="7"
                    viewBox="0 0 4 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 6.5L3.5 3.5L0.5 0.5"
                      stroke="#202020"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MegaMenu;
