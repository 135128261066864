import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { setupStore } from "./app/store";
import ScrollToTop from "./functions/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter } from "react-router-dom";
import "react-phone-number-input/style.css";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root")!;
const root = createRoot(container);
export const store = setupStore();

root.render(
  <AnimatePresence>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </AnimatePresence>
);
