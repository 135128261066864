import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const validateToken = createAsyncThunk(
    "user/validateToken",
    async (args: any,thunkAPI) => {
        try {

            const response = await axios.get(
                `https://www.stockperfume.com/wp/wp?rest_route=/simple-jwt-login/v1/auth/validate&AUTH_KEY=tCV!^654i8IcMB^&JWT=${args.token}`
            );


            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });