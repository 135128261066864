import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion, { Title, Description } from "../../MyAccount/Accordion";
import { useDispatch } from "react-redux";
import { AddOrderInfo } from "../../../app/features/order/slices/orderSlice";
import { getCargoCompanies } from "../../../app/features/cart/getCargoCompanies";
import { getShippingPrice } from "../../../app/features/cart/getShippingPrice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { nanoid } from "@reduxjs/toolkit";
import { setCartInfo, setSelectedShipmentMobile } from "../../../app/features/cart/slices/cartSlice";

interface FormInterface {
  [key: string]: string;
}

function Shipping() {
  const user = useAppSelector((state) => state.userReducer.user);
  const cart = useAppSelector((state) => state.cartReducer);
  const cargoCompanies = useAppSelector(
    (state) => state.cartReducer.cargoCompanies
  );

  const [carrier, setCarrier] = React.useState({
    shipment_id: "",
    shipment_name: "",
  });
  const dispatch = useAppDispatch();
  console.log(carrier);
  // ürün sayısı değiştiğinde veya seçili adress değiştiği zaman cargoCompanies yenileniyor
  useEffect(() => {
    if (user.shipping.country) {
      dispatch(
        getCargoCompanies({
          to: {
            post_code: user.shipping.postcode,
            country: user.shipping.country,
          },
          quantity: cart?.products?.length,
        })
      );
    }
    dispatch(setCartInfo());
  }, [user.shipping.country, cart.products]);

  // seçili cargoCompany değiştiğinde shippingPrice yenileniyor
  useEffect(() => {
    const shippingInfo = {
      shipping_method: carrier.shipment_id,
      quantity: cart.products.length,
      to: { country: user.shipping.country },
    };
    dispatch(getShippingPrice(shippingInfo));
    dispatch(setCartInfo());
    dispatch(AddOrderInfo({ shipping: carrier }));
    dispatch(setSelectedShipmentMobile(carrier));
  }, [carrier, cart.products.length, user.shipping.country]);

  return (
    <div className="flex flex-col space-y-5 mx-4">
      <Accordion
        buttonPosition={"right-5 "}
        className="  rounded-20 bg-white w-full "
      >
        <Title
          className={
            "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
          }
        >
          <div className="flex space-x-4 items-center">
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.9091 1H1V10.4545H11.9091V1Z" fill="#202020" />
              <path
                d="M11.9091 4.63636H14.8182L17 6.81818V10.4545H11.9091V4.63636Z"
                fill="#202020"
              />
              <path
                d="M4.27273 14.0909C5.27688 14.0909 6.09091 13.2769 6.09091 12.2727C6.09091 11.2686 5.27688 10.4545 4.27273 10.4545C3.26857 10.4545 2.45455 11.2686 2.45455 12.2727C2.45455 13.2769 3.26857 14.0909 4.27273 14.0909Z"
                fill="#202020"
              />
              <path
                d="M13.7273 14.0909C14.7314 14.0909 15.5455 13.2769 15.5455 12.2727C15.5455 11.2686 14.7314 10.4545 13.7273 10.4545C12.7231 10.4545 11.9091 11.2686 11.9091 12.2727C11.9091 13.2769 12.7231 14.0909 13.7273 14.0909Z"
                fill="#202020"
              />
              <path
                d="M11.9091 10.4545V1H1V10.4545H11.9091ZM11.9091 10.4545H17V6.81818L14.8182 4.63636H11.9091V10.4545ZM6.09091 12.2727C6.09091 13.2769 5.27688 14.0909 4.27273 14.0909C3.26857 14.0909 2.45455 13.2769 2.45455 12.2727C2.45455 11.2686 3.26857 10.4545 4.27273 10.4545C5.27688 10.4545 6.09091 11.2686 6.09091 12.2727ZM15.5455 12.2727C15.5455 13.2769 14.7314 14.0909 13.7273 14.0909C12.7231 14.0909 11.9091 13.2769 11.9091 12.2727C11.9091 11.2686 12.7231 10.4545 13.7273 10.4545C14.7314 10.4545 15.5455 11.2686 15.5455 12.2727Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="text-xs font-medium">Select Shipping Method</p>
          </div>
        </Title>
        <Description className={"px-6 py-7"}>
          <div className="flex items-center  text-xs">
            <p>Carrier</p>
            <select
              required
              className="border rounded-20 px-4 ml-auto h-7 w-40
                "
              name="shipping_method"
              id={nanoid()}
              onChange={(e) => {
                const company = cargoCompanies.find(
                  (item: any) => item.id == e.target.value
                );
                dispatch(
                  AddOrderInfo({
                    shipment_id: e.target.value,
                    shipment_name: company.name,
                  })
                );
                setCarrier({
                  shipment_id: e.target.value,
                  shipment_name: company.name,
                });
              }}
              value={
                // @ts-ignore
                carrier?.id
              }
            >
              <option value="">Select Carrier</option>
              {cargoCompanies?.map((item: any, index: any) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex items-center  text-xs mt-6">
            <p>Insurance request</p>
            <p className="ml-auto   animate-text text-sm">Coming Soon</p>
            <div className=" hidden  ml-auto">
              <div className=" flex items-center">
                <p className="-mr-4 z-20 font-bold">€</p>
                <input
                  onChange={(e) => {}}
                  required
                  name="insurance"
                  className=" w-32 h-7 rounded-20 border px-4"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center  text-xs mt-6">
            <p>Shipping cost</p>
            <p className="ml-auto">
              €{cart.shippingPrice ? cart.shippingPrice : 0}
            </p>
          </div>
        </Description>
      </Accordion>

      <Link to={"/checkout/confirmation"} className="flex justify-center">
        <button className="bg-black text-white rounded-100 px-9 py-2 w-44  text-xs">
          Continue
        </button>
      </Link>
    </div>
  );
}

export default Shipping;
