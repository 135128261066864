import {
  setCartInfo,
  setVATprice,
  setPaymentFee,
} from "../../app/features/cart/slices/cartSlice";
import Accordion, { Title, Description } from "../MyAccount/Accordion";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { getCargoCompanies } from "../../app/features/cart/getCargoCompanies";
import { getShippingPrice } from "../../app/features/cart/getShippingPrice";
import { createOrder } from "../../app/features/order/createOrder";
import CalculateVAT from "../../app/features/cart/CalculateVAT";
import Cookies from "js-cookie";
import { BsCreditCard2FrontFill, BsFileEarmarkTextFill } from "react-icons/bs";
import paymentRates from "../../constants/paymentRates";
import { notify } from "../../functions/notify";
import filterPaymentMethods, {
  PaymentMethodsType,
} from "../../functions/filterPaymentMethods";

function OrderInfo({
  className,
  isShippingSameAsBilling,
}: {
  className?: string;
  isShippingSameAsBilling: boolean;
}) {
  const dispatch = useAppDispatch();
  const { paymentFee } = useAppSelector((state) => state.cartReducer);
  const user = useAppSelector((state) => state.userReducer.user);
  const cart = useAppSelector((state) => state.cartReducer);
  const totalCartItems = cart?.products?.reduce(
    (acc, item) => acc + parseInt(item.quantity, 10),
    0
  );
  console.log(totalCartItems);
  const userToken = useAppSelector((state: any) => state.userReducer.token);
  const cargoCompanies = useAppSelector(
    (state) => state.cartReducer.cargoCompanies
  );
  const [payment_method, setPayment_method] = React.useState(null);

  const [availablePaymentMethods, setAvailablePaymentMethods] =
    React.useState<PaymentMethodsType>({
      banktransfer: false,
      amex: true,
      mastercard: true,
      visa: true,
      maestro: true,
      ideal: false,
      giropay: false,
      sofort: false,
      bancontact: false,
      mybank: false,
    });

  const [carrier, setCarrier] = React.useState({
    shipment_id: "",
    shipment_name: "",
  });
  console.log(carrier);
  const [paymentRateRatio, setPaymentRateRatio] = React.useState("%0");
  const [inputErrors, setInputErrors] = React.useState("");
  const checkInputsFilled = () => {
    let errors = "";

    if (!user?.billing) {
      errors += "Please fill in the billing address. ";
    }
    if (!user?.shipping) {
      errors += "Please fill in the shipping address. ";
    }
    if (cart.totalPrice < 500) {
      errors += "To proceed, make sure your order total is above 500 euros. ";
    }

    setInputErrors(errors);
    return errors === ""; // Hata yoksa true döner
  };

  function handleSubmit(e: any) {
    e.preventDefault();
    const isFormValid = checkInputsFilled(); // Form geçerliyse true dner
    if (!isFormValid) {
      notify("Please fill in all the fields", "error");
      return; // Form geçerli değilse, işlemi durdur
    }
    let token = null;

    if (Cookies.get("sp-token")) {
      // @ts-ignore
      token = Cookies.get("sp-token");
    } else {
      token = userToken;
    }
    const products = cart?.products?.map((product: any) => product?.id);
    const quantities = cart?.products?.map((product: any) => product?.quantity);
    const shipping = isShippingSameAsBilling ? user?.billing : user?.shipping;
    const billing = user?.billing;

    if (products.length != 0) {
      if (inputErrors == "") {
        dispatch(
          createOrder({
            billing: billing,
            shipping: shipping,
            products: products,
            quantities: quantities,
            jwt: token,
            shipment_name: carrier.shipment_name,
            shipment_id: carrier.shipment_id,
            payment_method,
            shipping_price: cart.shippingPrice,
            vat_number:
              user?.VATnumber?.country == "GR"
                ? "EL" + user?.VATnumber?.number
                : user?.VATnumber?.country + user?.VATnumber?.number,
          })
        );
      }
    }
  }

  // ürün sayısı değiştiğinde veya seçili adress değiştiği zaman cargoCompanies yenileniyor
  useEffect(() => {
    if (user?.shipping?.country) {
      dispatch(
        getCargoCompanies({
          to: {
            post_code: user?.shipping?.postcode,
            country: user?.shipping?.country,
          },
          quantity: totalCartItems,
        })
      );
    }
    dispatch(setCartInfo());
  }, [
    user?.shipping?.country,
    cart?.products,
    dispatch,
    totalCartItems,
    user?.shipping?.postcode,
  ]);

  // seçili cargoCompany değiştiğinde shippingPrice yenileniyor
  useEffect(() => {
    const shippingInfo = {
      shipping_method: carrier?.shipment_id,
      quantity: totalCartItems,
      to: { country: user?.shipping?.country },
    };
    dispatch(getShippingPrice(shippingInfo));
    const rate = CalculateVAT(user);
    console.log(rate);
    dispatch(setVATprice(rate));
    dispatch(setCartInfo());
    console.log(user);
  }, [
    carrier,
    cart?.products?.length,
    user?.shipping?.country,
    user,
    dispatch,
    totalCartItems,
  ]);

  useEffect(() => {
    if (carrier) {
      setCarrier({ shipment_id: "", shipment_name: "" });
    }
  }, [user?.shipping?.country]);

  useEffect(() => {
    console.log(payment_method);
    const rate = paymentRates[payment_method] || 0;
    console.log(rate);
    setPaymentRateRatio((rate * 100).toFixed(2) + "%");
    const fee =
      (parseFloat(cart?.vatPrice ?? 0) +
        parseFloat(cart?.subTotalPrice ?? 0) +
        parseFloat(cart?.shippingPrice ?? 0)) *
      rate;
    if (!isNaN(fee)) {
      dispatch(setPaymentFee(fee));
    } else {
      dispatch(setPaymentFee(0));
    }
    dispatch(setCartInfo());
  }, [
    carrier,
    cart?.products?.length,
    user?.shipping?.country,
    totalCartItems,
    cart,
    dispatch,
    payment_method,
  ]);

  useEffect(() => {
    if (user?.billing?.country) {
      const methods = filterPaymentMethods(user.billing.country);
      setAvailablePaymentMethods(methods);
    }
    dispatch(setPaymentFee(0));
    setPayment_method(null);
    dispatch(setCartInfo());
  }, [user?.billing?.country]);

  console.log(payment_method);
  console.log(user?.billing?.country);
  console.log(availablePaymentMethods);
  const handlePaymentMethodChange = (e: any) => {
    console.log(e.target.value);
    setPayment_method(e.target.value);
    const rate = paymentRates[e.target.value] || 0;
    console.log(rate);
    setPaymentRateRatio((rate * 100).toFixed(2) + "%");
    const fee =
      (parseFloat(cart?.vatPrice ?? 0) +
        parseFloat(cart?.subTotalPrice ?? 0) +
        parseFloat(cart?.shippingPrice ?? 0)) *
      rate;

    if (!isNaN(fee)) {
      dispatch(setPaymentFee(fee));
    } else {
      dispatch(setPaymentFee(0));
    }
    dispatch(setCartInfo());
  };

  const filteredMethods = cargoCompanies?.filter(
    (method: any) =>
      !method.name.includes("Unstamped") &&
      !method.name.includes("Colis") &&
      !method.name.includes("Drop Off") &&
      !method.name.includes("Dropoff") &&
      !method.name.includes("non-EU")
  );
  return (
    <form
      onSubmit={handleSubmit}
      className={`lg:col-span-3 col-span-12 space-y-4 ${className}`}
    >
      <div className="col-span-12  flex rounded-20 bg-white shadow h-fit">
        <Accordion
          buttonPosition={"right-5 "}
          className="  rounded-20 bg-white w-full "
        >
          <Title
            className={
              "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
            }
          >
            <div className="flex space-x-4 items-center">
              <BsCreditCard2FrontFill className="text-md" />

              <p className="text-sm text-black font-semibold">
                Select payment method
              </p>
            </div>
          </Title>
          <Description
            onChange={(e: React.ChangeEvent) => handlePaymentMethodChange(e)}
            className={"flex flex-col space-y-2 px-6 py-7"}
          >
            {availablePaymentMethods.banktransfer && (
              <div className="flex  text-xs">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-8 h-8 object-contain"
                      src="/images/payment-methods/banktransfer.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">
                    Bank transfer
                  </p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  value={"BANKTRANS"}
                  checked={payment_method === "BANKTRANS"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.mastercard && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-7 h-7 object-contain"
                      src="/images/payment-methods/mastercard.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Mastercard</p>
                </div>
                <input
                  className="ml-auto"
                  required
                  type="radio"
                  name="payment"
                  checked={payment_method === "MASTERCARD"}
                  value={"MASTERCARD"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.visa && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-8 h-8 object-contain"
                      src="/images/payment-methods/visa.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Visa</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "VISA"}
                  value={"VISA"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.amex && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full overflow-hidden">
                    <img
                      className="w-7 h-7 object-contain rounded-full"
                      src="/images/payment-methods/amex.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">
                    American express
                  </p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "AMEX"}
                  value={"AMEX"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.ideal && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-6 h-6 object-contain"
                      src="/images/payment-methods/ideal.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">iDeal</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "IDEAL"}
                  value={"IDEAL"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.giropay && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-7 h-7object-contain"
                      src="/images/payment-methods/giropay.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Giropay</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "GIROPAY"}
                  value={"GIROPAY"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.maestro && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-7 h-7 object-contain"
                      src="/images/payment-methods/maestro.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Maestro</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "MAESTRO"}
                  value={"MAESTRO"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.sofort && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-8 h-8 object-contain"
                      src="/images/payment-methods/sofort.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Sofort</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "DIRECTBANK"}
                  value={"DIRECTBANK"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.bancontact && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-8 h-8 object-contain"
                      src="/images/payment-methods/bancontact.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">Bancontact</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "MISTERCASH"}
                  value={"MISTERCASH"}
                  id={nanoid()}
                />
              </div>
            )}

            {availablePaymentMethods.mybank && (
              <div className="flex  text-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center place-content-center w-9 h-9 bg-[#F5F6FA] rounded-full">
                    <img
                      className="w-8 h-8 object-contain"
                      src="/images/payment-methods/mybank.png"
                      alt=""
                    />
                  </div>
                  <p className="text-sm font-normal text-black">MyBank</p>
                </div>
                <input
                  className="ml-auto"
                  type="radio"
                  name="payment"
                  checked={payment_method === "MYBANK"}
                  value={"MYBANK"}
                  id={nanoid()}
                />
              </div>
            )}
          </Description>
        </Accordion>
      </div>
      <div className="col-span-12  flex rounded-20 bg-white shadow h-fit">
        <Accordion
          buttonPosition={"right-5 "}
          className="  rounded-20 bg-white w-full "
        >
          <Title
            className={
              "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
            }
          >
            <div className="flex space-x-4 items-center">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.9091 1H1V10.4545H11.9091V1Z" fill="#202020" />
                <path
                  d="M11.9091 4.63636H14.8182L17 6.81818V10.4545H11.9091V4.63636Z"
                  fill="#202020"
                />
                <path
                  d="M4.27273 14.0909C5.27688 14.0909 6.09091 13.2769 6.09091 12.2727C6.09091 11.2686 5.27688 10.4545 4.27273 10.4545C3.26857 10.4545 2.45455 11.2686 2.45455 12.2727C2.45455 13.2769 3.26857 14.0909 4.27273 14.0909Z"
                  fill="#202020"
                />
                <path
                  d="M13.7273 14.0909C14.7314 14.0909 15.5455 13.2769 15.5455 12.2727C15.5455 11.2686 14.7314 10.4545 13.7273 10.4545C12.7231 10.4545 11.9091 11.2686 11.9091 12.2727C11.9091 13.2769 12.7231 14.0909 13.7273 14.0909Z"
                  fill="#202020"
                />
                <path
                  d="M11.9091 10.4545V1H1V10.4545H11.9091ZM11.9091 10.4545H17V6.81818L14.8182 4.63636H11.9091V10.4545ZM6.09091 12.2727C6.09091 13.2769 5.27688 14.0909 4.27273 14.0909C3.26857 14.0909 2.45455 13.2769 2.45455 12.2727C2.45455 11.2686 3.26857 10.4545 4.27273 10.4545C5.27688 10.4545 6.09091 11.2686 6.09091 12.2727ZM15.5455 12.2727C15.5455 13.2769 14.7314 14.0909 13.7273 14.0909C12.7231 14.0909 11.9091 13.2769 11.9091 12.2727C11.9091 11.2686 12.7231 10.4545 13.7273 10.4545C14.7314 10.4545 15.5455 11.2686 15.5455 12.2727Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p className="text-sm text-black font-semibold">
                Select Shipping Method
              </p>
            </div>
          </Title>
          <Description className={"px-6 pt-4 pb-8"}>
            <div className="flex items-center  text-xs">
              <p className="text-sm font-normal text-black">Carrier</p>
              {cart?.products?.length > 0 && user?.shipping?.country ? (
                <select
                  required
                  className="border rounded-20 lg:w-48 text-center ml-auto h-7 px-4 text-sm text-black"
                  name="shipping_method"
                  id={nanoid()}
                  onChange={(e) => {
                    const company = cargoCompanies?.find(
                      (item: any) => item?.id == e?.target?.value
                    );
                    const rate = CalculateVAT(user);
                    dispatch(setVATprice(rate));
                    setCarrier({
                      shipment_id: e?.target?.value,
                      shipment_name: company?.name,
                    });
                  }}
                  value={carrier?.shipment_id}
                >
                  <option disabled selected value="">
                    Select Carrier
                  </option>
                  {filteredMethods?.map((item: any, index: any) => {
                    return (
                      <option
                        key={index}
                        value={item.id}
                        className={`${
                          !user?.shipping?.country ? "hidden" : ""
                        }`}
                      >
                        {item?.name?.substring(0, 30)}
                      </option>
                    );
                  })}
                </select>
              ) : cart?.products?.length == 0 ? (
                <div className="ml-auto">Please add product to cart</div>
              ) : (
                <div className="ml-auto">Add Shipping Address</div>
              )}
            </div>
            <div className="flex items-center gap-4 text-xs mt-3">
              <p className="text-sm font-normal text-[#9B9DA4]">
                Insurance request <br />
                <span className="italic">
                  (this option will be available soon)
                </span>
              </p>

              <div className=" hidden  ml-auto">
                <div className=" flex items-center">
                  <p className="-mr-4 z-20 font-medium">€</p>
                  <input
                    className=" w-32 h-7 rounded-20 border px-4"
                    name="insurance"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center  text-xs mt-6">
              <p className="text-sm font-semibold text-black">Shipping cost</p>
              <p className="ml-auto text-sm font-semibold text-black">
                {cart.shippingPrice ? cart.shippingPrice : 0}
                {" €"}
              </p>
            </div>
          </Description>
        </Accordion>
      </div>
      <div>
        {filteredMethods?.length === 0 && totalCartItems > 200 && (
          <p className="bg-red p-4 rounded-lg text-sm text-center text-white">
            If the shipping option is not available in the quantity you
            selected, please contact us.
          </p>
        )}
      </div>
      <div className="col-span-12  flex rounded-20 bg-white shadow h-fit">
        <Accordion
          buttonPosition={"hidden "}
          className="  rounded-20 bg-white w-full "
        >
          <Title
            className={
              "lg:text-start text-center px-6 py-7  shadow w-full  rounded-20 "
            }
          >
            <div className="flex justify-between  items-center">
              <div className="flex items-center gap-3">
                <BsFileEarmarkTextFill />
                <p className="text-sm text-black font-semibold">Order Total</p>
              </div>
              <span className="text-base font-semibold text-black">
                {(Number(cart?.totalPrice).toFixed(2) || 0) + " €"}
              </span>
            </div>
          </Title>
          <Description className={"px-6 py-7"}>
            <div className="flex items-center  text-sm">
              <p className="text-sm font-normal text-black">Total items: </p>
              <p className="ml-auto text-sm font-normal text-black">
                {totalCartItems} pcs
              </p>
            </div>

            <div className="flex items-center  text-sm mt-4">
              <p className="text-sm font-normal text-black">Shipping cost: </p>
              <p className="ml-auto text-sm font-normal text-black">
                {(cart?.shippingPrice ? cart?.shippingPrice : 0) + " €"}
              </p>
            </div>
            <div className="flex items-center  text-sm mt-4">
              <p className="text-sm font-normal text-black">Items subtotal: </p>
              <p className="ml-auto text-sm font-normal text-black">
                {cart?.subTotalPrice?.toFixed(2) + " €"}
              </p>
            </div>
            <div className="flex items-center  text-sm mt-4">
              <p className="text-sm font-normal text-black">VAT: </p>
              <p className="ml-auto text-sm font-normal text-black">
                {cart?.vatPrice?.toFixed(2) + " €"}
              </p>
            </div>
            <div className="flex items-center  text-sm mt-4">
              <p className="text-sm font-normal text-black">Payment fee: </p>
              <p className="ml-auto text-sm font-normal text-black">
                {paymentFee?.toFixed(2) + " €"} ({paymentRateRatio})
              </p>
            </div>
            {/*  <div className="flex items-center  text-sm mt-4">
            <p className="text-sm font-normal text-brown  ">
              Wallet used
            </p>
            <p className="ml-auto text-sm font-normal text-brown">
              -€ 463,25
            </p>
          </div> */}
            <div className="flex items-center  text-sm mt-4">
              <p className="text-base font-semibold text-black">Order total:</p>
              <p className="ml-auto text-base font-semibold text-black">
                {(Number(cart?.totalPrice).toFixed(2) || 0) + " €"}
              </p>
            </div>
          </Description>
        </Accordion>
      </div>
      <div className="flex items-center gap-4">
        <input required type="checkbox" name="" id={nanoid()} />
        <p className="text-xs text-black">
          I hereby acknowledge that I have read and agree to abide by the Terms
          and Conditions outlined on this website.
        </p>
      </div>
      {}
      <button className="flex w-full font-medium place-content-center items-center bg-black text-white h-10 mx-auto rounded-100 justify-center text-sm">
        Complete & pay order
      </button>
      <div>
        {inputErrors && (
          <p className="bg-red p-4 rounded-lg text-sm text-center text-white">
            {inputErrors}
          </p>
        )}
      </div>
    </form>
  );
}

export default OrderInfo;
