import React, { useState } from "react";
import Accordion, { Title, Description } from "../Accordion";
import LeftSideBar from "../../SideBars/LeftSideBar";
import { nanoid } from "@reduxjs/toolkit";
import PersonalInfoForm from "./PersonalInfoForm";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect } from "react";
import { getAddress } from "../../../app/features/user/actions/getAdress";
import { getUserInfo } from "../../../app/features/user/actions/getUserInfo";
import { changePassword } from "../../../app/features/user/actions/changePassword";
import { useSelector } from "react-redux";

function PersonalInfo() {
  const [open, setOpen] = React.useState(true);
  const [passwordShown, setPasswordShown] = useState([false, false, false]);
  const togglePasswordVisiblity = (index: number) => {
    const temp = passwordShown;
    temp[index] = !temp[index];
    setPasswordShown([...temp]);
  };
  const [error, setError] = useState("");
  const { message, loading } = useSelector((state: any) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAddress());
  }, []);

  const handleSubmitVerificationCode = (e: any) => {
    e.preventDefault();
    if (e.target.password.value === e.target.password2.value) {
      dispatch(
        changePassword({
          new_password: e.target.password.value,
          current_password: e.target.password_current.value,
        })
      );
    } else {
      setError("Passwords do not match");
    }
  };
  return (
    <div className="flex flex-col lg:mx-20 md:mt-20">
      <div className="text-center my-8 font-semibold text-lg">
        Personal Information
      </div>
      <div className="flex lg:space-x-5 mx-4">
        <LeftSideBar key={nanoid()} setOpen={setOpen} open={open} />
        <div className={`w-full flex flex-col `}>
          <PersonalInfoForm />

          {/* CHANGE PASSWORD */}
          <Accordion
            buttonPosition={"lg:right-28 right-14"}
            className="grid  rounded-20 bg-white shadow py-8  lg:px-20 mt-4"
          >
            <Title
              className={
                "lg:text-start text-center text-md text-black font-semibold "
              }
            >
              Change your current password
            </Title>
            <Description className={"lg:mt-5"}>
              {
                <form onSubmit={handleSubmitVerificationCode}>
                  <p className="text-base py-4 font-medium text-center lg:text-start">
                    To change your password, you must enter the current
                    password.
                  </p>
                  <div className="grid grid-cols-12 justify-items-center  lg:justify-items-stretch  lg:gap-4 lg:gap-x-10 w-full">
                    <span className="col-span-12 text-sm">
                      Current password
                    </span>
                    <div className="relative col-span-12 mt-6 lg:mt-0 lg:col-span-6 w-11/12 lg:w-full">
                      <input
                        required
                        name="password_current"
                        placeholder="Current password*"
                        className="border rounded-20 placeholder:text-black text-sm text-black lg:w-full py-3 px-5 w-full"
                        type={passwordShown[0] ? "text" : "password"}
                      />
                      <div
                        onClick={() => {
                          togglePasswordVisiblity(0);
                        }}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        <svg
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="col-span-6"></div>
                    <span className="col-span-12 text-sm mt-7 lg:mt-0">New password</span>
                    <div className="relative col-span-12  lg:mt-0 lg:col-span-6 w-11/12 lg:w-full mt-4">
                      <input
                        required
                        name="password"
                        placeholder="New password*"
                        className="border rounded-20 placeholder:text-black text-sm text-black w-full py-3 px-5"
                        type={passwordShown[1] ? "text" : "password"}
                      />
                      <div
                        onClick={() => {
                          togglePasswordVisiblity(1);
                        }}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        <svg
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="relative col-span-12  lg:mt-0 lg:col-span-6 w-11/12 lg:w-full mt-4">
                      <input
                        required
                        name="password2"
                        placeholder="New password again*"
                        className="border rounded-20 w-full placeholder:text-black text-sm text-black py-3 px-5"
                        type={passwordShown[2] ? "text" : "password"}
                      />
                      <div
                        onClick={() => {
                          togglePasswordVisiblity(2);
                        }}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      >
                        <svg
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 6.81818C1 6.81818 3.90909 1 9 1C14.0909 1 17 6.81818 17 6.81818C17 6.81818 14.0909 12.6364 9 12.6364C3.90909 12.6364 1 6.81818 1 6.81818Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 9C10.205 9 11.1818 8.02317 11.1818 6.81818C11.1818 5.6132 10.205 4.63636 9 4.63636C7.79502 4.63636 6.81818 5.6132 6.81818 6.81818C6.81818 8.02317 7.79502 9 9 9Z"
                            stroke="#202020"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="col-span-12 mt-6 lg:mt-0 grid justify-items-center">
                      {error && (
                        <p className="text-red-500 text-sm col-span-full pb-4">
                          {error}
                        </p>
                      )}
                      {message && (
                        <p className="text-black text-sm col-span-full pb-4">
                          {message}
                        </p>
                      )}
                      <button
                        className="bg-black hover:bg-brown text-white px-14 h-10 text-sm rounded-full"
                        type="submit"
                      >
                        {loading ? (
                          <div className="flex w-full h-full place-items-center justify-center object-contain spinner-container">
                            <div className="loading-spinner w-8 h-8"></div>
                          </div>
                        ) : (
                          "Change password"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              }
            </Description>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
