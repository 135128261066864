import React, {useEffect, useState} from "react";
import Filters from "../Shop/Filters";
import Accordion from '../MyAccount/Accordion';
import {Title, Description} from '../MyAccount/Accordion';
import {Link} from "react-router-dom";
import {searchProductsList} from "../../app/features/product/actions/searchProductList";
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {productsReducer} from '../../app/features/product/slices/productSlice';
import ProductCard from "../Shop/ProductCard";


function MobileSearchPage() {
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    const category = {
        men_fragrance:
            [
                { title: "Eau de Toilette", img: "category.png", category: "8145" },
                { title: "Eau de parfum", img: "category2.png", category: "8145" },
                { title: "Eau intense", img: "category3.png", category: "8145" },
            ],
        women_fragrance:  [
            { title: "Eau de Toilette", img: "category.png", category: "8145" },
            { title: "Eau de parfum", img: "category2.png", category: "8145" },
            { title: "Eau intense", img: "category3.png", category: "8145" },
        ],

        bath_body: [
            { title: "Eau de Toilette", img: "category.png", category: "8145" },
            { title: "Eau de parfum", img: "category2.png", category: "8145" },
            { title: "Eau intense", img: "category3.png", category: "8145" },
        ],

        makeup: [
            { title: "Eau de Toilette", img: "category.png", category: "8145" },
            { title: "Eau de parfum", img: "category2.png", category: "8145" },
            { title: "Eau intense", img: "category3.png", category: "8145" },
        ],

        personal_care:
            [
                { title: "Eau de Toilette", img: "category.png", category: "8145" },
                { title: "Eau de parfum", img: "category2.png", category: "8145" },
                { title: "Eau intense", img: "category3.png", category: "8145" },
            ],

    };


    const [isSearchOpen, setIsSearchOpen] = React.useState(false);
    const [search, setSearch] = useState("")
    const [showResults, setShowResults] = useState(false)
    const dispatch = useAppDispatch()
    const products = useAppSelector((state) => state.productsReducer.products)
    const handleSearch = (event: any) => {
        if (event.target.value) {
            const searchText = event.target.value;
            setSearch(searchText)
        } else {
            setSearch("")
        }
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 1) {
                dispatch(searchProductsList(search))
                setShowResults(true)
            }
        }, 1000)

        if (search.length >= 1) {
            return () => clearTimeout(delayDebounceFn)
        } else {
            setShowResults(false)
        }
    }, [search])

 

    return (
        <div>
            <div className="flex justify-items-center">
                <input
                    onChange={handleSearch} onClick={handleSearch}
                    className="w-full bg-white  placeholder:text-xs text-xs text-black placeholder:text-black
                 border border-[#F1F1F1] rounded-full mt-4 mx-4 py-3 px-4 focus:outline-none"
                    placeholder="Type to find a product"
                    type="text"
                    value={search}
                />
            </div>

            <Filters/>

            {
                showResults && products.length > 0 ? (

                        <div className="mt-10 flex flex-col gap-y-3">
                            {
                                products.map((product: any) => {
                                    return <ProductCard key={product.id} product={product}/>
                                })
                            }
                        </div>

                    )
                    : (
                        <div className="mt-10 flex flex-col gap-y-4">
                            <Accordion buttonPosition="hidden" className=" mx-2  rounded-20">
                                <Title click={(setOpen) => {
                                    setOpen((open) => !open)
                                }} className=" bg-white shadow w-full rounded-20 py-7  px-10">
                                  <h1>Men Fragrance</h1>  
                                </Title>
                                <Description className="py-5 mx-10 px-7 flex flex-col gap-y-4 bg-white mt-4 rounded-20">

                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de Toilette</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de parfum</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau intense</p>
                                        </button>
                                    </Link>

                                </Description>
                            </Accordion>
                            <Accordion buttonPosition="hidden" className=" mx-2  rounded-20">
                                <Title click={(setOpen) => {
                                    setOpen((open) => !open)
                                }} className=" bg-white shadow w-full rounded-20 py-7  px-10">
                                  <h1>Women Fragrances</h1>  
                                </Title>
                                <Description className="py-5 mx-10 px-7 flex flex-col gap-y-4 bg-white mt-4 rounded-20">

                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de Toilette</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de parfum</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau intense</p>
                                        </button>
                                    </Link>
                                </Description>
                            </Accordion>
                            <Accordion buttonPosition="hidden" className=" mx-2  rounded-20">
                                <Title click={(setOpen) => {
                                    setOpen((open) => !open)
                                }} className=" bg-white shadow w-full rounded-20 py-7  px-10">
                                   <h1>Bath & Body</h1>
                                </Title>
                                <Description className="py-5 mx-10 px-7 flex flex-col gap-y-4 bg-white mt-4 rounded-20">
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de Toilette</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de parfum</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau intense</p>
                                        </button>
                                    </Link>
                                </Description>
                            </Accordion>
                            <Accordion buttonPosition="hidden" className=" mx-2  rounded-20">
                                <Title click={(setOpen) => {
                                    setOpen((open) => !open)
                                }} className=" bg-white shadow w-full rounded-20 py-7  px-10">
                                   <h1>Makeup</h1>  
                                </Title>
                                <Description className="py-5 mx-10 px-7 flex flex-col gap-y-4 bg-white mt-4 rounded-20">

                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de Toilette</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de parfum</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau intense</p>
                                        </button>
                                    </Link>
                                </Description>
                            </Accordion>
                            <Accordion buttonPosition="hidden" className=" mx-2  rounded-20">
                                <Title click={(setOpen) => {
                                    setOpen((open) => !open)
                                }} className=" bg-white shadow w-full rounded-20 py-7  px-10">
                                    <h1>Personal Care</h1> 
                                </Title>
                                <Description className="py-5 mx-10 px-7 flex flex-col gap-y-4 bg-white mt-4 rounded-20">

                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de Toilette</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau de parfum</p>
                                        </button>
                                    </Link>
                                    <Link to="/shop/category/15">
                                        <button className="border rounded-20 w-full py-2">
                                            <p className="text-sm text-start  px-5 text-black">Eau intense</p>
                                        </button>
                                    </Link>
                                </Description>
                            </Accordion>
                        </div>
                    )
            }


        </div>
    )
}

export default MobileSearchPage