import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const addAddress = createAsyncThunk(
    "user/addAddress",
    async (args: any,thunkAPI) => {
        try {
            const state = store.getState();
       
            const token = Cookies.get("sp-token");
            const user = state.userReducer.user;
          
            // @ts-ignore
            const response = await axios.post(`https://www.stockperfume.com/wp/wp-json/wp/v3/customer/address?JWT=${token}`, args);
           
            return response.data;

        } catch (error: any) {
     
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });