import React, { useEffect, useRef, useState } from "react";
import { menu } from "../../constants";
import MenuItem from "./MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { searchProductsInline } from "../../app/features/product/actions/searchProductsInline";
import {
  clearSearch,
  setCategory,
} from "../../app/features/product/slices/searchSlice";
import { nanoid } from "@reduxjs/toolkit";
import ProfileIcon from "../Common/ProfileIcon";
import LoadingSpinner from "../Common/LoadingSpinner";

function NavBar({ setIsMenuOpen }: { setIsMenuOpen: any }) {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const navigate = useNavigate();
  const cart = useAppSelector((state) => state.cartReducer.products);
  const searchList = useSelector(
    (state: any) => state.searchReducer.searchResult
  );
  const loading = useSelector((state: any) => state.searchReducer.isLoading);

  const location = useLocation();
  const searchCategory = useSelector(
    (state: any) => state.searchReducer.category
  );

  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();

  const myRef = React.useRef<HTMLInputElement>(null);

  const handleSearch = (event: any) => {
    if (event.target.value) {
      const searchText = event.target.value;
      setSearch(searchText);
    } else {
      setSearch("");
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 1) {
        dispatch(
          searchProductsInline({ search: search, category: searchCategory })
        );
      }
    }, 1000);
    if (search.length <= 1) {
      dispatch(clearSearch());
    } else {
      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsSearchOpen(false);
    }
  };

  const handleClickInside = () => setIsSearchOpen(true);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const totalCartItems = cart.reduce(
    (acc, item) => acc + parseInt(item.quantity, 10),
    0
  );

  return (
    <>
      {loading && <LoadingSpinner />}

      <div
        className="flex  flex-row rounded-full bg-white  shadow-secondary lg:px-9 lg:h-20 h-16 px-4 place-items-center
            fixed inset-y-6 inset-x-3 z-20 lg:inset-x-6 lg:inset-y-4 "
      >
        <button className="md:hidden">
          <svg
            onClick={() => setIsMenuOpen(true)}
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6.5H14.1765M1 12H17M1 1H10.4118"
              stroke="#202020"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </button>
        {/*  Logo  */}
        <Link
          className={`flex justify-center  mx-auto ${
            isSearchOpen ? "hidden md:block" : ""
          }`}
          to="/"
        >
          <img
            src="/logo stock-perfume black.png"
            alt="logo"
            className="lg:w-52 md:w-40 w-32  object-contain "
          />
        </Link>

        {/* Nav Menu  */}
        {isSearchOpen ? (
          <div
            ref={myRef}
            onClick={handleClickInside}
            className=" grow-0 w-2/3 "
          >
            <div className="flex grow  place-items-center ">
              <div className="relative w-full translate-x-7">
                <input
                  type="search"
                  id="search-dropdown"
                  onChange={handleSearch}
                  onClick={handleSearch}
                  className="block h-11 pl-10 w-full text-black text-sm outline-none rounded-100 border z-50 relative"
                  placeholder="Search for a product, brand, EAN..."
                  required
                />
                {/*      <button className="absolute top-0 right-5 ">
                                <div>
                                     <svg
                                        className="lg:w-11 md:w-9 w-8 group"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 44 44"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="22"
                                            cy="22"
                                            r="21.5"
                                            className="group-hover:fill-black group-hover:stroke-black"
                                            fill="#B59679"
                                            stroke="#B59679"
                                        />
                                        <path
                                            d="M25.7425 23.3432C26.7107 22.0218 27.1444 20.3834 26.9567 18.7559C26.769 17.1284 25.9739 15.6318 24.7303 14.5654C23.4867 13.4991 21.8863 12.9417 20.2495 13.0048C18.6126 13.0679 17.06 13.7468 15.9021 14.9057C14.7442 16.0645 14.0665 17.6179 14.0046 19.255C13.9427 20.8922 14.5012 22.4923 15.5682 23.7353C16.6353 24.9783 18.1323 25.7725 19.7597 25.9591C21.3871 26.1456 23.0249 25.7107 24.3455 24.7414H24.3445C24.3745 24.7814 24.4065 24.8194 24.4425 24.8564L28.2924 28.7068C28.4799 28.8944 28.7342 28.9999 28.9995 29C29.2647 29.0001 29.5192 28.8948 29.7068 28.7073C29.8944 28.5197 29.9999 28.2653 30 28C30.0001 27.7348 29.8948 27.4803 29.7073 27.2926L25.8575 23.4422C25.8217 23.406 25.7833 23.3726 25.7425 23.3422V23.3432ZM26.0004 19.4988C26.0004 20.2212 25.8582 20.9364 25.5818 21.6038C25.3054 22.2712 24.9003 22.8775 24.3896 23.3883C23.8789 23.8991 23.2726 24.3042 22.6053 24.5807C21.9381 24.8571 21.2229 24.9994 20.5007 24.9994C19.7784 24.9994 19.0632 24.8571 18.396 24.5807C17.7287 24.3042 17.1224 23.8991 16.6117 23.3883C16.101 22.8775 15.6959 22.2712 15.4195 21.6038C15.1431 20.9364 15.0009 20.2212 15.0009 19.4988C15.0009 18.04 15.5803 16.6409 16.6117 15.6093C17.6431 14.5778 19.042 13.9983 20.5007 13.9983C21.9593 13.9983 23.3582 14.5778 24.3896 15.6093C25.421 16.6409 26.0004 18.04 26.0004 19.4988Z"
                                            fill="white"
                                        />
                                    </svg> 
                                </div>
                            </button> */}

                {searchList?.products?.length > 0 && !loading && (
                  <div className="absolute flex-col rounded-xl z-10">
                    <ul
                      id="style-5"
                      className="lg:h-auto lg:rounded-xl lg:ml-10 lg-mt-5 shadow"
                    >
                      {searchList?.products?.map((product) => (
                        <Link to={`/shop/${product.slug}`} key={product.id}>
                          <li className="w-full lg:text-start text-left text-gray-700 p-4 bg-white text-xs hover:bg-gray lg:w-[600px]">
                            <div className="grid grid-cols-12 gap-6 ">
                              <div className="col-span-10">
                                <div>{product.name}</div>
                                <div className="text-xs">
                                  {"SKU:" + product.sku}
                                </div>
                              </div>
                              <div className="col-span-2 ">
                                <span className="lg:text-sm lg:text-black">
                                  {"€ " + product.price}
                                </span>
                              </div>
                            </div>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <ul className="mx-auto  whitespace-nowrap hidden  text-black  md:flex md:flex-row flex-col lg:space-x-12 md:space-x-6  lg:text-base md:text-sm  font-medium place-items-center">
            {menu.map((page, index) => (
              <li key={index}>
                <MenuItem page={page} />
              </li>
            ))}
          </ul>
        )}

        {/*  Icon Menu  */}
        <div className="hidden md:block">
          <div className=" flex flex-row ml-auto  lg:space-x-5 space-x-2.5">
            <div
              onClick={() => {
                setIsSearchOpen((state) => !state);
              }}
            >
              <svg
                className="lg:w-11 md:w-9 w-8 group z-100 relative"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="22"
                  cy="22"
                  r="21.5"
                  className="group-hover:fill-black group-hover:stroke-black"
                  fill="#B59679"
                  stroke="#B59679"
                />
                <path
                  d="M25.7425 23.3432C26.7107 22.0218 27.1444 20.3834 26.9567 18.7559C26.769 17.1284 25.9739 15.6318 24.7303 14.5654C23.4867 13.4991 21.8863 12.9417 20.2495 13.0048C18.6126 13.0679 17.06 13.7468 15.9021 14.9057C14.7442 16.0645 14.0665 17.6179 14.0046 19.255C13.9427 20.8922 14.5012 22.4923 15.5682 23.7353C16.6353 24.9783 18.1323 25.7725 19.7597 25.9591C21.3871 26.1456 23.0249 25.7107 24.3455 24.7414H24.3445C24.3745 24.7814 24.4065 24.8194 24.4425 24.8564L28.2924 28.7068C28.4799 28.8944 28.7342 28.9999 28.9995 29C29.2647 29.0001 29.5192 28.8948 29.7068 28.7073C29.8944 28.5197 29.9999 28.2653 30 28C30.0001 27.7348 29.8948 27.4803 29.7073 27.2926L25.8575 23.4422C25.8217 23.406 25.7833 23.3726 25.7425 23.3422V23.3432ZM26.0004 19.4988C26.0004 20.2212 25.8582 20.9364 25.5818 21.6038C25.3054 22.2712 24.9003 22.8775 24.3896 23.3883C23.8789 23.8991 23.2726 24.3042 22.6053 24.5807C21.9381 24.8571 21.2229 24.9994 20.5007 24.9994C19.7784 24.9994 19.0632 24.8571 18.396 24.5807C17.7287 24.3042 17.1224 23.8991 16.6117 23.3883C16.101 22.8775 15.6959 22.2712 15.4195 21.6038C15.1431 20.9364 15.0009 20.2212 15.0009 19.4988C15.0009 18.04 15.5803 16.6409 16.6117 15.6093C17.6431 14.5778 19.042 13.9983 20.5007 13.9983C21.9593 13.9983 23.3582 14.5778 24.3896 15.6093C25.421 16.6409 26.0004 18.04 26.0004 19.4988Z"
                  fill="white"
                />
              </svg>
            </div>
            <Link to="/my-account/favourites">
              <button
                className={
                  "group flex items-center justify-center gap-6hover:bg-black rounded-full lg:w-11 lg:h-11 md:w-9 md:h-9 w-8  h-8 " +
                  (location.pathname == "/my-account/favourites"
                    ? "bg-black"
                    : "bg-brown ")
                }
              >
                <div>
                  {" "}
                  <svg
                    className=" fill-white  "
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.49991 2.23279L5.91735 1.63398C4.54991 0.228352 2.04254 0.713415 1.13741 2.4806C0.712477 3.31179 0.616602 4.51185 1.39254 6.04342C2.14004 7.5181 3.69516 9.28448 6.49991 11.2085C9.30466 9.28448 10.859 7.5181 11.6073 6.04342C12.3832 4.51104 12.2882 3.31179 11.8624 2.4806C10.9573 0.713415 8.44991 0.22754 7.08248 1.63316L6.49991 2.23279ZM6.49991 12.1875C-5.95815 3.95529 2.6641 -2.46996 6.35691 0.928727C6.40566 0.973414 6.4536 1.01973 6.49991 1.06766C6.54576 1.01977 6.59346 0.973694 6.64291 0.92954C10.3349 -2.47159 18.958 3.95448 6.49991 12.1875Z" />
                  </svg>
                </div>
              </button>
            </Link>
            <Link to="/checkout" className="relative inline-block">
              {" "}
              <svg
                className="lg:w-11 md:w-9 w-8 group"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="group-hover:fill-black group-hover:stroke-black"
                  cx="22"
                  cy="22"
                  r="21.5"
                  fill={location.pathname == "/checkout" ? "black" : "#B59679"}
                  stroke={
                    location.pathname == "/checkout" ? "black" : "#B59679"
                  }
                />
                <path
                  d="M12 14H15.0476L17.0895 24.2019C17.1592 24.5527 17.35 24.8678 17.6286 25.092C17.9072 25.3163 18.2558 25.4354 18.6133 25.4286H26.019C26.3766 25.4354 26.7252 25.3163 27.0038 25.092C27.2824 24.8678 27.4732 24.5527 27.5429 24.2019L28.7619 17.8095H15.8095M18.8571 29.2381C18.8571 29.6589 18.516 30 18.0952 30C17.6744 30 17.3333 29.6589 17.3333 29.2381C17.3333 28.8173 17.6744 28.4762 18.0952 28.4762C18.516 28.4762 18.8571 28.8173 18.8571 29.2381ZM27.2381 29.2381C27.2381 29.6589 26.897 30 26.4762 30C26.0554 30 25.7143 29.6589 25.7143 29.2381C25.7143 28.8173 26.0554 28.4762 26.4762 28.4762C26.897 28.4762 27.2381 28.8173 27.2381 29.2381Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="bg-black w-5 h-5 rounded-full  absolute top-2 right-1  transform translate-x-1/2 -translate-y-1/2">
                <p className="text-sm text-center text-white ">
                  {totalCartItems}
                </p>
              </div>
            </Link>
            <Link to="/my-account">
              <ProfileIcon
                bgColor={
                  location.pathname == "/my-account" ? "bg-black" : false
                }
                size="w-11 h-11"
              />
            </Link>
          </div>
        </div>

        {/*  Icon Menu Mobile */}
        <div className="md:hidden flex flex-row space-x-6">
          <svg
            onClick={() => {
              navigate("/filters");
            }}
            width="20"
            height="20"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
              stroke="#202020"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <Link to={"/checkout"} className="relative inline-block">
            <svg
              width="20"
              height="20"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H3.28571L4.81714 8.65143C4.8694 8.91451 5.01252 9.15083 5.22145 9.31903C5.43039 9.48722 5.69183 9.57657 5.96 9.57143H11.5143C11.7825 9.57657 12.0439 9.48722 12.2528 9.31903C12.4618 9.15083 12.6049 8.91451 12.6571 8.65143L13.5714 3.85714H3.85714M6.14286 12.4286C6.14286 12.7442 5.88702 13 5.57143 13C5.25584 13 5 12.7442 5 12.4286C5 12.113 5.25584 11.8571 5.57143 11.8571C5.88702 11.8571 6.14286 12.113 6.14286 12.4286ZM12.4286 12.4286C12.4286 12.7442 12.1727 13 11.8571 13C11.5416 13 11.2857 12.7442 11.2857 12.4286C11.2857 12.113 11.5416 11.8571 11.8571 11.8571C12.1727 11.8571 12.4286 12.113 12.4286 12.4286Z"
                stroke="#202020"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="bg-brown w-2.5 h-2.5 rounded-full  absolute top-0 right-0   transform translate-x-1/2 -translate-y-1/2">
              <p className="text-xxs text-center text-white "> {cart.length}</p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default NavBar;
