"use client";
import React from "react";
import { Tooltip } from "react-tooltip";

type Props = {
  id: string;
  content: string;
  place?:
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end";
};

const Popper = ({ id, content, place = "top" }: Props) => {
  return <Tooltip id={id} place={place} content={content} />;
};

export default Popper;
