import React from "react";
import NatureSVG from "../Svg/NatureSVG";
import Badge, { OrderBadge } from "../Common/Badge";

function LogisticEnquiries() {
  const [number, setNumber] = React.useState<string | undefined>("");
  return (
    <div>
      {/* BANNER START */}
      <div className="lg:bg-homeBanner  bg-homeBannerMobile bg-cover lg:min-h-[504px] -mt-10  bg-no-repeat grid grid-cols-12 py-8 pt-16   lg:py-0 lg:px-12">
        <div className="lg:col-start-1 lg:col-end-5 col-start-9  mt-2 col-end-13 self-center lg:mx-10">
          <p className="lg:text-2xl text-2xl text-black font-medium tracking-normal">
            Logistic
          </p>
        </div>

        <div className="hidden lg:block col-start-9 col-end-13  self-center mt-16 ">
          <p className=" lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">
            High quality perfume and{" "}
          </p>
          <p className="lg:text-xl text-sm text-black font-thin text-end tracking-[0.015em]">
            wide range of beauty products
          </p>
        </div>
      </div>
      {/* BANNER END */}
      <div className="flex flex-row lg:w-9/12 w-11/12 mx-auto checkout-block justify-center bg-white rounded-20 -mt-3 lg:-mt-16">
        <div className="flex flex-col basis-1/1 w-[80%] mx-auto pt-10 pb-10 lg:pt-24 lg:pb-20 gap-16">
          <Badge
            title="Efficient Logistics"
            subtitle="Seamless Logistics for Swift and Reliable Delivery Across Europe"
            content="At Stockperfume.com, we understand that efficient logistics are crucial to ensuring your business runs smoothly.
             Our advanced logistics network is designed to provide fast and reliable delivery across Europe and beyond. 
             With strategically located warehouses and a dedicated logistics team, we ensure that your orders are processed quickly and shipped with the utmost care. Our state-of-the-art
             tracking systems allow you to monitor your shipments in real-time, providing you with peace of mind and confidence in our services."
          />
          <div className="md:grid grid-cols-12 gap-14 w-full">
            <Badge
              className="col-span-full md:col-span-7 w-full"
              title="Cutting-Edge Warehousing Solutions"
              subtitle="Efficient Order Fulfillment and Real-Time Inventory Management"
              content="Our state-of-the-art warehouses are strategically located to ensure efficient 
              and timely delivery of your orders. We prioritize the safety and security of our products, implementing 
              rigorous quality control measures to maintain the highest standards. Our advanced inventory management system 
              allows us to keep track of stock levels in real-time, ensuring that we can fulfill your orders promptly and accurately."
            />
            <img
              className="hidden md:flex w-full h-full object-cover col-span-5 rounded-30"
              src="/images/logistic/boxes.png"
              alt="boxes"
            />
          </div>
          <Badge
            title="Our Commitment to Nature And Eco-Friendly Packaging"
            subtitle="Eco-Friendly Practices and Initiatives at Stockperfume.com"
            content="At Stockperfume.com, we understand the importance of preserving our planet. We actively engage in initiatives
             aimed at protecting the environment and promoting sustainable practices. at the same time Our packaging solutions are designed
              to minimize waste and reduce our carbon footprint. We use recyclable materials and eco-friendly practices to ensure that our operations have a minimal 
              impact on the environment. From reducing energy consumption in our warehouses to supporting reforestation projects,
             we are dedicated to making a positive impact. Our goal is to not only provide exceptional products but also to contribute to a healthier and more sustainable world."
            icon={<NatureSVG className="hidden md:flex" />}
          />
          <Badge
            title="Logistics Enquiries"
            subtitle="Frequently Asked Questions"
            content={
              <div className="flex flex-col gap-3">
                <OrderBadge
                  title="How to ship your order?"
                  number="01"
                  innerClassName="!py-7"
                  content={
                    <p>
                      At Stock Perfume we understand the importance of logistics
                      to being a key supplier. That’s why we’ve partnered with
                      UPS, one of the largest logistics companies in the world
                      and DPD to organize all of our parcel deliveries in Europe
                      and the rest of the world. In addition to that we also use
                      our partnership with DHL and Fedex. <br />
                      Whether your order is packed in a single box, multiple
                      boxes or on pallets; we offer our clients shipping options
                      to suit their needs.
                    </p>
                  }
                />
                <OrderBadge
                  title="How to track your order?"
                  number="02"
                  innerClassName="!py-6"
                  content={
                    <p>
                      Once the purchase has been dispatched, you will receive
                      the tracking number by e-mail or you can find it from your
                      account. The link can give you an idea of the status of
                      your order. <br />
                      Know that our team follows the smooth running of your
                      orders at each step and gets in touch with the carrier if
                      necessary to ensure delivery in the best conditions.
                    </p>
                  }
                />
                <OrderBadge
                  title="You need to change the delivery address?"
                  number="03"
                  innerClassName="!py-5"
                  content="We will be happy to assist you and change the delivery address 
                  if you notify our team before your order is shipped. Once dispatched,
                   it will no longer be possible to modify the delivery address."
                />
                <OrderBadge
                  title="What are the shipping costs?"
                  number="04"
                  innerClassName="!py-4"
                  content="Shipping costs vary depending on your delivery address as well as the quantity of product purchased.
                   You can check your shipping costs, you can always contact us for more assistance."
                />
                <OrderBadge
                  title="We do not ship to your country?"
                  number="05"
                  innerClassName="!py-3"
                  content="If your country is not listed and if you want to find out if our carriers ships to your country, please contact us."
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default LogisticEnquiries;
