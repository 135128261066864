import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch } from "react-redux";
import "../../index.css";
import { Link } from "react-router-dom";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../app/features/wishlist/wishlistSlice";
import WishListButton from "../Common/WishListButton";
import AddToCartButton from "../Common/AddToCartButton";

function ProductCard({ product, isInWishList }: any) {
  return (
    <div className="shadow-secondary m-2 bg-white  rounded-25 p-5 h-60  flex flex-row relative  ">
      <WishListButton product={product} className="absolute" />
      {/*    <AddToCartButton product={product} className="absolute right-4 top-4">
                <button
                    className={`inline-flex hover:animate-pulse items-center justify-center lg:w-6 lg:h-6 w-5 h-5 p-1 lg:p-1 rounded-full border border-solid bg-white`}>
                    <svg className="stroke-black" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.03101 1.05957H3.8063L5.66575 9.80406C5.72919 10.1047 5.90297 10.3748 6.15666 10.567C6.41035 10.7593 6.72778 10.8614 7.0534 10.8555H13.7974C14.123 10.8614 14.4404 10.7593 14.6941 10.567C14.9478 10.3748 15.1216 10.1047 15.185 9.80406L16.2951 4.32488H4.50012M7.27542 14.1208C7.27542 14.4815 6.96478 14.7739 6.5816 14.7739C6.19841 14.7739 5.88777 14.4815 5.88777 14.1208C5.88777 13.7601 6.19841 13.4677 6.5816 13.4677C6.96478 13.4677 7.27542 13.7601 7.27542 14.1208ZM14.9075 14.1208C14.9075 14.4815 14.5968 14.7739 14.2137 14.7739C13.8305 14.7739 13.5198 14.4815 13.5198 14.1208C13.5198 13.7601 13.8305 13.4677 14.2137 13.4677C14.5968 13.4677 14.9075 13.7601 14.9075 14.1208Z" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </button>
            </AddToCartButton> */}
      <Link
        to={`/shop/${product.slug}`}
        className="grid justify-items-center mx-auto  "
      >
        <img
          src={product?.image ? product.image : product?.images?.[0]}
          alt=""
          className="w-auto h-20"
        />
        <p className="mt-4 font-medium text-xs">{product?.brand?.name}</p>
        <p className=" text-center text-sm">{product?.name}</p>
        <p className="font-semibold text-base mt-4">
          € {product?.price?.toFixed(2)}
        </p>
      </Link>
    </div>
  );
}

export default ProductCard;
