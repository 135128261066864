export const info = {
  title: "React Redux Starter",
  description: "React Redux Starter",
  email: "info@stockperfume.com",
  vat: "FR 00 000000000",
  coc: "00000000000000",
  address: "Orteliuslaan 850, 3528BB Utrecht, The Netherlands",
  phone: "31621176665",
  formattedPhone: "+31 (6) 21 17 66 65",
  facebook: "https://www.facebook.com/stockperfume",
  twitter: "",
  instagram: "",
  skype: "live:.cid.ce16fff9e2b76de7",
};
