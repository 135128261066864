import React, { useEffect, useRef, useState } from "react";
import Filter from "./Filter";
import filterGenerator from "../../app/features/filter/actions/filterGenerator";
import { getProducts } from "../../app/features/product/actions/getProducts";
import { searchProductsList } from "../../app/features/product/actions/searchProductList";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetFilters,
  setIsSaved,
} from "../../app/features/filter/slices/filterSlice";
import {
  BsArrowDownCircle,
  BsCheckCircle,
  BsSearch,
  BsXCircle,
} from "react-icons/bs";
import Popper from "../Common/Popper";
import LoadingSpinner from "../Common/LoadingSpinner";
function Filters() {
  const [open, setOpen] = React.useState(true);
  const isSaved = useAppSelector((state: any) => state.filterReducer.isSaved);
  const dispatch = useAppDispatch();
  const selectedFilters = useAppSelector(
    (state: any) => state.filterReducer.selectedFilter
  );
  console.log(selectedFilters);
  useEffect(() => {
    setIsActivated(isSaved);
  }, [isSaved]);
  const [isActivated, setIsActivated] = useState(isSaved);
  useEffect(() => {
    dispatch(getProducts(filterGenerator(selectedFilters)));
  }, [selectedFilters]);

  const [search, setSearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const handleSearch = (event: any) => {
    if (event.target.value) {
      const searchText = event.target.value;
      setSearch(searchText);
    } else {
      setSearch("");
      setLoadingSearch(false);
    }
  };
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current += 1;
    if (renderCount.current >= 2) {
      const delayDebounceFn = setTimeout(() => {
        if (search.length > 1) {
          setLoadingSearch(true);
          dispatch(searchProductsList(search))
            .then(() => {
              setLoadingSearch(false);
            })
            .catch(() => {
              setLoadingSearch(false);
            });
        }
        if (search.length === 0) {
          dispatch(resetFilters());
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [search, dispatch]);

  const handleClick = () => {
    setIsActivated((state: any) => !state);
    dispatch(setIsSaved(!isSaved));
  };

  return (
    <>
      {loadingSearch && <LoadingSpinner />}
      <div className="lg:px-24 md:px-12 pl-4">
        <div className="grid grid-cols-6 lg:bg-white relative lg:shadow lg:rounded-50 w-full p-6 gap-4">
          <div className="col-span-6 lg:col-span-5 order-last lg:order-first">
            {open ? (
              <div className="hidden lg:block w-full">
                <label className="relative block">
                  <BsSearch className="absolute top-1/2 transform -translate-y-1/2 left-5" />
                  <input
                    onChange={handleSearch}
                    className="w-full bg-white placeholder:text-sm text-sm text-black placeholder:text-black
                 border   rounded-full h-10 pl-20 pr-4 outline-none"
                    placeholder="Type to find a product"
                    type="text"
                    value={search}
                  />
                </label>
              </div>
            ) : null}
            <div
              className={`${
                open ? "hidden" : "flex"
              }  justify-between gap-4 overflow-x-auto scrollbar-hide scrolling-touch pr-4 md:pr-0 w-full`}
            >
              <Filter type="category" />
              <Filter type="pa_brand" />
              <Filter type="pa_gender" />
              <Filter type="pa_color" />
              <Filter type="pa_size" />
              {/*  <Filter type="pa_stock" /> */}
            </div>
          </div>
          <div className="lg:col-span-1 self-end lg:self-start col-span-6">
            <div className="flex justify-between py-3 lg:py-0 items-center">
              <Popper id="clear-filters" content="Clear filters" place="top" />
              <button
                data-tooltip-id="clear-filters"
                onClick={() => dispatch(resetFilters())}
                className="border bg-white group focus:bg-brown w-10 h-10 rounded-full flex items-center place-content-center"
              >
                <BsXCircle className="group-focus:text-white text-md" />
              </button>
              <Popper id="apply-filter" content="Apply filters" place="top" />
              <button
                data-tooltip-id="apply-filter"
                onClick={() => {
                  handleClick();
                }}
                className={`border bg-white group flex items-center place-content-center ${
                  isActivated && "bg-brown"
                }  w-10 h-10  rounded-full`}
              >
                <BsCheckCircle
                  className={`text-md ${
                    isActivated ? "text-white" : "text-black"
                  }`}
                />
              </button>
              <Popper id="save-filter" content="Save filters" place="top" />
              <button
                data-tooltip-id="save-filter"
                className={`border bg-white group flex items-center place-content-center w-10 h-10 rounded-full focus:bg-brown`}
              >
                <BsArrowDownCircle
                  className={`text-md group-focus:text-white text-black`}
                />
              </button>
            </div>
          </div>
          <div
            className={`col-span-full ${
              open ? "grid" : "hidden"
            }  grid-cols-5 gap-4 w-full`}
          >
            <Filter type="category" />
            <Filter type="pa_brand" />
            <Filter type="pa_gender" />
            <Filter type="pa_color" />
            <Filter type="pa_size" />
            {/*  <Filter type="pa_stock" /> */}
          </div>
          <div className="hidden lg:block absolute right-28 bottom-0">
            <button
              onClick={() => {
                setOpen((state) => !state);
              }}
              className="bg-black px-6 py-[0.4rem] rounded-t-md "
            >
              <svg
                className={!open ? "transform rotate-180" : ""}
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.887 6.27238C10.8513 6.30819 10.8089 6.3366 10.7621 6.35599C10.7154 6.37538 10.6653 6.38536 10.6147 6.38536C10.5642 6.38536 10.5141 6.37538 10.4673 6.35599C10.4206 6.3366 10.3782 6.30819 10.3425 6.27238L5.9999 1.92904L1.65733 6.27238C1.58512 6.34459 1.48718 6.38516 1.38506 6.38516C1.28293 6.38516 1.18499 6.34459 1.11278 6.27238C1.04057 6.20016 1 6.10222 1 6.0001C1 5.89798 1.04057 5.80004 1.11278 5.72782L5.72762 1.11298C5.76335 1.07717 5.80578 1.04875 5.85251 1.02937C5.89923 1.00998 5.94932 1 5.9999 1C6.05048 1 6.10057 1.00998 6.14729 1.02937C6.19401 1.04875 6.23645 1.07717 6.27217 1.11298L10.887 5.72782C10.9228 5.76355 10.9512 5.80599 10.9706 5.85271C10.99 5.89943 11 5.94952 11 6.0001C11 6.05068 10.99 6.10077 10.9706 6.14749C10.9512 6.19421 10.9228 6.23665 10.887 6.27238Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
