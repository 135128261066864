import React from "react";
import { TfiPlus } from "react-icons/tfi";
import { AiOutlineMinus } from "react-icons/ai";

function QuestionAccordion({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className=" border rounded-20  px-5 lg:px-10 py-2">
      <div className="flex items-center">
        <div className="mr-auto lg:pl-4 text-sm font-normal ">{question}</div>
        <div className="ml-auto -mr-4 ">
          {
            <button
              onClick={() => {
                setOpen((state) => !state);
              }}
              className="group hover:bg-brown bg-black hover:text-white border p-3 rounded-full"
            >
              {open ? (
                <AiOutlineMinus className="fill-white" />
              ) : (
                <TfiPlus className="fill-white" />
              )}
            </button>
          }
        </div>
      </div>
      {open && (
        <div className="py-2 self-center">
          <p className="ml-4 text-xs  leading-6 font-normal">{answer}</p>
        </div>
      )}
    </div>
  );
}

export default QuestionAccordion;
