import React from "react";
import priceFormatter from "../../functions/priceFormatter";

type Props = {
  price: number;
  className?: string;
};

const PriceInfo = ({ price, className }: Props) => {
  return (
    <div
      className={`flex items-center place-content-center w-20 h-10 rounded-100 bg-gray ${className}`}
    >
      <p className={`text-sm font-bold text-black`}>{priceFormatter(price)}</p>
    </div>
  );
};

export default PriceInfo;
