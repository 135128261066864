import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { sortProducts } from "../../app/features/product/slices/productSlice";
import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";
import SortBatch from "../Common/SortBatch";

function Sort() {
  const dispatch = useAppDispatch();
  const [sort, setSort] = React.useState("default");

  const handleSort = (sortType: string) => {
    if (sort !== sortType) {
      dispatch(sortProducts(sortType));
      setSort(sortType);
    } else {
      dispatch(sortProducts("id"));
      setSort("default");
    }
  };

  return (
    <div className="lg:grid lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 hidden bg-white items-center h-16 rounded-20 shadow">
      <div className="col-span-2 flex place-content-center">
        <SortBatch
          onClick={() => handleSort("name")}
          active={sort === "name"}
          type="Product"
        />
      </div>
      <div className="col-span-1 flex place-content-center">
        <SortBatch
          onClick={() => handleSort("brand")}
          active={sort === "brand"}
          type="Brand"
        />
      </div>
      <div className="col-span-1 flex place-content-center">
        <SortBatch
          onClick={() => handleSort("ean")}
          active={sort === "ean"}
          type="Barcode"
        />
      </div>

      <div className="col-span-1 flex place-content-center">
        <SortBatch
          onClick={() => handleSort("size")}
          active={sort === "size"}
          type="Size"
        />
      </div>
       <div className="col-span-1 hidden 2xl:flex place-content-center">
        <SortBatch
          onClick={() => handleSort("stock_quantity")}
          active={sort === "stock_quantity"}
          type="Quantity"
        />
      </div> 
      <div className="col-span-1 lg:hidden xl:flex place-content-center">
        <SortBatch
          onClick={() => handleSort("price")}
          active={sort === "price"}
          type="Price"
        />
      </div>
      <div className="col-span-1 flex place-content-center">
        <p className="text-sm font-semibold text-black">Add to cart</p>
      </div>
    </div>
  );
}

export default Sort;
