import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSaved,
  updateFilter,
} from "../../app/features/filter/slices/filterSlice";
import { useEffect } from "react";
import { getBrandFilters } from "../../app/features/filter/actions/getBrandFilters";
import { getCategoryFilters } from "../../app/features/filter/actions/getCategoryFilters";
import { getMemoryFilters } from "../../app/features/filter/actions/getMemoryFilters";
import { getColorFilters } from "../../app/features/filter/actions/getColorFilters";
import { getPriceFilters } from "../../app/features/filter/actions/getPriceFilters";
import { getGenderFilters } from "../../app/features/filter/actions/getGenderFilters";
import Cookies from "js-cookie";

function Filter({ type }: { type: string }) {
  const selectRef = React.useRef(null);

  const filters = {
    orderby: { name: "orderby_list", placeHolder: "Sort by" },
    pa_color: { name: "color_list", placeHolder: "Color" },
    pa_brand: { name: "brand_list", placeHolder: "Brand" },
    pa_gender: { name: "gender_list", placeHolder: "Gender" },
    category: { name: "category_list", placeHolder: "Category" },
    pa_size: { name: "memory_list", placeHolder: "Size" },
    order: { name: "order_list", placeHolder: "Order" },
  };
  const [selected, setSelected] = React.useState("default");
  const selectedFilter = useSelector(
    (state: any) => state.filterReducer?.selectedFilter[type]
  );
  /* const [isActivated, setIsActivated] = React.useState(false); */

  // @ts-ignore
  const items = filters[type]?.name;

  const dispatch = useDispatch();
  let list = useSelector((state: any) => state.filterReducer?.[items]);

  let filteredListforCategory = list.filter((item) => {
    // Regex kullanarak, ismin sadece sayılardan ve '/' karakterlerinden oluşup oluşmadığını kontrol ediyoruz.
    // Eğer öyleyse, bu öğeyi listeye dahil etmiyoruz.
    return !/^\d+(\/\d+)+$/.test(item.name);
  });

  function fixAmpersand(data) {
    return data.map((item) => {
      return { ...item, name: item.name.replace(/&amp;/g, "&") };
    });
  }
  function truncateName(name) {
    if (name.length > 35) {
      return name.substring(0, 34) + "...";
    }
    return name;
  }
  list = list.map((item) => {
    return { ...item, name: truncateName(item.name) };
  });
  list = fixAmpersand(list);
  filteredListforCategory = fixAmpersand(filteredListforCategory);
  const handleChange = (e: any) => {
    setSelected(e.target.value);
    /* setIsActivated(false); */
  };

  /*   const handleClick = () => {
    if (!isActivated) {
      dispatch(updateFilter({ filterType: type, value: selected }));
    } else {
      dispatch(updateFilter({ filterType: type, value: null }));
      setSelected("default");
      dispatch(setIsSaved(false));
    }
    setIsActivated(!isActivated);
  }; */

  useEffect(() => {
    if (selectedFilter) {
      /*   setIsActivated(true); */
      setSelected(selectedFilter);
    } else {
      const filters = Cookies.get("sp-filters");

      if (
        filters &&
        filters !== "undefined" &&
        filters !== "null" &&
        filters !== ""
      ) {
        const parsedFilters = JSON.parse(filters);
        if (parsedFilters[type]) {
          setSelected(parsedFilters[type]);
        } else {
          setSelected("default");
        }
      } else {
        setSelected("default");
      }
    }
    // @ts-ignore
    dispatch(getGenderFilters());
    // @ts-ignore
    dispatch(getBrandFilters());
    // @ts-ignore
    dispatch(getCategoryFilters());
    // @ts-ignore
    dispatch(getMemoryFilters());
    // @ts-ignore
    dispatch(getColorFilters());
    //@ts-ignore
    dispatch(getPriceFilters());
  }, [selectedFilter]);

  useEffect(() => {
    if (selected !== "default") {
      dispatch(updateFilter({ filterType: type, value: selected }));
    } else {
      dispatch(updateFilter({ filterType: type, value: null }));
    }
  }, [selected]);

  return (
    <div className="flex items-center bg-white rounded-100 border w-full h-10">
      {/*  <button
        onClick={() => handleClick()}
        className={`border-2 group ${
          isActivated && "bg-brown"
        }  border-[#F1F1F1] p-1.5 md:p-2 lg:p-3 rounded-full`}
      >
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`fill-current  ${
            isActivated && "text-white"
          } w-3 h-3 md:w-4 md:h-4`}
        >
          <path d="M0 0.571444C0 0.419888 0.0602055 0.274539 0.167372 0.167372C0.274539 0.0602055 0.419888 0 0.571444 0H14.2861C14.4377 0 14.583 0.0602055 14.6902 0.167372C14.7973 0.274539 14.8575 0.419888 14.8575 0.571444V2.85722C14.8575 2.99815 14.8054 3.1341 14.7113 3.23895L9.71455 8.7911V14.2861C9.71446 14.406 9.67666 14.5228 9.60652 14.62C9.53637 14.7172 9.43742 14.7899 9.32368 14.8278L5.89502 15.9707C5.80916 15.9993 5.71773 16.0071 5.62827 15.9935C5.5388 15.9798 5.45386 15.9451 5.38043 15.8922C5.307 15.8393 5.24719 15.7697 5.20591 15.6892C5.16463 15.6087 5.14307 15.5195 5.143 15.429V8.7911L0.14629 3.23895C0.052125 3.1341 2.53154e-05 2.99815 0 2.85722V0.571444ZM1.14289 1.14289V2.63779L6.1396 8.18994C6.23376 8.29479 6.28586 8.43073 6.28588 8.57166V14.6358L8.57166 13.8747V8.57166C8.57169 8.43073 8.62379 8.29479 8.71795 8.18994L13.7147 2.63779V1.14289H1.14289Z" />
        </svg>
      </button> */}

      <select
        ref={selectRef}
        value={selected}
        onChange={(e) => {
          handleChange(e);
        }}
        className="text-sm text-black text-center w-full h-full md:text-sm rounded-100 pl-1.5 pr-1.5 md:pl-4 md:pr-4
        focus:outline-none hover:cursor-pointer"
      >
        <option value="default">
          {
            // @ts-ignore
            filters?.[type]?.placeHolder
          }
        </option>

        {type === "category" && Array.isArray(filteredListforCategory)
          ? filteredListforCategory?.map((item: any) => {
              return (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              );
            })
          : Array.isArray(list)
          ? list?.map((item: any) => {
              return (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              );
            })
          : null}
      </select>
    </div>
  );
}

export default Filter;
