 

const countries = [
  ["Afghanistan","AF"  ],
  ["Albania","AL"  ],
  ["Algeria","DZ"  ],
  ["Andorra","AD"  ],
  ["Angola","AO"  ],
  ["Anguilla","AI"  ],
  ["Antarctica","AQ"  ],
  ["Antigua and Barbuda","AG"  ],
  ["Argentina","AR"  ],
  ["Armenia","AM"  ],
  ["Aruba","AW"  ],
  ["Australia","AU"  ],
  ["Austria","AT"  ],
  ["Azerbaijan","AZ"  ],
  ["Bahamas","BS"  ],
  ["Bahrain","BH"  ],
  ["Bangladesh","BD"  ],
  ["Barbados","BB"  ],
  ["Belarus","BY"  ],
  ["Belau","PW"  ],
  ["Belgium","BE"  ],
  ["Belize","BZ"  ],
  ["Benin","BJ"  ],
  ["Bermuda","BM"  ],
  ["Bhutan","BT"  ],
  ["Bolivia","BO"  ],
  ["Bonaire, Saint Eustatius and Saba","BQ"  ],
  ["Bosnia and Herzegovina","BA"  ],
  ["Botswana","BW"  ],
  ["Bouvet Island","BV"  ],
  ["Brazil","BR"  ],
  ["British Indian Ocean Territory","IO"  ],
  ["British Virgin Islands","VG"  ],
  ["Brunei","BN"  ],
  ["Bulgaria","BG"  ],
  ["Burkina Faso","BF"  ],
  ["Burundi","BI"  ],
  ["Cambodia","KH"  ],
  ["Cameroon","CM"  ],
  ["Canada","CA"  ],
  ["Cape Verde","CV"  ],
  ["Cayman Islands","KY"  ],
  ["Central African Republic","CF"  ],
  ["Chad","TD"  ],
  ["Chile","CL"  ],
  ["China","CN"  ],
  ["Christmas Island","CX"  ],
  ["Cocos (Keeling) Islands","CC"  ],
  ["Colombia","CO"  ],
  ["Comoros","KM"  ],
  ["Congo (Brazzaville)","CG"  ],
  ["Congo (Kinshasa)","CD"  ],
  ["Cook Islands","CK"  ],
  ["Costa Rica","CR"  ],
  ["Croatia","HR"  ],
  ["Cuba","CU"  ],
  ["CuraÇao","CW"  ],
  ["Cyprus","CY"  ],
  ["Czech Republic","CZ"  ],
  ["Denmark","DK"  ],
  ["Djibouti","DJ"  ],
  ["Dominica","DM"  ],
  ["Dominican Republic","DO"  ],
  ["Ecuador","EC"  ],
  ["Egypt","EG"  ],
  ["El Salvador","SV"  ],
  ["Equatorial Guinea","GQ"  ],
  ["Eritrea","ER"  ],
  ["Estonia","EE"  ],
  ["Ethiopia","ET"  ],
  ["Falkland Islands","FK"  ],
  ["Faroe Islands","FO"  ],
  ["Fiji","FJ"  ],
  ["Finland","FI"  ],
  ["France","FR"  ],
  ["French Guiana","GF"  ],
  ["French Polynesia","PF"  ],
  ["French Southern Territories","TF"  ],
  ["Gabon","GA"  ],
  ["Gambia","GM"  ],
  ["Georgia","GE"  ],
  ["Germany","DE"  ],
  ["Ghana","GH"  ],
  ["Gibraltar","GI"  ],
  ["Greece","GR"  ],
  ["Greenland","GL"  ],
  ["Grenada","GD"  ],
  ["Guadeloupe","GP"  ],
  ["Guatemala","GT"  ],
  ["Guernsey","GG"  ],
  ["Guinea","GN"  ],
  ["Guinea-Bissau","GW"  ],
  ["Guyana","GY"  ],
  ["Haiti","HT"  ],
  ["Heard Island and McDonald Islands","HM"  ],
  ["Honduras","HN"  ],
  ["Hong Kong","HK"  ],
  ["Hungary","HU"  ],
  ["Iceland","IS"  ],
  ["India","IN"  ],
  ["Indonesia","ID"  ],
  ["Iran","IR"  ],
  ["Iraq","IQ"  ],
  ["Isle of Man","IM"  ],
  ["Israel","IL"  ],
  ["Italy","IT"  ],
  ["Ivory Coast","CI"  ],
  ["Jamaica","JM"  ],
  ["Japan","JP"  ],
  ["Jersey","JE"  ],
  ["Jordan","JO"  ],
  ["Kazakhstan","KZ"  ],
  ["Kenya","KE"  ],
  ["Kiribati","KI"  ],
  ["Kuwait","KW"  ],
  ["Kyrgyzstan","KG"  ],
  ["Laos","LA"  ],
  ["Latvia","LV"  ],
  ["Lebanon","LB"  ],
  ["Lesotho","LS"  ],
  ["Liberia","LR"  ],
  ["Libya","LY"  ],
  ["Liechtenstein","LI"  ],
  ["Lithuania","LT"  ],
  ["Luxembourg","LU"  ],
  ["Macao S.A.R., China","MO"  ],
  ["Macedonia","MK"  ],
  ["Madagascar","MG"  ],
  ["Malawi","MW"  ],
  ["Malaysia","MY"  ],
  ["Maldives","MV"  ],
  ["Mali","ML"  ],
  ["Malta","MT"  ],
  ["Marshall Islands","MH"  ],
  ["Martinique","MQ"  ],
  ["Mauritania","MR"  ],
  ["Mauritius","MU"  ],
  ["Mayotte","YT"  ],
  ["Mexico","MX"  ],
  ["Micronesia","FM"  ],
  ["Moldova","MD"  ],
  ["Monaco","MC"  ],
  ["Mongolia","MN"  ],
  ["Montenegro","ME"  ],
  ["Montserrat","MS"  ],
  ["Morocco","MA"  ],
  ["Mozambique","MZ"  ],
  ["Myanmar","MM"  ],
  ["Namibia","NA"  ],
  ["Nauru","NR"  ],
  ["Nepal","NP"  ],
  ["Netherlands","NL"  ],
  ["Netherlands Antilles","AN"  ],
  ["New Caledonia","NC"  ],
  ["New Zealand","NZ"  ],
  ["Nicaragua","NI"  ],
  ["Niger","NE"  ],
  ["Nigeria","NG"  ],
  ["Niue","NU"  ],
  ["Norfolk Island","NF"  ],
  ["North Korea","KP"  ],
  ["Norway","NO"  ],
  ["Oman","OM"  ],
  ["Pakistan","PK"  ],
  ["Palestinian Territory","PS"  ],
  ["Panama","PA"  ],
  ["Papua New Guinea","PG"  ],
  ["Paraguay","PY"  ],
  ["Peru","PE"  ],
  ["Philippines","PH"  ],
  ["Pitcairn","PN"  ],
  ["Poland","PL"  ],
  ["Portugal","PT"  ],
  ["Qatar","QA"  ],
  ["Republic of Ireland","IE"  ],
  ["Reunion","RE"  ],
  ["Romania","RO"  ],
  ["Russia","RU"  ],
  ["Rwanda","RW"  ],
  ["São Tomé and Príncipe","ST"  ],
  ["Saint Barthélemy","BL"  ],
  ["Saint Helena","SH"  ],
  ["Saint Kitts and Nevis","KN"  ],
  ["Saint Lucia","LC"  ],
  ["Saint Martin (Dutch part)","SX"  ],
  ["Saint Martin (French part)","MF"  ],
  ["Saint Pierre and Miquelon","PM"  ],
  ["Saint Vincent and the Grenadines","VC"  ],
  ["San Marino","SM"  ],
  ["Saudi Arabia","SA"  ],
  ["Senegal","SN"  ],
  ["Serbia","RS"  ],
  ["Seychelles","SC"  ],
  ["Sierra Leone","SL"  ],
  ["Singapore","SG"  ],
  ["Slovakia","SK"  ],
  ["Slovenia","SI"  ],
  ["Solomon Islands","SB"  ],
  ["Somalia","SO"  ],
  ["South Africa","ZA"  ],
  ["South Georgia/Sandwich Islands","GS"  ],
  ["South Korea","KR"  ],
  ["South Sudan","SS"  ],
  ["Spain","ES"  ],
  ["Sri Lanka","LK"  ],
  ["Sudan","SD"  ],
  ["Suriname","SR"  ],
  ["Svalbard and Jan Mayen","SJ"  ],
  ["Swaziland","SZ"  ],
  ["Sweden","SE"  ],
  ["Switzerland","CH"  ],
  ["Syria","SY"  ],
  ["Taiwan","TW"  ],
  ["Tajikistan","TJ"  ],
  ["Tanzania","TZ"  ],
  ["Thailand","TH"  ],
  ["Timor-Leste","TL"  ],
  ["Togo","TG"  ],
  ["Tokelau","TK"  ],
  ["Tonga","TO"  ],
  ["Trinidad and Tobago","TT"  ],
  ["Tunisia","TN"  ],
  ["Turkiye","TR"  ],
  ["Turkmenistan","TM"  ],
  ["Turks and Caicos Islands","TC"  ],
  ["Tuvalu","TV"  ],
  ["Uganda","UG"  ],
  ["Ukraine","UA"  ],
  ["United Arab Emirates","AE"  ],
  ["United Kingdom (UK)","GB"  ],
  ["United States (US)","US"  ],
  ["Uruguay","UY"  ],
  ["Uzbekistan","UZ"  ],
  ["Vanuatu","VU"  ],
  ["Vatican","VA"  ],
  ["Venezuela","VE"  ],
  ["Vietnam","VN"  ],
  ["Wallis and Futuna","WF"  ],
  ["Western Sahara","EH"  ],
  ["Western Samoa","WS"  ],
  ["Yemen","YE"  ],
  ["Zambia","ZM"  ],
  ["Zimbabwe","ZW"  ]
]

export const european_countries = [{ code: "AT" , name: "Austuria" }, { code: "BE" , name: "Belgium"}, { code: "BG" ,name: "Bulgaria"}, { code: "CY" ,name: "Cyprus"}, { code: "CZ" ,name: "Czechia"}, { code: "DE" ,name: "Germany"}, { code: "DK" ,name: "Denmark"}, { code: "EE" ,name: "Estonia"}, { code: "ES" ,name: "Spain"}, { code: "FI" ,name: "Finland"}, { code: "FR" ,name: "France"}, { code: "GR" ,name: "Greece"}, { code: "HR" ,name: "Crotia"}, { code: "HU" ,name: "Hungary"}, { code: "IE" ,name: "Ireland"}, { code: "IT" ,name: "Italia"}, { code: "LT" ,name: "Lithuania"}, { code: "LU" ,name: "Luxemburg"}, { code: "LV" ,name: "Latvia"}, { code: "MT" ,name: "Malta"}, { code: "NL" ,name: "Netherlands"}, { code: "PL" ,name: "Poland"}, { code: "PT" ,name: "Portugal"}, { code: "RO" ,name: "Romania"}, { code: "SE" ,name: "Sweden"}, { code: "SI" ,name: "Slovenia"},{ code: "SK" ,name: "Slovakia"},{code:"US",name:"United States (US)"}];

export const european_countries_vat = [{ code: "AT" , name: "Austuria" }, { code: "BE" , name: "Belgium"}, { code: "BG" ,name: "Bulgaria"}, { code: "CY" ,name: "Cyprus"}, { code: "CZ" ,name: "Czechia"}, { code: "DE" ,name: "Germany"}, { code: "DK" ,name: "Denmark"}, { code: "EE" ,name: "Estonia"}, { code: "ES" ,name: "Spain"}, { code: "FI" ,name: "Finland"}, { code: "FR" ,name: "France"}, { code: "EL" ,name: "Greece"}, { code: "HR" ,name: "Crotia"}, { code: "HU" ,name: "Hungary"}, { code: "IE" ,name: "Ireland"}, { code: "IT" ,name: "Italia"}, { code: "LT" ,name: "Lithuania"}, { code: "LU" ,name: "Luxemburg"}, { code: "LV" ,name: "Latvia"}, { code: "MT" ,name: "Malta"}, { code: "NL" ,name: "Netherlands"}, { code: "PL" ,name: "Poland"}, { code: "PT" ,name: "Portugal"}, { code: "RO" ,name: "Romania"}, { code: "SE" ,name: "Sweden"}, { code: "SI" ,name: "Slovenia"},{ code: "SK" ,name: "Slovakia"},{code:"US",name:"United States (US)"}];

export default countries