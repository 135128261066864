import React, { useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Navigation} from "swiper";
import ReviewCard from "./ReviewCard";
import {customerReviews} from "../../constants/customerReviews";


function CustomerReviews() {
    const [myIndex, setMyIndex] = useState(0);

    return (
        <div className="relative h-80">
            <h2 className="text-center  font-semibold text-lg py-10">Our customers says</h2>
            <svg className="z-20 lg:left-16 md:left-10 left-5 absolute top-[60%] review-swiper-button-prev hover:cursor-pointer" width="56"
                 height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_89_1591)">
                    <circle cx="25" cy="25" r="17" transform="rotate(180 25 25)" fill="#202020"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M26.8871 20.113C26.9229 20.1487 26.9514 20.1911 26.9707 20.2379C26.9901 20.2846 27.0001 20.3347 27.0001 20.3853C27.0001 20.4358 26.9901 20.4859 26.9707 20.5327C26.9514 20.5794 26.9229 20.6218 26.8871 20.6575L22.5438 25.0001L26.8871 29.3427C26.9593 29.4149 26.9999 29.5128 26.9999 29.6149C26.9999 29.7171 26.9593 29.815 26.8871 29.8872C26.8149 29.9594 26.717 30 26.6148 30C26.5127 30 26.4148 29.9594 26.3426 29.8872L21.7277 25.2724C21.6919 25.2367 21.6635 25.1942 21.6441 25.1475C21.6247 25.1008 21.6147 25.0507 21.6147 25.0001C21.6147 24.9495 21.6247 24.8994 21.6441 24.8527C21.6635 24.806 21.6919 24.7635 21.7277 24.7278L26.3426 20.113C26.3783 20.0772 26.4207 20.0488 26.4675 20.0294C26.5142 20.01 26.5643 20 26.6148 20C26.6654 20 26.7155 20.01 26.7622 20.0294C26.809 20.0488 26.8514 20.0772 26.8871 20.113Z"
                      fill="white" stroke="white" stroke-width="0.5"/>
                <defs>
                    <filter id="filter0_d_89_1591" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feMorphology radius="3" operator="dilate" in="SourceAlpha"
                                      result="effect1_dropShadow_89_1591"/>
                        <feOffset dx="3" dy="3"/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix"
                                       values="0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_1591"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_1591" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <svg className="z-20 lg:right-16 md:right-10 right-5  absolute top-[60%]  review-swiper-button-next hover:cursor-pointer"
                 width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_89_1588)">
                    <circle cx="25" cy="25" r="17" fill="#202020"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.113 29.887C23.0772 29.8513 23.0488 29.8089 23.0294 29.7621C23.01 29.7154 23 29.6653 23 29.6147C23 29.5642 23.01 29.5141 23.0294 29.4673C23.0488 29.4206 23.0772 29.3782 23.113 29.3425L27.4563 24.9999L23.113 20.6573C23.0408 20.5851 23.0002 20.4872 23.0002 20.3851C23.0002 20.2829 23.0408 20.185 23.113 20.1128C23.1852 20.0406 23.2832 20 23.3853 20C23.4874 20 23.5853 20.0406 23.6576 20.1128L28.2724 24.7276C28.3082 24.7633 28.3366 24.8058 28.356 24.8525C28.3754 24.8992 28.3854 24.9493 28.3854 24.9999C28.3854 25.0505 28.3754 25.1006 28.356 25.1473C28.3366 25.194 28.3082 25.2365 28.2724 25.2722L23.6576 29.887C23.6218 29.9228 23.5794 29.9512 23.5327 29.9706C23.4859 29.99 23.4359 30 23.3853 30C23.3347 30 23.2846 29.99 23.2379 29.9706C23.1912 29.9512 23.1487 29.9228 23.113 29.887Z"
                      fill="white" stroke="white" stroke-width="0.5"/>
                <defs>
                    <filter id="filter0_d_89_1588" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feMorphology radius="3" operator="dilate" in="SourceAlpha"
                                      result="effect1_dropShadow_89_1588"/>
                        <feOffset dx="3" dy="3"/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix"
                                       values="0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0 0.827451 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_89_1588"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_89_1588" result="shape"/>
                    </filter>
                </defs>
            </svg>

            <i className="icon-arrow-long-left review-swiper-button-prev"></i>

            <Swiper
                modules={[Navigation]}
                onSlideChange={(e) => setMyIndex(e.realIndex)}
                slidesPerView={3}
                centeredSlides={false}
                spaceBetween={25}
                grabCursor={false}
                loop={true}
                breakpoints={{
                    // when window width is >= 640px
                    140: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                    },
                    960: {
                        slidesPerView: 3,
                    },
                }}
                navigation={{
                    nextEl: '.review-swiper-button-next',
                    prevEl: '.review-swiper-button-prev',
                }}


                className="mySwiper lg:mx-44 lg:mb-20 md:mx-22 md:mb-10 mx-10 sm:mb-5 p-3"
            >
                {customerReviews.map((review, index) => {
                    return (
                        <SwiperSlide  key={index}>
                            <ReviewCard review={review} zoom={myIndex+1 === index}/>
                        </SwiperSlide>
                    )
                })
                }

            </Swiper>

        </div>
    );
}

export default CustomerReviews;