import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {store} from "../../../../index";
import Cookies from "js-cookie";

export const changePassword = createAsyncThunk(
    "user/changePassword",
    async (args: any,thunkAPI) => {
       
        try {
            const state = store.getState();

            const token = Cookies.get("sp-token");
            const response = await axios.post(
                `https://stockperfume.com/wp/wp-json/wp/v3/user/password_reset?JWT=${token}&new_password=${args.new_password}
                &AUTH_KEY=tCV!^654i8IcMB^&current_password=${args.current_password}`
            );
       
               
            return response.data;
        } catch (error: any) {
  
            return thunkAPI.rejectWithValue({ error: error.response });
        }
    });