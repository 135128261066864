import React from 'react';
import {Page} from "../../constants";
import {Link} from "react-router-dom";

function MenuItem({page}: { page: Page }) {
    
    return (
        <div className='group'>
            <div 
             className="  relative flex flex-row ">
            {
                typeof page.path === "string" ?
                    (<Link className="uppercase z-20 hover:text-brown" to={page.path}>{page.name}</Link>)
                    : (<span className="uppercase hover:text-brown">{page.name}</span>)
            }
            {page.dropDownComponent && (
                <svg className="my-auto ml-1.5" width="8" height="5" viewBox="0 0 8 5" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.34042L4 4.34042L7 1.34042" stroke="#202020" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            )}
            
            <div className='hidden group-hover:block'>
            {page.dropDownComponent}
            </div>
        </div>
        </div>
    )
}

export default MenuItem;