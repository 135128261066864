import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { productsReducer } from "./features/product/slices/productSlice";
import { cartReducer } from "./features/cart/slices/cartSlice";
import { filterReducer } from "./features/filter/slices/filterSlice";
import { searchReducer } from "./features/product/slices/searchSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { singleProductReducer } from "./features/product/slices/singleProductSlice";
import { userReducer } from "./features/user/userSlice";
import { ordersReducer } from "./features/order/slices/orderSlice";
import { contactReducer } from "./features/contact/contactSlice";
import { wishlistReducer } from "./features/wishlist/wishlistSlice";

const rootReducers = combineReducers({
  productsReducer,
  cartReducer,
  singleProductReducer,
  userReducer,
  filterReducer,
  searchReducer,
  ordersReducer,
  contactReducer,
  wishlistReducer,
});
export const setupStore: any = () => {
  return configureStore({
    reducer: rootReducers,
  });
};

export type AppDispatch = typeof setupStore.dispatch;
export type RootState = ReturnType<typeof setupStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
